import { Comment } from '../../atlasguides-web-common/src/functions/comments/dto/comment.dto'
import { GetCommentsResponse } from 'atlasguides-web-common/src/functions/comments/get-comments'


type Item = {
    time: number,
	comments: Comment[]
}

export class CommentsStore {
    private lifetimeMunutes = 30;
    private map = new Map();

    public hasCommentsForWaypoint(waypointId: string): boolean {
        return this.map.has(waypointId);
    }

    public add(response: GetCommentsResponse) {
        this.review();

        const item: Item = {
            time: new Date().getTime(),
            comments: response.comments
        }
        return this.map.set(response.waypointId, item);
    }

    public getComments(waypointId: string): Comment[]{
        const item: Item = this.map.get(waypointId)
        return item.comments;
    }

    public review(){
        for (var key of this.map.keys()) {
            const item: Item = this.map.get(key)
            if (item){
                const timespan = (new Date().getTime() - item.time) / 1000 / 60
                if (timespan > this.lifetimeMunutes){
                    this.map.delete(key);
                }
            }
        }
    }
}

export const commentsStore = new CommentsStore()