export enum GuideType {
    FreeDemo = 'Free-Demo',
    FreeStandalone = 'Free-Standalone',
    SKU_BUNDLER_ONLY = 'SKU-BundlerOnly',
    SKU_HAS_BUNDLE_NO_GEO = 'SKU-HasBundleNoGeo',
    SKU_HAS_BUNDLE_HAS_GEO = 'SKU-HasBundleHasGeo',
    SKU_IN_BUNDLE_SECTION = 'SKU-InBundleSection',
    SKU_IN_BUNDLE_NETWORK_SECTION = 'SKU-InBundleNetworkSection',
    SKU_IN_BUNDLE_STANDALONE = 'SKU-InBundleStandalone',
    NoSkuInBundle = 'noSKU-InBundle',
    SKU_STANDALONE = 'SKU-Standalone',
}

export interface ParentGuideInfo {
    guideId: string
    guideName: string
    isPurchased: boolean
    guidePrice: {
        regular: number
        final: number
        currencyCode: string
    }
    discount: {
        amount: number
        percent: number
    }
    parentGuideIds?: string[] | null    
}
export interface GuideDto {
    shopifyVariantId: string
    guideId: string
    guideName: string
    guideType: GuideType

    guideIsFree: boolean
    guidePrice: {
        regular: number
        final: number
        currencyCode: string
    }
    discount: {
        amount: number
        percent: number
    }
    guideIcon: string
    isPurchased: boolean

    guidePreviewCoordinates: PolygonCoordinates | MultiLineStringCoordinates | PlentyOfMultiLineStringCoordinates
    guidePromoText: string
    guideLength: {
        meters: number
        kilometers: number
        miles: number
    }
    guideBounds: GuideBoudns
    hasChildren: boolean

    childGuides?: GuideDto[]
    childGuidesIds: string[] | null

    guideTags: string[]
    isFeatured: boolean
    isChild: boolean    
    guideDescription: string,
    photosCarousel: string[],
    guideSavingsInfo: {
        difference: number,
        percentage: number
    }
    parentGuides?: ParentGuideInfo[] | null
    parentGuideIds?: string[] | null
    
    guideIsExpandable: boolean
    guideHasOpenButton: boolean
    guideIsPurchaseable: boolean
}

export type GuideBoudns = [[number, number], [number, number]]
export interface PolygonCoordinates {
    type: "Polygon",
    coordinates: [number, number][]
}

export interface MultiLineStringCoordinates {
    type: "MultiLineString",
    coordinates: [number, number][]
}

export interface PlentyOfMultiLineStringCoordinates {
    type: "MultiLineString",
    coordinates: [number, number][][]
}
