import styled, { keyframes } from 'styled-components';
import { Modal as AntModal } from 'antd';
interface LayerCellProps {
  isSelected: boolean;
}

const OverlayAppearing = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.7;
`;

export const S = {
  Modal: styled(AntModal)`
    & .ant-modal-content {
      max-width: 100%;
      margin: 0 auto;
      min-height: 50vh;
      width: 80vw;
      overflow: hidden;
      border-radius: ${({ theme }) =>
        theme.layersModal.dimensions.borderRadius}px;
      overflow-y: auto;
      height: 450px;
    }

    & .ant-modal-body {
      padding: 0;
    }

    & .ant-modal-content::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  `,
  Container: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    overflow-y: auto;
    background: ${({ theme }) => theme.layersModal.colors.backgroundContainer};
  `,
  Body: styled.div`
    padding-top: 20px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  `,
  Header: styled.div`
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
  `,
  Title: styled.span`
    font-size: 36px;
    font-weight: bold;
  `,
  LayerCellWrapper: styled.div`
    padding: 15px;
  `,
  LayerCell: styled.div<LayerCellProps>`
    position: relative;
    width: 180px;
    height: 150px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
    border: ${({ theme, isSelected }) =>
      isSelected && `4px solid ${theme.layersModal.colors.borderLayerCell}`};

    &:hover {
      border: 4px solid
        ${({ theme }) => theme.layersModal.colors.borderLayerCell};
    }
  `,
  LayerCellImg: styled.img`
    height: 100%;
    width: 100%;
  `,
  LayerOverlay: styled.div`
    position: absolute;
    background: ${({ theme }) => theme.layersModal.colors.backgroundLayerCell};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    opacity: 0;

    &:hover {
      animation: ${OverlayAppearing};
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
    }
  `,
  OverlayTitle: styled.span`
    color: ${({ theme }) => theme.layersModal.colors.textLayerCell};
    font-size: 21px;
    text-align: center;
  `,
};
