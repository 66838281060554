import styled from 'styled-components';
import { Tag as AntTag } from 'antd';
import { TagProps, CheckableTagProps } from './Tag.interface';

const { CheckableTag } = AntTag;

export const StyledTag: React.FC = styled((props: TagProps) => (
  <AntTag {...props} />
))`
  padding: 5px 10px;
  border: 0px;
  border-radius: 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.tag.colors.default.text};
  background: ${({ theme }) => theme.tag.colors.default.background};
  cursor: pointer;
`;

export const StyledCheckableTag: React.FC = styled(
  (props: CheckableTagProps) => <CheckableTag {...props} />
)`
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  padding: 5px 10px;

  border-color: ${({ theme, checked }) =>
    checked
      ? theme.tag.colors.checkable.borderChecked
      : theme.tag.colors.checkable.border};

  background: ${({ theme, checked }) =>
    checked
      ? theme.tag.colors.checkable.backgroundChecked
      : theme.tag.colors.checkable.background};

  color: ${({ theme, checked }) =>
    checked
      ? theme.tag.colors.checkable.textChecked
      : theme.tag.colors.checkable.text};

  &&&:active {
    background-color: ${({ theme }) =>
      theme.tag.colors.checkable.backgroundChecked};
  }
  &&&:not(.ant-tag-checkable-checked):hover {
    color: ${({ theme }) => theme.tag.colors.checkable.text};
  }
`;
