import styled from 'styled-components';
import { BaseSwitch } from '../BaseSwitch/BaseSwitch';

export const S = {
  StyledSwitch: styled(BaseSwitch)`
    height: 13px;
    line-height: 13px;
    width: 30px;
    min-width: 30px;

    .ant-switch-handle::before {
      top: -5px;
      bottom: 5px;
      right: 5px;
      left: -5px;
      background-color: ${({ theme }) =>
        theme.profileSettingsForm.colors.circleSwitchBackground};
    }
  `,
  SwitchContainer: styled.div`
    .ant-switch-checked {
      background-color: ${({ theme }) =>
        theme.profileSettingsForm.colors.checkedSwitchBackground};
    }

    .ant-switch-checked .ant-switch-handle {
      left: calc(100% - 10px);
    }
  `,
};
