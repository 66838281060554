import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

export const S = {
  LoadingSpinnerWrapper: styled.div`
    margin: auto;
  `,
  ContentWrapper: styled.div`
    position: relative;
    top: -20px;
  `,
  ContentHeader: styled.header`
    text-align: center;
    padding: 0 10px;
  `,
  Title: styled.span`
    font-size: 26px;
    line-height: 32px;
  `,
  ShortInfo: styled.ul`
    margin: 0;
    padding: 5px 0 0 0;
    color: ${({ theme }) => theme.guideViewDetail.colors.textShortInfo};
    font-size: 12px;
  `,
  ButtonWrapper: styled.div`
    padding: 15px 0;

    & .ant-btn {
      font-weight: bold;
    }
  `,
  Li: styled.li`
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &:not(:last-child)::after {
      position: absolute;
      top: 9px;
      right: -7px;
      display: inline-block;
      content: '';
      height: 3px;
      width: 3px;
      border-radius: 50px;
      background: ${({ theme }) => theme.guideViewDetail.colors.textShortInfo};
    }
  `,
  HeaderFieldWrapper: styled.div`
    margin-top: 10px;
  `,
  Price: styled.span`
    padding: 5px 10px;
    color: ${({ theme }) => theme.guideViewDetail.colors.textPrice};
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
  BodyContainer: styled.div`
    padding: 0 10px;
  `,
  OtherPurchasedOtions: styled.div``,
  SectionTitle: styled.div<{ hasVerticalPadding?: boolean }>`
    padding: ${({ hasVerticalPadding }) => (hasVerticalPadding ? '15' : '0')}px
      0 5px 5px;
    color: ${({ theme }) => theme.guideViewDetail.colors.sectionTitle};
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
  `,
  PurchasedOption: styled.div`
    padding: 0 15px;
    margin-bottom: 10px;
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    background: ${({ theme }) =>
      theme.guideViewDetail.colors.backgroundPurchasedOption};
    cursor: pointer;
  `,
  OptionName: styled.span`
    font-size: 13px;
    color: ${({ theme }) =>
      theme.guideViewDetail.colors.textOtherPurchaseOptionTitle};
  `,
  OptionPriceSection: styled.div`
    display: flex;
    font-size: 13px;
  `,
  ActualOptionPrice: styled.span`
    padding-right: 12px;
    color: ${({ theme }) =>
      theme.guideViewDetail.colors.textOtherPurchaseOptionActualPrice};
  `,
  OldOptionPrice: styled.span`
    position: relative;
    display: inline-block;
    margin-right: 12px;
    color: ${({ theme }) =>
      theme.guideViewDetail.colors.textOtherPurchaseOptionOldPrice};

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${({ theme }) =>
        theme.guideViewDetail.colors.backgroundOldPriceCross};
    }

    &::before {
      transform: rotate(20deg);
    }

    &::after {
      transform: rotate(-20deg);
    }
  `,
  InfoCircleOutlinedIcon: styled(InfoCircleOutlined)`
    font-size: 18px;
    color: ${({ theme }) => theme.guideViewDetail.colors.iconParentInfo};
    cursor: pointer;
  `,
  Description: styled.div`
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;

    & > a {
      word-wrap: break-word;
    }
  `,
  TagsSection: styled.div`
    padding: 10px 0;
    text-align: center;
  `,
  TagWrapper: styled.span`
    display: inline-block;
    margin: 5px 0;
  `,
  AddToCartButtonWrapper: styled.div`
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    overflow: hidden;
    font-weight: bold;
  `,
  GuideMapWrapper: styled.div`
    width: 100%;
    height: 400px;
  `,
  DemoLinkArrowWrapper: styled.div`
    position: absolute;
    right: 0;
    top: -62px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  `,
  LinkTextSection: styled.div``,
  Text: styled.span`
    font-size: 13px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.guideViewDetail.colors.textLinkToDemo};
  `,
  DiscountText: styled.span`
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.guideViewDetail.colors.textDiscount};
  `,
  DiscountLine1: styled.span`
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
    padding-left: 10px;
    padding-top: 10px;
  `,
  DiscountCross: styled.span`
    display: inline-block;
    text-decoration:line-through;
    text-transform: uppercase;
    color: red;
    padding-right: 10px;
    padding-top: 10px;
  `,
  SalePriceOld: styled.span`
    display: inline-block;
    text-decoration:line-through;
    text-transform: uppercase;
    color: red;
    padding-right: 10px;
    padding-top: 10px;
  `,
  SalePriceNew: styled.span`
    display: inline-block;
    text-transform: uppercase;
    color: black;
    padding-left: 10px;
    padding-top: 10px;
  `,
};
