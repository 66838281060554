import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import isEqual from 'lodash.isequal';
import { AppState } from 'store/rootReducer';
import * as fromGuideUse from './reducer';
import * as fromCommon from '../Common/reducer';
import { initialState } from './reducer';
import { LAST_ELEVATION_PROFILE_EXPANSION_LEVEL } from '../../commonTypes/enums';
import { Waypoint } from 'atlasguides-web-common/src/functions/guides/dto/guide-geo.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getGuideUseState = (state: AppState): fromGuideUse.State =>
  state.guideUse || initialState;
export const getCommonState = (state: AppState): fromCommon.State =>
  state.common || initialState;

export const getGuideDataSelector = createSelector(
  getGuideUseState,
  getCommonState,
  (state: fromGuideUse.State, commonState: fromCommon.State) =>
    commonState.clockwiseDirectionTrails.includes(state.guideData.guideId)
      ? {
          ...state.guideData,
          waypoints: state.guideData.waypoints.map((waypoint: any) => ({
            ...waypoint,
            waypointDistance: waypoint.waypointDistance
              ? state.guideData.trailLength - waypoint.waypointDistance
              : waypoint.waypointDistance,
            geometry: {
              ...waypoint.geometry,
              coordinates:
                waypoint.geometry.coordinates[3] !== -9999
                  ? [
                      waypoint.geometry.coordinates[0],
                      waypoint.geometry.coordinates[1],
                      waypoint.geometry.coordinates[2],
                      state.guideData.trailLength -
                        waypoint.geometry.coordinates[3],
                    ]
                  : waypoint.geometry.coordinates,
            },
          })),
          trails: state.guideData.trails.map((trail: any) => ({
            ...trail,
            geometry: {
              ...trail.geometry,
              coordinates: trail.geometry.coordinates.map((coordsPeace: any) =>
                coordsPeace.map((coord: any) => [
                  coord[0],
                  coord[1],
                  coord[2],
                  state.guideData.trailLength - coord[3],
                ])
              ),
            },
          })),
        }
      : state.guideData
);

export const getSortedGuideDataSelector = createDeepEqualSelector(
  getGuideDataSelector,
  guideData => ({
    ...guideData,
    waypoints: guideData.waypoints?.sort((itemA: any, itemB: any) =>
      itemA.waypointDistance < itemB.waypointDistance ? -1 : 1
    ),
    trails: guideData.trails?.map((trail: any) => ({
      ...trail,
      geometry: {
        ...trail.geometry,
        coordinates: trail.geometry?.coordinates?.sort(
          (itemA: any, itemB: any) => (itemA[3] < itemB[3] ? -1 : 1)
        ),
      },
    })),
  })
);

export const getIsFetchingGuideDataSelector = createSelector(
  getGuideUseState,
  state => state.isFetchingGuideData
);

export const getSelectedWaypointSelector = createSelector(
  getGuideUseState,
  getCommonState,
  (state, commonState) => {
    const selectedWaypoint = state.selectedWaypoint;

    return commonState.clockwiseDirectionTrails.includes(
      state.guideData.guideId
    ) && selectedWaypoint
      ? {
          ...selectedWaypoint,
          waypointDistance: selectedWaypoint.waypointDistance
            ? state.guideData.trailLength - selectedWaypoint.waypointDistance
            : selectedWaypoint.waypointDistance,
          geometry: {
            ...selectedWaypoint.geometry,
            coordinates:
              selectedWaypoint.geometry.coordinates[3] !== -9999
                ? [
                    selectedWaypoint.geometry.coordinates[0],
                    selectedWaypoint.geometry.coordinates[1],
                    selectedWaypoint.geometry.coordinates[2],
                    state.guideData.trailLength -
                      selectedWaypoint.geometry.coordinates[3],
                  ]
                : selectedWaypoint.geometry.coordinates,
          },
          guideName: state.guideData.guideName,
        }
      : selectedWaypoint;
  }
);

export const getIsWaypointSelectedSelector = createSelector(
  getGuideUseState,
  state => state.isWaypointSelected
);

export const getIsWaypointDetailsOpenSelector = createSelector(
  getGuideUseState,
  state => state.isWaypointDetailsOpen
);

export const getIsMapSettingsOpenSelector = createSelector(
  getGuideUseState,
  state => state.isMapSettingsOpen
);

export const getSelectedWaypointCommentsSelector = createSelector(
  getGuideUseState,
  state => ({
    comments: state.comments,
    isFetchingComments: state.isWaypointCommentsFetching,
  })
);

export const getIsPointsMenuModeSelector = createSelector(
  getGuideUseState,
  state => state.isPointsMenuMode
);

export const getIsLayersModalOpenSelector = createSelector(
  getGuideUseState,
  state => state.isLayersModalOpen
);

export const getCurrentLayerLinkSelector = createSelector(
  getGuideUseState,
  state => state.mapLayerLink
);

export const getCurrentLayerAttributionSelector = createSelector(
  getGuideUseState,
  state => state.mapLayerAttribution
);

export const getIsLeftMenuCollapsedSelector = createSelector(
  getGuideUseState,
  state => state.isLeftMenuCollapsed
);

export const getIsWaypointSearchingStateSelector = createSelector(
  getGuideUseState,
  state => state.isWaypointSearchingState
);

export const getAllWaypointsListSelector = createSelector(
  getGuideUseState,
  state => state.guideData.waypoints
);

export const getWaypointsListSelector = createSelector(
  getGuideUseState,
  state =>
    state.isWaypointSearchingState
      ? state.searchedWaypointsList
      : state.guideData.waypoints
);

export const getWaypointsDependingOnDirectionSelector = createSelector(
  getWaypointsListSelector,
  getGuideUseState,
  getCommonState,
  (waypoints: Waypoint[], state: fromGuideUse.State, commonState: fromCommon.State) =>
  commonState.clockwiseDirectionTrails.includes(state.guideData.guideId)
  ? waypoints.map((waypoint: any) => ({
    ...waypoint,
    waypointDistance: (waypoint.waypointDistance || waypoint.waypointDistance === 0)
      ? state.guideData.trailLength - waypoint.waypointDistance
      : waypoint.waypointDistance,
    geometry: {
      ...waypoint.geometry,
      coordinates:
        waypoint.geometry.coordinates[3] !== -9999
          ? [
              waypoint.geometry.coordinates[0],
              waypoint.geometry.coordinates[1],
              waypoint.geometry.coordinates[2],
              state.guideData.trailLength -
                waypoint.geometry.coordinates[3],
            ]
          : waypoint.geometry.coordinates,
    },
  })) : waypoints
    
);

export const getWaypointSearchedValueSelector = createSelector(
  getGuideUseState,
  state => state.waypointSearchedValue
);

export const getIsElevationProfileExpandedSelector = createSelector(
  getGuideUseState,
  state =>
    state.elevationProfileExpansionLevel ===
    LAST_ELEVATION_PROFILE_EXPANSION_LEVEL
);

export const getIsElevationProfileModalOpenedSelector = createSelector(
  getGuideUseState,
  state => state.isElevationModalOpened
);

export const getElevationProfileExpansionLevel = createSelector(
  getGuideUseState,
  state => state.elevationProfileExpansionLevel
);
