import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { GuidesSearchInput } from '../GuidesSearchInput/GuidesSearchInput';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { ControlsPanel } from 'shared/ControlsPanel/ControlsPanel';

import { S } from './ControlsPanel.styles';
import { TrailsStoreControlsPanelProps } from 'containers/TrailsStore/TrailsStore.interface';

const ControlsPanelComponent: React.FC<TrailsStoreControlsPanelProps> = ({
  isMobile,
  isUnlayoutMode,
  isFetchingTrailsList,
  selectedFiltersListLength,
  onSetIsUnlayoutMode,
  setIsFilterShown,
}) => (
  <ControlsPanel isUnlayoutMode={isUnlayoutMode} isMobile={isMobile}>
    <GuidesSearchInput />
    {isMobile && (
      <S.ControlWrapper onClick={onSetIsUnlayoutMode}>
        <BaseButton
          type="text"
          icon={isUnlayoutMode ? <S.MapIcon /> : <S.UnorderedListIcon />}
        />
      </S.ControlWrapper>
    )}
    <S.WhiteBkgControlWrapper>
      {selectedFiltersListLength > 0 && (
        <S.CountFilters>
          <S.CountFiltersText>{selectedFiltersListLength}</S.CountFiltersText>
          </S.CountFilters>
      )}
      <BaseButton
        type="text"
        icon={<S.FilterIcon onClick={() => setIsFilterShown(true)} />}
        disabled={isFetchingTrailsList}
      />
    </S.WhiteBkgControlWrapper>
  </ControlsPanel>
);

export const TrailsStoreControlsPanel = memo(ControlsPanelComponent, isEqual);
