import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Header } from 'views/Header/Header';
import { HeaderContainerProps } from './Header.interfaces';
import { useAuth } from '../../services/AuthManager/AuthManager.react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/rootReducer';
import {
  selectUserProfile,
} from '../../store/UserStore';
import { useSignOutWithRedirect } from '../../hooks/useSignOutWithRedirect';
import { UserProfileWithPurchases } from 'atlasguides-web-common/src/functions/users/dto/user-profile.dto';

export const HeaderContainer: React.FC<HeaderContainerProps> = ({
  isUserLoggedIn,
  isMobileLayout,
  handleShowDrawer,
  isTabletLayout,
}) => {
  const userProfile = useSelector<AppState, UserProfileWithPurchases | null>(
    selectUserProfile
  );
  const [isProfilePageShown, setIsProfilePageShown] = useState<boolean>(false);
  const [isRedeemCodeDialogShown, setIsRedeemCodeDialogShown] = useState<boolean>(false);
  const [isPurchasesShown, setIsPurchasesShown] = useState<boolean>(false);
  const [profilePopupVisibility, setProfilePopupVisibility] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { signOut } = useSignOutWithRedirect();

  const onShowProfilePage = useCallback(() => {
    setIsProfilePageShown(true);
    setIsPurchasesShown(false);
    setProfilePopupVisibility(false);
    setIsRedeemCodeDialogShown(false);
  }, []);

  const handleSignIn = useCallback(() => {
    history.push('/login');
  }, [history]);

  const handleShowPurchases = useCallback(() => {
    setIsPurchasesShown(true);
    setIsProfilePageShown(false);
    setProfilePopupVisibility(false);
    setIsRedeemCodeDialogShown(false);
  }, []);

  const handleHidePurchases = useCallback(() => {
    setIsPurchasesShown(false);
  }, []);

  const handleSignOut = useCallback(async () => {
    await signOut();
    setIsProfilePageShown(false);
    setIsPurchasesShown(false);
    setProfilePopupVisibility(false);
    setIsRedeemCodeDialogShown(false);
    localStorage.removeItem('parseUserId');
    localStorage.removeItem('shopifyCartId');
  }, [signOut]);

  const onHideProfilePage = useCallback(() => {
    setIsProfilePageShown(false);
  }, []);

  const onShowRedeemCode = useCallback(() => {
    setIsProfilePageShown(false);
    setIsPurchasesShown(false);
    setProfilePopupVisibility(false);
    setIsRedeemCodeDialogShown(true);
  }, []);

  const onHideRedeemCode = useCallback(() => {
    setIsRedeemCodeDialogShown(false);
  }, []);

  const onProfilePopupVisibleChange = useCallback((visible: boolean) => {
    setProfilePopupVisibility(visible);
  }, []);

  const handleGoToExploreTrails = useCallback(() => {
    const targetLocation = '/guides';
    location.pathname !== targetLocation && history.push(targetLocation);
  }, [location, history]);

  return (
    <Header
      isProfilePageShown={isProfilePageShown}
      isRedeemCodeDialogShown={isRedeemCodeDialogShown}
      isTabletLayout={isTabletLayout}
      onShowProfilePage={onShowProfilePage}
      onHideProfilePage={onHideProfilePage}
      isUserLoggedIn={isUserLoggedIn}
      handleShowDrawer={handleShowDrawer}
      isMobileLayout={isMobileLayout}
      handleSignIn={handleSignIn}
      handleShowPurchases={handleShowPurchases}
      handleHidePurchases={handleHidePurchases}
      handleShowRedeemCode={onShowRedeemCode}
      handleHideRedeemCode={onHideRedeemCode}
      handleGoToExploreTrails={handleGoToExploreTrails}
      isPurchasedReceiptsShown={isPurchasesShown}
      handleSignOut={handleSignOut}
      userName={userProfile?.primaryInfo.displayName || userProfile?.primaryInfo.username || ''}
      profilePopupVisibility={profilePopupVisibility}
      onProfilePopupVisibleChange={onProfilePopupVisibleChange}
    />
  );
};
