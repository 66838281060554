import styled from 'styled-components';

export const S = {
  Feedback: styled.div`
    border-bottom: 1px solid
      ${({ theme }) => theme.pointFeedback.colors.borderFeedback};
    padding: 10px;
  `,
  FeedbackText: styled.span`
    color: ${({ theme }) => theme.pointFeedback.colors.textFeedbackText};
    font-size: 16px;
    white-space: pre-wrap;
    line-height: 1;
  `,
  FeedbackDetail: styled.div`
    padding-top: 8px;
  `,
  FeedbackAuthor: styled.span`
    color: ${({ theme }) => theme.pointFeedback.colors.textFeedbackAuthor};
    font-size: 13px;
  `,
  FeedbackDate: styled.span`
    padding-left: 10px;
    color: ${({ theme }) => theme.pointFeedback.colors.textFeedbackDate};
  `,
};
