import { memo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { DistanceToPoint } from '../DistanceToPoint/DistanceToPoint';
import { actions } from 'store/GuideUse/actions';
import {
  getDistanceUnitSelector,
  getElevationUnitSelector,
} from 'store/Common/selectors';
import {
  getGuideDataSelector,
  getSelectedWaypointCommentsSelector,
  getSelectedWaypointSelector,
  getSortedGuideDataSelector,
} from 'store/GuideUse/selectors';
import { useViewportSize } from 'hooks/useViewportSize';
import { BackButton } from 'shared/BackButton/BackButton';
import { PointFeedback } from 'views/GuideUse/PointFeedback/PointFeedback';
import { findAndMakeLinksInText } from 'utils/findAndMakeLinksInText';
import { findAndMakeEmailsInText } from 'utils/findAndMakeEmailsInText';

import { S } from './PointDetails.styles';
import { PointDetailsProps } from './PointDetails.interface';
import { commentsStore } from 'store/GuideUse/CommentsStore'
import { Comment } from 'atlasguides-web-common/src/functions/comments/dto/comment.dto';
import { LAST_ELEVATION_PROFILE_EXPANSION_LEVEL } from '../../../commonTypes/enums';
import { SkeletonCommentsList } from '../../../skeleton-views/SkeletonCommentsList/SkeletonCommentsList';
import { WaypointLocalizator } from 'services/LocalizationManager/WaypointLocalizator'
import { FormattedMessage } from 'react-intl';

const PointDetailsComponent: React.FC<PointDetailsProps> = ({ iconTypes }) => {
  const dispatch = useDispatch();
  const selectedWaypoint = useSelector(getSelectedWaypointSelector);
  const actualDistanceUnit = useSelector(getDistanceUnitSelector);
  const actualElevationUnit = useSelector(getElevationUnitSelector);
  const { primaryDirection, secondaryDirection } = useSelector(getGuideDataSelector);
  useSelector(getSelectedWaypointCommentsSelector);
  const { waypoints } = useSelector(getSortedGuideDataSelector);
  const { isMobile } = useViewportSize();

  const {
    waypointId,
    title,
    description,
    geometry,
    waypointIconSet,
    guideName,
    waypointDistance,
  } = selectedWaypoint;

  var comments = null
  if (commentsStore.hasCommentsForWaypoint(waypointId)){
    comments = commentsStore.getComments(waypointId);
  }
  
  const handleClose = () => {
    dispatch(actions.setSelectedWaypoint(''));
    dispatch(actions.setIsWaypointDetailsOpen(false));
  };

  const handleShowOnMap = useCallback(() => {
    dispatch(actions.setIsPointsMenuMode(false));
    dispatch(actions.setIsElevationModalOpen(false));
    dispatch(actions.setIsWaypointDetailsOpen(false));
  }, [dispatch]);

  /* @TODO Removed for the first release */
  // const handleShowOnElevationProfile = useCallback(() => {
  //   if (isMobile) {
  //     dispatch(actions.setIsElevationModalOpen(true));
  //     dispatch(actions.setIsWaypointDetailsOpen(false));
  //   } else {
  //     handleShowOnMap();
  //     dispatch(
  //       actions.setElevationProfileExpansionLevel(
  //         LAST_ELEVATION_PROFILE_EXPANSION_LEVEL
  //       )
  //     );
  //   }
  // }, [isMobile, dispatch, handleShowOnMap]);

  useEffect(() => {
    dispatch(actions.getWaypointCommentsRequest(waypointId));
  }, [dispatch, waypointId]);

  const handleTransformText = (text: string) => {
    const textWithLinks = findAndMakeLinksInText(text);
    const textWithEmails = findAndMakeEmailsInText(textWithLinks);

    return textWithEmails;
  };

  return (
    <S.Container>
      <S.HeaderContainer>
        {isMobile ? (
          <S.BackButtonContainer>
            <BackButton onClick={handleClose} />
          </S.BackButtonContainer>
        ) : (
          <S.CloseButton onClick={handleClose} />
        )}
        <S.DetailSection withoutBorder={true}>
          <S.PointName>{WaypointLocalizator.getTitle(selectedWaypoint)}</S.PointName>
          <S.ShortInfo>
            {primaryDirection &&
              secondaryDirection &&
              (waypointDistance || waypointDistance === 0) && (
                <S.Li>
                  <FormattedMessage id={actualDistanceUnit.shortName}/>{' '}
                  {(
                    geometry.coordinates[3] / actualDistanceUnit.valueInMeters
                  ).toFixed(1)}
                </S.Li>
              )}
            <S.Li>
              <FormattedMessage id="elevation"/>:{' '}
              {geometry.coordinates[2] &&
                (
                  geometry.coordinates[2] / actualElevationUnit.valueInMeters
                ).toFixed()}{' '}
              <FormattedMessage id={actualElevationUnit.shortName}/>
            </S.Li>
          </S.ShortInfo>
          <span>{guideName}</span>
        </S.DetailSection>
      </S.HeaderContainer>
      <S.DetailSection>
        <S.WaypointIconSet>
          {waypointIconSet.map((icon: number) => (
            <S.Icon
              key={icon}
              dangerouslySetInnerHTML={{ __html: iconTypes[icon] }}
            ></S.Icon>
          ))}
        </S.WaypointIconSet>
        <S.Description
          dangerouslySetInnerHTML={{
            __html: handleTransformText(WaypointLocalizator.getDescription(selectedWaypoint) || ''),
          }}
        />
      </S.DetailSection>
      <S.DetailSection>
        {primaryDirection && selectedWaypoint.waypointDistance && (
          <S.DistanceToPointWrapper>
            <DistanceToPoint
              distanceUnit={actualDistanceUnit}
              currentWaypoint={selectedWaypoint}
              waypoints={waypoints}
              waypointIconSet={waypointIconSet}
              iconTypes={iconTypes}
            />
          </S.DistanceToPointWrapper>
        )}
      </S.DetailSection>
      {isMobile && (
        <>
          <S.DetailSection>
            <S.ShowOnSection onClick={handleShowOnMap}>
              <FormattedMessage id="show_on_map"/>
            </S.ShowOnSection>
          </S.DetailSection>
          {/* @TODO Removed for the first release */}
          {/* {primaryDirection && secondaryDirection && (
            <S.DetailSection>
              <S.ShowOnSection onClick={handleShowOnElevationProfile}>
                Show on elevation
              </S.ShowOnSection>
            </S.DetailSection>
          )} */}
        </>
      )}
      {(comments == null) ? (
        <S.SkeletonListContainer>
          <SkeletonCommentsList listItemsCount={3} />
        </S.SkeletonListContainer>
      ) : (
        <>
          {comments.length ? (
                comments.map((comment: Comment) => (              
                <PointFeedback
                  comment={{ ...comment, date: new Date(comment.date) }}
              />
            ))
          ) : (
            <S.CommentsContainer>
              <p><FormattedMessage id="no_comments"/></p>
            </S.CommentsContainer>
          )}
        </>
      )}
    </S.Container>
  );
};

export const PointDetails = memo(PointDetailsComponent, isEqual);
