import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { StyledLinkProps } from './Link.interfaces';
import { StyledLink } from './Link.styles';

const LinkComponent: React.FC<StyledLinkProps> = props => {
  return <StyledLink {...props} />;
};

export const Link = memo(LinkComponent, isEqual);
