import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import { CloseCircleFilled as AntCloseCircleFilled } from '@ant-design/icons';
import { animated } from 'react-spring';
import { BaseButton } from 'controls/BaseButton/BaseButton';

import { CardProps } from './Card.interface';

interface WrapperProps {
  shape: 'bumpy' | 'flat';
}
interface StyleProps {
  size?: 'small' | 'default';
  onDelete?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

export const S = {
  TrailCardContainer: styled.div<WrapperProps>`
    border-radius: ${({ theme, shape = 'bumpy' }) =>
      theme.trailCard.shape[shape].borderRadius};
    box-shadow: ${({ theme, shape = 'bumpy' }) =>
      theme.trailCard.shape[shape].boxShadow};
    overflow: hidden;
  `,
  TrailCard: styled.div<StyleProps>`
    display: grid;
    grid-template-columns: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].templateColumns};
    grid-template-rows: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].templateRows};
    column-gap: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].columnGap};
    grid-template-areas:
      'cardLogo title cost'
      'cardLogo promoText cost'
      'cardLogo milesInfo collapseArrow';
  `,
  LogoGrid: styled.div<StyleProps>`
    grid-area: cardLogo;

    padding: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].logoPadding};
  `,
  CostGrid: styled.div<StyleProps>`
    grid-area: cost;

    position: relative;
    padding: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].costPadding};
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  `,
  TitleGrid: styled.div<StyleProps>`
    padding-right: 20px;
    grid-area: title;
    word-break: break-word;
    line-height: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].titleLineHeight};
    color: ${({ theme }) => theme.trailCard.colors.titleText};
    font-size: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].titleFont};
  `,
  PromoTextGrid: styled.div<StyleProps>`
    grid-area: promoText;
    padding-right: 25px;
    white-space: pre-wrap;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: block;
    line-height: 1em;
    max-height: 2em;

    color: ${({ theme }) => theme.trailCard.colors.promoText};
    font-size: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].promoFont};
  `,
  PromoPriceGrid: styled.div<StyleProps>`
    grid-area: promoText;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].promoFont};
    color: ${({ theme }) => theme.trailCard.colors.promoPrice};
  `,
  MilesInfoGrid: styled.div<StyleProps>`
    grid-area: milesInfo;

    color: ${({ theme }) => theme.trailCard.colors.milesInfoText};
    font-size: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].milesInfoFont};
  `,
  CollapseArrowGrid: styled.div<StyleProps>`
    grid-area: collapseArrow;

    display: flex;
    flex-direction: row-reverse;
  `,
  Cost: styled(BaseButton)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.trailCard.colors.costText};
    font-size: 14px;
    padding: 0 5px;
    line-height: 22px;
  `,
  CostFlat: styled.span<StyleProps>`
    font-size: ${({ theme, size = 'default' }) =>
      theme.trailCard.sizes[size].costFont};
    font-weight: bold;
  `,
  CloseCircleFilled: styled(AntCloseCircleFilled)`
    color: ${({ theme }) => theme.trailCard.colors.backgroundCloseCircle};
  `,
  DefaultLogo: styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: ${({ theme }) =>
      theme.trailCard.colors.backgroundDefaultIconWrapper};
  `,
  Logo: styled.img.attrs(({ src }) => ({
    src,
  }))`
    height: 100%;
    width: 100%;
    border-radius: 5px;
  `,
  TitleWrapper: styled.div`
    min-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  PromoWrapper: styled.div`
    max-height: 25px;
  `,
  CostWrapper: styled.div<StyleProps>`
    position: absolute;
    right: ${({ onDelete }) => onDelete && '-5px'};

    & button {
      font-weight: 500;
    }
  `,
  FreeMark: styled.div`
    position: absolute;
    right: -11px;
    top: -11px;
    height: 20px;
    padding: 0 7px;
    color: ${({ theme }) => theme.guideViewDetail.colors.textFreeMark};
    background: ${({ theme }) =>
      theme.guideViewDetail.colors.backgroundFreeMark};
    border-radius: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
  `,
  FreeMarkText: styled.span`
    text-align: center;
  `,
  CartIconWrapper: styled.span`
    padding-right: 5px;
    font-size: 22px;
  `,
  LoadingSpinnerWrapper: styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  NestedCardsWrapper: styled.div`
    max-height: 100%;
    transition: max-height 0.3s;
  `,
  NestedCardWrapper: styled(animated.div)`
    & .ant-card {
      background: ${({ theme }) => theme.trailCard.colors.nestedCardBackground};
      border-top: 1px solid
        ${({ theme }) => theme.trailCard.colors.nestedCardBorder};
    }
  `,
  BaseButtonMediumHeight: styled(BaseButton)`
    height: 32px;
  `,
  CollapseArrowGridInner: styled.span`
    position: relative;
    top: -10px;
  `,
};

export const StyledCard: React.FC<CardProps> = styled((props: CardProps) => (
  <AntCard {...props} bordered={false} />
))`
  ${({ theme, size = 'default' }) =>
    `height: ${theme.trailCard.sizes[size].height};`}
  border: 0;
  border-radius: 0;
  outline: 0;
  cursor: pointer;

  .ant-card-body {
    padding: ${({ theme, size = 'default', shape = 'bumpy' }) =>
      shape === 'bumpy' ? theme.trailCard.sizes[size].padding : '0'};
    height: 100%;
  }
`;

export const StyledLoadedCard: React.FC<any> = styled((props: any) => (
  <AntCard {...props} bordered={false} />
))`
  height: ${({ theme, size = 'default' }) =>
    theme.trailCard.sizes[size].height};
  margin-bottom: 10px;
  border-radius: 7px;
  border: 0;
  outline: 0;

  .ant-card-body {
    padding: 5px 15px;
  }

  .ant-skeleton-with-avatar
    .ant-skeleton-content
    .ant-skeleton-title
    + .ant-skeleton-paragraph {
    margin-top: 12px;
  }

  .ant-skeleton-header {
    padding-top: 10px;
  }

  .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 5px;
  }

  .ant-skeleton-header .ant-skeleton-avatar {
    width: ${({ size = 'default' }) => size === 'default' && '50px'};
    height: ${({ size = 'default' }) => size === 'default' && '50px'};
    line-height: ${({ size = 'default' }) => size === 'default' && '50px'};
  }
`;
