import styled, { DefaultTheme } from 'styled-components';
import { MapContainer, Polyline, PolylineProps } from 'react-leaflet';

export interface ContainerProps {
  isMobile: boolean;
}

export interface StyledPolylineProps extends PolylineProps {
  ownTrailId: string;
  selectedGuideId: string;
  theme: DefaultTheme;
}

export const S = {
  MapContainer: styled(MapContainer)`
    height: 100%;
    width: 100%;
  `,
  Container: styled.div<ContainerProps>`
    position: ${({ isMobile }) => (isMobile ? 'absolute' : 'relative')};
    top: 0;
    width: 100%;
    height: 100%;

    & .leaflet-control-zoom {
      border: none;
      right: 30px;
      bottom: 30px;
    }

    &&& .leaflet-control-zoom-in,
    &&& .leaflet-control-zoom-out {
      margin: 20px 0px;
      height: 65px;
      width: 65px;
      background: ${({ theme }) =>
        theme.trailsStore.colors.commonMap.backgoundZoomControl};
      color: ${({ theme }) =>
        theme.trailsStore.colors.commonMap.textZoomButton};
      font-size: 42px;
      border-radius: 50px;
      line-height: 55px;
    }
  `,
  Polyline: styled(Polyline).attrs(
    ({ ownTrailId, selectedGuideId, theme }: StyledPolylineProps) => ({
      pathOptions: {
        weight: 4,
        color:
          ownTrailId !== selectedGuideId
            ? theme.LeafletPolyline.colors.default
            : theme.LeafletPolyline.colors.selected,
      },
    })
  )<StyledPolylineProps>``,
};
