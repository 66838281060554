import styled from 'styled-components';
import { Divider } from 'antd';

export const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    width: 130px;
  `,
  SelectableRow: styled.span`
    padding: 4px 0;
    color: ${({ theme }) => theme.profilePage.colors.textPopoverLink};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.profilePage.colors.textPopoverLinkHover};
    }
  `,
  StyledDivider: styled(Divider)`
    margin: 3px 0;
    padding: 0;
  `,
  DisplayName: styled.span``,
};
