import {
  memo,
  useEffect,
  useRef,
  useCallback,
  Ref,
  MutableRefObject,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import isEqual from 'lodash.isequal';
import L, { LatLngBoundsLiteral } from 'leaflet';
import { TileLayer, GeoJSON, ScaleControl } from 'react-leaflet';
import { actions } from 'store/GuideUse/actions';
import {
  getCurrentLayerAttributionSelector,
  getCurrentLayerLinkSelector,
  getIsWaypointSelectedSelector,
  getSelectedWaypointSelector,
} from 'store/GuideUse/selectors';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { DEFAULT_LAYER } from 'views/GuideUse/LayersModal/layers';

import {
  S,
  MarkerClass,
  ClusterClass,
  CustomMarkerClass,
  getClusterInlineStyles,
} from './GuideUseMap.styles';
import {
  GuideUseMapViewProps,
  MarkerIconProps,
} from 'containers/GuideUseMap/GuideUseMap.interfaces';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { useDefaultStateForMapInstance } from '../../hooks/useDefaultStateForMapInstance';

const MarkerIcon = ({ svg, color }: MarkerIconProps) => {
  return (
    <S.MarkerContainer
      dangerouslySetInnerHTML={{ __html: svg || '' }}
      color={color}
    />
  );
};

const GuideUseMapComponent: React.FC<GuideUseMapViewProps> = ({
  map,
  handleSetMap,
  isMobile,
  trailsData,
  waypointsData,
  useTrailPopup = true,
}) => {
  const dispatch = useDispatch();
  const currentLayerLink = useSelector(getCurrentLayerLinkSelector);
  const currentLayerAttribution = useSelector(
    getCurrentLayerAttributionSelector
  );
  const selectedWaypoint = useSelector(getSelectedWaypointSelector);
  const isWaypointSelected = useSelector(getIsWaypointSelectedSelector);
  const [localSelectedWaypointId, setLocalSelectedWaypointId] = useState('');
  const tileLayerRef: Ref<L.TileLayer> = useRef(null);
  const allMarkersRef: MutableRefObject<L.Marker[]> = useRef([]);
  const { handleWhenCreatedMap, mapRef } =
    useDefaultStateForMapInstance(handleSetMap);

  const handleLayerClick = (waypointId: string) => {
    setLocalSelectedWaypointId(waypointId);
    dispatch(actions.setSelectedWaypoint(waypointId));
    dispatch(actions.setIsWaypointDetailsOpen(true));
  };

  const handleEachWaypoint = (feature: any, marker: L.Marker): void => {
    const markerIcon = L.divIcon({
      html: ReactDOMServer.renderToString(
        <MarkerIcon
          svg={
            selectedWaypoint?.id === feature.id
              ? waypointsData?.iconTypes.selected
              : waypointsData?.iconTypes[feature?.waypointIconSet[0]] || null
          }
          color={feature?.markerColor}
        />
      ),
      className: MarkerClass,
    });

    marker.setIcon(markerIcon);

    marker.on({
      click: () => handleLayerClick(feature.id),
    });

    allMarkersRef.current.push(marker);
  };

  const handleEachTrail = (feature: any, layer: L.Path) => {
    layer.options = {
      ...layer.options,
      color: `#${feature.stroke}` || undefined,
      opacity: feature.strokeOpacity || 1,
      weight: feature.strokeWidth || 3,
    };
    useTrailPopup && layer.bindPopup(feature.trackName);
  };

  useEffect(() => {
    const localStorage = LocalStorageManager.getInstance();
    const actualLayerLinkJSON: string | null =
      localStorage.getItem('layerData');

    if (actualLayerLinkJSON) {
      const actualLayerLink: { attribution?: string; link?: string } =
        JSON.parse(actualLayerLinkJSON);

      actualLayerLink.attribution &&
        dispatch(actions.setMapLayerAttribution(actualLayerLink.attribution));
      actualLayerLink.link &&
        dispatch(actions.setMapLayerLink(actualLayerLink.link));
    }
  }, [dispatch]);

  useEffect(() => {
    if (waypointsData?.iconTypes) {
      const selectedIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
          <MarkerIcon svg={waypointsData.iconTypes.selected} />
        ),
        className: MarkerClass,
      });

      allMarkersRef.current.forEach((layer: L.Marker) => {
        const markerIcon = L.divIcon({
          html: ReactDOMServer.renderToString(
            <MarkerIcon
              svg={
                selectedWaypoint?.id === layer.feature?.id
                  ? waypointsData.iconTypes.selected
                  : waypointsData.iconTypes[
                      (layer.feature as any).waypointIconSet[0]
                    ] || null
              }
            />
          ),
          className: MarkerClass,
        });

        selectedWaypoint?.id === layer.feature?.id
          ? layer.setIcon(selectedIcon)
          : layer.setIcon(markerIcon);
      });
    }
  }, [selectedWaypoint, waypointsData]);

  useEffect(() => {
    if (tileLayerRef.current) {
      tileLayerRef.current.setUrl(`${currentLayerLink}`);
    }
  }, [currentLayerLink]);

  const createClusterCustomIcon = (cluster: any) => {
    const iicon = cluster.getAllChildMarkers().map((icon: L.Marker) => {
      return (icon.options.icon?.options as L.DivIconOptions).html;
    });

    return L.divIcon({
      html:
        cluster.getAllChildMarkers().length > 0
          ? `<div>${iicon
              .slice(0, 5)
              .map((el: string, index: number) => {
                const styles = getClusterInlineStyles(index);

                return `<div class="${CustomMarkerClass}" style="${styles}">${el}</div>`;
              })
              .join('')}</div>`
          : `<div class="${CustomMarkerClass}">${cluster.getChildCount()}</div>`,
      className: `${ClusterClass}`,
      iconSize: L.point(20, 27, true),
    });
  };

  const handleFitBounds = useCallback(
    (bounds: LatLngBoundsLiteral | null) => {
      if (map) {
        bounds
          ? map.fitBounds(bounds)
          : map.flyTo([0, 0], 2, {
              duration: 1,
            });
      }
    },
    [map]
  );

  const handleOutsideClick = useCallback(() => {
    dispatch(actions.setIsWaypointDetailsOpen(false));
    selectedWaypoint && dispatch(actions.setSelectedWaypoint(''));
  }, [dispatch, selectedWaypoint]);

  useEffect(() => {
    mapRef.current?.addEventListener('click', handleOutsideClick);

    return () => {
      mapRef.current?.removeEventListener('click', handleOutsideClick);
    };
  }, [mapRef, handleOutsideClick]);

  useEffect(() => {
    if (trailsData?.bbox) {
      if (!isWaypointSelected) {
        const geoBounds: LatLngBoundsLiteral = [
          [trailsData.bbox[0], trailsData.bbox[1]],
          [trailsData.bbox[2], trailsData.bbox[3]],
        ];
        handleFitBounds(geoBounds);
      }
    }
  }, [trailsData, handleFitBounds]);

  useEffect(() => {
    if (
      map &&
      selectedWaypoint &&
      selectedWaypoint.id !== localSelectedWaypointId
    ) {
      const [coord1, coord2] = selectedWaypoint.geometry.coordinates;

      map.flyTo([coord2, coord1], 17, {
        duration: 1,
        animate: false,
      });
    }
    setLocalSelectedWaypointId('');
  }, [selectedWaypoint, map]);

  return (
    <S.Container isMobile={isMobile}>
      <S.MapContainer
        bounds={
          trailsData?.bbox && [
            [trailsData.bbox[0], trailsData.bbox[1]],
            [trailsData.bbox[2], trailsData.bbox[3]],
          ]
        }
        maxBoundsViscosity={1}
        maxBounds={[
          [-80, -175],
          [90, 265],
        ]}
        center={[40, 0]}
        zoom={3}
        scrollWheelZoom
        zoomControl={false}
        attributionControl={true}
        maxZoom={20}
        minZoom={3}
        whenCreated={handleWhenCreatedMap}
        preferCanvas
        renderer={L.canvas({ padding: 1 })}
      >
        <TileLayer
          attribution={currentLayerAttribution || DEFAULT_LAYER.attribution}
          url={`${currentLayerLink || DEFAULT_LAYER.link}`}
          ref={tileLayerRef}
        />
        <ScaleControl />
        {trailsData && (
          <GeoJSON data={trailsData} onEachFeature={handleEachTrail} />
        )}
        {waypointsData && (
          <S.MarkerGroup
            iconCreateFunction={createClusterCustomIcon}
            showCoverageOnHover={false}
          >
            <GeoJSON data={waypointsData} onEachFeature={handleEachWaypoint} />
          </S.MarkerGroup>
        )}
      </S.MapContainer>
    </S.Container>
  );
};

export const GuideUseMapView = memo(GuideUseMapComponent, isEqual);
