import { devEnv } from "./env/dev.env";
import { prodEnv } from "./env/prod.env";
import { localEnv } from "./env/local.env";

declare const process: any;

export interface Env {
    backendUrl: string
    cognito: {
        region: string,
        userPoolId: string,
        userPoolWebClientId: string,
    }
}

let stage;

export var isLocalInstance = false

if(process.env.REACT_APP_ENV === 'production'){
  stage = prodEnv
} else if(process.env.REACT_APP_ENV === 'local'){
  isLocalInstance = true
  stage = localEnv
} else {
  stage = devEnv
}

export const env: Env = stage
