import { RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  getDistanceUnitSelector,
  getElevationUnitSelector,
} from 'store/Common/selectors';
import { Tooltip } from 'antd';

import { S } from './PointCard.styles';
import { PointCardProps } from './PointCard.inteface';
import { FormattedMessage } from 'react-intl';


export const PointCard: React.FC<PointCardProps> = ({
  title,
  primaryDirection,
  waypointDistance,
  elevation,
  guideName,
  markerColor,
  icon,
}) => {
  const actualDistanceUnit = useSelector(getDistanceUnitSelector);
  const actualElevationUnit = useSelector(getElevationUnitSelector);

  return (
    <S.Container>
      <S.Icon
        dangerouslySetInnerHTML={{ __html: icon }}
        color={markerColor}
      ></S.Icon>
      <S.PointInfo>
        <Tooltip placement="top" title={title}>
          <S.Title>{title}</S.Title>
        </Tooltip>
        <S.ShortInfo>
          {primaryDirection && (waypointDistance || waypointDistance === 0) && (
            <S.Li>
              <FormattedMessage id={actualDistanceUnit.shortName}/>{' '}
              {(waypointDistance / actualDistanceUnit.valueInMeters).toFixed(1)}
            </S.Li>
          )}
          <S.Li>
            <FormattedMessage id="elevation"/>:{' '}
            {(elevation / actualElevationUnit.valueInMeters).toFixed(0)}{' '}
            <FormattedMessage id={actualElevationUnit.shortName}/>
          </S.Li>
        </S.ShortInfo>
        <S.Location>{guideName}</S.Location>
      </S.PointInfo>
      <RightOutlined />
    </S.Container>
  );
};
