import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;

interface StyleProps {
  isMobile: boolean;
}

export const S = {
  Container: styled.section`
    border-radius: 15px;
    width: 40px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    align-items: 'center';
    background: ${({ theme }) =>
      theme.guidesOptionsPanel.colors.backgroundContainer};
    height: 140px;
    border-radius: 5px;
  `,

  Collapse: styled(Collapse)<StyleProps>`
    border-radius: ${({ theme }) =>
      theme.guidesOptionsPanel.dimensions.borderRadius}px;
    ${({ isMobile }) =>
      isMobile
        ? `
        max-width: 36px;
        min-width: 36px;
      `
        : `
        max-width: 42px;
        min-width: 42px;
      `}

    &&& .ant-collapse-item {
      border-radius: ${({ theme }) =>
        theme.guidesOptionsPanel.dimensions.collapseItemBorderRadius};
    }

    &&& .ant-collapse-header {
      padding: 12px 16px;
    }

    &&& .ant-collapse-arrow {
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &&& .ant-collapse-content-box {
      padding: 5px;
    }

    &&& .ant-collapse-content {
      border-radius: 0 0 12px 12px;
    }
  `,
  Panel: styled(Panel)``,
  IconWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0;
    cursor: pointer;
  `,
};
