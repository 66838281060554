import { useCallback, useRef } from 'react';
import { Map } from 'leaflet';

interface UseDefaultStateForMapInstanceReturnType {
  handleWhenCreatedMap: (mapInstance: Map) => void;
  mapRef: React.MutableRefObject<Map | null>;
}

type UseDefaultStateForMapInstanceType = (
  setInstanceHandler?: (mapInstance: Map) => void
) => UseDefaultStateForMapInstanceReturnType;

export const useDefaultStateForMapInstance: UseDefaultStateForMapInstanceType =
  setInstanceHandler => {
    const mapRef = useRef<Map | null>(null);

    const handleWhenCreatedMap = useCallback(
      (mapInstance: Map) => {
        mapRef.current = mapInstance;
        mapInstance.attributionControl.setPrefix('');
        setInstanceHandler && setInstanceHandler(mapInstance);
      },
      [setInstanceHandler]
    );

    return { handleWhenCreatedMap, mapRef };
  };
