import { ElevationProfileExpansionLevels } from '../commonTypes/enums';

export const getHeightAccordingToElevationExpansionLevel = (
  expansionLevel: ElevationProfileExpansionLevels
) => {
  switch (expansionLevel) {
    case ElevationProfileExpansionLevels.level_1:
      return '0px';
    case ElevationProfileExpansionLevels.level_2:
      return '180px';
    case ElevationProfileExpansionLevels.level_3:
      return '94%';
  }
};
