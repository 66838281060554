import { APIResponse } from 'global_types';

export type AuthServiceType = CognitoAuthServiceType | CustomAuthServiceType;
export type CognitoAuthServiceType = 'cognito';
export type CustomAuthServiceType = 'custom';

export type AuthMethod = EmailAndPassAuthMethod | GoogleAuthMethod;
type EmailAndPassAuthMethod = 'email';
type GoogleAuthMethod = 'google';

export type SetIsSignedIn = (isSignedIn: boolean) => void;
export interface AuthManager {
  initialize(): Promise<void>;
  signUp(
    type: AuthServiceType,
    method: AuthMethod,
    credentials?: OAuthEmailSignUpCredentials
  ): Promise<undefined | OAuthEmailSignUpErrorResponse | AuthErrorResponse>;
  signIn(
    type: CognitoAuthServiceType,
    method: EmailAndPassAuthMethod,
    credentials: OAuthEmailSignInCredentials
  ): Promise<undefined | AuthErrorResponse>;
  signOut(): Promise<void>;
  subscribeOnIsSignedIn(callback: SetIsSignedIn): void;
  unsubscribeFromIsSignedIn(callback: SetIsSignedIn): void;
  confirmEmail(
    type: AuthServiceType,
    username: string,
    code: string
  ): Promise<undefined | AuthErrorResponse>;
  resendConfirmationCode(
    type: AuthServiceType,
    username: string
  ): Promise<undefined | AuthErrorResponse>;
  requestResetPassword(
    params: RequestResetPasswordParams
  ): Promise<RequestResetPasswordResponse>;
  confirmResetPassword(
    password: string,
    confirmationCode: string
  ): Promise<ConfirmationResetPasswordResponse>;
  getAccessToken: () => Promise<string | null>;
}

export type AuthServiceInterface = GeneralAuthServiceInterface &
  CognitoAuthServiceInterface;

export interface GeneralAuthServiceInterface {
  type: AuthServiceType;
  initialize(): Promise<void>;
  getAccessToken(): Promise<string | never>;
  signOut(): Promise<void>;
  getAccessToken: () => Promise<string>;
  subscribeOnIsSignedIn(callback: SetIsSignedIn): void;
  unsubscribeFromIsSignedIn(callback: SetIsSignedIn): void;
}

export interface CognitoAuthServiceInterface {
  signUp(
    method: AuthMethod,
    credentials?: OAuthEmailSignUpCredentials
  ): Promise<undefined | OAuthEmailSignUpErrorResponse | AuthErrorResponse>;
  signIn(
    method: AuthMethod,
    credentials?: OAuthEmailSignInCredentials
  ): Promise<undefined | OAuthEmailSignInErrorResponse | AuthErrorResponse>;
  confirmEmail(
    username: string,
    code: string
  ): Promise<undefined | AuthErrorResponse>;
  resendConfirmationCode(
    username: string
  ): Promise<undefined | AuthErrorResponse>;
}

export interface AuthUserData {
  username: string;
  email: string;
  firstName: string;
}

// General OAuth scheme, including third-party SDK like Cognito

export interface OAuthEmailSignInCredentials {
  username: string;
  password: string;
  isMarketingAllowed?: boolean;
}

export interface OAuthEmailSignUpCredentials {
  displayName: string;
  email: string;
  username: string;
  password: string;
  isMarketingAllowed: boolean;
  isTermsOfUseAccepted: boolean;
}

export interface OAuthEmailSignUpConfirmationCode {
  username: string;
  code: string;
}

export interface OAuthEmailSignInErrorResponse extends APIResponse {
  result: 'error';
  method: EmailAndPassAuthMethod;
  errorMessage: string;
}

export interface OAuthEmailSignUpErrorResponse extends APIResponse {
  result: 'error';
  method: 'email';
  fieldsErrors: null | Partial<Omit<AuthUserData, 'isMarketingAllowed'>>;
  errorMessage: string;
}

// 3rd-party auth

export interface AuthErrorResponse extends APIResponse {
  result: 'error';
  method: Omit<AuthMethod, 'email'>;
  errorMessage: string;
}

export type RequestResetPasswordParams = { email?: string; username?: string };

export interface RequestResetPasswordResponse extends APIResponse {
  errorMessage?: string;
}

export interface ConfirmationResetPasswordResponse extends APIResponse {
  errorMessage?: string;
}

export enum CredentialsTypeUsedForPasswordReset {
  username = 'USERNAME',
  email = 'EMAIL',
}
