import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Input } from 'antd';

import { InputProps } from 'antd';
import { PasswordProps } from 'antd/lib/input';

import { StyledInput, StyledPasswordInputWrapper } from './BaseInput.styles';

const TextInputComponent: React.FC<InputProps> = props => {
  return <StyledInput {...props} />;
};

const PasswordInputComponent: React.FC<PasswordProps> = props => {
  return (
    <StyledPasswordInputWrapper>
      <Input.Password {...props} />
    </StyledPasswordInputWrapper>
  );
};

export const TextInput = memo(TextInputComponent, isEqual);
export const PasswordInput = memo(PasswordInputComponent, isEqual);
