import React, { memo, useEffect, useCallback, useState, useRef } from 'react';
import isEqual from 'lodash.isequal';
import { TextInput } from "controls/BaseTextInput/BaseInput";
import { Form } from 'antd';
import { RedeemCodeDialogProps } from "./RedeemCodeDialog.interface";
import { S } from './RedeemCodeDialog.styles';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { FormattedMessage  } from 'react-intl';
import { useIntl } from 'react-intl';
import { LoadingOverlay } from 'shared/LoadingOverlay/LoadingOverlay';
import { ERROR_REDEEM_CODE_CONSUMED, ERROR_REDEEM_CODE_EXPIRED, ERROR_REDEEM_CODE_NOT_FOUND, RedeemCode, RedeemCodeResponse } from 'atlasguides-web-common/src/functions/payment/redeem-code';
import { actions, getAllTrailsListSelector, getIsFetchingAllTrailsListSelector } from 'store/TrailsStore';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/ModalDialog/ModalDialog';

const RedeemCodeDialogComponent: React.FC<RedeemCodeDialogProps> = ({
  isRedeemCodeDialogShown,
  onHideRedeemCodeDialog
}) => {
  
  let intl = useIntl()
  const headerSize = 'large';
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setErrorValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const allTrails = useSelector(getAllTrailsListSelector);
  const isFetchingTrailsList = useSelector(getIsFetchingAllTrailsListSelector);
  
  const handleInputChange = (event: any) => {
    setErrorValue('')
    setInputValue(event.target.value);
  };

  const handleSubmit = async(event: any) => {
    setLoading(true)
    setErrorValue('')

    let response: RedeemCodeResponse = await RedeemCode({code: inputValue})
    setLoading(false)

    if (response){
      if (response.guideId){
        dispatch(actions.setGuideAsFullPurchased(response.guideId));
        handleClose()

        // Get Guide Display name
        let guideName = null
        for (const guide of allTrails){
          if (guide.guideId === response.guideId){
            guideName = guide.guideName;
          }
        }
        if (guideName){
          ModalDialog(intl.formatMessage({id:"get_access_to"}, {guide: guideName}));
        }
      }
      else if (response.error){
        if (response.error === ERROR_REDEEM_CODE_NOT_FOUND){
          setErrorValue(intl.formatMessage({id:"redeem_code_error_not_found"}))
        }
        else if (response.error === ERROR_REDEEM_CODE_EXPIRED){
          setErrorValue(intl.formatMessage({id:"redeem_code_error_expired"}))
        }
        else if (response.error === ERROR_REDEEM_CODE_CONSUMED){
          setErrorValue(intl.formatMessage({id:"redeem_code_error_consumed"}))
        }
        else{
          setErrorValue(response.error)
        }
      }
    }
  }
  
  const handleClose = () => {
    isRedeemCodeDialogShown = false
    form.resetFields()
    setInputValue("");
    setErrorValue("")
    onHideRedeemCodeDialog()
  };

  return (
    <>
      <S.Modal 
        visible={isRedeemCodeDialogShown} 
        onCancel={handleClose}
        zIndex={1000}
        centered
        footer={[
          <BaseButton type={'text'} label={intl.formatMessage({id:"cancel"})} onClick={handleClose} />,
          <BaseButton type={'primary'} label={intl.formatMessage({id:"redeem"})} onClick={form.submit} disabled={!inputValue || isFetchingTrailsList} />,
        ]}
      >
        <Form form={form} onFinish={(values) => handleSubmit(values)}>
          <S.Label size={headerSize}><FormattedMessage id="redeem_code"/>
            <span>: </span><TextInput type="text" placeholder="Code" value={inputValue} autoFocus onChange={handleInputChange}/>
          </S.Label>
          <p></p>
          <p><S.Error>{inputError}</S.Error></p>
        </Form>
      </S.Modal>
      {isLoading && <LoadingOverlay />}
    </>
  )
}

export const RedeemCodeDialog = memo(RedeemCodeDialogComponent, isEqual);