import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Popover, Menu } from 'antd';
import { Cart } from 'containers/Cart/Cart';

import { ReactComponent as MenuIcon } from 'static/menu-24px.svg';
import { ReactComponent as PersonIcon } from 'static/person-24px.svg';
import { ReactComponent as ShoppingCartIcon } from 'static/shopping_cart-24px.svg';

import { S } from './HeaderIconsMenu.styles';
import { HeaderIconsMenuInterface } from 'containers/Header/Header.interfaces';
import { ProfilePopoverContentView } from '../../ProfilePopoverContent/ProfilePopoverContent';
import { useAuth } from '../../../services/AuthManager/AuthManager.react';

const HeaderIconsMenuComponent: React.FC<HeaderIconsMenuInterface> = ({
  isMobileLayout,
  handleShowDrawer,
  onShowProfilePage,
  handleShowPurchases,
  handleShowRedeemCodeDialog,
  handleSignIn,
  handleSignOut,
  userName,
  profilePopupVisibility,
  onProfilePopupVisibleChange,
}) => {
  const { isSignedIn } = useAuth();

  return (
    <S.IconsMenu>
      {!isMobileLayout && (isSignedIn ? (
        <Popover
          placement="bottomRight"
          arrowPointAtCenter
          trigger="click"
          onVisibleChange={onProfilePopupVisibleChange}
          visible={profilePopupVisibility}
          content={
            <ProfilePopoverContentView
              handleMyProfile={onShowProfilePage}
              handleMyPurchases={handleShowPurchases}
              handleRedeemCode={handleShowRedeemCodeDialog}
              onSignOut={handleSignOut}
              displayName={userName}
            />
          }
        >
          <PersonIcon />
        </Popover>
      ) : (
        <PersonIcon onClick={handleSignIn} />
      ))}
      <Cart icon={<ShoppingCartIcon />} />
      
      {isMobileLayout && <MenuIcon onClick={handleShowDrawer} />}
    </S.IconsMenu>
  );
};

export const HeaderIconsMenu = memo(HeaderIconsMenuComponent, isEqual);
