import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { S } from './Modal.styles';
import { ModalComponentProps } from './Modal.interfaces';

const ModalComponent: React.FC<ModalComponentProps> = ({
  children,
  modalWindowWidth,
  withBorderRadius,
  closeButtonBackground,
  containerMinHeight,
  ...modalProps
}) => {
  return (
    <S.Modal
      {...modalProps}
      width={modalWindowWidth}
      withBorderRadius={withBorderRadius}
      closeButtonBackground={closeButtonBackground}
    >
      <S.Container
        minHeight={containerMinHeight}
        withBorderRadius={withBorderRadius}
      >
        {children}
      </S.Container>
    </S.Modal>
  );
};

export const Modal = memo(ModalComponent, isEqual);
