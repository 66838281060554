import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { CheckableTag } from 'controls/Tag/Tag';

import { OtherProps } from '../Filter.interface';
import { S } from './Other.styles';

const OtherComponent: React.FC<OtherProps> = ({
  filterParamsState: { other: otherParams },
  selectedFilters,
  handleSetSelectedFilters,
}) => {
  const { other } = selectedFilters;

  const handleChange = (tag: string, isChecked: boolean) => {
    handleSetSelectedFilters({
      sectionName: 'other',
      value: isChecked
        ? other
          ? [...other, tag]
          : [tag]
        : other?.filter((item: string) => item !== tag),
    });
  };

  return (
    <S.Container>
      {otherParams?.map(({ tag }) => (
        <S.TagWrapper key={tag}>
          <CheckableTag
            key={tag}
            checked={other ? other.includes(tag) : false}
            onChange={checked => handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        </S.TagWrapper>
      ))}
    </S.Container>
  );
};

export const Other = memo(OtherComponent, isEqual);
