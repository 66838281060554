import { memo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

const LinkWithPreviousPathComponent: React.FC<
  Omit<LinkProps, 'to'> & { to: string }
> = ({ children, to, ...props }) => {
  const location = useLocation();

  return (
    <Link
      to={{ pathname: to, state: { prevPath: location.pathname } }}
      {...props}
    >
      {children}
    </Link>
  );
};

export const LinkWithPreviousPath = memo(LinkWithPreviousPathComponent);
