import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { S } from './SwitchButton.styles';
import { SwitchProps } from 'antd';

const SwitchComponent: React.FC<SwitchProps> = ({ ...props }) => {
  return (
    <S.SwitchContainer>
      <S.StyledSwitch {...props} />
    </S.SwitchContainer>
  );
};

export const SwitchButton = memo(SwitchComponent, isEqual);
