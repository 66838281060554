import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const StyledInput = styled(AntdInput)`
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.baseTextInput.colors.border};

  padding-right: 0;
  padding-left: 0;

  && {
    background-color: transparent;
  }

  &:focus {
    border-top: 0;
    border-right: 0;
    border-left: 0;

    border-bottom: 1px solid
      ${({ theme }) => theme.baseTextInput.colors.borderFocus};
    box-shadow: 0 5px 5px -2px ${({ theme }) => theme.baseTextInput.colors.shadowFocus};
  }
`;

export const StyledPasswordInputWrapper = styled.div`
  & > .ant-input-affix-wrapper {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.baseTextInput.colors.border};

    padding-right: 0;
    padding-left: 0;
  }

  .ant-input-affix-wrapper:hover {
    border-color: ${({ theme }) => theme.baseTextInput.colors.borderHover};
  }

  & > .ant-input-affix-wrapper-focused {
    border-top: 0;
    border-right: 0;
    border-left: 0;

    border-bottom: 1px solid
      ${({ theme }) => theme.baseTextInput.colors.borderFocus};
    box-shadow: 0 5px 5px -2px ${({ theme }) => theme.baseTextInput.colors.shadowFocus};
  }
`;
