import { memo, useCallback } from 'react';
import isEqual from 'lodash.isequal';
import { S } from './ProfilePopoverContent.styles';
import { ProfilePopoverContentViewProps } from './ProfilePopoverContent.interfaces';
import { FormattedMessage  } from 'react-intl';
import { useSelector } from 'react-redux';
import { getActiveSubscriptionInfoSelector, getIsActiveSubscriptionSelector, getIsFetchingSubscriptionInfoSelector } from 'store/TrailsStore';

const ProfilePopoverContentComponent: React.FC<ProfilePopoverContentViewProps> =
  ({ handleMyProfile, handleMyPurchases, handleRedeemCode, onSignOut, displayName }) => {

    let isFetchingSubscriptionInfoSelector = useSelector(getIsFetchingSubscriptionInfoSelector);
    let isActiveSubscriptionSelector = useSelector(getIsActiveSubscriptionSelector);
    let subscriptionInfoSelector = useSelector(getActiveSubscriptionInfoSelector);
  
    const onManageSubscriptionClick = useCallback(() => {
      window.open(subscriptionInfoSelector?.managementUrl, '_blank', 'noreferrer');
    }, [subscriptionInfoSelector]);  
  
    return (
      <S.Container>
        <S.DisplayName>{displayName}</S.DisplayName>
        <S.SelectableRow onClick={handleMyProfile}><FormattedMessage id="my_profile"/></S.SelectableRow>
        <S.SelectableRow onClick={handleMyPurchases}><FormattedMessage id="my_purchases"/></S.SelectableRow>
        { (!isFetchingSubscriptionInfoSelector && isActiveSubscriptionSelector && subscriptionInfoSelector?.managementUrl) ? ( 
            <S.SelectableRow onClick={onManageSubscriptionClick}><FormattedMessage id="manage_subscriptions"/></S.SelectableRow>
          ) : null
        }
        <S.SelectableRow onClick={handleRedeemCode}><FormattedMessage id="redeem_code"/></S.SelectableRow>
        <S.StyledDivider />
        <S.SelectableRow onClick={onSignOut}><FormattedMessage id="sign_out"/></S.SelectableRow>
      </S.Container>
    );
  };

export const ProfilePopoverContentView = memo(
  ProfilePopoverContentComponent,
  isEqual
);
