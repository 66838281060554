import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

interface ModalStyleProps {
  withBorderRadius?: boolean;
  closeButtonBackground?: string;
}

interface ContainerStyleProps {
  withBorderRadius?: boolean;
  minHeight?: number;
}

export const S = {
  Modal: styled(AntModal)<ModalStyleProps>`
    & .ant-modal-content {
      max-width: 100%;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      ${({ withBorderRadius, theme }) =>
        withBorderRadius ? `border-radius: ${theme.modal.dimensions.modalBorderRadius}px;` : ''}
    }

    & .ant-modal-close-x {
      margin: 10px;
      width: 30px;
      height: 30px;
      line-height: 35px;
      border-radius: 50%;
      background-color: ${({ closeButtonBackground = 'transparent' }) =>
        closeButtonBackground};
    }

    & .ant-modal-body {
      padding: 0;
      width: 100%;
    }
  `,
  Container: styled.div<ContainerStyleProps>`
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.guideViewDetail.colors.background};
    ${({ withBorderRadius }) =>
      withBorderRadius ? 'border-radius: 25px;' : ''}
    ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : '')}
  `,
};
