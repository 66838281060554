import styled from 'styled-components';

export const S = {
  Container: styled.div`
    height: 100%;
    flex: 1;
  `,

  CardWrapper: styled.div``,
};
