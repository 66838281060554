import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { PictureCarousel } from '../PictureCarousel/PictureCarousel';

import { S } from './ViewDetail.styles';
import { ViewDetailProps } from './ViewDetail.interface';
import { BackButton } from 'shared/BackButton/BackButton';

const ViewDetailComponent: React.FC<ViewDetailProps> = ({
  children,
  iconView,
  picturesView,
  onBackButtonClick,
}) => {
  const goToPreviousPage = () => {
    onBackButtonClick();
  };

  return (
    <S.Container>
      <PictureCarousel picturesView={picturesView} />
      <S.ContentContainer>
        <S.Content>
          <S.BackButtonWrapper>
            <BackButton onClick={goToPreviousPage} />
          </S.BackButtonWrapper>
          <S.IconView src={iconView} />
          {children}
        </S.Content>
      </S.ContentContainer>
    </S.Container>
  );
};

export const ViewDetail = memo(ViewDetailComponent, isEqual);
