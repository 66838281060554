export const getUniqueObjectsArrayByPropFunction = (prop: string | number) => (list: any[]) => {
    const uniques: any = {}
    return list.reduce(
        (result, item) => {
            if (uniques[item[prop]]) return result
            uniques[item[prop]] = item
            return [...result, item]
        },
        [],
    )
}