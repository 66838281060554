import ReactDOM from 'react-dom';
import { S } from './LoadingOverlay.styles';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { LoadingOverlayProps } from './LoadingOverlay.interfaces';

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  relative = false,
}) => {
  if (relative) {
    return (
      <S.Overlay>
        <LoadingSpinner size="large" />
      </S.Overlay>
    );
  }

  const appContainer = document.getElementById('AppContainer');

  if (!appContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <S.Overlay>
      <LoadingSpinner size="large" />
    </S.Overlay>,
    appContainer
  );
};
