import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';
import { ProfileSettingsView } from '../../views/ProfileSettings/ProfileSettings';
import { ProfileSettingsContainerProps } from './ProfileSettings.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/rootReducer';
import {
  selectUserProfile,
  userActions,
} from '../../store/UserStore';
import pic from '../../views/ProfilePage/pic.jpg';
import back from '../../views/ProfilePage/back.jpg';
import { getBase64EncodedFile } from '../../utils/getBase64EncodedFile';
import { SubmitProfilePrimaryInfoForm } from '../../views/ProfilePrimaryInfoForm/ProfilePrimaryInfoForm.interfaces';
import { UserProfileWithPurchases } from 'atlasguides-web-common/src/functions/users/dto/user-profile.dto';

const ProfileSettingsContainerComponent: React.FC<ProfileSettingsContainerProps> =
  ({ visibility, onClose }) => {
    const dispatch = useDispatch();
    const userProfile = useSelector<AppState, UserProfileWithPurchases | null>(
      selectUserProfile
    );
    const [selectedProfileImage, setSelectedProfileImage] = useState<
      string | null
    >(null);
    const [selectedCoverImage, setSelectedCoverImage] = useState<string | null>(
      null
    );
    const [primaryInformationVisibility, setPrimaryInformationVisibility] =
      useState(false);

    const profilePrimaryInfoInitialFormValues = useMemo(
      () => ({
        bio: userProfile?.primaryInfo.bio || '',
        coverPhoto: '',
        displayName: userProfile?.primaryInfo.displayName || '',
        firstName: userProfile?.primaryInfo.firstName || '',
        lastName: userProfile?.primaryInfo.lastName || '',
        profilePhoto: '',
        username: userProfile?.primaryInfo.username || '',
        isPublicProfile: !!userProfile?.primaryInfo.isPublicProfile,
      }),
      [userProfile?.primaryInfo]
    );

    const submitProfileSettings = useCallback<SubmitProfilePrimaryInfoForm>(
      async (values, actions) => {
        try {
          const primaryInfo = {
            userId: 'mine',
            bio: values.bio,
            displayName: values.displayName,
            firstName: values.firstName,
            isPublicProfile: values.isPublicProfile,
            lastName: values.lastName,
          };

          // Promise is needed to handle formik `submitting` property automatically by itself.
          await new Promise((resolve, reject) => {
            dispatch(
              userActions.getUpdatedProfileRequest({
                ...primaryInfo,
                onSuccess: resolve as () => void,
                onError: reject as () => void,
              })
            );
          });
        } catch (e) {
        } finally {
          onClose();
        }
      },
      [dispatch]
    );

    const onChangeCoverPhoto = useCallback(async (file: File) => {
      const base64FormatUrl: string = await getBase64EncodedFile(file);
      dispatch(
        userActions.updateUserCoverImageRequest({
          base64EncodedPhoto: base64FormatUrl,
        })
      );
    }, []);

    const onChangeProfilePhoto = useCallback(async (file: File) => {
      const base64FormatUrl: string = await getBase64EncodedFile(file);
      dispatch(
        userActions.updateUserImageProfileRequest({
          base64EncodedPhoto: base64FormatUrl,
        })
      );
    }, []);

    const handleChangePrimaryInformationVisibility = useCallback(() => {
      setPrimaryInformationVisibility(true);
    }, []);

    useEffect(() => {
      if (!visibility) {
        setSelectedProfileImage(null);
        setSelectedCoverImage(null);
        setPrimaryInformationVisibility(false);
      }
    }, [visibility]);

    return (
      <ProfileSettingsView
        visibility={visibility}
        onClose={onClose}
        handleSubmit={submitProfileSettings}
        userPhoto={
          selectedProfileImage || userProfile?.photos.profilePhotoUrl || pic
        }
        userCoverPhoto={
          selectedCoverImage || userProfile?.photos.profileCoverImageUrl || back
        }
        onChangeCoverPhoto={onChangeCoverPhoto}
        onChangeProfilePhoto={onChangeProfilePhoto}
        profilePrimaryInfoInitialFormValues={
          profilePrimaryInfoInitialFormValues
        }
        primaryInformationVisibility={primaryInformationVisibility}
        handleChangePrimaryInformationVisibility={
          handleChangePrimaryInformationVisibility
        }
      />
    );
  };

export const ProfileSettingsContainer = memo(
  ProfileSettingsContainerComponent,
  isEqual
);
