import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import { AppState } from 'store/rootReducer';
import { CartProps } from './Cart.interface';
import { CartView } from 'views/Cart/CartView';
import { AppDispatch } from 'store';
import { AppHistoryState, AppLocationState } from 'router';
import {
  actions,
  selectCartPopoverVisibility,
  selectShopifyCart
} from 'store/CartStore';
import { useViewportSize } from '../../hooks/useViewportSize';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';

const CartComponent: React.FC<CartProps> = ({ icon }) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory<AppHistoryState>();
  const location = useLocation<AppLocationState>();
  const shopifyCart = useSelector<AppState, any>(selectShopifyCart);
  const popoverVisibility = useSelector(selectCartPopoverVisibility);
  //const isMergingCartItems = useSelector(selectIsMergingCartItems);
  let shopifyCheckoutUrl = ''
  if (shopifyCart.checkoutUrl) {
    shopifyCheckoutUrl = shopifyCart.checkoutUrl
    console.log("Shopify Web Url: ", shopifyCheckoutUrl);
  }
  const { isMobile } = useViewportSize();
  console.log("CART COMPONENT SHOPIFY TRAILS LIST: ", shopifyCart);
  let lineItems = []
  if (shopifyCart.lineItems !== null && shopifyCart.lineItems !== undefined ) {
    console.log("CART COMPONENT SHOPIFY TRAILS LIST ITEMS: ", shopifyCart.lineItems);
    lineItems = shopifyCart.lineItems
  }

  const handleRemoveShopifyItem = useCallback(
    (id: string) => {
      console.log("handleRemoveShopifyItem: ", id);
      dispatch(actions.removeLineItemFromShopify(id));
    },
    [dispatch]
  );

  const handleGoToSignIn = useCallback(() => {
    history.push('/login');
  }, [history]);

  const handleGoToExploreTrails = useCallback(() => {
    const targetLocation = '/guides';
    location.pathname !== targetLocation && history.push(targetLocation);
  }, [location, history]);

  const handleGoToCheckout = useCallback(() => {
    history.push('/checkout');
  }, [history]);

  const handleClearCart = useCallback(() => {
    //dispatch(actions.clearCart());
  }, []);

  const onVisibleChange = useCallback((visible: boolean) => {
    dispatch(actions.setPopoverVisibility(visible));
  }, []);

  const localStorage = LocalStorageManager.getInstance();
  const selectedCountryCode = localStorage.getItem('shopifyCountry');

  return (
    <CartView
      selectedCountryCode={selectedCountryCode}
      icon={icon}
      isCartEmpty={lineItems.length < 1}
      lineItems={lineItems}
      areTrailsLoading={false}
      handleGoToExploreTrails={handleGoToExploreTrails}
      handleGoToCheckout={handleGoToCheckout}
      handleClearCart={handleClearCart}
      handleRemoveShopifyItem={handleRemoveShopifyItem}
      isMobile={isMobile}
      popoverVisibility={popoverVisibility}
      onVisibleChange={onVisibleChange}
      shopifyCheckoutUrl={shopifyCheckoutUrl}
      handleGoToSignIn={handleGoToSignIn}
    />
  );
};

export const Cart = memo(CartComponent, isEqual);
