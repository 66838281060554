import { actions } from 'store/GuideUse/actions';
import {
  ElevationProfileExpansionLevels,
  FIRST_ELEVATION_PROFILE_EXPANSION_LEVEL,
  LAST_ELEVATION_PROFILE_EXPANSION_LEVEL,
} from '../../commonTypes/enums';
import { DEFAULT_LAYER } from '../../views/GuideUse/LayersModal/layers';

export const initialState = {
  guideData: {} as any,
  isPointsMenuMode: false as boolean,
  isWaypointDetailsOpen: false as boolean,
  isFetchingGuideData: true as boolean,
  selectedWaypoint: null as any | null,
  isWaypointSelected: false as boolean,
  isMapSettingsOpen: false as boolean,
  isLayersModalOpen: false as boolean,
  comments: [] as any | null,
  isWaypointCommentsFetching: true as boolean,
  mapLayerLink: DEFAULT_LAYER.link as string,
  mapLayerAttribution: DEFAULT_LAYER.attribution as string,
  isLeftMenuCollapsed: true as boolean,
  isWaypointSearchingState: false as boolean,
  searchedWaypointsList: [] as any,
  waypointSearchedValue: '' as string,
  isElevationProfileExpanded: false as boolean,
  isElevationModalOpened: false as boolean,
  elevationProfileExpansionLevel: ElevationProfileExpansionLevels.level_2,
};

export type State = typeof initialState;

export type PropActions<T> = T extends { [key: string]: infer R } ? R : never;
export type ReturnAnyActions<
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<PropActions<T>>;

export type GuideUseAnyActions = ReturnAnyActions<typeof actions>;
export const reducer = (
  state: State = initialState,
  action: GuideUseAnyActions
): State => {
  switch (action.type) {
    case '@/GET_GUIDE_DATA_REQUEST':
      return {
        ...state,
        isFetchingGuideData: true,
      };
    case '@/GET_GUIDE_DATA_RESPONSE':
      return {
        ...state,
        isFetchingGuideData: false,
        guideData: action.payload,
      };
      case '@/SET_IS_POINTS_MENU_MODE':
      return {
        ...state,
        isPointsMenuMode:
          action.payload || action.payload === false
            ? action.payload
            : !state.isPointsMenuMode,
      };
    case '@/SET_IS_WAYPOINT_DETAILS_OPEN':
      return {
        ...state,
        isWaypointDetailsOpen:
          action.payload || action.payload === false
            ? action.payload
            : !state.isPointsMenuMode,
      };
    case '@/SET_SELECTED_WAYPOINT':
      return {
        ...state,
        selectedWaypoint:
          state.guideData.waypoints?.find(
            (waypoint: any) => waypoint.id === action.payload.id
          ) || null,
        isWaypointSelected: action.payload.id ? true : false,
      };
    case '@/SET_IS_MAP_SETTINGS_OPEN':
      return {
        ...state,
        isMapSettingsOpen: action.payload,
      };
    case '@/SET_IS_LAYERS_MODAL_OPEN':
      return {
        ...state,
        isLayersModalOpen: action.payload,
      };
    case '@/GET_WAYPOINT_COMMENTS_REQUEST':
      return {
        ...state,
        isWaypointCommentsFetching: true
      };
    case '@/GET_WAYPOINT_COMMENTS_RESPONSE':
      return {
        ...state,
        comments: action.payload,
        isWaypointCommentsFetching: false,
      };
    case '@/SET_MAP_LAYER_LINK':
      return {
        ...state,
        mapLayerLink: action.payload,
      };
    case '@/SET_MAP_LAYER_ATTRIBUTION':
      return {
        ...state,
        mapLayerAttribution: action.payload,
      };
    case '@/SET_IS_LEFT_MENU_COLLAPSED':
      return {
        ...state,
        isLeftMenuCollapsed: action.payload,
      };
    case '@/SET_IS_WAYPOINT_SEARCHING_STATE':
      return {
        ...state,
        isWaypointSearchingState: action.payload,
      };
    case '@/SET_SEARCHED_WAYPOINTS_LIST':
      return {
        ...state,
        searchedWaypointsList: action.payload,
      };
    case '@/SET_WAYPOINT_SEARCHED_VALUE':
      return {
        ...state,
        waypointSearchedValue: action.payload,
      };
    case '@/INCREASE_ELEVATION_PROFILE_EXPANSION_LEVEL':
      const increasedElevationProfileExpansionLevel =
        state.elevationProfileExpansionLevel + 1;
      return {
        ...state,
        elevationProfileExpansionLevel:
          increasedElevationProfileExpansionLevel >
          LAST_ELEVATION_PROFILE_EXPANSION_LEVEL
            ? LAST_ELEVATION_PROFILE_EXPANSION_LEVEL
            : increasedElevationProfileExpansionLevel,
      };
    case '@/DECREASE_ELEVATION_PROFILE_EXPANSION_LEVEL':
      const decreasedElevationProfileExpansionLevel =
        state.elevationProfileExpansionLevel - 1;
      return {
        ...state,
        elevationProfileExpansionLevel:
          decreasedElevationProfileExpansionLevel <
          FIRST_ELEVATION_PROFILE_EXPANSION_LEVEL
            ? FIRST_ELEVATION_PROFILE_EXPANSION_LEVEL
            : decreasedElevationProfileExpansionLevel,
      };
    case '@/SET_ELEVATION_PROFILE_EXPANSION_LEVEL':
      return {
        ...state,
        elevationProfileExpansionLevel: action.payload,
      };
    case '@/SET_IS_ELEVATION_MODAL_OPEN':
      return {
        ...state,
        isElevationModalOpened: action.payload,
      };
    default:
      return state;
  }
};
