import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { DC, DialogCard } from 'shared/DialogCard/DialogCard.styles';
import { S as AuthPageWrapperStyles } from 'views/AuthPageWrapper/AuthPageWrapper.styles';
import { ForgotPasswordForm } from '../ForgotPasswordForm/ForgotPasswordForm';
import { CardForgotPasswordViewProps } from './CardForgotPassword.interfaces';
import { Link } from '../../controls/Link/Link';
import { FormattedMessage } from 'react-intl';

const CardForgotPasswordViewComponent: React.FC<CardForgotPasswordViewProps> =
  ({ handleForgotPasswordSubmit }) => {
    return (
      <AuthPageWrapperStyles.ContentContainer>
        <DialogCard>
          <DC.WithMargins>
            <DC.TitleSection>
              <DC.Title><FormattedMessage id="forgot_password"/></DC.Title>
              <DC.TitleText>
                <FormattedMessage id="forgot_password_info"/>
              </DC.TitleText>
            </DC.TitleSection>
            <DC.BodySection>
              <ForgotPasswordForm handleSubmit={handleForgotPasswordSubmit} />
            </DC.BodySection>
            <DC.FooterSection>
              <Link to="/login" type="light">
                <DC.FooterText><FormattedMessage id="forgot_password_cancel"/></DC.FooterText>
              </Link>
            </DC.FooterSection>
          </DC.WithMargins>
        </DialogCard>
      </AuthPageWrapperStyles.ContentContainer>
    );
  };

export const CardForgotPasswordView = memo(
  CardForgotPasswordViewComponent,
  isEqual
);
