export const getBase64EncodedFile: (file: File) => Promise<string> = (
  file: File
) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      }
    };
    reader.onerror = error => reject(error);
  });
