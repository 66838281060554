import { IAxiosDirector } from './AxiosDirector.interfaces';
import { toast } from 'react-toastify';
import { importantToastConfig } from '../../configs/toastsConfigs';
import { IAxiosBuilder } from '../AxiosBuilder/AxiosBuilder.interfaces';

const errorMap = (id: number): string => {
  switch (true) {
  case id <= 99:
    return "Something went wrong! Please try refreshing the page";
    break;
  case id >= 100 && id <= 199:
    return "Uh-Oh! We're having some trouble with your cart";
    break;
  case id >= 200 && id <= 299:
    return "Sorry, something is wrong with our commects feature right now";
    break;
  case id >= 300 && id <= 399:
    return "Yikes! Something went wrong with your payment!";
    break;
  case id >= 400 && id <= 499:
    return "We're having trouble with our resources";
    break;
  case id >= 500 && id <=599:
    return "Something went wrong with your user profile";
    break;
  case id >= 600 && id <=699:
    return "Something went wrong with our guides";
  case id >= 700 && id <=799:
    return "Something went wrong with our Authentication";
  default:
    return "Sorry we're not sure what happened here!";
    break;
}
}

export class AxiosDirector implements IAxiosDirector {
  private builder: IAxiosBuilder;

  constructor(builder: IAxiosBuilder) {
    this.constructAxiosWithToastInterceptor =
      this.constructAxiosWithToastInterceptor.bind(this);
    this.builder = builder;
  }

  constructAxiosWithToastInterceptor() {
    return this.builder
      .setRequestInterceptor({
        onRejected: error => {
          toast.error(error.toString(), importantToastConfig);
        },
      })
      .setResponseInterceptor({
        onRejected: error => {
          // console.log("ERROR FORMAT: ", error);
          if( error.response ){
            // console.log("ERROR Response: ", error.response.data); // => the response payload
            const errorId = error.response.data.id;
            if (errorId && (errorId === 107 || errorId === 104 || errorId === 105 || errorId === 502 || errorId === 503)) {
              // supress error
              // console.log("Error Supressed");
            } else {
              toast.error(errorMap(error.response.data.id), importantToastConfig);
            }
          } else {
            toast.error(error.toString(), importantToastConfig);
          }

        },
      })
      .getInstance();
  }
}
