import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';
import { withAntdRoundedSubmenuPopup } from 'shared.styles';

const CARET_ICON_OFFSET = '12px';

const { SubMenu: AntSubMenu, Item: AntMenuItem } = AntMenu;

export const S = {
  MenuContainer: styled.div`
    ${withAntdRoundedSubmenuPopup}

    & > .ant-menu-horizontal > .ant-menu-item,
    & > .ant-menu-horizontal > .ant-menu-submenu {
      margin-right: 13px;
      margin-left: 13px;
    }

    & .ant-menu-horizontal > .ant-menu-item a:hover,
    & .ant-menu-submenu > .ant-menu-submenu-title:hover,
    & .ant-menu-submenu > .ant-menu-submenu-open {
      color: ${({ theme }) => theme.header.colors.menuItemTextHover};
    }

    & .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: ${({ theme }) => theme.header.colors.subMenuItemTextHover};
    }

    & .ant-menu-horizontal .ant-menu-item,
    .ant-menu-horizontal .ant-menu-submenu-title {
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-bottom: 2px solid transparent;
    }

    & .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
      color: ${({ theme }) => theme.header.colors.menuItemTextHover};
      border-bottom: 2px solid transparent;
    }
  `,

  Menu: styled(AntMenu)`
    display: flex;
    justify-content: flex-end;
    background: ${({ theme }) => theme.header.colors.background};
    color: ${({ theme }) => theme.header.colors.menuItemText};
    border: 0;

    & .ant-menu-item {
      margin-right: 0;
    }

    & .ant-menu-submenu-title {
      margin-right: ${CARET_ICON_OFFSET};
    }

    & .ant-menu-item,
    & .ant-menu-submenu-title {
      text-transform: none;
    }

    & > .ant-menu-item a {
      color: ${({ theme }) => theme.header.colors.menuItemText};
    }

    // re-styles the icon for expandable elements

    & > .ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
      display: block;
      right: 0;
      transform: translateX(${CARET_ICON_OFFSET}) rotate(90deg);
      color: ${({ theme }) => theme.header.colors.menuItemText};
    }

    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-open
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
    .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.header.colors.menuItemTextHover};
      transform: translateX(${CARET_ICON_OFFSET}) rotate(90deg) rotateY(180deg);
    }
  `,

  MenuItem: styled(AntMenuItem)`
    & a:hover {
      color: ${({ theme }) => theme.header.colors.subMenuItemTextHover};
    }
  `,

  SubMenu: styled(AntSubMenu)`
    background: ${({ theme }) => theme.header.colors.background};
  `,
};
