import styled from 'styled-components';

import background from 'static/whitney.jpg';
// import backgroundPlaceholder from 'static/whitney-compressed.jpg';

export const WhitneyBackground = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
