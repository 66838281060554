import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { DC, DialogCard } from 'shared/DialogCard/DialogCard.styles';
import { S as AuthPageWrapperStyles } from 'views/AuthPageWrapper/AuthPageWrapper.styles';
import { CardResetPasswordViewProps } from './CardResetPassword.interfaces';
import { ResetPasswordForm } from '../ResetPasswordForm/ResetPasswordForm';
import { FormattedMessage } from 'react-intl';

const CardResetPasswordViewComponent: React.FC<CardResetPasswordViewProps> = ({
  handleResetPasswordSubmit,
}) => {
  return (
    <AuthPageWrapperStyles.ContentContainer>
      <DialogCard>
        <DC.WithMargins>
          <DC.TitleSection>
            <DC.Title><FormattedMessage id="reset_password"/></DC.Title>
            <DC.TitleText><FormattedMessage id="reset_password_info"/></DC.TitleText>
          </DC.TitleSection>
          <DC.BodySection>
            <ResetPasswordForm handleSubmit={handleResetPasswordSubmit} />
          </DC.BodySection>
        </DC.WithMargins>
      </DialogCard>
    </AuthPageWrapperStyles.ContentContainer>
  );
};

export const CardResetPasswordView = memo(
  CardResetPasswordViewComponent,
  isEqual
);
