import 'crypto-js/lib-typedarrays';
import { Auth } from 'aws-amplify';
import { cognitoConfig } from 'configs/cognitoConfig';
import {
  CognitoEmailSignUpRequest,
  CognitoEmailSignInRequest,
} from './APICognitoRequestsManager.interfaces';
import { CognitoError } from "adapters/APICognitoAuth/apiCognitoAuthAdapters.interfaces";
export class APICognitoRequestsManager {
  private static instance: APICognitoRequestsManager | null = null;

  private constructor() {
    Auth.configure(cognitoConfig);
  }

  public static getInstance() {
    if (!APICognitoRequestsManager.instance) {
      APICognitoRequestsManager.instance = new APICognitoRequestsManager();
    }
    return APICognitoRequestsManager.instance;
  }

  public async signUpWithEmailAndPassword(data: CognitoEmailSignUpRequest) {
    return Auth.signUp(data);
  }

  public async signInWithEmailAndPass(data: CognitoEmailSignInRequest) {
    console.log("APICOGNITOREQUESTMANAGER SIGNINWITHEMAILANDPASS : ", data);
    try {
      const response = await Auth.signIn(data)
      console.log("APICOGNITOREQUESTMANAGER Auth.signIn(data) response: ", response);
      return false;
    } catch (error) {
      let cognitoError = error as CognitoError;

      console.log("APICOGNITOREQUESTMANAGER Auth.signIn(data) error: ", cognitoError);
      const code = cognitoError.code;
      console.log("Error Code: ", code);
      // console.log("Trying Switch");

      this.trySwitch(code, data);
      return false;
    }
    return false;
  }

  public async trySwitch(code: string, data:CognitoEmailSignInRequest) {
    switch (code) {
        case 'UserNotFoundException':
            // console.log(" UserNotFoundException : ");
            break;
        case 'NotAuthorizedException':
            // console.log(" NotAuthorizedException : ");
            break;
        case 'PasswordResetRequiredException':
           // console.log(" PasswordResetRequiredException : ");
           break;
       case 'UserLambdaValidationException':
           // console.log(" UserLambdaValidationException : ");
           break;
        case 'UserNotConfirmedException':
            // console.log(" UserNotConfirmedException : ");
            break;
        default:
            // console.log(" DEFAULR : ");
            break;
    }
  }

  public async confirmEmail(username: string, code: string) {
    return Auth.confirmSignUp(username, code);
  }

  public async resendConfirmationCode(username: string) {
    return Auth.resendSignUp(username);
  }
}
