import React, { memo } from 'react';
import { Modal } from '../../shared/Modal/Modal';
import { theme } from '../../theme';
import { ProfileSettingsViewProps } from './ProfileSettings.interfaces';
import { ProfileSettingsForm } from '../ProfileSettingsForm/ProfileSettingsForm';
import { S } from './ProfileSettings.styles';
import { ProfileHeaderContainer } from '../../containers/ProfileHeader/ProfileHeader';

const ProfileSettingsViewComponent: React.FC<ProfileSettingsViewProps> = ({
  visibility,
  onClose,
  handleSubmit,
  onChangeCoverPhoto,
  onChangeProfilePhoto,
  userPhoto,
  userCoverPhoto,
  profilePrimaryInfoInitialFormValues,
  handleChangePrimaryInformationVisibility,
  primaryInformationVisibility,
}) => {
  return (
    <Modal
      visible={visibility}
      onCancel={onClose}
      zIndex={999}
      footer={null}
      centered
      closeButtonBackground={theme.profilePage.colors.closeButton}
      withBorderRadius
    >
      <ProfileHeaderContainer
        picturesView={[userCoverPhoto]}
        backgroundPictureHeight={300}
        userPhoto={userPhoto}
      />
      <S.FormContainer>
        <ProfileSettingsForm
          onChangeCoverPhoto={onChangeCoverPhoto}
          onChangeProfilePhoto={onChangeProfilePhoto}
          handleSubmit={handleSubmit}
          initialValues={profilePrimaryInfoInitialFormValues}
          primaryInformationVisibility={primaryInformationVisibility}
          handleChangePrimaryInformationVisibility={
            handleChangePrimaryInformationVisibility
          }
        />
      </S.FormContainer>
    </Modal>
  );
};

export const ProfileSettingsView = memo(ProfileSettingsViewComponent);
