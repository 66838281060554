import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { TrailsStoreContainer } from 'containers/TrailsStore/TrailsStore';
import { GuideUseContainer } from 'containers/GuideUse/GuideUse';
import { ConfirmationContainer } from 'containers/Confirmation/Confirmation';
import { AuthPageWrapperView } from 'views/AuthPageWrapper/AuthPageWrapper';

export const MainRouter = () => {
  const params = new URLSearchParams(useLocation().search);
  console.log("params: " + params)
  const subtotal = params.get('subtotal');
  const order_number = params.get('order_number');

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/guides" />
      </Route>
      <Route exact path={['/guides', '/guides/:trailId']}>
        <TrailsStoreContainer />
      </Route>
      <Route
        path={[
          '/login',
          '/create-account',
          '/confirm-email-notice',
          '/forgot',
          '/reset',
        ]}
      >
        <AuthPageWrapperView />
      </Route>
      <Route exact path="/guides/:trailId/geo">
        <GuideUseContainer />
      </Route>
      <Route path="/thank-you">
        <ConfirmationContainer subtotal={subtotal} order_number={order_number} />
      </Route>
    </Switch>
  );
};
