import { S } from './CartIsEmptyMessage.styles';
import { CartIsEmptyMessageProps } from './CartIsEmptyMessage.interface';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { FormattedMessage  } from 'react-intl';

export const CartIsEmptyMessage: React.FC<CartIsEmptyMessageProps> = ({
  size = 'large',
  fitFullContainerSize = false,
  actionButtonLabel,
  actionButtonType = 'primary',
  actionButtonHandler,
}) => {
  return (
    <S.Container fitFullContainerSize={fitFullContainerSize}>
      <S.CartIsEmptyHeader size={size}><FormattedMessage id="cart_is_empty"/></S.CartIsEmptyHeader>
      <S.AddGuidesPrompt size={size}><FormattedMessage id="cart_add_guide"/></S.AddGuidesPrompt>
      {actionButtonHandler ? (
        <BaseButton
          type={actionButtonType}
          label={actionButtonLabel}
          onClick={actionButtonHandler}
        />
      ) : null}
    </S.Container>
  );
};
