import { fetcher } from '../../configure'

export type ConfirmResetPasswordRequest = (ConfirmResetPasswordByEmailRequest | ConfirmResetPasswordByUsernameRequest) & ResetInfo

export type ResetInfo = {
    confirmationCode: string
    password: string
}



export type ConfirmResetPasswordStatus = 'SUCCESS' | 'INVALID_CODE' | 'USER_NOT_EXIST' | 'INTERNAL_ERROR'

export type ConfirmResetPasswordResponse = {
	success: boolean
	status: ConfirmResetPasswordStatus
}

type ConfirmResetPasswordByEmailRequest = {
	email: string
}

type ConfirmResetPasswordByUsernameRequest = {
	username: string
}

export type ConfirmResetPasswordEndpoint = (
	request: ConfirmResetPasswordRequest
) => Promise<ConfirmResetPasswordResponse>

export const confirmResetPassword: ConfirmResetPasswordEndpoint = async (request) => {
	return await fetcher({
		method: 'post',
		path: '/users/confirm-reset-password',
		body: request,
	})
}
