import {DistanceUnitInfo, ElevationUnitInfo} from 'commonTypes/CommonStore/distanceUnits'

export const actions = {
  createShopifyCart: (payload: any) =>
    ({
      type: '@/CREATE_SHOPIFY_CART',
      payload,
    } as const),
  setShopifyCart: (payload: any) =>
    ({
      type: '@/SET_SHOPIFY_CART',
      payload,
    } as const),
  setDistanceUnit: (distanceUnitInfo: DistanceUnitInfo) =>
    ({
      type: '@/SET_DISTANCE_UNIT',
      payload: distanceUnitInfo,
    } as const),
  setElevationUnit: (elevationUnitInfo: ElevationUnitInfo) =>
    ({
      type: '@/SET_ELEVATION_UNIT',
      payload: elevationUnitInfo,
    } as const),
  setClockwiseDirectionTrails: (trailIds: string[]) =>
    ({
      type: '@/SET_CLOCKWISE_DIRECTION_TRAILS',
      payload: trailIds,
    } as const),
}
