import { memo } from 'react';
import { Link } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { ControlsPanel } from 'shared/ControlsPanel/ControlsPanel';

import { S } from './ControlsPanel.styles';
import { GuideUseControlsPanelProps } from 'containers/GuideUse/GuideUse.interface';
import { useIntl } from 'react-intl';


const ControlsPanelComponent: React.FC<GuideUseControlsPanelProps> = ({
  isMobile,
  isUnlayoutMode,
  guideName,
}) => (
  <ControlsPanel isUnlayoutMode={isUnlayoutMode} isMobile={isMobile}>
    <S.ControlWrapper>
      <Link to={`/guides/`}>
        <S.AllGuidesButton label={useIntl().formatMessage({id:"all_guides"}, {br:<br/>})} type="default" />
      </Link>
    </S.ControlWrapper>
    <S.TrailTitleButtonWrapper>
      <S.TrailTitleButton label={guideName} type="primary" fullwidth />
    </S.TrailTitleButtonWrapper>
  </ControlsPanel>
);

export const GuideUseControlsPanel = memo(ControlsPanelComponent, isEqual);
