import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Field, FieldProps } from 'formik';
import { FormikFieldProps } from '../FormikField.interfaces';
import { InputProps } from 'antd';
import {
  TextInput as Input,
  PasswordInput as Password,
} from 'controls/BaseTextInput/BaseInput';
import { S } from './InputFormik.styles';

export type ForikInputProps = FormikFieldProps & InputProps;

const InputFormikComponent = ({
  name,
  validate,
  onChange: _onChange,
  onBlur: _onBlur,
  topLabel,
  hasCharactersCounter,
  ...otherProps
}: ForikInputProps) => {
  return (
    <Field name={name} validate={validate}>
      {({ field: { value, onChange, onBlur } }: FieldProps) => (
        <>
          {topLabel && <S.TopLabel>{topLabel}</S.TopLabel>}
          <Input
            name={name}
            value={value}
            onChange={event => {
              onChange(event);
              _onChange && _onChange(event);
            }}
            onBlur={event => {
              onBlur(event);
              _onBlur && _onBlur(event);
            }}
            {...otherProps}
          />
          {hasCharactersCounter && (
            <S.CounterContainer>
              {value.length}
              {otherProps.maxLength && `/${otherProps.maxLength}`}
            </S.CounterContainer>
          )}
        </>
      )}
    </Field>
  );
};

const PasswordFormikComponent = ({
  name,
  validate,
  onChange: _onChange,
  onBlur: _onBlur,
  topLabel,
  hasCharactersCounter,
  ...otherProps
}: ForikInputProps) => {
  return (
    <Field name={name} validate={validate}>
      {({ field: { value, onChange, onBlur } }: FieldProps) => (
        <>
          {topLabel && <S.TopLabel>{topLabel}</S.TopLabel>}
          <Password
            name={name}
            value={value}
            onChange={event => {
              onChange(event);
              _onChange && _onChange(event);
            }}
            onBlur={event => {
              onBlur(event);
              _onBlur && _onBlur(event);
            }}
            {...otherProps}
          />
          {hasCharactersCounter && (
            <S.CounterContainer>
              {value.length}
              {otherProps.maxLength && `/${otherProps.maxLength}`}
            </S.CounterContainer>
          )}
        </>
      )}
    </Field>
  );
};

export const InputFormik = memo(InputFormikComponent, isEqual);
export const PasswordFormik = memo(PasswordFormikComponent, isEqual);
