import {
  MutableRefObject,
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { Slider } from 'controls/Slider/Slider';
import { getDistanceUnitSelector } from 'store/Common/selectors';

import { TrailLengthProps } from '../Filter.interface';
import { S } from './TrailLength.styles';
import { FormattedMessage } from 'react-intl';

const TrailLengthComponent: React.FC<TrailLengthProps> = ({
  filterParamsState: { minLength: minLengthParam, maxLength: maxLengthParam },
  selectedFilters,
  handleSetSelectedFilters,
}) => {
  const { minLength, maxLength } = selectedFilters;
  const distanceUnit = useSelector(getDistanceUnitSelector);
  const executeTimerRef: MutableRefObject<NodeJS.Timeout | null> = useRef(null);
  const [value, setValue] = useState<[number, number]>([
    minLength as number,
    maxLength as number,
  ]);

  const handleChange = (value: [number, number]) => {
    value[0] = Math.pow(10, value[0])
    value[1] = Math.pow(10, value[1])
    setValue(value);
  };

  const handleApplyChanges = useCallback(() => {
    minLength !== value[0] &&
      handleSetSelectedFilters({
        sectionName: 'minLength',
        value: value[0],
      });

    maxLength !== value[1] &&
      handleSetSelectedFilters({
        sectionName: 'maxLength',
        value: value[1],
      });
  }, [handleSetSelectedFilters, value, minLength, maxLength]);

  useEffect(() => {
    executeTimerRef.current && clearTimeout(executeTimerRef.current);

    executeTimerRef.current = setTimeout(handleApplyChanges, 500);
  }, [handleApplyChanges]);

  useEffect(() => {
    setValue([minLength as number, maxLength as number]);
  }, [minLength, maxLength]);

  const handleRenderValueSpan = (length: number) => {
    const formattedValue = length / distanceUnit.valueInMeters;

    return (
      <S.ValueSpan>
        {formattedValue < 0.1 || formattedValue > 1
          ? formattedValue.toFixed(0)
          : formattedValue.toFixed(1)}{' '}
        <FormattedMessage id={distanceUnit.shortName}/>
      </S.ValueSpan>
    );
  };

  return (
    <S.Container>
      {handleRenderValueSpan(value[0] || minLengthParam)}
      {` - `}
      {handleRenderValueSpan(value[1] || maxLengthParam)}
      <Slider
        range={true}
        value={[Math.log10(value[0] || minLengthParam), Math.log10(value[1] || maxLengthParam)]}
        max={Math.log10(maxLengthParam)}
        min={Math.log10(minLengthParam)}
        step={0.01}
        tipFormatter={null}
        onChange={handleChange}
      />
    </S.Container>
  );
};

export const TrailLength = memo(TrailLengthComponent, isEqual);
