import styled from 'styled-components';

export const S = {
  Container: styled.section``,

  TagWrapper: styled.span`
    display: inline-block;
    margin: 8px 0;
  `,
};
