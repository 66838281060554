import React, { memo, useCallback, useState } from 'react';
import isEqual from 'lodash.isequal';

import { makeMenu } from 'utils/makeMenu';
import { DrawerViewProps } from 'containers/Drawer/Drawer.interfaces';
import { S } from './Drawer.styles';
import { useAuth } from 'services/AuthManager/AuthManager.react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSignOutWithRedirect } from 'hooks/useSignOutWithRedirect';

const DrawerViewComponent: React.FC<DrawerViewProps> = ({
  isVisible,
  handelClose,
  isUserLoggedIn,
}) => {
  const { isSignedIn } = useAuth();
  const { signOut } = useSignOutWithRedirect();
  const [isProfilePageShown, setIsProfilePageShown] = useState<boolean>(false);
  const [isPurchasesShown, setIsPurchasesShown] = useState<boolean>(false);
  const [profilePopupVisibility, setProfilePopupVisibility] = useState(false);
  
  const handleSignOut = useCallback(async () => {
    await signOut();
    setIsProfilePageShown(false);
    setIsPurchasesShown(false);
    setProfilePopupVisibility(false);
    localStorage.removeItem('parseUserId');
    localStorage.removeItem('shopifyCartId');
  }, [signOut]);

  const menu = makeMenu(S.MenuItem, 'drawer-menu', isSignedIn, handleSignOut, false);

  return (
    <S.Drawer
      title={<S.GuthookGuidesLogo />}
      placement="right"
      width={global.window.innerWidth}
      onClose={handelClose}
      visible={isVisible}
    > 
      <S.Menu mode="inline" selectable={false} itemIcon={<S.RightIcon />}>
        {menu}
      </S.Menu>
    </S.Drawer>
  );
};

export const DrawerView = memo(DrawerViewComponent, isEqual);
