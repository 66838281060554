import { TileLayerInfo } from 'containers/GuideUse/GuideUse.interface';
import accuterra_outdoors_imperial from './static/accuterra_outdoors_imperial.png';
import accuterra_outdoors_imperial_m from './static/accuterra_outdoors_imperial_m.png';
import openStreetMaps from './static/openStreetMaps.png';
import satellite from './static/satellite.png';

const ACCUTERRA_API_KEY = 'jpDhoGj9SqSZ6KHKlM6QOYVYT14mOIpwnamodLyGXF5Q3UKf9W';
const MAPTILER_API_KEY = 'bMT3JRcEKWnWb1pP2h7I';

export const layers: TileLayerInfo[] = [
  {
    name: 'AccuTerra Outdoors Feet',
    link: `https://maps.accuterra.com/v1/raster/accuterra-outdoors/{z}/{x}/{y}.jpg?key=${ACCUTERRA_API_KEY}`,
    icon: accuterra_outdoors_imperial,
    attribution:
      '&copy; <a href="https://accuterra.com/">AccuTerra</a> contributors',
  },
  {
    name: 'AccuTerra Outdoors Meters',
    link: `https://maps.accuterra.com/v1/raster/accuterra-outdoors-m/{z}/{x}/{y}.jpg?key=${ACCUTERRA_API_KEY}`,
    icon: accuterra_outdoors_imperial_m,
    attribution:
      '&copy; <a href="https://accuterra.com/">AccuTerra</a> contributors',
  },
  {
    name: 'OpenStreetMap',
    link: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    icon: openStreetMaps,
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },
  // {
  //   name: 'MapTiler Satellite',
  //   link: `https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=${MAPTILER_API_KEY}`,
  //   icon: satellite,
  //   attribution:
  //     '&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> contributors',
  // },
];

export const DEFAULT_LAYER = layers[2];
