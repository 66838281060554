import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { useViewportSize } from 'hooks/useViewportSize';
import { GuideUseMapView } from 'views/GuideUseMap/GuideUseMap';

import { GuideUseMapContainerProps } from 'containers/GuideUseMap/GuideUseMap.interfaces';

const GuideUseMapComponent: React.FC<GuideUseMapContainerProps> = ({
  map,
  handleSetMap,
  waypointsData,
  trailsData,
}) => {
  const { isMobile } = useViewportSize();

  return (
    <GuideUseMapView
      map={map}
      handleSetMap={handleSetMap}
      isMobile={isMobile}
      trailsData={trailsData}
      waypointsData={waypointsData}
    />
  );
};

export const GuideUseMap = memo(GuideUseMapComponent, isEqual);
