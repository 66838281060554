import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { DrawerContainer } from 'containers/Drawer/DrawerContainer';
import { MainRouter } from 'containers/MainRouter/MainRouter';
import { HeaderContainer } from 'containers/Header/HeaderContainer';

import { S } from './App.styles';
import { AppViewProps } from 'containers/App/App.interfaces';
import { useViewportDimensions } from '../../hooks/useViewportDimensions';
import { useFullHeight } from 'hooks/useFullHeight';

const AppComponent: React.FC<AppViewProps> = ({
  isUserLoggedIn,
  useMobileLayout,
  isDrawerVisible,
  handleShowDrawer,
  handleCloseDrawer,
  isTabletLayout,
}) => {
  const height = useFullHeight();
  const { height: viewportHeight } = useViewportDimensions();

  return (
    <S.Container
      id="AppContainer"
      height={
        height
          ? `${height}px`
          : useMobileLayout
          ? `${viewportHeight}px`
          : '100vh'
      }
    >
      <HeaderContainer
        isUserLoggedIn={isUserLoggedIn}
        handleShowDrawer={handleShowDrawer}
        isMobileLayout={useMobileLayout}
        isTabletLayout={isTabletLayout}
      />
      {useMobileLayout && (
        <DrawerContainer
          isUserLoggedIn={isUserLoggedIn}
          isVisible={isDrawerVisible}
          handleClose={handleCloseDrawer}
        />
      )}
      <S.MainContent>
        <MainRouter />
      </S.MainContent>
    </S.Container>
  );
};

export const App = memo(AppComponent, isEqual);
