import { fetcher } from '../../configure'
import { BaseResponse } from '../../common/base-response'

export type PostSignupRequest = {
    displayName: string
    username: string
    email: string
    password: string
}

export enum LinkingStatus {
    LINKED = 'LINKED',
    USERNAME_IS_USED = 'USERNAME_IS_USED',
    EMAIL_IS_USED = 'EMAIL_IS_USED',
    ERROR = 'ERROR',
}

export type PostSignupResponse = BaseResponse & {
    success: boolean
    linkingStatus: LinkingStatus
}

export type PostSignupEndpoint = (
	request: PostSignupRequest
) => Promise<PostSignupResponse>

export const postSignup: PostSignupEndpoint = async (request): Promise<PostSignupResponse> => {
	return await fetcher({
		method: 'post',
		path: '/users/post-signup',
		body: request,
	})
}

