import { TrailLength } from './TrailLength/TrailLength';
import { ActivityType } from './ActivityType/ActivityType';
import { Region } from './Region/Region';
import { Environment } from './Environment/Environment';
import { Other } from './Other/Other';

import { FilterSectionProps } from './Filter.interface';

export const filterRenderConfig = [
  {
    title: 'trail_length',
    component: (props: FilterSectionProps) => <TrailLength {...props} />,
  },
  {
    title: 'activity_type',
    component: (props: FilterSectionProps) => <ActivityType {...props} />,
  },
  {
    title: 'region',
    component: (props: FilterSectionProps) => <Region {...props} />,
  },
  {
    title: 'environment',
    component: (props: FilterSectionProps) => <Environment {...props} />,
  },
  {
    title: 'other',
    component: (props: FilterSectionProps) => <Other {...props} />,
  },
];
