import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { theme } from 'theme';

export type HeaderSize = 'small' | 'large';

interface ModalStyleProps {
}

interface EmptyProps {
}

type HeaderProps = {
  size: HeaderSize;
};

export const S = {
  Modal: styled(AntModal)<ModalStyleProps>`
    & .ant-modal-content {
      ${({ theme }) =>
        `border-radius: ${theme.modal.dimensions.modalBorderRadius}px;`}
    }
  `,
  Header: styled.h5<HeaderProps>`
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '20px';
      case 'large':
        return '35px';
    }
  }};
  font-weight: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return 'normal';
      case 'large':
        return 'bold';
    }
  }};
  color: ${({ size }) => {
    switch (size) {
      case 'small':
        return 'unset';
      case 'large':
        return theme.cart.colors.cartIsEmptyMessage;
    }
  }};
  text-align: center;
  `,  
  Label: styled.label<HeaderProps>`
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '20px';
      case 'large':
        return '35px';
    }
  }};
  font-weight: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return 'normal';
      case 'large':
        return 'bold';
    }
  }};
  color: ${({ size }) => {
    switch (size) {
      case 'small':
        return 'unset';
      case 'large':
        return theme.cart.colors.cartIsEmptyMessage;
    }
  }};
  text-align: left;
  `,
  SpinnerContainer: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  `,  
  Error: styled.span<EmptyProps>`
  font-size: '20px';
  font-weight: 'bold';
  color: red;
  text-align: left;
  `,
  LoadingSpinnerWrapper: styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,  
};
