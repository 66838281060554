import {
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  MutableRefObject,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { SearchInput } from 'controls/SearchInput/SearchInput';
import { GuidesSearchProps } from './GuidesSearchInput.interface';
import { actions } from 'store/TrailsStore/actions';
import {
  getAllOrFilteredGuidesSelector,
  getSearchedValue,
} from 'store/TrailsStore/selectors';

import { FilteredGuides, Guides } from 'commonTypes/TrailsStore/guides';

export const searchByParams = (
  guides: Guides | FilteredGuides,
  value: string
) => {
  const lcValue = value.toLowerCase();

  return guides.filter(
    guide =>
      guide.guideId.toLowerCase().includes(lcValue || '') ||
      guide.guideName.toLowerCase().includes(lcValue || '') ||
      guide.guidePromoText?.toLowerCase()?.includes(lcValue || '') ||
      guide.guideDescription?.toLowerCase()?.includes(lcValue || '') ||
      guide.guideTags?.map(tag => tag?.toLowerCase())?.includes(lcValue || '')
  );
};

const GuidesSearchInputComponent: React.FC = (props: GuidesSearchProps) => {
  const dispatch = useDispatch();
  const [innerSearchedValue, setInnerSearchedValue] = useState('');
  const searchedValue = useSelector(getSearchedValue);
  const guidesList = useSelector(getAllOrFilteredGuidesSelector);
  const timerRef: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  const handleSearch = useCallback(() => {
    const searchedGuides = searchByParams(
      guidesList,
      innerSearchedValue.trim()
    );

    dispatch(actions.setSearchedGuides(searchedGuides));

    if (innerSearchedValue.trim().length > 1) {
      dispatch(actions.setIsSearchedState(true));
    } else {
      dispatch(actions.setIsSearchedState(false));
    }

    timerRef.current && clearTimeout(timerRef.current);
  }, [dispatch, guidesList, innerSearchedValue]);

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current);

    timerRef.current = setTimeout(handleSearch, 300);
  }, [handleSearch]);

  useEffect(() => {
    setInnerSearchedValue(searchedValue);
  }, [searchedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setSearchedValue(e.target.value));
  };

  return (
    <SearchInput
      {...props}
      onChange={handleChange}
      value={innerSearchedValue}
    />
  );
};

export const GuidesSearchInput = memo(GuidesSearchInputComponent, isEqual);
