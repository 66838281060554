import { css } from 'styled-components';

export const baseFormStyles = css`
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  & .ant-input {
    font-size: 17px;
    display: block;
  }

  && .ant-checkbox-wrapper {
    font-size: 10px;
  }

  && .ant-checkbox-wrapper > .ant-checkbox {
    transform: scale(0.75);
  }

  .ant-checkbox + span {
    padding-left: 3px;
    padding-right: 3px;
  }

  & > form > .ant-input,
  & > form > div {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
  }

  & .formik-error-message-placeholder {
    min-height: 12px;
    line-height: 10px;
    font-size: 10px;
  }
`;

export const withAntdRoundedSubmenuPopup = css`
  .ant-menu-submenu-popup,
  .ant-menu-submenu > .ant-menu {
    border-radius: ${({theme}) => theme.roundedSubmenuPopup.dimensions.borderRadius}px;
  }
`;

export const withAntdRoundedPopoverMenu = css`
  .ant-popover-inner {
    border-radius: ${({theme}) => theme.roundedPopoverMenu.dimensions.borderRadius}px;
  }
`;
