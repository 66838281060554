import { fetcher } from '../../configure'
import {ShopifyCartDto} from './dto/cart.dto'

export type CreateShopifyCartRequest = void

export type CreateShopifyCartResponse = ShopifyCartDto

export type CreateShopifyCartEndpoint = (
	request: CreateShopifyCartRequest
) => Promise<CreateShopifyCartResponse>

export const createShopifyCart: CreateShopifyCartEndpoint = async (request) => {
	return await fetcher({
		method: 'post',
		path: '/shopify/cart',
		body: request,
	})
}
