import { StyledTag, StyledCheckableTag } from './Tag.styles';
import { TagProps, CheckableTagProps } from './Tag.interface';

export const Tag: React.FC<TagProps> = ({ ...props }) => (
  <StyledTag {...props} />
);

export const CheckableTag: React.FC<CheckableTagProps> = ({ ...props }) => (
  <StyledCheckableTag {...props} />
);
