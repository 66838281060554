import {
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  MutableRefObject,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { SearchInput } from 'controls/SearchInput/SearchInput';
import { WaypointSearchProps } from './WaypointSearchInput.interface';
import { actions } from 'store/GuideUse/actions';
import {
  getAllWaypointsListSelector,
  getWaypointSearchedValueSelector,
  getIsWaypointSearchingStateSelector,
} from 'store/GuideUse/selectors';

import { GeoWaypoint } from 'containers/GuideUse/GuideUse.interface';

const WaypointSearchInputComponent: React.FC = (props: WaypointSearchProps) => {
  const dispatch = useDispatch();
  const [innerSearchedValue, setInnerSearchedValue] = useState('');
  const searchedValue = useSelector(getWaypointSearchedValueSelector);
  const waypointList = useSelector(getAllWaypointsListSelector);
  const isWaypointSearched = useSelector(getIsWaypointSearchingStateSelector);
  const timerRef: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  const searchByParams = useCallback(
    (waypoints: GeoWaypoint[], value: string) => {
      const lcValue = value.toLowerCase();

      return waypoints?.filter(
        waypoint =>
          waypoint.title?.toLowerCase().includes(lcValue || '') ||
          waypoint.title_cs?.toLowerCase().includes(lcValue || '') ||
          waypoint.description?.toLowerCase()?.includes(lcValue || '') ||
          waypoint.description_cs?.toLowerCase()?.includes(lcValue || '') ||
          waypoint.type?.toLowerCase()?.includes(lcValue || '')
      );
    },
    []
  );

  const handleSearch = useCallback(
    (waypoints: GeoWaypoint[], value: string) => {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        const searchedWaypoints = searchByParams(waypoints, value);

        dispatch(actions.setSearchedWaypointsList(searchedWaypoints));

        if (value.length > 1) {
          !isWaypointSearched &&
            dispatch(actions.setIsWaypointSearchingState(true));
        } else {
          dispatch(actions.setIsWaypointSearchingState(false));
        }
      }, 300);
    },
    [dispatch, isWaypointSearched, searchByParams]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(actions.setWaypointSearchedValue(e.target.value));
    },
    [dispatch]
  );

  useEffect(() => {
    handleSearch(waypointList, innerSearchedValue.trim());
  }, [innerSearchedValue, waypointList, handleSearch]);

  useEffect(() => {
    setInnerSearchedValue(searchedValue);
  }, [searchedValue]);

  return (
    <SearchInput
      {...props}
      onChange={handleChange}
      value={innerSearchedValue}
    />
  );
};

export const WaypointSearchInput = memo(WaypointSearchInputComponent, isEqual);
