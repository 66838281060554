import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Map } from 'leaflet';
import isEqual from 'lodash.isequal';
import { actions } from 'store/GuideUse/actions';
import {
  getIsElevationProfileExpandedSelector,
  getIsElevationProfileModalOpenedSelector,
  getIsFetchingGuideDataSelector,
  getIsLeftMenuCollapsedSelector,
  getIsWaypointSelectedSelector,
  getSelectedWaypointSelector,
  getSortedGuideDataSelector,
  getWaypointsDependingOnDirectionSelector,
  getIsWaypointDetailsOpenSelector,
} from 'store/GuideUse/selectors';
import { GuideUse } from 'views/GuideUse/GuideUse';
import { useViewportSize } from 'hooks/useViewportSize';
import { ElevationProfileExpansionLevels } from '../../commonTypes/enums';

const GuideUseContainerComponent: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useViewportSize();
  const { trailId }: { trailId: string } = useParams();
  const [map, setMap] = useState<Map | null>(null);
  const guideData = useSelector(getSortedGuideDataSelector);
  const waypoints = useSelector(getWaypointsDependingOnDirectionSelector);
  const isGuideDataFetching = useSelector(getIsFetchingGuideDataSelector);
  const isWaypointSelected = useSelector(getIsWaypointSelectedSelector);
  const selectedWaypoint = useSelector(getSelectedWaypointSelector);
  const isLeftMenuCollapsed = useSelector(getIsLeftMenuCollapsedSelector);
  const isElevationProfileExpanded = useSelector(
    getIsElevationProfileExpandedSelector
  );
  const isElevationProfileModalOpened = useSelector(
    getIsElevationProfileModalOpenedSelector
  );
  const isWaypointDetailsOpen = useSelector(getIsWaypointDetailsOpenSelector);

  const [waypointsMenuScrollPosition, setWaypointsMenuScrollPosition] =
    useState<number>(0);

  const onSetIsLeftMenuCollapsed = useCallback(() => {
    dispatch(actions.setIsLeftMenuCollapsed(!isLeftMenuCollapsed));
  }, [dispatch, isLeftMenuCollapsed]);

  const onPointClick = useCallback(
    (id: string) => {
      dispatch(actions.setSelectedWaypoint(id));
      dispatch(actions.setIsWaypointDetailsOpen(true));
    },
    [dispatch]
  );

  const handleSetWaypointsMenuScrollPosition = useCallback(
    (pageYOffset: number) => {
      setWaypointsMenuScrollPosition(pageYOffset);
    },
    []
  );

  useEffect(() => {
    dispatch(actions.getGuideGeoDataRequest(trailId));

    return () => {
      dispatch(actions.setSelectedWaypoint(''));
      dispatch(actions.setIsWaypointDetailsOpen(false));
      dispatch(actions.setIsPointsMenuMode(false));
      dispatch(actions.setWaypointSearchedValue(''));
      dispatch(actions.setIsLeftMenuCollapsed(true));
    };
  }, [dispatch, trailId]);

  useEffect(() => {
    isGuideDataFetching
      ? dispatch(actions.setIsLeftMenuCollapsed(true))
      : dispatch(actions.setIsLeftMenuCollapsed(false));
  }, [dispatch, isGuideDataFetching]);

  const handleCollapsedElevationProfileZoomIn = useCallback(() => {
    map?.zoomIn();
  }, [map]);

  const handleCollapsedElevationProfileZoomOut = useCallback(() => {
    map?.zoomOut();
  }, [map]);

  useEffect(() => {
    if (isWaypointSelected && selectedWaypoint && isLeftMenuCollapsed) {
      dispatch(actions.setIsLeftMenuCollapsed(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isWaypointSelected, selectedWaypoint]);

  useEffect(() => {
    dispatch(
      actions.setElevationProfileExpansionLevel(
        ElevationProfileExpansionLevels.level_2
      )
    );
  }, []);

  useEffect(() => {
    if (isWaypointDetailsOpen) {
      history.push('geo');
    }

    return history.listen(location => {
      if (history.action === 'POP') {
        if (isWaypointDetailsOpen) {
          dispatch(actions.setIsWaypointDetailsOpen(false));
          dispatch(actions.setSelectedWaypoint(''));
        }
      }
    });
  }, [dispatch, history, isWaypointDetailsOpen]);

  const handleSetMap = useCallback((map: Map | null) => {
    setMap(map);
  }, []);

  return (
    <GuideUse
      guideData={guideData}
      waypoints={waypoints}
      isGuideDataFetching={isGuideDataFetching}
      onSetIsLeftMenuCollapsed={onSetIsLeftMenuCollapsed}
      isElevationProfileExpanded={isElevationProfileExpanded}
      onPointClick={onPointClick}
      map={map}
      handleSetMap={handleSetMap}
      waypointsMenuScrollPosition={waypointsMenuScrollPosition}
      handleSetWaypointsMenuScrollPosition={
        handleSetWaypointsMenuScrollPosition
      }
      isMobile={isMobile}
      handleCollapsedElevationProfileZoomIn={
        handleCollapsedElevationProfileZoomIn
      }
      handleCollapsedElevationProfileZoomOut={
        handleCollapsedElevationProfileZoomOut
      }
      isElevationProfileModalOpened={isElevationProfileModalOpened}
    />
  );
};

export const GuideUseContainer = memo(GuideUseContainerComponent, isEqual);
