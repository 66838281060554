import {
  ParseCognitoEmailSignUpError,
  ParseCognitoSignInError,
} from './apiCognitoAuthAdapters.interfaces';

export const parseCognitoEmailSignUpError: ParseCognitoEmailSignUpError =
  error => {
    let fieldsErrors = null;
    if (error.code === 'UsernameExistsException') {
      fieldsErrors = {
        username: 'validation_username_already_used',
      };
    }
    if (error.message.includes('User with email ')) {
      fieldsErrors = {
        email: 'validation_email_already_used',
      };
    }
    if (error.message.includes('User with username ')) {
      fieldsErrors = {
        username: 'validation_username_already_used',
      };
    }
    if (error.message.includes('Emoji is not allowed')) {
      fieldsErrors = {
        username: 'validation_username_emoji_not_allowed',
      };
    }
    if (error.code === 'InvalidParameterException'){
      if (error.message.includes('Username cannot be of email format')) {
        fieldsErrors = {
          username: 'validation_username_not_email',
        };
      }
    }
    if (error.code === 'InvalidParameterException' && error.message.includes('Member must satisfy regular expression pattern')){
      fieldsErrors = {
        username: 'validation_username_invalid_symbols',
      };
    }
    return fieldsErrors;
  };

export const parseCognitoSignInError: ParseCognitoSignInError = error => {
  let fieldsErrors = error.message;

  if (error.code === 'NotAuthorizedException') {
    fieldsErrors = 'validation_incorrect_username_password';
  }

  return fieldsErrors;
};
