import React, { memo } from 'react';
import { ProfileHeaderViewProps } from './ProfileHeader.interfaces';
import { S } from './ProfileHeader.styles';
import { PictureCarousel } from '../../shared/PictureCarousel/PictureCarousel';
import { LoadingOverlay } from '../../shared/LoadingOverlay/LoadingOverlay';

const ProfileHeaderViewComponent: React.FC<ProfileHeaderViewProps> = ({
  picturesView,
  userPhoto,
  isUploadingProfileImage = false,
  isUploadingCoverImage = false,
  pictureCarouselBorderRadius,
}) => {
  return (
    <S.Header>
      <S.PictureCarouselContainer>
        <PictureCarousel
          isLoading={isUploadingCoverImage}
          height={300}
          picturesView={picturesView}
          borderRadius={pictureCarouselBorderRadius}
        />
      </S.PictureCarouselContainer>
      <S.IconWrapper>
        <S.IconContainer isLoading={isUploadingProfileImage}>
          {isUploadingProfileImage ? (
            <LoadingOverlay relative={true} />
          ) : (
            <S.IconView src={userPhoto} />
          )}
        </S.IconContainer>
      </S.IconWrapper>
    </S.Header>
  );
};

export const ProfileHeaderView = memo(ProfileHeaderViewComponent);
