import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthManagerService } from './AuthManager';
import {
  AuthManager,
  AuthMethod,
  AuthServiceType,
  OAuthEmailSignInCredentials,
  RequestResetPasswordParams,
  SetIsSignedIn,
} from './AuthManager.interfaces';
import { useAppClearStateDecorator } from '../../hooks/useAppClearStateDecorator';

type AuthContextType = Omit<
  AuthManager,
  | 'subscribeOnIsSignedIn'
  | 'unsubscribeFromIsSignedIn'
  | 'initialize'
  | 'confirmEmail'
  | 'getAccessToken'
> & { isSignedIn: boolean };

const makeErrorMessage = (functionName: string) =>
  `ReactAuthManagerError: ${functionName} method is requested outside of the AuthProvider`;

const fallbackAuthContextMethods: AuthContextType = {
  isSignedIn: false,
  async signUp(
    type: AuthServiceType,
    method: AuthMethod,
    credentials?: OAuthEmailSignInCredentials
  ) {
    throw new Error(makeErrorMessage('signUp'));
  },
  async signIn(
    type: AuthServiceType,
    method: AuthMethod,
    credentials?: OAuthEmailSignInCredentials
  ) {
    throw new Error(makeErrorMessage('signIn'));
  },
  async signOut() {
    throw new Error(makeErrorMessage('signOut'));
  },
  async resendConfirmationCode(type: AuthServiceType, username: string) {
    throw new Error(makeErrorMessage('resendConfirmationCode'));
  },
  async requestResetPassword(params: RequestResetPasswordParams) {
    throw new Error(makeErrorMessage('requestResetPassword'));
  },
  async confirmResetPassword(password: string, confirmationCode: string) {
    throw new Error(makeErrorMessage('confirmResetPassword'));
  },
};

const AuthContext = React.createContext(fallbackAuthContextMethods);

export const AuthProvider: React.FC = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const { withClearAppState } = useAppClearStateDecorator();

  const AuthMethodsAndData = useMemo(() => {
    const {
      signUp,
      signIn,
      signOut,
      resendConfirmationCode,
      requestResetPassword,
      confirmResetPassword,
    } = AuthManagerService.getInstance();

    return {
      isSignedIn,
      signUp,
      signIn,
      signOut: withClearAppState<typeof signOut>(signOut),
      resendConfirmationCode,
      requestResetPassword,
      confirmResetPassword,
    };
  }, [isSignedIn, withClearAppState]);

  useEffect(() => {
    const auth = AuthManagerService.getInstance();
    const handleSetIsSignedIn: SetIsSignedIn = _isSignedIn => {
      setIsSignedIn(_isSignedIn);
    };

    auth.subscribeOnIsSignedIn(handleSetIsSignedIn);

    return () => {
      auth.unsubscribeFromIsSignedIn(handleSetIsSignedIn);
    };
  }, []);

  return (
    <AuthContext.Provider value={AuthMethodsAndData}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authMethods = useContext(AuthContext);
  return authMethods;
};
