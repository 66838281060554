import styled, { css } from 'styled-components';
import { ReactComponent as UnorderedListIcon } from 'static/list-24px.svg';
import { ReactComponent as FilterIcon } from 'static/filter_alt_theme_24dp.svg';
import { ReactComponent as MapIcon } from 'static/map-24px.svg';

interface StyledProps {
  isMobile: boolean;
}

interface ControlsPanelProps extends StyledProps {
  isCardsMenuMode: boolean;
}

const iconSharedStyle = css`
  font-size: 24px;
  fill: ${({ theme }) => theme.trailsStore.colors.controlsPanel.iconBackground};
`;

const ControlWrapper = styled.div`
  margin-left: 8px;
  height: 32px;
  border-radius: 5px;
`;

export const S = {
  ControlsPanel: styled.section<ControlsPanelProps>`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: 'center';
    position: ${({ isCardsMenuMode, isMobile }) =>
      isCardsMenuMode || !isMobile ? 'relative' : 'absolute'};
    z-index: 1001;
  `,

  ControlWrapper: styled.div`
    margin-left: 8px;
    height: 32px;
    border-radius: 5px;
  `,

  WhiteBkgControlWrapper: styled(ControlWrapper)`
    position: relative;
    border: none;
  `,

  UnorderedListIcon: styled(UnorderedListIcon)`
    ${iconSharedStyle}
  `,

  FilterIcon: styled(FilterIcon)`
    ${iconSharedStyle}
  `,

  MapIcon: styled(MapIcon)`
    ${iconSharedStyle}
  `,

  CountFilters: styled.div`
    position: absolute;
    top: -7px;
    right: -5px;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    background: ${({ theme }) =>
      theme.trailsStore.colors.controlsPanel.backgroundCountFilters};
    color: ${({ theme }) =>
      theme.trailsStore.colors.controlsPanel.textCountFilters};
    border-radius: 50px;
    font-size: 13px;
    user-select: none;
    z-index: 1;
  `,
  CountFiltersText: styled.span`
    text-align: center;
  `
};
