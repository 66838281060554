import { fetcher } from '../../configure'

export type UpdateUserProfilePhotoRequest = {
	base64EncodedPhoto: string
}

export type UpdateUserProfilePhotoResponse = {
	success: boolean
	message: 'SUCCESS'
}

export type UpdateUserProfilePhotoEndpoint = (
	request: UpdateUserProfilePhotoRequest
) => Promise<UpdateUserProfilePhotoResponse>

export const updateUserProfilePhoto: UpdateUserProfilePhotoEndpoint = async (request) => {
	return await fetcher({
		method: 'put',
		path: '/users/photos/profile',
		body: request,
	})
}
