import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { SearchProps } from './SearchInput.interface';
import { useIntl } from 'react-intl';

export const StyledSearch: React.FC = styled(
  ({ onSearch, ...props }: SearchProps) => (
    <AntInput
      {...props}
      prefix={<SearchOutlined />}
      placeholder={props.placeholder || useIntl().formatMessage({ id: "search" })}
      allowClear
    />
  )
)`
  color: ${({ theme }) => theme.searchInput.colors.text};
  border-radius: 8px;
  border-color: ${({ theme }) => theme.searchInput.colors.border};

  &.ant-input-affix-wrapper:hover {
    border-color: ${({ theme }) => theme.searchInput.colors.border};
  }

  & .ant-input::placeholder {
    color: ${({ theme }) => theme.searchInput.colors.placeholder};
  }

  & .ant-input-clear-icon > svg {
    height: 1.5em;
    width: 1.4em;
    color: ${({ theme }) => theme.searchInput.colors.reset};
    &:hover {
      color: ${({ theme }) => theme.searchInput.colors.resetHover};
    }
  }
`;
