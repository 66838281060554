import menuConfig from 'configs/mainMenuConfig.json';
import { generateUniqueId } from './generateUniqueId';
import { Menu } from 'antd';
import { DefaultTheme, StyledComponent } from 'styled-components';
import { FormattedMessage  } from 'react-intl';
import { MouseEventHandler } from 'react';

type LinkKey = keyof typeof menuConfig.items;

const makeKey = (prefix: string) => `${prefix}-item-${generateUniqueId()}`;

export const makeMenu = (
  MenuItemComponent: StyledComponent<typeof Menu.Item, DefaultTheme, {}, never>,
  keyPrefix: string,
  isSignedIn: boolean,
  handleSignOut: MouseEventHandler<HTMLAnchorElement> | undefined,
  isDesktopView: boolean
) => {
  const topMenuItems = menuConfig.menuTree;
  const assembleMenu: any = (items: typeof topMenuItems) => (
    <>
     { !isDesktopView && (!isSignedIn ? <MenuItemComponent key={makeKey(keyPrefix)}>
        <a
          href={'/login'}
          rel="noopener noreferrer"
        ><FormattedMessage id={'Log In'} />
        </a>
      </MenuItemComponent> : 
      <MenuItemComponent key={makeKey(keyPrefix)}>
      <a
        href={'/guides'}
        rel="noopener noreferrer"
        onClick={handleSignOut}
      ><FormattedMessage id={'Log Out'} />
      </a>
    </MenuItemComponent>
      )}
      {items.map(item => {
        return (
          <MenuItemComponent key={makeKey(keyPrefix)}>
            <a
              href={menuConfig.items[item.title as LinkKey]}
              target="_blank"
              rel="noopener noreferrer"
            ><FormattedMessage id={item.title} />
            </a>
          </MenuItemComponent>
        );
      })}
    </>
  );
  return assembleMenu(topMenuItems);
};