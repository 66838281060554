import React, { memo } from 'react';
import { S } from './ProfileSettingsForm.styles';
import { ProfileSettingsFormProps } from './ProfileSettingsForm.interfaces';
import { BaseButton } from '../../controls/BaseButton/BaseButton';
import { EditImageUploader } from '../../shared/EditImageUploader/EditImageUploader';
import { useTransition, animated } from 'react-spring';
import { ProfilePrimaryInfoFormView } from '../ProfilePrimaryInfoForm/ProfilePrimaryInfoForm';
import { useIntl } from 'react-intl';

const ProfileSettingsFormComponent: React.FC<ProfileSettingsFormProps> = ({
  handleSubmit,
  onChangeProfilePhoto,
  onChangeCoverPhoto,
  initialValues,
  isAbsoluteSubmitButton = false,
  primaryInformationVisibility,
  handleChangePrimaryInformationVisibility,
}) => {
  const primaryInformationFadeTransitions = useTransition(
    primaryInformationVisibility,
    item => (item ? 'shown' : 'hidden'),
    {
      from: {
        opacity: '0',
        maxHeight: 0,
      },
      enter: { opacity: '1', maxHeight: 500 },
      leave: { opacity: '0', maxHeight: 0 },
    }
  );
  let intl = useIntl()
  return (
    <S.Layout>
      <S.ChangePhotoButtonContainer>
        <EditImageUploader handleChangeImage={onChangeProfilePhoto}>
          <BaseButton
            type="primary"
            label={intl.formatMessage({id:"change_profile_photo"})}
            size="middle"
            htmlType="button"
            fullwidth
          />
        </EditImageUploader>
      </S.ChangePhotoButtonContainer>
      <S.ChangePhotoButtonContainer>
        <EditImageUploader handleChangeImage={onChangeCoverPhoto}>
          <BaseButton
            type="primary"
            label={intl.formatMessage({id:"change_cover_photo"})}
            htmlType="button"
            size="middle"
            fullwidth
          />
        </EditImageUploader>
      </S.ChangePhotoButtonContainer>
      {!primaryInformationVisibility && (
        <S.ChangePrimaryInfoButton
          type="primary"
          label={intl.formatMessage({id:"change_my_information"})}
          htmlType="button"
          size="middle"
          onClick={handleChangePrimaryInformationVisibility}
          fullwidth
        />
      )}
      {primaryInformationFadeTransitions.map(
        ({ item, props, key }) =>
          item && (
            <animated.div key={key} style={{ ...props, width: '100%' }}>
              <ProfilePrimaryInfoFormView
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                isAbsoluteSubmitButton={isAbsoluteSubmitButton}
              />
            </animated.div>
          )
      )}
    </S.Layout>
  );
};

export const ProfileSettingsForm = memo(ProfileSettingsFormComponent);
