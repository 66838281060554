import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import {
  getElevationProfileExpansionLevel,
  getIsLeftMenuCollapsedSelector,
  getIsPointsMenuModeSelector,
  getIsWaypointSelectedSelector,
  getIsWaypointDetailsOpenSelector,
} from 'store/GuideUse/selectors';
import { WaypointSearchInput } from './WaypointSearchInput/WaypointSearchInput';
import { GuideUseMap } from 'containers/GuideUseMap/GuideUseMap';
import { LoadingOverlay } from 'shared/LoadingOverlay/LoadingOverlay';
import { ScrollSaverMenuWrapper } from 'shared/ScrollSaverMenuWrapper/ScrollSaverMenuWrapper';
import { MapSettingsModal } from './MapSettingsModal/MapSettingsModal';
import { LayersModal } from './LayersModal/LayersModal';
import { GuideUseControlsPanel } from './ControlsPanel/ControlsPanel';
import { PointsMenu } from './PointsMenu/PointsMenu';
import { GuidesOptionsPanel } from './GuidesOptionsPanel/GuidesOptionsPanel';
import { ElevationProfile } from './ElevationProfile/ElevationProfile';
import { PointDetails } from './PointDetails/PointDetails';
import { ElevationProfileModalContainer } from '../../containers/ElevationProfileModal/ElevationProfileModal';

import { S } from './GuideUse.styles';
import {
  GeoTrail,
  GeoWaypoint,
  GuideUseViewProps,
} from 'containers/GuideUse/GuideUse.interface';
import { ZoomButtonsView } from '../ZoomButtons/ZoomButtons';
import { FloatPlacement } from '../../commonTypes/enums';

const GuideUseComponent: React.FC<GuideUseViewProps> = ({
  isElevationProfileExpanded,
  guideData,
  isGuideDataFetching,
  waypointsMenuScrollPosition,
  waypoints,
  map,
  handleSetMap,
  handleSetWaypointsMenuScrollPosition,
  onSetIsLeftMenuCollapsed,
  onPointClick,
  isMobile,
  handleCollapsedElevationProfileZoomIn,
  handleCollapsedElevationProfileZoomOut,
  isElevationProfileModalOpened,
}) => {
  const isWaypointDetailsOpen = useSelector(getIsWaypointDetailsOpenSelector);
  const isPointsMenuMode = useSelector(getIsPointsMenuModeSelector);
  const isLeftMenuCollapsed = useSelector(getIsLeftMenuCollapsedSelector);
  const elevationProfileExpansionLevel = useSelector(
    getElevationProfileExpansionLevel
  );

  const recountData = useMemo(() => {
    if (guideData.trails && guideData.waypoints && guideData.iconTypes) {
      return {
        trails: guideData.trails.filter(
          (trail: GeoTrail) => trail.geometry && trail.geometry.coordinates
        ),
        waypoints: guideData.waypoints.filter(
          (waypoint: GeoWaypoint) =>
            waypoint.geometry && waypoint.geometry.coordinates
        ),
        iconTypes: guideData.iconTypes,
      };
    }
  }, [guideData.iconTypes, guideData.trails, guideData.waypoints]);

  const getWaypointsForPointMenu = useMemo(() => {
    if (waypoints) {
      const extendedWaypoints = waypoints?.map((waypoint: GeoWaypoint) => ({
        ...waypoint,
        guideName: guideData.guideName,
        elevation: waypoint.geometry?.coordinates[2],
      }));

      return [
        ...extendedWaypoints
          .filter(
            waypoint =>
              waypoint.waypointDistance || waypoint.waypointDistance === 0
          )
          .sort((a, b) =>
            (a.waypointDistance as number) < (b.waypointDistance as number)
              ? -1
              : 1
          ),
        ...extendedWaypoints
          .filter(waypoint => waypoint.waypointDistance === null)
          .sort((a, b) => (a.title < b.title ? -1 : 1)),
      ];
    }
  }, [guideData.guideName, waypoints]);

  return (
    <>
      {isGuideDataFetching && <LoadingOverlay />}
      <S.Container>
        {(isPointsMenuMode || isWaypointDetailsOpen || !isMobile) && (
          <S.SideWrapper isMobile={isMobile}>
            <S.Sider
              collapsible
              collapsedWidth={0}
              collapsed={!isMobile && isLeftMenuCollapsed}
              width={isMobile ? '100vw' : 400}
              trigger={null}
            >
              {!isGuideDataFetching && (
                <S.ColumnWrapper isMobile={isMobile}>
                  <S.ControlsPanelWrapper isMobile={isMobile}>
                    <GuideUseControlsPanel
                      guideName={guideData.guideName}
                      isMobile={isMobile}
                      isUnlayoutMode={isPointsMenuMode}
                    />
                  </S.ControlsPanelWrapper>
                  {isMobile && (
                    <S.GuidesOptionsPanelWrapper isMobile={isMobile}>
                      <GuidesOptionsPanel
                        hasElevationProfile={!!guideData.primaryDirection}
                      />
                    </S.GuidesOptionsPanelWrapper>
                  )}
                  <S.MenuContainer>
                    <S.SearchInputWrapper isMobile={isMobile}>
                      <WaypointSearchInput />
                    </S.SearchInputWrapper>
                    {getWaypointsForPointMenu && (
                      <ScrollSaverMenuWrapper
                        scrollPosition={waypointsMenuScrollPosition}
                        handleSetScrollPosition={
                          handleSetWaypointsMenuScrollPosition
                        }
                      >
                        <PointsMenu
                          waypoints={getWaypointsForPointMenu}
                          iconTypes={guideData.iconTypes}
                          primaryDirection={guideData.primaryDirection}
                          onPointClick={onPointClick}
                        />
                      </ScrollSaverMenuWrapper>
                    )}
                  </S.MenuContainer>
                </S.ColumnWrapper>
              )}
            </S.Sider>
            <S.WrapTab onClick={onSetIsLeftMenuCollapsed}>
              {isLeftMenuCollapsed ? <S.RightOutlined /> : <S.LeftOutlined />}
            </S.WrapTab>
            {isWaypointDetailsOpen && (
              <PointDetails iconTypes={guideData.iconTypes} />
            )}
          </S.SideWrapper>
        )}
        {
          <S.GuideBody>
            <GuideUseMap
              map={map}
              handleSetMap={handleSetMap}
              waypointsData={
                !isGuideDataFetching
                  ? {
                      features: guideData.waypoints,
                      iconTypes: guideData.iconTypes,
                      type: 'FeatureCollection',
                    }
                  : null
              }
              trailsData={
                !isGuideDataFetching
                  ? {
                      bbox: guideData?.bbox,
                      features: guideData.trails
                        ?.filter(
                          (trail: GeoTrail) =>
                            trail.geometry && trail.geometry.coordinates
                        )
                        ?.map((trail: GeoTrail) => ({
                          ...trail,
                          type: 'Feature',
                          geometry: { ...trail.geometry },
                          coordinates: trail.geometry.coordinates,
                        })),
                      type: 'FeatureCollection',
                    }
                  : null
              }
            />

            {(!isMobile || (!isPointsMenuMode && !isWaypointDetailsOpen)) && (
              <S.GuidesOptionsPanelWrapper isMobile={isMobile}>
                <GuidesOptionsPanel
                  hasElevationProfile={!!guideData.primaryDirection}
                />
              </S.GuidesOptionsPanelWrapper>
            )}
            {/* @TODO Removed for the first release */}
            {/*{guideData.primaryDirection &&*/}
            {/*!isGuideDataFetching &&*/}
            {/*!isMobile &&*/}
            {/*recountData ? (*/}
            {/*  <S.ElevationProfileWrapper*/}
            {/*    elevationProfileExpansionLevel={elevationProfileExpansionLevel}*/}
            {/*  >*/}
            {/*    <ElevationProfile*/}
            {/*      data={recountData}*/}
            {/*      isMobile={isMobile}*/}
            {/*      isElevationProfileExpanded={isElevationProfileExpanded}*/}
            {/*      handleCollapsedElevationProfileZoomIn={*/}
            {/*        handleCollapsedElevationProfileZoomIn*/}
            {/*      }*/}
            {/*      handleCollapsedElevationProfileZoomOut={*/}
            {/*        handleCollapsedElevationProfileZoomOut*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </S.ElevationProfileWrapper>*/}
            {/*) : (*/}
            {(!isPointsMenuMode || !isMobile) && (
              <ZoomButtonsView
                isAbsolute={true}
                floatButtonsPlacement={FloatPlacement.bottomRight}
                onZoomIn={handleCollapsedElevationProfileZoomIn}
                onZoomOut={handleCollapsedElevationProfileZoomOut}
              />
            )}
            {/*)}*/}
          </S.GuideBody>
        }
      </S.Container>
      {/* @TODO Removed for the first release */}
      {/*{guideData.primaryDirection && !isGuideDataFetching && isMobile && (*/}
      {/*  <ElevationProfileModalContainer*/}
      {/*    visibility={isElevationProfileModalOpened}*/}
      {/*    data={{*/}
      {/*      trails: guideData.trails.filter(*/}
      {/*        (trail: GeoTrail) => trail.geometry && trail.geometry.coordinates*/}
      {/*      ),*/}
      {/*      waypoints: guideData.waypoints.filter(*/}
      {/*        (waypoint: GeoWaypoint) =>*/}
      {/*          waypoint.geometry && waypoint.geometry.coordinates*/}
      {/*      ),*/}
      {/*      iconTypes: guideData.iconTypes,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      <MapSettingsModal />
      <LayersModal />
    </>
  );
};

export const GuideUse = memo(GuideUseComponent, isEqual);
