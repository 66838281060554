import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { S } from './CollapseArrow.styles';
import { CollapseArrowProps } from './CollapseArrow.interface';
import { RightArrow } from '../Arrow/RightArrow/RightArrow';

const CollapseArrowComponent: React.FC<CollapseArrowProps> = ({
  size = 'large',
  isItemExpanded,
  onExpand,
}) => {
  const handleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onExpand(!isItemExpanded);
  };

  return (
    <S.Wrapper onClick={handleExpand}>
      <S.InnerWrapper isExpanded={isItemExpanded}>
        <RightArrow sizes={size} />
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export const CollapseArrow = memo(CollapseArrowComponent, isEqual);
