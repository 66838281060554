import { FloatPlacement } from '../commonTypes/enums';

type GetStylePropertiesByFloatPlacementType = (placement: FloatPlacement) => {
  top: 'auto' | number;
  bottom: 'auto' | number;
  left: 'auto' | number;
  right: 'auto' | number;
};

export const getStylePropertiesByFloatPlacement: GetStylePropertiesByFloatPlacementType =
  placement => {
    switch (placement) {
      case FloatPlacement.bottomLeft:
        return {
          top: 'auto',
          bottom: 0,
          left: 0,
          right: 'auto',
        };
      case FloatPlacement.bottomRight:
        return {
          top: 'auto',
          bottom: 0,
          left: 'auto',
          right: 0,
        };
      case FloatPlacement.topLeft:
        return {
          top: 0,
          bottom: 'auto',
          left: 0,
          right: 'auto',
        };
      case FloatPlacement.topRight:
        return {
          top: 0,
          bottom: 'auto',
          left: 'auto',
          right: 0,
        };
    }
  };
