import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { BaseButton } from 'controls/BaseButton/BaseButton';

import { FilterViewProps } from 'containers/Filter/FilterContainer.interface';
import { FilterConfig } from './Filter.interface';
import { S } from './Filter.styles';
import { FormattedMessage, useIntl  } from 'react-intl';

import { filterRenderConfig } from './filterComponentsRenderConfig';

const FilterViewComponent: React.FC<FilterViewProps> = ({
  filterParamsState,
  selectedFilters,
  allTrailsListLength,
  handleSetSelectedFilters,
  isFetchingTrailsList,
  filteredGuidesLength,
  isSearchedState,
  expandedSections,
  handleSetExpandedSections,
  onApply,
  onClose,
  onClearAllFilters,
  getSelectedFiltersListLength,
}) => {

  const intl = useIntl();
  const selectedFiltersListLength = getSelectedFiltersListLength(selectedFilters, filterParamsState) >
    0 || isSearchedState
    ? filteredGuidesLength
    : allTrailsListLength

  return (
    <S.Container>
      <S.Header>
        <S.HeaderTopWrapper>
          <BaseButton
            type="text"
            label={intl.formatMessage({ id: "back" })}
            onClick={onClose}
            disabled={isFetchingTrailsList}
          />
          <S.HeaderTitle><FormattedMessage id="filters" /></S.HeaderTitle>
          <BaseButton
            type="text"
            label={intl.formatMessage({ id: "filter_apply"}, {count:selectedFiltersListLength})}
            onClick={onApply}
            disabled={isFetchingTrailsList}
          />
        </S.HeaderTopWrapper>
        <S.HeaderBottomWrapper>
          <BaseButton
            type="text"
            label={intl.formatMessage({ id: "clear_all" })}
            size="small"
            onClick={onClearAllFilters}
          />
        </S.HeaderBottomWrapper>
      </S.Header>
      <S.Collapse
        activeKey={expandedSections}
        ghost
        expandIconPosition="right"
        onChange={handleSetExpandedSections}
      >
        {filterRenderConfig.map(({ title, component }: FilterConfig) => (
          <S.Panel header={intl.formatMessage({ id: title})} key={title}>
            {component({
              filterParamsState,
              selectedFilters,
              handleSetSelectedFilters,
            })}
          </S.Panel>
        ))}
      </S.Collapse>
    </S.Container>
  );
};

export const FilterView = memo(FilterViewComponent, isEqual);
