import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { SwitchProps } from 'antd';
import { S } from './BaseSwitch.styles';

const BaseSwitchComponent: React.FC<SwitchProps> = ({ ...props }) => {
  return (
    <S.SwitchContainer>
      <S.Switch {...props} />
    </S.SwitchContainer>
  );
};

export const BaseSwitch = memo(BaseSwitchComponent, isEqual);
