import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { StyledLinkProps } from './Link.interfaces';

export const StyledLink = styled(({ href, to, ...rest }: StyledLinkProps) =>
  href 
    ? <a href={href} {...rest}>{rest.children}</a>
    : <Link to={to as string} {...rest} /> 
)`
  font-size: inherit;
  font-weight: ${({ type }) => (type === 'primary' ? 'bold' : 'inherit')};
  text-decoration: ${({ type }) => (type === 'default' ? 'underline' : 'none')};

  color: ${({ theme, type }) => theme.links[type].colors.color};

  &:visited {
    color: ${({ theme, type }) => theme.links[type].colors.colorVisited};
    text-decoration: ${({ type }) => (type === 'default' ? 'underline' : 'none')};
  }
  &:hover {
    color: ${({ theme, type }) => theme.links[type].colors.colorHover};
    text-decoration: ${({ type }) => (type === 'default' ? 'underline' : 'none')};
  }
  &:active {
    color: ${({ theme, type }) => theme.links[type].colors.colorActive};
    text-decoration: ${({ type }) => (type === 'default' ? 'underline' : 'none')};
  }
`;
