import { AvailableCountryDto } from "atlasguides-web-common/src/functions/payment/dto/countries.dto";

export const actions = {
  getAllTrailsListRequest: () =>
    ({
      type: '@/GET_ALL_TRAILS_LIST_REQUEST',
    } as const),
  getAllTrailsListResponse: (response: any[]) =>
    ({
      type: '@/GET_ALL_TRAILS_LIST_RESPONSE',
      payload: response,
    } as const),
  getTrailGuideViewRequest: (id: string) =>
    ({
      type: '@/GET_TRAIL_GUIDE_VIEW_REQUEST',
      payload: id,
    } as const),
  getTrailGuideViewResponse: (response: any) =>
    ({
      type: '@/GET_TRAIL_GUIDE_VIEW_RESPONSE',
      payload: response,
    } as const),
  getActiveSubscriptionRequest: () =>
    ({
      type: '@/GET_ACTIVE_SUBSCRIPTION_REQUEST',
    } as const),
  getActiveSubscriptionResponse: (response: any) =>
    ({
      type: '@/GET_ACTIVE_SUBSCRIPTION_RESPONSE',
      payload: response,
    } as const),
  setSelectedTrailGuideId: (id: any) =>
    ({
      type: '@/SET_SELECTED_TRAIL_GUIDE_ID',
      payload: id,
    } as const),
  getCardChildrenByIdRequest: (id: string) =>
    ({
      type: '@/GET_CARD_CHILDREN_BY_ID_REQUEST',
      payload: id,
    } as const),
  getCardChildrenByIdResponse: (response: any) =>
    ({
      type: '@/GET_CARD_CHILDREN_BY_ID_RESPONSE',
      payload: response,
    } as const),
  getGuidesFilterParamsResponse: (response: any) =>
    ({
      type: '@/GET_GUIDES_FILTER_PARAMS_RESPONSE',
      payload: response,
    } as const),
  setActiveSelectedFilters: (filter: any) =>
    ({
      type: '@/SET_ACTIVE_SELECTED_FILTERS',
      payload: filter,
    } as const),
  setIsFilteredState: (bool: boolean) =>
    ({
      type: '@/SET_IS_FILTERED_STATE',
      payload: bool,
    } as const),
  setIsSearchedState: (bool: boolean) =>
    ({
      type: '@/SET_IS_SEARCHED_STATE',
      payload: bool,
    } as const),
  setFilteredGuides: (guides: any) =>
    ({
      type: '@/SET_FILTERED_GUIDES',
      payload: guides,
    } as const),
  setSearchedGuides: (guides: any) =>
    ({
      type: '@/SET_SEARCHED_GUIDES',
      payload: guides,
    } as const),
  setSearchedValue: (guides: any) =>
    ({
      type: '@/SET_SEARCHED_VALUE',
      payload: guides,
    } as const),
  setClearedFilters: () =>
    ({
      type: '@/SET_CLEARED_FILTERS',
    } as const),  
  setAvailableCountries: (countries: AvailableCountryDto[]) =>
    ({
      type: '@/SET_AVAILABLE_COUNTRIES',
      payload: countries,
    } as const),
  setIsDetailsDisplaying: (bool: boolean) =>
    ({
      payload: bool,
      type: '@/SET_IS_DETAILS_DISPLAYING',
    } as const),
  setGuidesAsFullPurchased: (parentGuidesIndexes: string[]) =>
    ({
      payload: parentGuidesIndexes,
      type: '@/SET_GUIDES_AS_FULL_PURCHASED',
    } as const),
  setGuideAsFullPurchased: (guideId: string) =>
    ({
      payload: guideId,
      type: '@/SET_GUIDE_AS_FULL_PURCHASED',
    } as const),
  clearAllGuidesPurchasedState: () =>
    ({
      type: '@/CLEAR_ALL_GUIDES_PURCHASED_STATE',
    } as const),
};
