import { fetcher } from '../../configure'

export const ERROR_REDEEM_CODE_NOT_FOUND = "not_found"
export const ERROR_REDEEM_CODE_EXPIRED = "expired"
export const ERROR_REDEEM_CODE_CONSUMED = "consumed"

export type RedeemCodeRequest = {
    code: string
}

export type RedeemCodeResponse = {
    guideId?: string
    error?: string
}

export type RedeemCodeEndpoint = (
    request: RedeemCodeRequest
) => Promise<RedeemCodeResponse>

export const RedeemCode: RedeemCodeEndpoint = async (
    request
): Promise<RedeemCodeResponse> => {
    return await fetcher({
        method: 'post',
        path: `/payment/redeemCode`,
        body: request,
    })
}
