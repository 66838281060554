import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { actions } from 'store/GuideUse/actions';
import {
  getIsLayersModalOpenSelector,
  getCurrentLayerLinkSelector,
} from 'store/GuideUse/selectors';
import { layers } from './layers';
import { S } from './LayersModal.styles';
import { TileLayerInfo } from 'containers/GuideUse/GuideUse.interface';
import { FormattedMessage } from 'react-intl';

const LayersModalComponent: React.FC = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getIsLayersModalOpenSelector);
  const currentLayerLink = useSelector(getCurrentLayerLinkSelector);

  const handleHideModal = useCallback(() => {
    dispatch(actions.setIsLayersModalOpen(false));
  }, [dispatch]);

  const handleSetLayerLink = ({
    link,
    attribution,
  }: {
    link: string;
    attribution: string;
  }) => {
    const localStorage = LocalStorageManager.getInstance();
    localStorage.setItem('layerData', JSON.stringify({ link, attribution }));

    dispatch(actions.setMapLayerLink(link));
    dispatch(actions.setMapLayerAttribution(attribution));
  };

  useEffect(() => {
    return () => {
      handleHideModal();
    };
  }, [handleHideModal]);

  return (
    <S.Modal
      visible={isModalOpen}
      onCancel={handleHideModal}
      zIndex={1002}
      footer={null}
    >
      <S.Container>
        <S.Header>
          <S.Title><FormattedMessage id="map_layers"/></S.Title>
        </S.Header>
        <S.Body>
          {layers.map((layer: TileLayerInfo) => (
            <S.LayerCellWrapper key={layer.name}>
              <S.LayerCell
                isSelected={currentLayerLink === layer.link}
                onClick={() =>
                  handleSetLayerLink({
                    link: layer.link,
                    attribution: layer.attribution,
                  })
                }
              >
                <S.LayerCellImg src={layer.icon} />
                <S.LayerOverlay>
                  <S.OverlayTitle>{layer.name}</S.OverlayTitle>
                </S.LayerOverlay>
              </S.LayerCell>
            </S.LayerCellWrapper>
          ))}
        </S.Body>
      </S.Container>
    </S.Modal>
  );
};

export const LayersModal = memo(LayersModalComponent, isEqual);
