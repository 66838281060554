import styled from 'styled-components';

export const S = {
  CarouselContainer: styled.div<{ borderRadius?: string }>`
    position: relative;
    max-height: 300px;
    width: 100%;
    height: 100%;

    ${({ borderRadius }) =>
      borderRadius &&
      `
      overflow: hidden;
      border-radius: ${borderRadius};
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    `}

    .ant-carousel,
    & .slick-slider,
    & .slick-track,
    & .slick-slide > div {
      height: 100%;
    }
  `,

  ImageContainer: styled.img.attrs(({ src }) => ({
    src,
  }))`
    width: 100%;
    height: 100%;
    cursor: pointer;
  `,
};
