import styled, { css } from 'styled-components';
import { RightOutlined, DownOutlined } from '@ant-design/icons';

interface cssProps {
  sizes?: 'small' | 'medium' | 'large';
}
const iconSharedStyle = css<cssProps>`
  color: ${({ theme }) => theme.collapseArrow.colors.default};
  font-size: ${({ theme, sizes = 'medium' }) =>
    theme.collapseArrow.sizes[sizes]};
  cursor: pointer;
`;

export const S = {
  Wrapper: styled.div``,
  InnerWrapper: styled.span<{ isExpanded: boolean }>`
    && svg {
      transform: ${({ isExpanded }) => (isExpanded ? `rotate(90deg)` : null)};
      transition: transform 0.3s;
    }
  `,
  RightArrow: styled(RightOutlined)<cssProps>`
    ${iconSharedStyle}
  `,

  DownArrow: styled(DownOutlined)<cssProps>`
    ${iconSharedStyle}
  `,
};
