import { ToastOptions } from 'react-toastify';

const sharedConfig: ToastOptions = {
  position: 'bottom-center',
} as const;

export const importantToastConfig: ToastOptions = {
  ...sharedConfig,
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: false,
  draggable: true,
} as const;
