export enum distanceUnitName {
    M = 'meters',
    MI = 'miles',
    KM = 'kilometers',
    FT = 'feet',
}

export const metersInDistanceUnit = {
    [distanceUnitName.M]: 1,
    [distanceUnitName.MI]: 1609.344,
    [distanceUnitName.KM]: 1000,
    [distanceUnitName.FT]: 0.3048,
}

export const shortDistanceUnitName = {
    [distanceUnitName.M]: 'm',
    [distanceUnitName.MI]: 'mi',
    [distanceUnitName.KM]: 'km',
    [distanceUnitName.FT]: 'ft'
}