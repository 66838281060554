import styled from 'styled-components';
import { baseFormStyles } from '../../shared.styles';
import { Form } from 'formik';
import { BaseButton } from '../../controls/BaseButton/BaseButton';

export const S = {
  Layout: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${baseFormStyles}

    padding-bottom: 5px;
  `,
  ChangePhotoButtonContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    && {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  `,
  SaveButtonContainer: styled.div<{ isAbsolutePosition?: boolean }>`
    ${({ isAbsolutePosition }) =>
      isAbsolutePosition &&
      `
    position: absolute;
    top: -56px;
    right: -60px;
    `}

    && {
      width: ${({ isAbsolutePosition }) =>
        isAbsolutePosition ? 'auto' : '100%'};
    }

    & > button {
      width: ${({ isAbsolutePosition }) =>
        isAbsolutePosition ? 'auto' : '100%'};
    }

    padding-top: 5px;
  `,
  FormikForm: styled(Form)`
    position: relative;

    && > .ant-input {
      margin-top: 0px;
    }
  `,
  MakePublicProfileContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  MakePublicProfileText: styled.span`
    display: flex;
    align-items: center;
    font-size: 16px;
  `,
  HintContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.profileSettingsForm.colors.hint};
    padding: 5px;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-left: 10px;
  `,
  HintText: styled.span`
    color: ${({ theme }) => theme.profileSettingsForm.colors.hint};
    cursor: pointer;
  `,
  ChangePrimaryInfoButton: styled(BaseButton)`
    width: 100%;
    margin-bottom: 10px;
  `,
};
