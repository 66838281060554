import styled from 'styled-components';

export const S = {
  RightButton: styled.div`
  margin-left: auto;
  margin-right: 0;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
  `,
  ListContainer: styled.div`
    overflow-y: auto;
    max-height: 400px;
    min-height: 100px;
    padding-right: 20px;
  `,
  ButtonContainer: styled.div`
    padding-top: 5px;
  `,
};
