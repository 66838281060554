import { isNull } from 'lodash';
import { AuthServiceType } from 'services/AuthManager/AuthManager.interfaces';
import { LocalStorageManagerInterface } from './LocalStorageManager.interfaces';

const APP_LOCAL_STORAGE_PREFIX = 'GUTHOOK_GUIDES_WEB_APP';
const LAST_USED_AUTH_TYPE_POSTFIX = 'LAST_USED_AUTH_TYPE';
const GUEST_ACCESS_TOKEN_POSTFIX = 'GUEST_ACCESS_TOKEN';

export class LocalStorageManager implements LocalStorageManagerInterface {
  private static instance: LocalStorageManager | null = null;

  public static getInstance() {
    if (!LocalStorageManager.instance) {
      LocalStorageManager.instance = new LocalStorageManager();
    }
    return LocalStorageManager.instance;
  }

  public setItem(name: string, value: string) {
    window.localStorage.setItem(name, value);
  }

  public getItem(name: string): any {
    return window.localStorage.getItem(name);
  }

  public removeItem(name: string) {
    window.localStorage.removeItem(name);
  }

  public setLastUsedAuthServiceType(type: AuthServiceType) {
    this.setItem(
      `${APP_LOCAL_STORAGE_PREFIX}_${LAST_USED_AUTH_TYPE_POSTFIX}`,
      type
    );
  }

  public getLastUsedAuthServiceType() {
    return this.getItem(
      `${APP_LOCAL_STORAGE_PREFIX}_${LAST_USED_AUTH_TYPE_POSTFIX}`
    );
  }

  public removeLastUsedAuthServiceType() {
    this.removeItem(
      `${APP_LOCAL_STORAGE_PREFIX}_${LAST_USED_AUTH_TYPE_POSTFIX}`
    );
  }

  public setGuestAccessToken(token: string) {
    this.setItem(
      `${APP_LOCAL_STORAGE_PREFIX}_${GUEST_ACCESS_TOKEN_POSTFIX}`,
      token
    );
  }

  public getGuestAccessToken() {
    return this.getItem(
      `${APP_LOCAL_STORAGE_PREFIX}_${GUEST_ACCESS_TOKEN_POSTFIX}`
    );
  }

  public removeGuestAccessToken() {
    this.removeItem(
      `${APP_LOCAL_STORAGE_PREFIX}_${GUEST_ACCESS_TOKEN_POSTFIX}`
    );
  }

  public setItemWithExpiry(key: string, value: string, ttl: number) {
    const now = new Date()
  
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  public getItemWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }

    try {
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    } catch (e) {
      console.log(e)
      return null
    }
  }
  
}
