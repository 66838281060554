import styled from 'styled-components';
import { Carousel as AntCarousel } from 'antd';

import { CarouselProps } from './Carousel.interface';
import { forwardRef } from 'react';

export const S = {};

export const StyledCarousel: React.FC<CarouselProps> = styled(
  forwardRef((props: CarouselProps, ref: any) => (
    <AntCarousel {...props} ref={ref} />
  ))
)`
  height: 100%;
  width: 100%;

  .slick-list {
    height: ${({ height }) => (height ? `${height}px` : '100%')};
  }
`;
