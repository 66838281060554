import styled, { css } from 'styled-components';
import { ReactComponent as UnorderedListIcon } from 'static/list-24px.svg';
import { ReactComponent as FilterIcon } from 'static/filter_alt_theme_24dp.svg';
import { ReactComponent as MapIcon } from 'static/map-24px.svg';

interface StyledProps {
  isMobile: boolean;
}

interface ControlsPanelProps extends StyledProps {
  isUnlayoutMode: boolean;
}

const iconSharedStyle = css`
  fill: ${({ theme }) => theme.trailsStore.colors.controlsPanel.icon};
  font-size: 24px;
`;

const ControlWrapper = styled.div`
  margin-left: 8px;
  height: 32px;
  border-radius: 5px;
`;

export const S = {
  ControlsPanel: styled.section<ControlsPanelProps>`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: 'center';
    position: ${({ isUnlayoutMode, isMobile }) =>
      isUnlayoutMode || !isMobile ? 'relative' : 'absolute'};
    z-index: 998;
  `,

  ControlWrapper: styled.div`
    margin-left: 8px;
    height: 32px;
    border-radius: 5px;
  `,

  WhiteBkgControlWrapper: styled(ControlWrapper)`
    background: ${({ theme }) =>
      theme.trailsStore.colors.controlsPanel.iconBackground};
    border: none;
  `,

  UnorderedListIcon: styled(UnorderedListIcon)`
    ${iconSharedStyle}
  `,

  FilterIcon: styled(FilterIcon)`
    ${iconSharedStyle}
  `,

  MapIcon: styled(MapIcon)`
    ${iconSharedStyle}
  `,
};
