import { actions } from 'store/Common/actions'
import {DistanceUnitInfo, ElevationUnitInfo} from 'commonTypes/CommonStore/distanceUnits'
import {distanceUnitName, shortDistanceUnitName} from 'constants/common/distanceUnits'

export const initialState = {
  distanceUnit: {name: distanceUnitName.MI, valueInMeters: 0, shortName: shortDistanceUnitName[distanceUnitName.MI]} as DistanceUnitInfo,
  elevationUnit: {name: distanceUnitName.FT, valueInMeters: 0, shortName: shortDistanceUnitName[distanceUnitName.FT]} as ElevationUnitInfo,
  clockwiseDirectionTrails: [] as string[]
}

export type State = typeof initialState

export type PropActions<T> = T extends { [key: string]: infer R } ? R : never
export type ReturnAnyActions<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<
  PropActions<T>
>

export type CommonAnyActions = ReturnAnyActions<typeof actions>
export const reducer = (state: State = initialState, action: CommonAnyActions): State => {
  switch (action.type) {
    case '@/SET_DISTANCE_UNIT':
      return {
        ...state,
        distanceUnit: action.payload,
      }
    case '@/SET_ELEVATION_UNIT':
      return {
        ...state,
        elevationUnit: action.payload,
      }
    case '@/SET_CLOCKWISE_DIRECTION_TRAILS':
      return {
        ...state,
        clockwiseDirectionTrails: action.payload
      }
    default:
      return state
  }
}
