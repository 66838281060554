import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxGroup } from 'controls/CheckBox/CheckBox';

import { RegionProps } from '../Filter.interface';
import { S } from './Region.styles';

const RegionComponent: React.FC<RegionProps> = ({
  filterParamsState: { region: regionParams },
  selectedFilters,
  handleSetSelectedFilters,
}) => {
  const { region } = selectedFilters;

  const handleChange = (checkedItems: CheckboxValueType[]) => {
    handleSetSelectedFilters({
      sectionName: 'region',
      value: checkedItems,
    });
  };

  return (
    <S.Container>
      {/* WARNING - will need to provide a valid name prop for Fromik */}

      <CheckboxGroup
        name="region"
        options={
          regionParams?.sort((a, b) => (a.label < b.label ? -1 : 1)) || []
        }
        value={region}
        onChange={handleChange}
      />

      {/* END */}
    </S.Container>
  );
};

export const Region = memo(RegionComponent, isEqual);
