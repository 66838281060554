import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { TileLayer } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import { getArrayDepth } from 'utils/getArrayDepth';

import { S } from './GuideMap.styles';
import { GuideMapProps } from 'containers/TrailsStore/TrailsStore.interface';
import { useDefaultStateForMapInstance } from '../../../hooks/useDefaultStateForMapInstance';

const GuideMapComponent: React.FC<GuideMapProps> = ({
  guidePrevCoords,
  guideBounds,
}) => {
  const { handleWhenCreatedMap } = useDefaultStateForMapInstance();

  return (
    <S.Container>
      <S.MapContainer
        zoomControl={false}
        attributionControl={true}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        boxZoom={false}
        dragging={false}
        touchZoom={false}
        bounds={guideBounds}
        whenCreated={handleWhenCreatedMap}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {guidePrevCoords &&
          (getArrayDepth(guidePrevCoords) > 2 ? (
            (guidePrevCoords as LatLngExpression[][]).map(
              (lineString: LatLngExpression[]) => (
                <S.Polyline positions={lineString} />
              )
            )
          ) : (
            <S.Polyline positions={guidePrevCoords} />
          ))}
      </S.MapContainer>
    </S.Container>
  );
};

export const GuideMap = memo(GuideMapComponent, isEqual);
