import { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { FilterContainer } from 'containers/Filter/FilterContainer';
import { CommonMap } from 'containers/CommonMap/CommonMap';
import { GuideViewDetail } from 'views/TrailsStore/GuideViewDetail/GuideViewDetail';
import { CardsMenu } from 'shared/CardsMenu/CardsMenu';
import { ScrollSaverMenuWrapper } from 'shared/ScrollSaverMenuWrapper/ScrollSaverMenuWrapper';
import { TrailsCorousel } from './TrailsCorousel/TrailsCorousel';
import { TrailsStoreControlsPanel } from './ControlsPanel/ControlsPanel';

import { S } from './TrailsStore.styles';
import { TrailsStoreViewProps } from 'containers/TrailsStore/TrailsStore.interface';
import { getIsFetchingSubscriptionInfoSelector } from 'store/TrailsStore';
import { useSelector } from 'react-redux';
import { LoadingOverlay } from 'shared/LoadingOverlay/LoadingOverlay';
import { CountrySelector } from 'views/CountrySelector/CountrySelector';

const TrailsStoreComponent: React.FC<TrailsStoreViewProps> = ({
  isCardsMenuMode,
  onSetIsCardsMenuMode,
  trailsList,
  isFetchingTrailsList,
  isFilterShown,
  setIsFilterShown,
  isMobile,
  selectedFiltersListLength,
  cardsMenuScrollPosition,
  isDetailsDisplaying,
  handleSetCardsMenuScrollPosition,
  handleGoToGuideView,
  handleCountryChanged,
  expandedGuideIds,
  handleSetExpandedGuideIds,
}) => {
  let isFetchingSubscriptionInfoSelector = useSelector(getIsFetchingSubscriptionInfoSelector);

  return (
    <>
      {isFetchingTrailsList && <LoadingOverlay />}
      <S.Container>
        <S.ColumnWrapper isMobile={isMobile}>
          {isDetailsDisplaying || (
            <TrailsStoreControlsPanel
              isMobile={isMobile}
              isUnlayoutMode={isCardsMenuMode}
              isFetchingTrailsList={isFetchingTrailsList}
              selectedFiltersListLength={selectedFiltersListLength}
              onSetIsUnlayoutMode={onSetIsCardsMenuMode}
              setIsFilterShown={setIsFilterShown}
            />
          )}
          <FilterContainer
            isOpen={isFilterShown}
            isFetchingTrailsList={isFetchingTrailsList}
            onClose={() => setIsFilterShown(false)}
          />
          <Switch>
            <Route exact path={`/guides`}>
              {((isCardsMenuMode && !isDetailsDisplaying) || !isMobile) && (
                <ScrollSaverMenuWrapper
                  scrollPosition={cardsMenuScrollPosition}
                  handleSetScrollPosition={handleSetCardsMenuScrollPosition}
                >
                  <CardsMenu
                    trailsList={trailsList?.sort((a, b) => {
                      const first =
                        a.guideName.toLowerCase().indexOf('the ') === 0
                          ? a.guideName.toLowerCase().replace('the ', '')
                          : a.guideName.toLowerCase();
                      const second =
                        b.guideName.toLowerCase().indexOf('the ') === 0
                          ? b.guideName.toLowerCase().replace('the ', '')
                          : b.guideName.toLowerCase();

                      return first > second ? 1 : -1;
                    })}
                    areTrailsLoading={isFetchingTrailsList || isFetchingSubscriptionInfoSelector}
                    expandedGuideIds={expandedGuideIds}
                    handleSetExpandedGuideIds={handleSetExpandedGuideIds}
                  />
                </ScrollSaverMenuWrapper>
              )}
            </Route>
            <Route exact path={`/guides/:trailId`}>
              {(isDetailsDisplaying || !isMobile) && (
                <GuideViewDetail
                  isMobile={isMobile}
                  isCardsMenuMode={isCardsMenuMode}
                  onSetIsCardsMenuMode={onSetIsCardsMenuMode}
                />
              )}
            </Route>
          </Switch>
          <CountrySelector handleCountryChanged={handleCountryChanged}/>
        </S.ColumnWrapper>
        {((!isCardsMenuMode && !isDetailsDisplaying) || !isMobile) && (
          <Route exact path={[`/guides`, `/guides/:trailId`]}>
            <CommonMap
              isMobile={isMobile}
              trailsList={trailsList}
              handleGoToGuideView={handleGoToGuideView}
            />
            {isMobile && (
              <TrailsCorousel
                trailsList={trailsList}
                isFetchingTrailsList={isFetchingTrailsList}
                handleGoToGuideView={handleGoToGuideView}
              />
            )}
          </Route>
        )}
      </S.Container>
    </>
  );
};

export const TrailsStore = memo(TrailsStoreComponent, isEqual);
