import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { ProfilePage } from 'containers/ProfilePage/ProfilePage';
import { HeaderHorizontalMenu } from './HeaderHorizontalMenu/HeaderHorizontalMenu';
import { HeaderIconsMenu } from 'views/Header/HeaderIconsMenu/HeaderIconsMenu';
import { S } from './Header.styles';

import { HeaderViewProps } from 'containers/Header/Header.interfaces';
import { PurchasedReceipts } from '../../containers/PurchasedReceipts/PurchasedReceipts';
import { useTransition, animated } from 'react-spring';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { RedeemCodeDialog } from 'containers/RedeemCode/RedeemCodeDialog';

const HeaderComponent: React.FC<HeaderViewProps> = ({
  isUserLoggedIn,
  handleShowDrawer,
  isMobileLayout,
  isProfilePageShown,
  isRedeemCodeDialogShown,
  onShowProfilePage,
  onHideProfilePage,
  handleSignIn,
  handleShowPurchases,
  handleHidePurchases,
  handleShowRedeemCode,
  handleHideRedeemCode,
  handleGoToExploreTrails,
  isPurchasedReceiptsShown,
  handleSignOut,
  userName,
  isTabletLayout,
  profilePopupVisibility,
  onProfilePopupVisibleChange,
}) => {
  const history = useHistory();
  const location = useLocation();
  const logoWidth =
    isTabletLayout && !isMobileLayout
      ? '100px'
      : isMobileLayout
      ? '150px'
      : '225px';
  return (
    <S.Container>
      <S.LogoContainer isMobileLayout={isMobileLayout}>
        <a onClick={()=>history.push('/')}>
          <S.GuthookGuidesLogo width={logoWidth} />
        </a>
      </S.LogoContainer>
      <S.EmptySpace />
      <Button style={{borderRadius: 5, background: '#FFFFFF', borderColor: "#DC1C50", color: "#DC1C50"}} type="primary"><a href='https://guthookguides.atlasguides.com/N59S/axz9byhj' target='_blank'>Get the app</a></Button>
      <HeaderHorizontalMenu show={!isMobileLayout} />
      <HeaderIconsMenu
        isMobileLayout={isMobileLayout}
        handleShowDrawer={handleShowDrawer}
        onShowProfilePage={onShowProfilePage}
        handleSignIn={handleSignIn}
        handleSignOut={handleSignOut}
        handleShowPurchases={handleShowPurchases}
        handleShowRedeemCodeDialog={handleShowRedeemCode}
        handleGoToExploreTrails={handleGoToExploreTrails}
        userName={userName}
        profilePopupVisibility={profilePopupVisibility}
        onProfilePopupVisibleChange={onProfilePopupVisibleChange}
      />
      <ProfilePage
        isProfilePageShown={isProfilePageShown}
        onHideProfilePage={onHideProfilePage}
        onShowProfilePage={onShowProfilePage}
      />
      <PurchasedReceipts
        onHidePurchasedReceipts={handleHidePurchases}
        isPurchasedReceiptsShown={isPurchasedReceiptsShown}
      />
      <RedeemCodeDialog
        isRedeemCodeDialogShown={isRedeemCodeDialogShown}
        onHideRedeemCodeDialog={handleHideRedeemCode}
      />
    </S.Container>
  );
};

export const Header = memo(HeaderComponent, isEqual);
