import styled from 'styled-components';
import { baseFormStyles } from '../../shared.styles';

export const S = {
  Layout: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${baseFormStyles}
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  `,
};
