import { Fragment, memo } from 'react';
import isEqual from 'lodash.isequal';
import { StyledGroup, StyledCheckbox } from './CheckBox.styles';
import {
  CheckboxGroupProps,
  GroupedCheckboxOptionType,
} from './CheckBox.interface';

export const Checkbox = memo(StyledCheckbox, isEqual);

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  AntCheckboxProps,
  ...props
}) => (
  <StyledGroup {...props}>
    {options.map((option: GroupedCheckboxOptionType) => (
      <Fragment key={`${option.label}${option.value}`}>
        <StyledCheckbox {...AntCheckboxProps} option={option} />
      </Fragment>
    ))}
  </StyledGroup>
);
