import { AppState } from '../rootReducer';
import { createSelector } from 'reselect';

const getUserStore = (state: AppState) => state.userState;

export const getUploadingCoverImageLoadingStateSelector = createSelector(
  getUserStore,
  state => state.isUploadingCoverImage
);

export const getUploadingProfileImageLoadingStateSelector = createSelector(
  getUserStore,
  state => state.isUploadingProfileImage
);
