import isEqual from 'lodash.isequal';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { actions } from 'store/TrailsStore/actions';
import { theme } from '../../../theme';
import {
  actions as cartActions,
  CartItemFrontend,
  selectCartPopoverVisibility,
} from 'store/CartStore';
import {
  getTrailGuideViewSelector,
  getIsFetchingTrailGuideViewSelector,
  getAllTrailsListSelector,
  getAllTrailsListWithChildrenSelector,
  getIsFilteredStateSelector,
  getIsSearchedStateSelector,
  getIsActiveSubscriptionSelector
} from 'store/TrailsStore/selectors';
import {
  selectCartItemsHashmapCollection,
  selectItemsBeingAddedToCart,
  selectShopifyCart,
} from 'store/CartStore';
import { getDistanceUnitSelector } from 'store/Common/selectors';
import { ViewDetail } from 'shared/ViewDetail/ViewDetail';
import { LoadingSpinner } from 'shared/LoadingSpinner/LoadingSpinner';
import { GuideMap } from 'views/TrailsStore/GuideMap/GuideMap';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { Tag } from 'controls/Tag/Tag';
import { CardsMenu } from 'shared/CardsMenu/CardsMenu';
import { findAndMakeLinksInText } from 'utils/findAndMakeLinksInText';
import { findAndMakeEmailsInText } from 'utils/findAndMakeEmailsInText';

import { S } from './GuideViewDetail.styles';
import { GuideViewDetailProps } from 'containers/TrailsStore/TrailsStore.interface';
import { Guide } from 'commonTypes/TrailsStore/guides';
import {
  GuideDto,
  GuideType,
} from '../../../atlasguides-web-common/src/functions/guides/dto/guide.dto';
import { LinkWithPreviousPath } from '../../../controls/LinkWithPreviousPath/LinkWithPreviousPath';
import { goBackByRelativePath } from '../../../utils/goBackByRelativePath';
import { RightArrow } from '../../../shared/Arrow/RightArrow/RightArrow';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatPrice } from 'utils/formatUtils';

const GuideViewDetailComponent: React.FC<GuideViewDetailProps> = ({
  isMobile,
  isCardsMenuMode,
  onSetIsCardsMenuMode,
}) => {
  const { trailId }: { trailId: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    guideId,
    guideType,
    guideIcon,
    guideName,
    guidePromoText,
    guidePrice,
    discount,
    guideSavingsInfo,
    guideDescription,
    guideLength,
    parentGuides,
    guideTags,
    guidePreviewCoordinates,
    guideBounds,
    childGuides,
    guideIsFree,
    isPurchased,
    photosCarousel,
    guideIsPurchaseable,
    guideHasOpenButton,
    shopifyVariantId,
  } = useSelector(getTrailGuideViewSelector);

  const isActiveSubscription = useSelector(getIsActiveSubscriptionSelector);

  const isGuideOnSale = (!guideIsFree && guideIsPurchaseable && !isPurchased && guidePrice?.regular !== null && guidePrice?.final < guidePrice?.regular);
  const isGuideDiscount = (!guideIsFree && guideIsPurchaseable && !isPurchased && !isGuideOnSale && guideSavingsInfo !== null && guideSavingsInfo?.percentage > 0);

  const isFetchingTrailGuideView = useSelector(
    getIsFetchingTrailGuideViewSelector
  );
  const allTrails = useSelector(getAllTrailsListSelector);
  const allTrailsWithChildren = useSelector(
    getAllTrailsListWithChildrenSelector
  );
  const isFilteredState = useSelector(getIsFilteredStateSelector);
  const isSearchedState = useSelector(getIsSearchedStateSelector);
  const distanceUnit = useSelector(getDistanceUnitSelector);
  const itemsInCart = useSelector(selectCartItemsHashmapCollection);
  const itemsBeingAddedToCart = useSelector(selectItemsBeingAddedToCart);
  const shopifyCart = useSelector(selectShopifyCart);

  var isInCart = false;
  if(shopifyCart.lineItems !== null && shopifyCart.lineItems !== undefined){
    shopifyCart.lineItems.forEach(lineItem => {
      if(lineItem.variant!== null && lineItem.variant !== undefined) {
        if(lineItem.variant.id === shopifyVariantId){
          isInCart=true;
        }
      }
    });
  }

  const handleHiglightTrail = useCallback(
    (trails: GuideDto[]) => {
      const isCurrentGuideStandAlone = trails.some(
        guide => guide.guideId === guideId
      );
      const doesTrailHaveOwnGeo = !!guidePreviewCoordinates;

      (!isCurrentGuideStandAlone || !doesTrailHaveOwnGeo) &&
      parentGuides &&
      parentGuides.length > 0
        ? dispatch(actions.setSelectedTrailGuideId(parentGuides[0].guideId))
        : dispatch(actions.setSelectedTrailGuideId(guideId));
    },
    [dispatch, guideId, guidePreviewCoordinates, parentGuides]
  );

  const handleHighlightAppropriateTrailOnMap = useCallback(() => {
    if (isFilteredState || isSearchedState) {
      handleHiglightTrail(allTrailsWithChildren);
    } else {
      handleHiglightTrail(allTrails);
    }
  }, [
    allTrails,
    allTrailsWithChildren,
    isFilteredState,
    isSearchedState,
    handleHiglightTrail,
  ]);

  const handleGoToTrailOnCommonMap = () => {
    if (isMobile) {
      dispatch(actions.setIsDetailsDisplaying(false));
      isCardsMenuMode && onSetIsCardsMenuMode();
    }
  };

  useEffect(() => {
    if (guideId) {
      handleHighlightAppropriateTrailOnMap();
    }
  }, [guideId, handleHighlightAppropriateTrailOnMap]);

  useEffect(() => {
    //dispatch(actions.getActiveSubscriptionRequest());
    dispatch(actions.setIsDetailsDisplaying(true));
    dispatch(actions.getTrailGuideViewRequest(trailId));

    return () => {
      dispatch(actions.getTrailGuideViewResponse({}));
      dispatch(actions.setIsDetailsDisplaying(false));
    };
  }, [dispatch, trailId]);

  const renderDemoLink = (freeTrail: Guide | null | undefined) =>
    freeTrail && (
      <LinkWithPreviousPath
        to={`/guides/${encodeURIComponent(freeTrail.guideName)}`}
      >
        <S.DemoLinkArrowWrapper>
          <S.LinkTextSection>
            <S.Text>
              <FormattedMessage id="free_demo" values={{br:<br/>}}/>
            </S.Text>
          </S.LinkTextSection>
          <RightArrow sizes="large" />
        </S.DemoLinkArrowWrapper>
      </LinkWithPreviousPath>
    );

  const handleSearchByTag = (tagName: string) => {
    history.push('/guides');
    dispatch(actions.setSearchedValue(tagName));
    dispatch(actions.setIsDetailsDisplaying(false));
    !isCardsMenuMode && onSetIsCardsMenuMode();
  };

  const handleAddGuideInCart = (isInCart: boolean, item: CartItemFrontend) => {
    isInCart
      ? dispatch(cartActions.setPopoverVisibility(true))
      : dispatch(cartActions.requestAddItem(item));
  };

  const handleGoBack = () => {
    const locationState = location.state as { prevPath?: string } | undefined;
    goBackByRelativePath({
      history,
      locationState,
      validRelativePath: '/guides',
    });
  };

  const handleTransformText = (text: string) => {
    const textWithLinks = findAndMakeLinksInText(text);
    const textWithEmails = findAndMakeEmailsInText(textWithLinks);

    return textWithEmails;
  };

  let intl = useIntl()
  return isFetchingTrailGuideView ? (
    <S.LoadingSpinnerWrapper>
      <LoadingSpinner size="large" />
    </S.LoadingSpinnerWrapper>
  ) : (
    <ViewDetail
      picturesView={photosCarousel || []}
      iconView={guideIcon}
      onBackButtonClick={handleGoBack}
    >
      <S.ContentWrapper>
        {renderDemoLink(
          !isPurchased
            ? childGuides?.find((trail: Guide) => trail.guideIsFree)
            : null
        )}
        <S.ContentHeader>
          <S.Title>{guideName}</S.Title>
          <S.ShortInfo>
            <S.Li>
              {guideLength ? guideLength[distanceUnit.name]?.toFixed() : '??'}{' '}
              <FormattedMessage id={distanceUnit.shortName}/>
            </S.Li>
            <S.Li>{guidePromoText}</S.Li>
          </S.ShortInfo>
          {(isPurchased || guideIsPurchaseable || guideIsFree || isActiveSubscription) && (
            <>
              {guideIsFree && (
                <S.Price>
                  <FormattedMessage id="free"/>
                </S.Price>
              )}
              {isPurchased && !guideIsFree && (
                <S.Price>
                  <FormattedMessage id="purchased"/>
                </S.Price>
              )}
              {(isGuideDiscount) && (
                <S.HeaderFieldWrapper>
                  <S.DiscountText>
                    <FormattedMessage id="guide_sale_info" values={{savings:(guideSavingsInfo.percentage * 100).toFixed(), br:<br/>}}/>
                  </S.DiscountText>
                </S.HeaderFieldWrapper>
              )}
              {!isPurchased && !guideIsFree && !isGuideOnSale && !isActiveSubscription && (
                <S.HeaderFieldWrapper>
                  <S.Price>
                    {formatPrice(guidePrice?.final, guidePrice?.currencyCode)}
                  </S.Price>
                </S.HeaderFieldWrapper>
              )}
              {isGuideOnSale && (
                <S.HeaderFieldWrapper>
                  <S.DiscountText>
                      <FormattedMessage id="sale"/>
                  </S.DiscountText>
                  <S.DiscountCross>
                      {formatPrice(guidePrice?.regular, guidePrice?.currencyCode)}
                  </S.DiscountCross>
                  <S.DiscountLine1>
                      {formatPrice(guidePrice.final, guidePrice?.currencyCode)}
                  </S.DiscountLine1>
                </S.HeaderFieldWrapper>
              )}                  

              <S.ButtonWrapper>
                {guideIsFree || isPurchased || isActiveSubscription ? (
                  <>
                    {guideHasOpenButton && (
                      <Link to={`/guides/${encodeURIComponent(guideName)}/geo`}>
                        <BaseButton
                          type={isActiveSubscription ? "subscription" : "primary"}
                          label={intl.formatMessage({id:"open"})}
                          size="large"
                          fullwidth
                        />
                      </Link>
                    )}
                  </>
                ) : (
                  <BaseButton
                    type="primary"
                    label={`${
                      isInCart
                        ? intl.formatMessage({id:"in_cart"})
                        : intl.formatMessage({id:"add_to_cart"})
                    }`}
                    loading={!!itemsBeingAddedToCart?.hasOwnProperty(guideId)}
                    size="large"
                    fullwidth
                    onClick={() =>
                      handleAddGuideInCart(
                        isInCart,
                        {
                          shopifyVariantId,
                          itemUid: '',
                          guideId,
                          guideName,
                          guideIcon,
                          guidePrice: guidePrice.final,
                          guideDiscountAmount: discount.amount,
                          guideDiscountPercent: discount.percent,
                          guidePromoText,
                          guideHasOpenButton,
                          guideType,
                        }
                      )
                    }
                  />
                )}
              </S.ButtonWrapper>
            </>
          )}
        </S.ContentHeader>
        {childGuides && childGuides.length > 0 && (
          <>
            <S.SectionTitle><FormattedMessage id="included_in_purchases"/></S.SectionTitle>
            <CardsMenu
              isOnSale={(guidePrice?.regular !== null && guidePrice?.final < guidePrice?.regular)}
              trailsList={childGuides
                .sort((a, b) => (a.guideId < b.guideId ? -1 : 1))
                .sort((a, b) => Number(b.guideIsFree) - Number(a.guideIsFree))}
            />
          </>
        )}
        <S.BodyContainer>
          {parentGuides && parentGuides.length > 0 && (
            <S.OtherPurchasedOtions>
              <S.SectionTitle
                hasVerticalPadding={guideType === GuideType.NoSkuInBundle}
              >
                {guideType === GuideType.NoSkuInBundle
                  ? intl.formatMessage({id:"bundled_guide_info"})
                  : intl.formatMessage({id:"other_purchase_info"})}
              </S.SectionTitle>
              {parentGuides.map(guide => (
                <LinkWithPreviousPath
                  to={`/guides/${encodeURIComponent(guide.guideName)}`}
                  key={guide.guideId}
                >
                  <S.PurchasedOption>
                    <S.OptionName>{guide.guideName}</S.OptionName>
                    {guide.isPurchased ? (
                      // If the guide is purchased, display 'Purchased'
                      <S.OptionPriceSection>
                        <S.ActualOptionPrice><FormattedMessage id="purchased"/></S.ActualOptionPrice>
                        <S.InfoCircleOutlinedIcon />
                      </S.OptionPriceSection>
                    ) : (
                      // If the guide is not purchased, display the existing pricing information
                      <S.OptionPriceSection>
                        {(guide.guidePrice &&
                          guide.guidePrice.regular &&
                          guide.guidePrice.regular !== guide.guidePrice.final) && (
                          <S.OldOptionPrice>{`${formatPrice(guide.guidePrice?.regular, guide.guidePrice?.currencyCode)}`}</S.OldOptionPrice>
                        )}
                        <S.ActualOptionPrice>{`${formatPrice(guide.guidePrice?.final, guide.guidePrice?.currencyCode)}`}</S.ActualOptionPrice>
                        <S.InfoCircleOutlinedIcon />
                      </S.OptionPriceSection>
                    )}
                  </S.PurchasedOption>
                </LinkWithPreviousPath>
              ))}
            </S.OtherPurchasedOtions>
          )}
        </S.BodyContainer>
        {guidePreviewCoordinates?.coordinates && (
          <S.GuideMapWrapper onClick={handleGoToTrailOnCommonMap}>
            <GuideMap
              guidePrevCoords={guidePreviewCoordinates?.coordinates}
              guideBounds={
                guideBounds || [
                  [-39.129364, 146.318756],
                  [-39.018536, 146.4711],
                ]
              }
            />
          </S.GuideMapWrapper>
        )}
        <S.BodyContainer>
          <S.Description
            dangerouslySetInnerHTML={{
              __html: handleTransformText(guideDescription || ''),
            }}
          />
          <S.TagsSection>
            {guideTags?.map((tag: string) => (
              <S.TagWrapper key={tag}>
                <Tag onClick={() => handleSearchByTag(tag)}>{tag}</Tag>
              </S.TagWrapper>
            ))}
          </S.TagsSection>
        </S.BodyContainer>
      </S.ContentWrapper>
      {(guideIsPurchaseable || guideIsFree) && (
        <>
          <S.AddToCartButtonWrapper>
            {guideIsFree || isPurchased || isActiveSubscription ? (
              guideHasOpenButton && (
                <Link to={`/guides/${encodeURIComponent(guideName)}/geo`}>
                  <BaseButton
                    type={isActiveSubscription ? "subscription" : "primary"}
                    label={intl.formatMessage({id:"open"})}
                    size="large"
                    fullwidth
                  />
                </Link>
              )
            ) : (
              <BaseButton
                type="primary"
                label={`${
                  isInCart
                    ? intl.formatMessage({id:"in_cart"})
                    : intl.formatMessage({id:"add_to_cart"}) + " - " + guidePrice?.final?.toFixed(2)
                }`}
                loading={!!itemsBeingAddedToCart?.hasOwnProperty(guideId)}
                fullwidth
                square
                onClick={() =>
                  handleAddGuideInCart(isInCart, {
                    shopifyVariantId,
                    itemUid: '',
                    guideId,
                    guideName,
                    guideIcon,
                    guidePrice: guidePrice.final,
                    guideDiscountAmount: discount.amount,
                    guideDiscountPercent: discount.percent,
                    guidePromoText,
                    guideHasOpenButton,
                    guideType,
                  })
                }
              />
            )}
          </S.AddToCartButtonWrapper>
        </>
      )}
    </ViewDetail>
  );
};

export const GuideViewDetail = memo(GuideViewDetailComponent, isEqual);
