import {
  memo,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useSelector } from 'react-redux';
import { S } from './CountrySelector.styles';
import isEqual from 'lodash.isequal';

import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { getAvailableCountriesSelector } from 'store/TrailsStore';
import { CountrySelectorProps } from 'containers/TrailsStore/TrailsStore.interface';

const CountrySelectorComponent: React.FC<CountrySelectorProps> = ({handleCountryChanged}) => {

  const availableCountriedList = useSelector(getAvailableCountriesSelector);

  const localStorage = LocalStorageManager.getInstance();
  const [ selectedCountryCode, setSelectedCountryCode] = useState(localStorage.getItem('shopifyCountry'));

  let geoCountryCode = localStorage.getItem('geoCountryCode'); 

  useEffect(() => {
    if (!selectedCountryCode){
      setSelectedCountryCode(geoCountryCode)
    }
  }, [selectedCountryCode, localStorage, geoCountryCode]);

  const handleOnLanguageChange = useCallback((value:any) => {
    if (value){
      if (value === geoCountryCode){
        localStorage.removeItem('shopifyCountry');
      }
      else{
        localStorage.setItem('shopifyCountry', value as string);
      }
      setSelectedCountryCode(value)

      handleCountryChanged(value)
    }
  }, [geoCountryCode, handleCountryChanged, localStorage]);

  return (
    <S.Container>
      <S.Wrapper>
        <Select 
          style={{width: 350, fontSize:'small'}} 
          name="country" 
          value={selectedCountryCode} 
          onChange={(e) => handleOnLanguageChange(e.target.value)}>
        {availableCountriedList?.map(country => {
            return (
              <MenuItem key={country.code} value={country.code}>
                {country.name + " (" + country.currencyCode + (country.currencySymbol !== country.currencyCode ? " " + country.currencySymbol : "" ) + ")"}
              </MenuItem>
            );
        })}
      </Select>
      </S.Wrapper>
    </S.Container>
  );
};

export const CountrySelector = memo(CountrySelectorComponent, isEqual);
