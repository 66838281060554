import styled from 'styled-components';

export const S = {
  Container: styled.div<{ height: string }>`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: ${props => props.height};
    display: flex;
    flex-direction: column;
  `,

  MainContent: styled.div`
    flex: 1;
    min-height: 0;
  `,
};
