import { useDispatch } from 'react-redux';
import { actions } from '../store/TrailsStore';
import { useCallback } from 'react';

export const useAppClearStateDecorator = () => {
  const dispatch = useDispatch();

  const clearAppStateDecorator = useCallback(
    <T extends (args?: unknown) => void>(func: T) => {
      return async (...funcArgs: Parameters<T>) => {
        await func(...funcArgs);
        dispatch(actions.clearAllGuidesPurchasedState());
      };
    },
    [dispatch]
  );

  return {
    withClearAppState: clearAppStateDecorator,
  };
};
