import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { ReactComponent as ShoppingCartIcon } from 'static/shopping_cart-24px.svg';
import { CartViewProps } from 'containers/Cart/Cart.interface';
import { CartContentView } from 'views/CartContent/CartContentView';
import { Popover } from 'antd';

import { S } from './CartView.styles';

const CartViewComponent: React.FC<CartViewProps> = ({
  icon,
  isMobile,
  popoverVisibility,
  onVisibleChange,
  lineItems,
  shopifyCheckoutUrl,
  handleRemoveShopifyItem,
  handleGoToSignIn,
  ...cartContentProps
}) => {
  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      arrowPointAtCenter
      overlayStyle={{ borderRadius: '7px', maxWidth: '80%' }}
      zIndex={1061}
      visible={popoverVisibility}
      onVisibleChange={onVisibleChange}
      content={<CartContentView 
          handleGoToSignIn={handleGoToSignIn} 
          handleRemoveShopifyItem={handleRemoveShopifyItem} 
          shopifyCheckoutUrl={shopifyCheckoutUrl} 
          lineItems={lineItems} {...cartContentProps} />}
    >
      {/*<S.IconContainer>
        {icon ? icon : <ShoppingCartIcon />}
        {!cartContentProps.isCartEmpty && (
          <S.BadgeContainer>
            <S.BadgeText>{cartContentProps.trailsList.length}</S.BadgeText>
          </S.BadgeContainer>
        )}
      </S.IconContainer>*/}
      <S.IconContainer>
        {icon ? icon : <ShoppingCartIcon />}
        {lineItems.length > 0 && (
          <S.BadgeContainer>
            <S.BadgeText>{lineItems.length}</S.BadgeText>
          </S.BadgeContainer>
        )}
      </S.IconContainer>
    </Popover>
  );
};

export const CartView = memo(CartViewComponent, isEqual);
