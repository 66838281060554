import { IntlConfig } from 'react-intl';
import { not_translatable } from './NotTranslatableStrings';

import messages_en from "../../translations/en.json";
import messages_uk from "../../translations/uk.json";
import messages_cs from "../../translations/cs.json";

let navLanguage = navigator.language || 'en'; 
navLanguage = navLanguage.split(/[-_]/)[0];  // language without region code

//navLanguage = 'cs';

const messages =  {
  'en': messages_en,
  'uk': messages_uk,
  'cs': messages_cs,
};

if (!(navLanguage in messages)){
   navLanguage = 'en';
}

var selectedMessages = messages[navLanguage as keyof IntlConfig['messages']];

// Add default locale messages to result dictionary if there are untraslated strings
if (navLanguage !== 'en' && Object.keys(messages_en).length !== Object.keys(selectedMessages).length){
  selectedMessages = Object.assign({}, messages_en, selectedMessages, not_translatable);
}
else{
  selectedMessages = Object.assign({}, selectedMessages, not_translatable);
}

export class LocalizationManager {

  public static getComponentConfig(){
    return {
      locale : navLanguage,
      messages: selectedMessages,
      defaultLocale: 'en',
      defaultMessages: messages_en
    }
  }

  public static getDefaultLanguage(){
    return 'en';
  }

  public static getLanguage(): string {
    return navLanguage;
  }

  public static getSelectedMessages(){
    return selectedMessages;
  }

  public static isDefaultLanguage(){
    return navLanguage === 'en';
  }

}
