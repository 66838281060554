import { StyledLoadingSpinner } from './LoadingSpinner.styles';
import { LoadingSpinnerProps } from './LoadingSpinner.interface';
import Icon, {
  LoadingOutlined
} from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const LoginSvgIcon = (props: any) => (
  <svg {...props} viewBox="0 0 313.37537 271.79001">
    <path d="m 141.93537,37.87 c 6.52,0 13.15,0.84 19.75,2.61 l 113.83,30.47 v 51.55 l -118.16,-31.61 58.3,101.12 -21.51,12.42 -21.51,12.42 -58.42,-101.05 -31.700003,118.13 -44.64,-25.77 30.51,-113.81 c 5.45,-20.32 18.61,-36.5 35.500003,-46.25 11.4,-6.58 24.49,-10.23 38.05,-10.23 m 0,-37.87 c -19.94,0 -39.65,5.29 -56.990003,15.31 -26.37,15.22 -45.24,39.81 -53.13,69.23 L 1.2953666,198.35 c -4.44,16.56 2.8,34.03 17.6400004,42.6 l 44.64,25.77 c 5.83,3.37 12.37,5.07 18.93,5.07 4.91,0 9.83,-0.95 14.48,-2.88 10.870003,-4.5 19.040003,-13.81 22.090003,-25.17 l 8.04,-29.96 12.73,22.02 c 7.02,12.14 19.74,18.92 32.82,18.92 6.42,0 12.94,-1.64 18.9,-5.08 l 21.51,-12.42 21.51,-12.42 c 18.1,-10.45 24.31,-33.6 13.87,-51.71 l -12.7,-22.03 29.96,8.02 c 3.23,0.86 6.52,1.29 9.79,1.29 8.25,0 16.37,-2.7 23.06,-7.83 9.34,-7.17 14.81,-18.27 14.81,-30.04 V 70.95 c 0,-17.14 -11.52,-32.15 -28.07,-36.58 L 171.47537,3.9 c -9.66,-2.58 -19.6,-3.9 -29.54,-3.9 z" />
  </svg>
);

const LoadingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    spin
    style={{
      color: "#00FF00",
      fontSize: "20pt" 
    }}
    component={LoginSvgIcon}
    {...props}
  />
);

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = props => (
  <StyledLoadingSpinner {...props} indicator={
    // <LoadingOutlined spin />
    <LoadingIcon style={{ fontSize: "42px" }} />
  } />
);
