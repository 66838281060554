import styled from 'styled-components';
interface StyledProps {
  isMobile: boolean;
}

interface ColumnWrapperProps extends StyledProps {}

export const S = {
  Container: styled.div`
    height: 100%;
    position: relative;
    display: flex;
  `,

  ColumnWrapper: styled.div<ColumnWrapperProps>`
    position: relative;
    height: 100%;
    width: 100%;
    max-width: ${({ isMobile }) => !isMobile && '400px'};
    display: flex;
    flex-direction: column;
  `,

  ItemWrapper: styled.div`
    max-width: 260px;
  `,

  CarouselWrapper: styled.div`
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    z-index: 1001;
  `,
};
