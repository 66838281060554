import React, { memo, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { Modal } from '../../shared/Modal/Modal';
import { S } from './PurchasedReceipts.styles';
import { PurchasedReceiptsViewProps } from './PurchasedReceipts.interfaces';
import { getPaymentMethodDescriptionByType } from '../../utils/getPaymentMethodDescriptionByType';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

const PurchasedReceiptsViewComponent: React.FC<PurchasedReceiptsViewProps> = ({
  onHidePurchasedReceipts,
  purchasedGuides,
  isPurchasedReceiptsShown,
}) => {
  const history = useHistory();

  const handleGuideNameClick = (guideName: string) => {
    onHidePurchasedReceipts();
    history.push(`/guides/${encodeURIComponent(guideName)}`);
  };

  /* REMOVED PAYMENT METHOD COLUMN AND DATA TRANSFORMATION:
  COLUMN:
  {
    title: 'Payment Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    render: (paymentMethod: { title: string; receiptLink?: string }) => (paymentMethod.title)
  },
  DATA:
  paymentMethod: {
    title:
      guide.paymentMethodDetails ||
      getPaymentMethodDescriptionByType(guide.paymentMethodType),
    receiptLink: guide.receiptLink,
  },
   */

  const columns = useMemo(
    () => [
      {
        title: <FormattedMessage id="purchase_date"/>,
        dataIndex: 'purchaseDate',
        key: 'purchaseDate',
      },
      {
        title: <FormattedMessage id="guide"/>,
        dataIndex: 'guide',
        key: 'guide',
        render: ({
          guideId,
          guideName,
        }: {
          guideName: string;
          guideId: string;
        }) => (
          <S.GuideName onClick={() => handleGuideNameClick(guideName)}>
            {guideName}
          </S.GuideName>
        ),
      },

    ],
    []
  );

  const data = useMemo(
    () =>
      purchasedGuides.map(guide => ({
        key: guide.guideId,
        guide: { guideName: guide.guideName, guideId: guide.guideId },

        purchaseDate: format(new Date(guide.purchaseDate), 'M/d/yyyy'),
      })),
    [purchasedGuides]
  );

  return (
    <Modal
      visible={isPurchasedReceiptsShown}
      onCancel={onHidePurchasedReceipts}
      zIndex={1002}
      footer={null}
      modalWindowWidth={650}
      withBorderRadius
      centered
    >
      <S.InnerContainer>
        <S.Header><FormattedMessage id="purchase_receipts"/></S.Header>
        <S.BodyContainer>
          <S.Table
            columns={columns}
            dataSource={data}
            pagination={false}
            sticky
          />
          <S.EmailUsTip>
            <FormattedMessage id="purchase_receipts_note" values={{email:
                    <S.EmailAnchor href="mailto:support@faroutguides.com">
                      support@faroutguides.com
                    </S.EmailAnchor>}}/>
          </S.EmailUsTip>
        </S.BodyContainer>
      </S.InnerContainer>
    </Modal>
  );
};

export const PurchasedReceiptsView = memo(
  PurchasedReceiptsViewComponent,
  isEqual
);
