import styled from 'styled-components';

export const S = {
  Container: styled.div`
    min-height: 30px;
    padding: 10px;
    padding-bottom: 5px;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.filter.colors.sectionBorder};
  `,
  Wrapper: styled.div`
    margin-left: 15px;
    margin-right: 30px;
  }`
};
