import { ElevationProfileExpansionLevels } from '../../commonTypes/enums';

export const actions = {
  getGuideGeoDataRequest: (id: string) =>
    ({
      type: '@/GET_GUIDE_DATA_REQUEST',
      payload: id,
    } as const),
  getGuideGeoDataResponse: (response: any) =>
    ({
      type: '@/GET_GUIDE_DATA_RESPONSE',
      payload: response,
    } as const),
  setIsPointsMenuMode: (bool?: boolean) =>
    ({
      type: '@/SET_IS_POINTS_MENU_MODE',
      payload: bool,
    } as const),
  setIsWaypointDetailsOpen: (bool?: boolean) =>
    ({
      type: '@/SET_IS_WAYPOINT_DETAILS_OPEN',
      payload: bool,
    } as const),
  setSelectedWaypoint: (id: string) =>
    ({
      type: '@/SET_SELECTED_WAYPOINT',
      payload: { id },
    } as const),
  setIsMapSettingsOpen: (bool: boolean) =>
    ({
      type: '@/SET_IS_MAP_SETTINGS_OPEN',
      payload: bool,
    } as const),
  setIsLayersModalOpen: (bool: boolean) =>
    ({
      type: '@/SET_IS_LAYERS_MODAL_OPEN',
      payload: bool,
    } as const),
  setIsElevationModalOpen: (bool: boolean) =>
    ({
      type: '@/SET_IS_ELEVATION_MODAL_OPEN',
      payload: bool,
    } as const),
  getWaypointCommentsRequest: (id: string) =>
    ({
      type: '@/GET_WAYPOINT_COMMENTS_REQUEST',
      payload: id,
    } as const),
  getWaypointCommentsResponse: (response: any) =>
    ({
      type: '@/GET_WAYPOINT_COMMENTS_RESPONSE',
      payload: response,
    } as const),
  setMapLayerLink: (link: any) =>
    ({
      type: '@/SET_MAP_LAYER_LINK',
      payload: link,
    } as const),
  setMapLayerAttribution: (attribution: string) =>
    ({
      type: '@/SET_MAP_LAYER_ATTRIBUTION',
      payload: attribution,
    } as const),
  setIsLeftMenuCollapsed: (bool: boolean) =>
    ({
      type: '@/SET_IS_LEFT_MENU_COLLAPSED',
      payload: bool,
    } as const),
  setIsWaypointSearchingState: (bool: boolean) =>
    ({
      type: '@/SET_IS_WAYPOINT_SEARCHING_STATE',
      payload: bool,
    } as const),
  setSearchedWaypointsList: (waypoints: any) =>
    ({
      type: '@/SET_SEARCHED_WAYPOINTS_LIST',
      payload: waypoints,
    } as const),
  setWaypointSearchedValue: (payload: string) =>
    ({
      type: '@/SET_WAYPOINT_SEARCHED_VALUE',
      payload,
    } as const),
  increaseElevationProfileExpansionLevel: () =>
    ({
      type: '@/INCREASE_ELEVATION_PROFILE_EXPANSION_LEVEL',
    } as const),
  decreaseElevationProfileExpansionLevel: () =>
    ({
      type: '@/DECREASE_ELEVATION_PROFILE_EXPANSION_LEVEL',
    } as const),
  setElevationProfileExpansionLevel: (
    payload: ElevationProfileExpansionLevels
  ) =>
    ({
      type: '@/SET_ELEVATION_PROFILE_EXPANSION_LEVEL',
      payload,
    } as const),
};
