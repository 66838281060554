import styled, { useTheme } from 'styled-components';
import { Drawer as AntDrawer, Menu as AntMenu } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import { ReactComponent as GuthookGuidesLogo } from 'static/logo_farout_white.svg';

import 'antd/lib/drawer/style/css';
import 'antd/lib/menu/style/css';

const { SubMenu: AntSubMenu, Item: AntMenuItem } = AntMenu;

export const S = {
  GuthookGuidesLogo: styled(GuthookGuidesLogo)`
    width: 100%;
    padding: 50px;
    padding-left: 0;
  `,
  Drawer: styled(props => {
    const theme = useTheme();
    return (
      <AntDrawer
        bodyStyle={{
          background: theme.drawer.colors.background,
          color: theme.drawer.colors.text,
        }}
        headerStyle={{
          background: theme.drawer.colors.background,
          height: '56px',
          borderBottom: 0,
        }}
        {...props}
      />
    );
  })<DrawerProps>`
    z-index: 1002;

    & .ant-drawer-header {
      display: flex;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
    }

    & .ant-drawer-header:after {
      flex-basis: 56px;
      flex-grow: 0;
      flex-shrink: 0;
      content: '';
    }

    & .ant-drawer-title {
      display: flex;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 250px;
      color: ${({ theme }) => theme.drawer.colors.text};
    }

    & .ant-drawer-close {
      flex-grow: 0;
      color: ${({ theme }) => theme.drawer.colors.text};
    }

    & .ant-drawer-body {
      padding-left: 20px;
      padding-right: 20px;
    }
  `,

  Menu: styled(AntMenu)`
    width: 100%;
    padding: 0;
    background: ${({ theme }) => theme.drawer.colors.background};
    color: ${({ theme }) => theme.drawer.colors.text};
    border: 0;

    & .ant-menu-sub {
      background-color: ${({ theme }) => theme.drawer.colors.background};
    }

    & .ant-menu-item {
      display: flex;
      justify-content: space-between;
    }

    & .ant-menu-item,
    & .ant-menu-submenu-title {
      padding-left: 0 !important;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: transparent;
    }

    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: black;
    }

    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      color: black;
    }

    .ant-menu-item a:hover {
      color: black;
    }
  `,

  MenuItem: styled(AntMenuItem)`
    width: 100%;
    color: ${({ theme }) => theme.drawer.colors.text};

    & a {
      text-transform: uppercase;
      color: ${({ theme }) => theme.drawer.colors.text};
    }
  `,

  SubMenu: styled(AntSubMenu)`
    width: 100%;
    background: ${({ theme }) => theme.drawer.colors.background};

    & .ant-menu-sub {
      padding-left: 20px;
    }

    & .ant-menu-submenu-title {
      height: 28px;
      text-transform: uppercase;
    }

    & .ant-menu-submenu-arrow {
      right: 0;
      color: ${({ theme }) => theme.drawer.colors.text};
    }
  `,

  RightIcon: styled.i`
    position: absolute;
    top: 50%;
    right: 6px;
    width: 6px;
    height: 6px;
    box-sizing: border-box;

    &::before {
      position: absolute;
      width: 6px;
      height: 1.5px;
      background-color: currentColor;
      border-radius: 2px;
      box-sizing: border-box;
      transform: rotate(45deg) translateY(-2.5px);
      transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      content: '';
    }

    &::after {
      position: absolute;
      width: 6px;
      height: 1.5px;
      background-color: currentColor;
      border-radius: 2px;
      box-sizing: border-box;
      transform: rotate(-45deg) translateY(2.5px);
      transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      content: '';
    }
  `,
};
