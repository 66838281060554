import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';

import { CheckboxProps, CheckboxGroupProps } from './CheckBox.interface';

const { Group: AntGroup } = AntCheckbox;

export const StyledGroup: React.FC = styled((props: CheckboxGroupProps) => (
  <AntGroup {...props} />
))`
  display: flex;
  flex-direction: column;
`;

export const StyledCheckbox: React.FC<CheckboxProps> = styled(
  ({ option, ...props }: CheckboxProps) =>
    option ? (
      <AntCheckbox {...props} value={option.value}>
        {option.label}
      </AntCheckbox>
    ) : (
      <AntCheckbox {...props} />
    )
)`
  && {
    margin: 3px 0;
  }

  && .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.checkbox.colors.border};
    background-color: ${({ theme }) => theme.checkbox.colors.background};
    border-radius: 5px;
  }

  .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.checkbox.colors.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ theme }) => theme.checkbox.colors.checkmark};
  }
`;
