import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

export const S = {
  Switch: styled(AntdSwitch)``,
  SwitchContainer: styled.div`
    .ant-switch-checked {
      background-color: ${({ theme }) =>
        theme.buttons.switch.colors.checkedBackground};
    }
  `,
};
