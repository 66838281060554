import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { store } from 'store';
import { AppContainer } from 'containers/App/AppContainer';
import { configure as configureAPIfunctions } from 'atlasguides-web-common/src/configure';
import { axiosAdapter } from 'atlasguides-web-common/src/adapters/axios.adapter';

import { GlobalStyle } from 'index.styles';
import { AuthProvider } from 'services/AuthManager/AuthManager.react';
import { AuthManagerService } from 'services/AuthManager/AuthManager';
import { AppConfig } from 'configs/app-config';
import { AxiosDirector } from './services/AxiosDirector/AxiosDirector';
import { AxiosBuilder } from './services/AxiosBuilder/AxiosBuilder';
import { LocalizationManager } from './services/LocalizationManager/LocalizationManager';

import 'antd/dist/antd.css';
import 'leaflet/dist/leaflet.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { IntlProvider } from 'react-intl';
import { GlobalWaitSpinnerContextProvider } from 'shared/GlobalWaitSpinner/GlobalWaitSpinnerContext';

configureAPIfunctions({
  fetcher: axiosAdapter(
    new AxiosDirector(new AxiosBuilder()).constructAxiosWithToastInterceptor()
  ),
  tokenExtractor: AuthManagerService.getInstance().getAccessToken,
  backendUrl: AppConfig.backendUrl,
});


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDq4p12obB1hglUq14fy_4JTkBCLTEG2lM",
  authDomain: "guthook-guides.firebaseapp.com",
  databaseURL: "https://guthook-guides.firebaseio.com",
  projectId: "guthook-guides",
  storageBucket: "guthook-guides.appspot.com",
  messagingSenderId: "502400356035",
  appId: "1:502400356035:web:a7ba3b35bde6b735011c76",
  measurementId: "G-KFL9BRF9QY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <AuthProvider>
          <IntlProvider {...LocalizationManager.getComponentConfig()}>
          <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
            <GlobalWaitSpinnerContextProvider>
              <AppContainer />
            </GlobalWaitSpinnerContextProvider>
            </IntlProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </Provider>
    {/* <ToastContainer newestOnTop /> */}
  </React.StrictMode>,
  document.getElementById('root')
);
