import { memo, useCallback, useState } from 'react';
import isEqual from 'lodash.isequal';

import { S } from './HeaderHorizontalMenu.styles';
import { makeMenu } from 'utils/makeMenu';
import { DownOutlined } from '@ant-design/icons';
import { useAuth } from 'services/AuthManager/AuthManager.react';
import { useSignOutWithRedirect } from 'hooks/useSignOutWithRedirect';

interface HeaderHorizontalMenuProps {
  show: boolean;
}


const HeaderHorizontalMenuComponent: React.FC<HeaderHorizontalMenuProps> = ({
  show,
}) => {
  const { isSignedIn } = useAuth();
  const { signOut } = useSignOutWithRedirect();
  const [isProfilePageShown, setIsProfilePageShown] = useState<boolean>(false);
  const [isPurchasesShown, setIsPurchasesShown] = useState<boolean>(false);
  const [profilePopupVisibility, setProfilePopupVisibility] = useState(false);
  
  const handleSignOut = useCallback(async () => {
    await signOut();
    setIsProfilePageShown(false);
    setIsPurchasesShown(false);
    setProfilePopupVisibility(false);
    localStorage.removeItem('parseUserId');
    localStorage.removeItem('shopifyCartId');
  }, [signOut]);

  const menu = makeMenu(S.MenuItem, 'drawer-menu', isSignedIn, handleSignOut, show);
  return (
    <>
      {show && (
        <S.MenuContainer>
          {
            <S.Menu
              mode="horizontal"
              selectable={false}
              expandIcon={<DownOutlined />}
            >
              {menu}
            </S.Menu>
          }
        </S.MenuContainer>
      )}
    </>
  );
};

export const HeaderHorizontalMenu = memo(
  HeaderHorizontalMenuComponent,
  isEqual
);
