import styled from 'styled-components';

export const S = {
  MainContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  `,

  ContentContainer: styled.div`
    max-height: 100%;
    max-width: 400px;

    overflow-y: auto;

    padding: 25px;

    &::-webkit-scrollbar {
      width: 0;
    }
  `,
};
