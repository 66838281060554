import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Formik, Form } from 'formik';

import {
  InputFormik as Input,
  PasswordFormik as Password,
} from 'controls-formik/Input/InputFormik';

import { BaseButton } from 'controls/BaseButton/BaseButton';
import { resetPasswordSchema } from 'configs/formsValidationSchemas';
import { FormikErrorMessage } from 'shared/FormikErrorMessage/FormikErrorMessage';

import { S } from './ResetPasswordForm.styles';
import {
  ResetPasswordFormValues,
  ResetPasswordFormViewProps,
} from './ResetPasswordForm.interfaces';
import { useIntl } from 'react-intl';

const initialValues: ResetPasswordFormValues = {
  password: '',
  confirmPassword: '',
  confirmationCode: '',
  errorField: '', // dummy field for displaying a single error for all fields
};

const ResetPasswordFormComponent: React.FC<ResetPasswordFormViewProps> = ({
  handleSubmit,
}) => {
  let intl = useIntl()  
  return (
    <S.Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input
              name="confirmationCode"
              placeholder="confirmation code"
              autoComplete="off"
            />
            <FormikErrorMessage name="confirmationCode" />
            <Password
              name="password"
              placeholder={intl.formatMessage({id:"password"})}
              autoComplete="new-password"
            />
            <FormikErrorMessage name="password" />
            <Password
              name="confirmPassword"
              placeholder={intl.formatMessage({id:"confirm_password"})}
              autoComplete="new-password"
            />
            <FormikErrorMessage name="confirmPassword" />
            <FormikErrorMessage name="errorField" />
            <S.ButtonContainer>
              <BaseButton
                type="primary"
                label={intl.formatMessage({id:"reset_password"})}
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </S.ButtonContainer>
          </Form>
        )}
      </Formik>
    </S.Layout>
  );
};

export const ResetPasswordForm = memo(ResetPasswordFormComponent, isEqual);
