import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Popover } from 'antd';
import { S } from './HintPopover.styles';
import { HintPopoverProps } from './HintPopover.interfaces';

const HintPopoverComponent: React.FC<HintPopoverProps> = ({
  hintText,
  hintHeader,
  contentWidth,
  horizontalOffset,
  verticalOffset,
  adjustX = true,
  adjustY = true,
  placement = 'right',
  customContent,
  containerPadding,
}) => {
  return (
    <Popover
      placement={placement}
      arrowPointAtCenter
      trigger="click"
      overlayStyle={{
        borderRadius: '7px',
        maxWidth: '80%',
        paddingLeft: '0px',
        paddingRight: '0px',
      }}
      popupAlign={{
        offset: [horizontalOffset || 0, verticalOffset || 0],
        overflow: { adjustX, adjustY },
      }}
      content={
        <S.HintContentContainer padding={containerPadding} width={contentWidth}>
          {hintHeader && (
            <S.HintContentHeader>{hintHeader}</S.HintContentHeader>
          )}
          <S.HintContentText>{hintText}</S.HintContentText>
        </S.HintContentContainer>
      }
    >
      {customContent || (
        <S.Hint>
          <S.HintLabel>?</S.HintLabel>
        </S.Hint>
      )}
    </Popover>
  );
};

export const HintPopover = memo(HintPopoverComponent, isEqual);
