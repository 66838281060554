import { reducer } from './reducer'
import saga from './saga'

export * from './actions'
export * from './selectors'

const trailStore = {
  saga,
  reducer,
}

export default trailStore
