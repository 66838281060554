import styled from 'styled-components';

export const S = {
  PictureCarouselContainer: styled.div`
    position: relative;
  `,
  Header: styled.div`
    position: relative;
    margin-bottom: 56px;
  `,
  IconContainer: styled.div<{ isLoading?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    border: 3px solid
      ${({ theme }) => theme.guideViewDetail.colors.backgroundContent};
    border-radius: 50px;
    overflow: hidden;
    background-color: ${({ isLoading }) =>
      isLoading ? 'white' : 'transparent'};
  `,
  IconWrapper: styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
  `,
  IconView: styled.img.attrs(({ src }) => ({
    src,
  }))`
    width: 100%;
    height: 100%;
  `,
};
