import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { WhitneyBackground } from 'shared/WhitneyBackgroundComponent/WhitneyBackground.styles';
import { S } from './AuthPageWrapper.styles';
import { AuthPage } from 'containers/AuthPage/AuthPage';

const AuthPageWrapperViewComponent: React.FC = () => {
  return (
    <WhitneyBackground>
      <S.MainContainer>
        <AuthPage />
      </S.MainContainer>
    </WhitneyBackground>
  );
};

export const AuthPageWrapperView = memo(AuthPageWrapperViewComponent, isEqual);
