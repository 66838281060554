import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Field, FieldProps } from 'formik';
import { Checkbox } from 'controls/CheckBox/CheckBox';
import { CheckboxProps } from 'controls/CheckBox/CheckBox.interface';
import { FormikFieldProps } from '../FormikField.interfaces';

export type ForikCheckboxProps = FormikFieldProps & CheckboxProps;

const CheckboxFormikComponent: React.FC<ForikCheckboxProps> = ({
  name,
  validate,
  onChange,
  ...otherProps
}) => {
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <Checkbox
          name={name}
          checked={value}
          onChange={event => {
            setFieldValue(name, event.target.checked);
            setFieldTouched(name, true, false);
            onChange && onChange(event);
          }}
          {...otherProps}
        />
      )}
    </Field>
  );
};

export const CheckboxFormik = memo(CheckboxFormikComponent, isEqual);
