import { ErrorMessage } from 'formik';
import { ErrorMessagePlaceholder } from '../ErrorMessagePlaceholder/ErrorMessagePlaceholder';
import { FormattedMessage } from 'react-intl';

interface ErrorMessageProps {
  name: string;
}

export const FormikErrorMessage: React.FC<ErrorMessageProps> = ({ name }) => {
  return (
    <ErrorMessagePlaceholder className="formik-error-message-placeholder">
      <ErrorMessage name={name as string} render={msg =><b><FormattedMessage id={msg}/></b>}/>
    </ErrorMessagePlaceholder>
  );
};
