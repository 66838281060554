import { createSelector } from 'reselect'
import { AppState } from 'store/rootReducer'
import * as fromTrailsStore from './reducer'
import { initialState } from './reducer'

export const getGuideUseState = (state: AppState): fromTrailsStore.State => state.common || initialState

export const getDistanceUnitSelector = createSelector(getGuideUseState, state =>
  state.distanceUnit
)

export const getElevationUnitSelector = createSelector(getGuideUseState, state =>
  state.elevationUnit
)

export const getClockwiseDirectionTrailsSelector = createSelector(getGuideUseState, state =>
  state.clockwiseDirectionTrails
)