import { use100vh } from 'react-div-100vh';

// It used to prevent unnecessary scroll bars
// Sometimes use100vh hook returns height rounded to the bigger side (ex. 760.60 -> 761)
const DELTA_HEIGHT = 1;

export const useFullHeight = () => {
  const height = use100vh();
  if (height) {
    return height - DELTA_HEIGHT;
  }
  return height;
};
