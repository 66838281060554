import styled from 'styled-components';
import { BaseButton } from 'controls/BaseButton/BaseButton';

export const S = {
  TrailTitleButton: styled(BaseButton)`
    font-size: 19px;
    padding: 0;
    white-space: pre-wrap;
    height: 100%;
  `,
  TrailTitleButtonWrapper: styled.div`
    margin-right: 10px;
    width: 100%;
  `,
  AllGuidesButton: styled(BaseButton)`
    font-size: 14px;
    height: 100%;
  `,
  ControlWrapper: styled.div`
    margin-right: 10px;
  `,
};
