import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxGroup } from 'controls/CheckBox/CheckBox';

import { EnvironmentProps } from '../Filter.interface';
import { S } from './Environment.styles';

const EnvironmentComponent: React.FC<EnvironmentProps> = ({
  filterParamsState: { environment: environmentParams },
  selectedFilters,
  handleSetSelectedFilters,
}) => {
  const { environment } = selectedFilters;

  const handleChange = (checkedItems: CheckboxValueType[]) => {
    handleSetSelectedFilters({
      sectionName: 'environment',
      value: checkedItems,
    });
  };

  return (
    <S.Container>
      {/* WARNING - will need to provide a valid name prop for Fromik */}

      <CheckboxGroup
        name=""
        options={
          environmentParams?.sort((a, b) => (a.label < b.label ? -1 : 1)) || []
        }
        onChange={handleChange}
        value={environment}
      />

      {/* END */}
    </S.Container>
  );
};

export const Environment = memo(EnvironmentComponent, isEqual);
