import styled from 'styled-components';

export const S = {
  Container: styled.div`
    position: relative;
    height: 100%;
    padding: 10px;
    flex: 1;
    overflow-x: visible;
  `,
  CardWrapper: styled.div`
    margin-bottom: 10px;
  `,
};
