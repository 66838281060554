import { fetcher } from '../../configure'

export type UpdateUserCoverPhotoRequest = {
	base64EncodedPhoto: string
}

export type UpdateUserCoverPhotoResponse = {
	success: boolean
	message: 'SUCCESS'
}

export type UpdateUserCoverPhotoEndpoint = (
	request: UpdateUserCoverPhotoRequest
) => Promise<UpdateUserCoverPhotoResponse>

export const updateUserCoverPhoto: UpdateUserCoverPhotoEndpoint = async (request) => {
	return await fetcher({
		method: 'put',
		path: '/users/photos/cover',
		body: request,
	})
}
