import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Formik, Form } from 'formik';

import {
  InputFormik as Input,
  PasswordFormik as Password,
} from 'controls-formik/Input/InputFormik';

import { S } from './LoginForm.styles';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { loginSchema } from 'configs/formsValidationSchemas';
import { FormikErrorMessage } from 'shared/FormikErrorMessage/FormikErrorMessage';
import { LogInFormProps, LogInFormValues } from './LogInForm.interfaces';
import { CheckboxFormik as Checkbox } from '../../controls-formik/Checkbox/CheckboxFormik';
import { FormattedMessage, useIntl } from 'react-intl';

const initialValues: LogInFormValues = {
  username: '',
  password: '',
  isMarketingAllowed: false,
  error_field: null, // dummy field for displaying a single error for all fields
};

const LogInFormComponent: React.FC<LogInFormProps> = ({ handleSubmit }) => {
  let intl = useIntl()
  return (
    <S.Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input
              name="username"
              placeholder={intl.formatMessage({ id: "email_hint" })}
              autoComplete="username"
            />
            <FormikErrorMessage name="username" />
            <Password
              name="password"
              placeholder={intl.formatMessage({ id: "password" })}
              autoComplete="current-password"
            />
            <FormikErrorMessage name="password" />
            <Checkbox name="isMarketingAllowed">
              <FormattedMessage id="notify_about_updates"/>
            </Checkbox>
            <FormikErrorMessage name="error_field" />
            <S.ButtonContainer>
              <BaseButton
                type="primary"
                label={intl.formatMessage({ id: "login" }) + " →"}
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </S.ButtonContainer>
          </Form>
        )}
      </Formik>
    </S.Layout>
  );
};

export const LogInForm = memo(LogInFormComponent, isEqual);
