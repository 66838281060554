import styled, { css } from 'styled-components';

type DialogCardProps = {
  type?: 'bumpy' | 'flat';
};

type DialogCardTitleProps = {
  size?: 'small' | 'large';
};

type DialogCardTitleTextProps = {
  centered?: boolean;
};

const dialogCardSharedStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const dialogCardStylesBumpy = css`
  border-radius: 25px;
  box-shadow: 0 0 15px #aaaaaa;
  background-color: ${({ theme }) => theme.dialogCard.colors.background};
`;

export const DialogCard = styled.main<DialogCardProps>`
  ${dialogCardSharedStyles}
  ${({ type = 'bumpy' }) => {
    switch (type) {
      case 'bumpy':
        return dialogCardStylesBumpy;
      case 'flat':
        return null;
    }
  }}
  width: 100%;
  overflow-y: auto;
`;

export const DialogCardFullContainerSize = styled.main<DialogCardProps>`
  ${dialogCardSharedStyles}
  ${({ type = 'bumpy' }) => {
    switch (type) {
      case 'bumpy':
        return dialogCardStylesBumpy;
      case 'flat':
        return null;
    }
  }}
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

export const DC = {
  WithMargins: styled.div`
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
  `,

  WithRestrictedDimensions: styled.div`
    /* height: 100%; */
    width: 100%;
    max-height: 100%;
    max-width: 300px;
    margin-top: auto;
    margin-bottom: auto;
    overflow-y: auto;
  `,

  FullContainerSize: styled.div``,

  TitleSection: styled.header`
    text-align: center;
    margin-bottom: 10px;
  `,

  Title: styled.h1<DialogCardTitleProps>`
    font-size: ${({ size = 'large' }) => {
      switch (size) {
        case 'large':
          return '32px';
        case 'small':
          return '23px';
      }
    }};
    color: ${({ theme }) => theme.dialogCard.colors.title};
    margin-bottom: 5px;
  `,

  TitleText: styled.small<DialogCardTitleTextProps>`
    ${({ centered }) => (centered ? 'text-align: center;' : null)}
    display: block;
    font-size: 14px;
    color: ${({ theme }) => theme.dialogCard.colors.titleParagraph};
  `,

  BodySection: styled.section``,

  FooterSection: styled.footer`
    font-size: 12px;
    text-align: center;
    padding-top: 15px;
  `,

  FooterText: styled.small`
    font-size: inherit;
    color: ${({ theme }) => theme.dialogCard.colors.footerText};
  `,
};
