import { fetcher } from '../../configure'
import { AvailableCountryDto } from './dto/countries.dto'

export type AvailableCountriesRequest = {
}

export type AvailableCountriesResponse = {
    countries: AvailableCountryDto[]
}

export type AvailableCountriesEndpoint = (
    request?: AvailableCountriesRequest
) => Promise<AvailableCountriesResponse>

export const getAvailableCountries: AvailableCountriesEndpoint = async (
    request
): Promise<AvailableCountriesResponse> => {
    return await fetcher({
        method: 'get',
        path: `/payment/available-countries`
    })
}
