import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import {LatLngTuple} from 'leaflet'
import isEqual from 'lodash.isequal';
import { swipeCoords } from 'utils/swipeTrailCoords';
import { AppState } from 'store/rootReducer';
import * as fromTrailsStore from './reducer';
import { initialState } from './reducer';
import { getUniqueObjectsArrayByPropFunction } from 'utils/getUniqueObjectsArrayByPropFunction';
import {getSelectedFiltersListLength} from 'containers/Filter/FilterContainer'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getTrailsStoreState = (state: AppState): fromTrailsStore.State =>
  state.trailStore || initialState;

export const getActiveSubscriptionInfoSelector = createDeepEqualSelector(
  getTrailsStoreState,
  state => state.activeSubscriptionInfo
);

export const getIsActiveSubscriptionSelector = createSelector(
  getTrailsStoreState,
  state => state.isActiveSubscription
);

export const getIsFetchingSubscriptionInfoSelector = createSelector(
  getTrailsStoreState,
  state => state.isFetchingSubscriptionInfo
);
  
export const getAllTrailsListSelector = createDeepEqualSelector(
  getTrailsStoreState,
  state => state.allTrailsList
);

export const getAllTrailsWithChildrenSelector = createSelector(
  getTrailsStoreState,
  state =>
    state.allTrailsList
      .map(guide => {
        if (guide.childGuides && guide.childGuides.length) {
          return [guide, ...guide.childGuides];
        }

        return guide;
      })
      .flat()
);

export const getAllTrailsListWithChildrenSelector = createDeepEqualSelector(
  getTrailsStoreState,
  state => {
    const uniqueById = getUniqueObjectsArrayByPropFunction('guideId');

    return uniqueById(
      state.allTrailsList
        .map(item =>
          item.childGuides && item.childGuides.length > 0
            ? [
                { ...item, hasChildren: false, childGuides: [] },
                ...item.childGuides.filter(child => !child.isFeatured).map(trail => ({
                  ...trail,
                  guidePreviewCoordinates: {
                    ...trail.guidePreviewCoordinates,
                    coordinates: swipeCoords(
                      trail.guidePreviewCoordinates?.coordinates as LatLngTuple[]
                    ),
                  },
                })),
              ]
            : { ...item, hasChildren: false, childGuides: [] }
        )
        .flat(1)
    );
  }
);

export const getIsFetchingAllTrailsListSelector = createSelector(
  getTrailsStoreState,
  state => state.isFetchingAllTrailsList
);

export const getTrailGuideViewSelector = createSelector(
  getTrailsStoreState,
  state => state.trailGuideView
);

export const getIsFetchingTrailGuideViewSelector = createSelector(
  getTrailsStoreState,
  state => state.isFetchingTrailGuideView
);

export const getSelectedTrailGuideIdSelector = createSelector(
  getTrailsStoreState,
  state => state.selectedTrailGuideId
);

export const getGuidesFilterParamsSelector = createSelector(
  getTrailsStoreState,
  state => state.filterParams
);

export const getIsFilteredStateSelector = createSelector(
  getTrailsStoreState,
  state => state.isFilteredState
);

export const getIsSearchedStateSelector = createSelector(
  getTrailsStoreState,
  state => state.isSearchedState
);

export const getActiveSelectedFiltersSelector = createSelector(
  getTrailsStoreState,
  state => state.activeSelectedFilters
);

export const getSelectedFiltersListLengthSelector = createSelector(
  getTrailsStoreState,
  state => {
    const selectedFilters = state.activeSelectedFilters;
    const filterParams = state.filterParams;

    return getSelectedFiltersListLength(selectedFilters, filterParams)
  }
);

export const getAllTrailsListLengthSelector = createSelector(
  getAllTrailsListWithChildrenSelector,
  state => state.length
);

export const getAllOrFilteredGuidesSelector = createSelector(
  getTrailsStoreState,
  getAllTrailsListWithChildrenSelector,
  (state, stateWithChildren) => {
    if (state.isFilteredState) {
      return state.filteredGuideList;
    }

    return stateWithChildren;
  }
);

export const getGuidesSelector = createSelector(getTrailsStoreState, state => {
  if (state.isSearchedState) {
    return state.searchedGuideList;
  }

  if (state.isFilteredState) {
    return state.filteredGuideList;
  }

  return state.allTrailsList;
});

export const getSearchedTrailsListLengthSelector = createSelector(
  getTrailsStoreState,
  state => state.searchedGuideList.length
);

export const getSearchedValue = createSelector(
  getTrailsStoreState,
  state => state.searchedValue
);

export const getIsDetailsDisplayingSelector = createSelector(
  getTrailsStoreState,
  state => state.isDetailsDisplaying
);

export const getAvailableCountriesSelector = createSelector(
  getTrailsStoreState,
  state => state.availableCountries
);
