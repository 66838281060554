import styled from 'styled-components';

export const S = {
  Overlay: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  `,
};
