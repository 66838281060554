import React, { memo, useCallback } from 'react';
import isEqual from 'lodash.isequal';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import back from './back.jpg';
import pic from './pic.jpg';

import { S } from './ProfilePage.styles';
import { ProfilePageViewProps } from 'containers/ProfilePage/ProfilePage.interface';
import { LoadingSpinner } from '../../shared/LoadingSpinner/LoadingSpinner';
import { Modal } from '../../shared/Modal/Modal';
import { theme } from '../../theme';
import { ProfileHeaderContainer } from '../../containers/ProfileHeader/ProfileHeader';
import { PluralLocalizator } from '../../services/LocalizationManager/PluralLocalizator';
import { FormattedMessage, useIntl } from 'react-intl';
import { getActiveSubscriptionInfoSelector, getIsActiveSubscriptionSelector, getIsFetchingSubscriptionInfoSelector } from 'store/TrailsStore';
import { useSelector } from 'react-redux';


const ProfilePageComponent: React.FC<ProfilePageViewProps> = ({
  isProfilePageShown,
  onHideProfilePage,
  followingsCount,
  followersCount,
  displayName,
  username,
  email,
  bio,
  isLoading,
  handleViewPurchaseReceipts,
  userBackgroundPhoto,
  userPhoto,
  handleEditProfileSettings,
  isProfilePublic,
  points
}) => {
  let intl = useIntl()
  let isFetchingSubscriptionInfoSelector = useSelector(getIsFetchingSubscriptionInfoSelector);
  let isActiveSubscriptionSelector = useSelector(getIsActiveSubscriptionSelector);
  let subscriptionInfoSelector = useSelector(getActiveSubscriptionInfoSelector);

  const onManageSubscriptionClick = useCallback(() => {
    window.open(subscriptionInfoSelector?.managementUrl, '_blank', 'noreferrer');
  }, [subscriptionInfoSelector]);  

  // const messages = defineMessages({
  //   test_followers: {
  //     id: "app.item",
  //     defaultMessage:
  //       "Test:: {num, plural, one {# {one}} few {# {few}} other {# {other}}}"
  //   }
  // });

  return (
    <Modal
      visible={isProfilePageShown}
      onCancel={onHideProfilePage}
      zIndex={999}
      footer={null}
      centered
      closeButtonBackground={theme.profilePage.colors.closeButton}
      withBorderRadius
      containerMinHeight={460}
    >
      {isLoading ? (
        <S.SpinnerContainer>
          <LoadingSpinner size="large" />
        </S.SpinnerContainer>
      ) : (
        <>
          <ProfileHeaderContainer
            backgroundPictureHeight={300}
            userPhoto={userPhoto || pic}
            picturesView={[userBackgroundPhoto || back]}
          />
          <S.Body>
            <S.Content>
              <S.DisplayedName>{displayName}</S.DisplayedName>
              {username && <S.UserName>{username}</S.UserName>}

              {email && <S.UserName>{email}</S.UserName>}

              {Number.isInteger(followingsCount) &&
              Number.isInteger(followersCount) ? (
                <S.FollowersInfo>
                  {PluralLocalizator.formatPlural(intl, followersCount as number, "plural_template_with_zero_placeholder", "followers")} 
                  <> | </>
                  {PluralLocalizator.formatPlural(intl, followingsCount as number, "plural_template_with_zero_placeholder", "following")}
                </S.FollowersInfo>
              ) : null}
              {bio && <S.Bio>{bio}</S.Bio>}

              <S.IsProfilePublicTitle>
                {isProfilePublic
                  ? intl.formatMessage({ id: "profile_is_public" })
                  : intl.formatMessage({ id: "profile_is_private" })}
              </S.IsProfilePublicTitle>
              <S.SignOutButtonContainer>
                <BaseButton
                  type="primary"
                  size="small"
                  label={intl.formatMessage({ id: "my_purchases" })}
                  onClick={handleViewPurchaseReceipts}
                />
              </S.SignOutButtonContainer>

              { (!isFetchingSubscriptionInfoSelector && isActiveSubscriptionSelector && subscriptionInfoSelector?.managementUrl) ? ( 
                <S.SignOutButtonContainer>
                  <BaseButton
                    type="primary"
                    size="small"
                    label={intl.formatMessage({ id: "manage_subscriptions" })}
                    onClick={onManageSubscriptionClick}
                  />
                </S.SignOutButtonContainer>
                ) : null
              }

              <S.SignOutButtonContainer>
                <BaseButton
                  type="primary"
                  label={intl.formatMessage({ id: "edit_profile" })}
                  size="small"
                  onClick={handleEditProfileSettings}
                />
              </S.SignOutButtonContainer>
              <S.MobileAppLogInSuggestion>
                <FormattedMessage id="profile_not_available"/>
              </S.MobileAppLogInSuggestion>
              <S.StyledDivider />
            </S.Content>
          </S.Body>
        </>
      )}
    </Modal>
  );
};

export const ProfilePageView = memo(ProfilePageComponent, isEqual);
