import { FormikHelpers } from 'formik';

export interface ProfilePrimaryInfoFormValues {
  displayName: string;
  username: string;
  firstName: string;
  lastName: string;
  bio: string;
  isPublicProfile: boolean;
}

export enum ProfilePrimaryInfoFormNames {
  username = 'username',
  firstName = 'firstName',
  lastName = 'lastName',
  bio = 'bio',
  displayName = 'displayName',
  isPublicProfile = 'isPublicProfile',
}

export type OnChangePublicProfileSwitch = (args: {
  checked: boolean;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
}) => void;

export type SubmitProfilePrimaryInfoForm = (
  values: ProfilePrimaryInfoFormValues,
  actions: FormikHelpers<ProfilePrimaryInfoFormValues>
) => Promise<void>;

export interface ProfilePrimaryInfoFormInterfaces {
  handleSubmit: SubmitProfilePrimaryInfoForm;
  initialValues?: ProfilePrimaryInfoFormValues;
  isAbsoluteSubmitButton?: boolean;
}
