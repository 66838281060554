import styled from 'styled-components';
import { Modal } from 'antd';

export const S = {
  Modal: styled(Modal)`
    &&&& .ant-btn-primary {
      background: gray;
    }
  `,
  
};
