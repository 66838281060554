import { LocalizationManager } from '../services/LocalizationManager/LocalizationManager'; 
 
export const getLocaleDate = (date: Date) : string => {
    var formatter = new Intl.DateTimeFormat(LocalizationManager.getLanguage());
    return formatter.format(date);
};

export const formatPrice = (price: any, currencyCode: string = "???") : string => {
    if (price === undefined || price == null){
        return 'N/A'
    }
    if (typeof price === "string"){
        price = parseFloat(price)
    }

    let priceStr = parseFloat(price.toFixed(2));

    if (currencyCode === "USD"){
        return "US $" + priceStr
    }
    else{
        return currencyCode + " " + priceStr
    }
};