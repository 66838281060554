import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { S } from './ControlsPanel.styles';
import { ControlsPanelProps } from './ControlsPanel.interface';

const ControlsPanelComponent: React.FC<ControlsPanelProps> = ({
  isMobile,
  isUnlayoutMode,
  children,
}) => (
  <S.ControlsPanel isUnlayoutMode={isUnlayoutMode} isMobile={isMobile}>
    {children}
  </S.ControlsPanel>
);

export const ControlsPanel = memo(ControlsPanelComponent, isEqual);
