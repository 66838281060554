import styled from 'styled-components';

export const S = {
  BadgeContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.cart.colors.badgeBackground};
    cursor: default;
  `,
  BadgeText: styled.span`
    color: ${({ theme }) => theme.cart.colors.badgeText};
    font-size: 11px;
    text-align: center;
    vertical-align: center;
    height: 15px;
  `,
  IconContainer: styled.div`
    position: relative;
    display: flex;
  `,
};
