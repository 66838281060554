import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { actions } from 'store/GuideUse/actions';
import { useViewportSize } from 'hooks/useViewportSize';
import { ReactComponent as MapIcon } from 'static/map-thin.svg';
import { ReactComponent as MapLayers } from 'static/view_list.svg';
import { ReactComponent as OverlayIcon } from 'static/overlay.svg';
import { ReactComponent as SettingsIcon } from 'static/settings.svg';
import { ReactComponent as ElevationProfileIcon } from 'static/terrain.svg';

import { S } from './GuidesOptionsPanel.styles';
import {
  getIsElevationProfileModalOpenedSelector,
  getIsPointsMenuModeSelector,
} from '../../../store/GuideUse';
import { GuidesOptionsPanelProps } from './GuidesOptionsPanel.interfaces';

const GuidesOptionsPanelComponent: React.FC<GuidesOptionsPanelProps> = ({
  hasElevationProfile,
}) => {
  const { isMobile } = useViewportSize();
  const dispatch = useDispatch();
  const elevationVisibility = useSelector(
    getIsElevationProfileModalOpenedSelector
  );
  const isPointsMenuMode = useSelector(getIsPointsMenuModeSelector);

  const handleOpenMapSettings = useCallback(() => {
    dispatch(actions.setIsMapSettingsOpen(true));
  }, [dispatch]);

  const handleChangeOverlay = useCallback(() => {
    dispatch(actions.setIsPointsMenuMode());
    dispatch(actions.setIsElevationModalOpen(false));
  }, [dispatch]);

  const handleOpenLayersModal = useCallback(() => {
    dispatch(actions.setIsLayersModalOpen(true));
  }, [dispatch]);

  const handleOpenElevation = () => {
    dispatch(actions.setIsElevationModalOpen(!elevationVisibility));
  };

  return (
    <S.Collapse defaultActiveKey={['1']} accordion isMobile={isMobile}>
      <S.Panel header={null} key="1">
        {isMobile && (
          <S.IconWrapper>
            {isPointsMenuMode ? (
              <MapIcon
                fill="#00828f"
                onClick={handleChangeOverlay}
                width="24px"
              />
            ) : (
              <MapLayers fill="#00828f" onClick={handleChangeOverlay} />
            )}
          </S.IconWrapper>
        )}
        <S.IconWrapper>
          <OverlayIcon fill="#00828f" onClick={handleOpenLayersModal} />
        </S.IconWrapper>
        <S.IconWrapper>
          <SettingsIcon fill="#00828f" onClick={handleOpenMapSettings} />
        </S.IconWrapper>
        {/* @TODO Removed for the first release */}
        {/*{isMobile && hasElevationProfile && (*/}
        {/*  <S.IconWrapper>*/}
        {/*    <ElevationProfileIcon*/}
        {/*      fill="#00828f"*/}
        {/*      onClick={handleOpenElevation}*/}
        {/*    />*/}
        {/*  </S.IconWrapper>*/}
        {/*)}*/}
      </S.Panel>
    </S.Collapse>
  );
};

export const GuidesOptionsPanel = memo(GuidesOptionsPanelComponent, isEqual);
