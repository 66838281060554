import styled, { DefaultTheme } from 'styled-components';
import { MapContainer, Polyline, PolylineProps } from 'react-leaflet';

export interface StyledPolylineProps extends PolylineProps {
  theme: DefaultTheme;
}

export const S = {
  MapContainer: styled(MapContainer)`
    height: 100%;
    width: 100%;
  `,
  Container: styled.div`
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
  `,
  Polyline:
    styled(Polyline).attrs(({ theme }: StyledPolylineProps) => ({
      pathOptions: {
        weight: 5,
        color: theme.LeafletPolyline.colors.default,
      },
    })) <
    StyledPolylineProps >
    ``,
};
