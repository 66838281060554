import { memo, useCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash.isequal';

import { S } from './ScrollSaverMenuWrapper.styles';
import { ScrollSaverMenuWrapperProps } from './ScrollSaverMenuWrapper.interface';

const ScrollSaverMenuWrapperComponent: React.FC<ScrollSaverMenuWrapperProps> =
  ({ scrollPosition, handleSetScrollPosition, children }) => {
    const wrapperRef: React.MutableRefObject<HTMLDivElement | null> =
      useRef(null);

    const handleSaveScroll = useCallback(() => {
      handleSetScrollPosition(wrapperRef.current?.scrollTop || 0);
    }, [handleSetScrollPosition]);

    const scrollToSavedPosition = useCallback(() => {
      wrapperRef.current?.scrollTo(0, scrollPosition);
    }, [scrollPosition]);

    useEffect(() => {
      scrollToSavedPosition();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <S.MenuWrapper ref={wrapperRef} onClick={handleSaveScroll}>
        {children}
      </S.MenuWrapper>
    );
  };

export const ScrollSaverMenuWrapper = memo(
  ScrollSaverMenuWrapperComponent,
  isEqual
);
