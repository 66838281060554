import { memo } from 'react';
import { Formik, Form } from 'formik';
import isEqual from 'lodash.isequal';

import {
  InputFormik as Input,
  PasswordFormik as Password,
} from 'controls-formik/Input/InputFormik';

import { S } from './CreateAccountForm.styles';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { Link } from 'controls/Link/Link';
import { CheckboxFormik as Checkbox } from 'controls-formik/Checkbox/CheckboxFormik';
import { createAccountSchema } from 'configs/formsValidationSchemas';
import { FormikErrorMessage } from 'shared/FormikErrorMessage/FormikErrorMessage';
import {
  CreateAccountFormProps,
  CreateAccountFormValues,
} from './CreateAccountForm.interfaces';
import { HintPopover } from '../../shared/HintPopover/HintPopover';
import { FormattedMessage, useIntl } from 'react-intl';

const initialValues: CreateAccountFormValues = {
  displayName: '',
  email: '',
  username: '',
  password: '',
  isTermsOfUseAccepted: false,
  isMarketingAllowed: false,
};

const TermsOfUseCheckboxLabel = (
  <FormattedMessage id="agree_terms" values={{terms:
      <Link type="default" href="https://faroutguides.com/terms-of-use/" target="_blank">
            <FormattedMessage id="terms_of_use"/>
      </Link>    
  }}/>
);

const CreateAccountFormComponent: React.FC<CreateAccountFormProps> = ({
  handleSubmit,
  showUsernameHint,
  usernameHintHorizontalOffset,
  usernameHintVerticalOffset,
  submitButtonLabel,
  usernameHintPlacement,
  usernameHintWidth,
  usernameHintAdjustY,
  usernameHintAdjustX,
}) => {
  let intl = useIntl()
  return (
    <S.Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={createAccountSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Input
              name="displayName"
              placeholder={intl.formatMessage({id:"name"})}
              autoComplete="given-name"
            />
            <FormikErrorMessage name="displayName" />
            <Input name="email" placeholder={intl.formatMessage({id:"email"})} autoComplete="email" />
            <FormikErrorMessage name="email" />
            <S.UserNameInputContainer>
              <Input
                name="username"
                placeholder={intl.formatMessage({id:"username"})}
                autoComplete="off"
              />
              {!values.username && showUsernameHint && (
                <S.UserNameHintContainer>
                  <HintPopover
                    adjustY={usernameHintAdjustY}
                    adjustX={usernameHintAdjustX}
                    placement={usernameHintPlacement}
                    contentWidth={usernameHintWidth}
                    horizontalOffset={usernameHintHorizontalOffset}
                    verticalOffset={usernameHintVerticalOffset}
                    hintText={intl.formatMessage({id:"new_account_username_info"})}
                  />
                </S.UserNameHintContainer>
              )}
            </S.UserNameInputContainer>
            <FormikErrorMessage name="username" />
            <Password
              name="password"
              placeholder={intl.formatMessage({id:"password"})}
              autoComplete="new-password"
            />
            <FormikErrorMessage name="password" />
            <Checkbox name="isTermsOfUseAccepted">
              {TermsOfUseCheckboxLabel}
            </Checkbox>
            <Checkbox name="isMarketingAllowed">
              <FormattedMessage id="notify_about_updates"/>
            </Checkbox>
            <FormikErrorMessage name="isTermsOfUseAccepted" />
            <S.ButtonContainer>
              <BaseButton
                type="primary"
                label={submitButtonLabel || intl.formatMessage({id:"signup"}) + " →"}
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </S.ButtonContainer>
          </Form>
        )}
      </Formik>
    </S.Layout>
  );
};

export const CreateAccountForm = memo(CreateAccountFormComponent, isEqual);
