export const findAndMakeLinksInText = (text: string) => {
  const urlRegex =
    /(\b((https?|ftp|file):\/\/|\bwww.)[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;
  return text.replace(
    urlRegex,
    (url, b: unknown, c: string) => {
      const resultUrl = (c === 'www.') ? `http://${url}` : url;
      return `<a href="${resultUrl}" target="_blank">${url}</a>`
    }
  );
};