import { History } from 'history';

interface GoBackByLocationStateArguments {
  history: History<unknown>;
  locationState?: { prevPath?: string };
  validRelativePath: string;
  fallbackPath?: string;
}

export const goBackByRelativePath = ({
  history,
  fallbackPath,
  locationState,
  validRelativePath,
}: GoBackByLocationStateArguments) => {
  if (locationState && locationState.prevPath?.includes(validRelativePath)) {
    history.goBack();
  } else {
    history.push(fallbackPath || validRelativePath);
  }
};
