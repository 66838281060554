import styled from 'styled-components';

export const S = {
  TopLabel: styled.span`
    font-size: 9px;
    color: ${({ theme }) => theme.formikInput.colors.topLabel};
  `,
  CounterContainer: styled.span`
    display: block;
    text-align: right;
    font-size: 12px;
    color: ${({ theme }) => theme.formikInput.colors.topLabel};
  `,
};
