import React, { Dispatch, ReactNode, useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import lottie from "lottie-web";
import lottie_white_spinning_arrow from "../../static/lottie_white_spinning_arrow.json";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

interface ContextType {
  isSpinnerVisible: boolean;
  setSpinnerVisible: Dispatch<boolean>;
}

export const GlobalWaitSpinnerContext = React.createContext<ContextType>(
  {
    isSpinnerVisible: false,
    setSpinnerVisible: () => null
  });

export const GlobalWaitSpinnerContextProvider: React.FC = ({children}: any) => {

  const [isSpinnerVisible, setSpinnerVisible] = React.useState<boolean>(false);
  const classes = useStyles();

  const animationContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottie_white_spinning_arrow,
      });
    }
  }, [animationContainer]);

  return (
    <GlobalWaitSpinnerContext.Provider value={ {isSpinnerVisible, setSpinnerVisible} }>
      <Backdrop
        className={classes.backdrop}
        open={isSpinnerVisible}
        onClick={() => { }}>
        <div ref={animationContainer} style={{alignItems: 'center', textAlign:'center', justifyContent: 'center', height: '21%', padding:50}}/>
      </Backdrop>
      {children}
    </GlobalWaitSpinnerContext.Provider>
  );
}