import isEqual from 'lodash.isequal';
import React, { memo } from 'react';
import { S } from './EditImageUploader.styles';
import { EditImageIconProps } from './EditImageUploader.interfaces';
import { Upload } from 'antd';

const EditImageIconComponent: React.FC<EditImageIconProps> = ({
  handleChangeImage,
  children,
}) => {
  return (
    <S.StyledImgCrop
      rotate
      zoom
      cropperProps={{
        style: {
          containerStyle: { zIndex: 4000 },
          mediaStyle: { zIndex: 4000 },
          cropAreaStyle: { zIndex: 4000 },
        },
      }}
    >
      <S.Upload
        maxCount={1}
        beforeUpload={async file => {
          await handleChangeImage(file);
          return;
        }}
        showUploadList={false}
      >
        {children}
      </S.Upload>
    </S.StyledImgCrop>
  );
};

export const EditImageUploader = memo(EditImageIconComponent, isEqual);
