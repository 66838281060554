import { fetcher } from '../../configure'
import { UserPrimaryInfo } from './dto/user-profile.dto'

export type UpdateUserProfileRequest = {
    userInfo: Partial<UserPrimaryInfo>
}

export type UpdateUserProfileResponse = {
	success: boolean
	message: 'SUCCESS' | 'USER_NOT_FOUND' | 'USERNAME_ALREADY_TAKEN'
	updatedUserInfo: Partial<UserPrimaryInfo>
}

export type UpdateUserProfileEndpoint = (
	request: UpdateUserProfileRequest
) => Promise<UpdateUserProfileResponse>

export const updateUserProfile: UpdateUserProfileEndpoint = async (request) => {
	return await fetcher({
		method: 'put',
		path: '/users/profile',
		body: request,
	})
}
