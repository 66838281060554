import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { BackButtonProps } from './BackButton.interfaces';
import { StyledBackIcon, StyledBaseButton } from './BackButton.styles';
import { LeftArrow } from '../Arrow/LeftArrow/LeftArrow';

const BackButtonComponent: React.FC<BackButtonProps> = ({
  label,
  iconFontSize,
  type: htmlType,
  ...otherProps
}) => {
  return (
    <StyledBaseButton
      type="invisible"
      htmlType={htmlType}
      label={label}
      icon={<LeftArrow sizes="large" />}
      {...otherProps}
    />
  );
};

export const BackButton = memo(BackButtonComponent, isEqual);
