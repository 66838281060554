import * as yup from 'yup';
import { ProfilePrimaryInfoFormNames } from '../views/ProfilePrimaryInfoForm/ProfilePrimaryInfoForm.interfaces';

const EMAIL_REGEX = /^(?=.{1,64}@.{1,255}$)(?=.{1,64}@.{0,255}\.{0,1}[^.]{0,63}$)[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]([a-zA-Z0-9]{0,61}(?<!-)[a-zA-Z0-9-])?){0,}$/;

export const createAccountSchema = yup.object().shape({
  displayName: yup
    .string()
    .min(2, "validation_limit_lower")
    .max(50, "validation_limit_upper")
    .matches(
      /[A-Za-zА-Яа-я]+/,
      "validation_first_name_format"
    ),
  password: yup
    .string()
    .min(6, "validation_password_signup_limit")
    .max(50, "validation_limit_upper")
    .required("validation_password_required")
    .matches(/^\S*$/, "validation_spaces"),
  email: yup
    .string()
    //.email("validation_email_format")
    .matches(EMAIL_REGEX, "validation_email_format")
    .required("validation_email_required")
    .matches(/^\S*$/, "validation_spaces"),
  username: yup
    .string()
    .min(2, "validation_limit_lower")
    .max(50, "validation_limit_upper")
    .required("validation_username_required")
    .matches(/^\S*$/, "validation_spaces"),
  isTermsOfUseAccepted: yup
    .bool()
    .oneOf([true], "validation_tou_warning"),
});

export const confirmEmailSchema = yup.object().shape({
  code: yup.string().max(20).required("validation_confirm_code_required"),
});

export const confirmEmailSchemaWithUsername = yup.object().shape({
  code: yup.string().max(20).required("validation_confirm_code_required"),
  username: yup.string().max(50).required("validation_username_required"),
});

//.matches(/^[^@]*$/, 'please sign in with username, not email')
//.
export const loginSchema = yup.object().shape({
  username: yup
    .string()
    .max(50, "validation_limit_upper")
    .required("validation_username_email_required")
    .matches(/^\S*$/, "validation_spaces"),
  password: yup
    .string()
    .min(6, "validation_password_signin_limit")
    .max(50, "validation_limit_upper")
    .required("validation_password_required")
    .matches(/^\S*$/, "validation_spaces"),
});

export const couponCodeSchema = yup.object().shape({
  code: yup.string().min(1).max(20).required(),
});

export const creditCardSchema = yup.object().shape({
  cardholderName: yup.string().max(50),
  cardNumber: yup.string().max(50).required("validation_card_number_required"),
  expirationDate: yup
    .string()
    .max(50, "validation_limit_upper")
    .required()
    .matches(/[0-9][0-9]\/[0-9][0-9]/, "validation_invalid_date_format"),
  cvv: yup.string().max(50).required("validation_cvv_required"),
  zipcode: yup.string().max(50).required(),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    //.email("validation_email_format")
    .matches(EMAIL_REGEX, "validation_email_format")
    .required("validation_email_required")
    .matches(/^\S*$/, "validation_spaces"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .max(50, "validation_limit_upper")
    .min(6)
    .required("validation_password_required")
    .matches(/^\S*$/, "validation_spaces"),
  confirmPassword: yup
    .string()
    .max(50, "validation_limit_upper")
    .min(6)
    .required("validation_confirm_password_required")
    .oneOf([yup.ref('password'), null], "validation_password_match_error")
    .matches(/^\S*$/, "validation_spaces"),
  confirmationCode: yup
    .string()
    .required("validation_confirm_code_required")
    .matches(/^\S*$/, "validation_spaces"),
});

export const updateProfileSchema = yup.object().shape({
  [ProfilePrimaryInfoFormNames.displayName]: yup
    .string()
    .min(2, "validation_limit_lower")
    .max(50, "validation_limit_upper")
    .matches(
      /[A-Za-zА-Яа-я]+/,
      "validation_first_name_format"
    ),
  [ProfilePrimaryInfoFormNames.firstName]: yup
    .string()
    .min(2, "validation_limit_lower")
    .max(50, "validation_limit_upper")
    .matches(
      /[A-Za-zА-Яа-я]+/,
      "validation_first_name_format"
    ),
  [ProfilePrimaryInfoFormNames.lastName]: yup
    .string()
    .min(2, "validation_limit_lower")
    .max(50, "validation_limit_upper")
    .matches(
      /[A-Za-zА-Яа-я]+/,
      "validation_first_name_format"
    ),
  [ProfilePrimaryInfoFormNames.username]: yup
    .string()
    .min(2, "validation_limit_lower")
    .max(50, "validation_limit_upper")
    .matches(/^\S*$/, "validation_spaces"),
  [ProfilePrimaryInfoFormNames.bio]: yup.string().max(500),
});
