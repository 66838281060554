import { memo, useCallback, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import { FlatCardsList } from 'shared/FlatCardsList/FlatCardsList';
import { CartIsEmptyMessage } from 'shared/CartIsEmptyMessage/CartIsEmptyMessage';
import { CartContentViewProps } from 'containers/Cart/Cart.interface';
import { S } from './CartContentView.styles';
import { useAuth } from 'services/AuthManager/AuthManager.react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsCartLoading, selectShopifyCart} from 'store/CartStore';
import { actions as cartActions } from '../../store/CartStore';

import { BaseButton } from 'controls/BaseButton/BaseButton';
import React from 'react'
import { Button } from 'antd';
import { GlobalWaitSpinnerContext } from 'shared/GlobalWaitSpinner/GlobalWaitSpinnerContext';
import { ShopifyCartDto } from 'atlasguides-web-common/src/functions/cart/dto/cart.dto';


const CartContentViewComponent: React.FC<CartContentViewProps> = ({
  isCartEmpty,
  areTrailsLoading,
  handleGoToExploreTrails,
  handleGoToCheckout,
  handleClearCart,
  lineItems,
  shopifyCheckoutUrl,
  handleRemoveShopifyItem,
  handleGoToSignIn,
}) => {
  
  let intl = useIntl()
  const { isSignedIn } = useAuth();

  const [isRequestSetCartBuyerCompleted, setRequestSetCartBuyerCompleted] = React.useState(false);
  const [isCheckoutClicked, setCheckoutClicked] = React.useState(false);
  const {setSpinnerVisible} = React.useContext(GlobalWaitSpinnerContext);

  const isCartLoading: boolean = useSelector(selectIsCartLoading);  
  const shopifyCart: ShopifyCartDto = useSelector(selectShopifyCart);  

  const onCartLoaded = useCallback(
    () => {
      function openCheckout(){
        setSpinnerVisible(true)
        setCheckoutClicked(true)
    
        setTimeout(() => {
          setSpinnerVisible(false)
          setCheckoutClicked(false)
        }, 15000)
    
        if (shopifyCheckoutUrl){
          window.location.assign(shopifyCheckoutUrl)
        }
      }

      if (isCheckoutClicked && isRequestSetCartBuyerCompleted && !isCartLoading && shopifyCheckoutUrl){
        const cost = shopifyCart?.cost?.totalAmount;
        if (cost){
            openCheckout()
        }
      }
    },
    [isCartLoading, isCheckoutClicked, isRequestSetCartBuyerCompleted, setSpinnerVisible, shopifyCart?.cost?.totalAmount, shopifyCheckoutUrl]
  );

  useEffect(() => {
    onCartLoaded()
  }, [isCartLoading, onCartLoaded]);

// Get user country, set Shopify Cart BuyerIdentity and then:
  // a) show a warning about changed currency and then open checkout url 
  // b) open checkout url 
  const onCheckoutClick = async () => {
    setCheckoutClicked(true)

    setRequestSetCartBuyerCompleted(true)

    // setRequestSetCartBuyerCompleted(false)
    // dispatch(actions.requestSetCartBuyerIdentity({onDone: () => {
    //   setRequestSetCartBuyerCompleted(true)
    // }}));
  };

  const avantLinkHash = LocalStorageManager.getInstance().getItemWithExpiry('avantLinkHash');
  if (avantLinkHash && shopifyCheckoutUrl) {
    const shopifyCheckoutUrlWithParams = new URL(shopifyCheckoutUrl)
    shopifyCheckoutUrlWithParams.searchParams.set('avad', avantLinkHash)
    shopifyCheckoutUrl = shopifyCheckoutUrlWithParams.toString()
  }

  return (
    <S.Container>
      {!isCartEmpty ? (
        <S.ListContainer>
          <FlatCardsList
            lineItems={lineItems}
            areTrailsLoading={areTrailsLoading}
            handleRemoveShopifyItem={handleRemoveShopifyItem}
          />
        </S.ListContainer>
      ) : (
        <CartIsEmptyMessage size="small" />
      )}
      {/*
        //SAVING IN CASE WE WANT TO RE ADD CLEAR ALL
        <S.RightButton>
        <Button style={{borderRadius:5}} type="text" danger onClick={handleClearCart}>Clear All</Button>

        </S.RightButton>
      */}

      {!isSignedIn && !isCartEmpty &&
        <S.ButtonContainer>
          <Button
            size='large'
            style={{borderRadius:5, background: '#01838F', borderColor: "#01838F"}}
            type="primary"
            onClick={handleGoToSignIn}
          >
          <FormattedMessage id="sing_in_checkout"/>
          </Button>
        </S.ButtonContainer>
      }
      {isCartEmpty &&
        <S.ButtonContainer>
          <Button
            style={{borderRadius:5, background: '#01838F', borderColor: "#01838F"}}
            type="primary"
            size='large'
            onClick={handleGoToExploreTrails}
          >
          <FormattedMessage id="explore_guides"/>
          </Button>
        </S.ButtonContainer>
      }
      {isSignedIn && !isCartEmpty &&
        <S.ButtonContainer>
          <BaseButton
            label={intl.formatMessage({id:"checkout"})}
            type="primary"
            size='large'
            style={{borderRadius:5, background: '#01838F', borderColor: "#01838F"}}
            onClick={onCheckoutClick}
            loading={isCheckoutClicked}
            disabled={isCheckoutClicked}
          />
        </S.ButtonContainer>
      }

      {/*<S.ButtonContainer>
        <BaseButton
          label={isCartEmpty ? 'Explore Guides' : 'Checkout'}
          type="primary"
          onClick={isCartEmpty ? handleGoToExploreTrails : handleGoToCheckout}
        />
      </S.ButtonContainer>*/}
    </S.Container>
  );
};

export const CartContentView = memo(CartContentViewComponent, isEqual);
