import { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { actions} from 'store/CartStore';
import { AppDispatch } from 'store';
import { AppHistoryState } from 'router';
import { useAuth } from 'services/AuthManager/AuthManager.react';
import { useViewportSize } from '../../hooks/useViewportSize';

import { Typography, Button } from 'antd';
import lottie from "lottie-web";
import confetti from "./confetti.json";
import ReactPixel from 'react-facebook-pixel';
import { GetTransactionResponse } from 'atlasguides-web-common/src/functions/payment/get-transaction';
import { fetcher } from 'atlasguides-web-common/src/configure';

const { Title } = Typography;

const ConfirmationComponent: React.FC<any> = ( subtotal, order_number ) => {
  
  const { isSignedIn } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory<AppHistoryState>();
  var transaction: GetTransactionResponse;
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: confetti,
      });
    }
  }, [animationContainer]);

  useEffect(() => {
    dispatch(actions.requestClearCart({
      onDone: () => console.log("Cart Cleared")
    }));
  }, [dispatch, isSignedIn]);

  const { isMobile } = useViewportSize();

  useEffect(() => {
    console.log('order_number: ' + JSON.stringify(subtotal.order_number));
    console.log('subtotal: ' + JSON.stringify(subtotal));
    // declare the async data fetching function
    const fetchTransaction = async (): Promise<GetTransactionResponse> => {
      // get the data from the api
      const transactionResponse: GetTransactionResponse = await fetcher({
        method: 'get',
        path: `/payment/transaction/${subtotal.order_number}`
      });

      return transactionResponse;
    }
  
    // call the function
    if (isSignedIn) {
      fetchTransaction().then((value) => {
        transaction = value
        console.log("transactionResponse: " + JSON.stringify(value));
        if (subtotal) {
          ReactPixel.track('Purchase', {
            value: (subtotal.subtotal / 100).toFixed(2),
            content_ids: transaction.guideIds,
            currency: 'USD'
          });
        }
      });
    }
  })

  // TODO: Fix Inline Styling
  return (
    <div style={{alignItems: 'center', textAlign:'center', justifyContent: 'center', height: '100%', padding:50}}>
      <Title style={{margin: 'auto'}}>Thank You For Your Purchase!</Title>
      <Title level={5}>You Will Receive A Confirmation Email Shortly</Title>
      <Button onClick={()=>history.push('/guides')} size='large' style={{borderRadius: 5, background: '#01838F', borderColor: "#01838F"}} type="primary"> Return To Guides </Button>
      <div ref={animationContainer} style={{alignItems: 'center', textAlign:'center', justifyContent: 'center', height: '80%', padding:50}}>
      </div>
    </div>
  );
};

export const ConfirmationContainer = memo(ConfirmationComponent, isEqual);
