import { GetGuestTokenResponse } from 'atlasguides-web-common/src/functions/auth/get-guest-token';
import axios from 'axios';
import { AppConfig } from 'configs/app-config';
import { CognitoAuthService } from 'services/CognitoAuthService/CognitoAuthService';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import {
  AuthManager,
  AuthMethod,
  AuthServiceInterface,
  AuthServiceType,
  CredentialsTypeUsedForPasswordReset,
  OAuthEmailSignInCredentials,
  OAuthEmailSignUpCredentials,
  RequestResetPasswordParams,
  SetIsSignedIn,
} from './AuthManager.interfaces';
import { resetPassword } from '../../atlasguides-web-common/src/functions/users/reset-password';
import {
  confirmResetPassword as confirmResetPasswordRequest,
  ConfirmResetPasswordRequest,
} from '../../atlasguides-web-common/src/functions/users/confirm-reset-password';
import { parseApiResetPasswordError } from '../../adapters/API/APIResetPasswordAdapters';
import { parseApiForgotPasswordError } from '../../adapters/API/APIForgotPasswordAdapters';

export class AuthManagerService implements AuthManager {
  private static instance: AuthManager | null = null;
  private guestAccessToken: string | null = null;
  private currentAuthService: AuthServiceInterface | null = null;
  private initializationPromise: Promise<void> | null = null;
  private isInitialized: boolean = false;
  private isSignedIn: boolean = false;
  private subscribersOnIsSignedIn: Set<SetIsSignedIn> = new Set();
  private SERVICE_ERROR_PREFIX = 'AuthManagerError';
  private credentialsUsedForPasswordReset = '';
  private credentialsTypeUsedForPasswordReset: CredentialsTypeUsedForPasswordReset | null =
    null;

  private constructor() {
    this.initializeIfNotInitialized();

    this.setIsSignedIn = this.setIsSignedIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.confirmEmail = this.confirmEmail.bind(this);
    this.resendConfirmationCode = this.resendConfirmationCode.bind(this);
    this.subscribeOnIsSignedIn = this.subscribeOnIsSignedIn.bind(this);
    this.unsubscribeFromIsSignedIn = this.unsubscribeFromIsSignedIn.bind(this);
    this.requestResetPassword = this.requestResetPassword.bind(this);
    this.confirmResetPassword = this.confirmResetPassword.bind(this);
  }

  private async initializeIfNotInitialized() {
    if (this.isInitialized) return;
    if (this.initializationPromise) return this.initializationPromise;

    this.initializationPromise = this.initializeAuthManager();
  }

  private async initializeAuthManager() {
    await this.recoverOrGetGuestAccessToken();
    this.recoverLastUsedAuthService();
    const service = this.currentAuthService;
    if (!service) {
      return;
    }
    service.subscribeOnIsSignedIn(this.setIsSignedIn);
    await service.initialize();
    this.isInitialized = true;
  }

  private async recoverOrGetGuestAccessToken() {
    this.guestAccessToken =
      LocalStorageManager.getInstance().getGuestAccessToken();
    if (this.guestAccessToken) return;
    await this.getSetNewGuestToken();
    if (!this.guestAccessToken) return;
    LocalStorageManager.getInstance().setGuestAccessToken(
      this.guestAccessToken
    );
  }

  private recoverLastUsedAuthService() {
    const lastusedServiceType = this.getLastUsedAuthServiceType();
    this.setCurrentAuthServiceByTypeIfChanged(lastusedServiceType);
  }

  private getLastUsedAuthServiceType() {
    return LocalStorageManager.getInstance().getLastUsedAuthServiceType();
  }

  private setLastUsedAuthServiceType(type: AuthServiceType) {
    return LocalStorageManager.getInstance().setLastUsedAuthServiceType(type);
  }

  private removeLastUsedAuthServiceType() {
    LocalStorageManager.getInstance().removeLastUsedAuthServiceType();
  }

  private setIsSignedIn(value: boolean) {
    this.isSignedIn = value;
    this.notifySubscribersOnIsSignedIn();
  }

  private notifySubscribersOnIsSignedIn() {
    this.subscribersOnIsSignedIn.forEach(setIsSignedIn =>
      setIsSignedIn(this.isSignedIn)
    );
  }

  private setCurrentAuthServiceByTypeIfChanged(type: string | null) {
    const currentAuthServiceType = this.currentAuthService?.type;
    const needUpdateAuthService =
      !currentAuthServiceType || currentAuthServiceType !== type;
    if (needUpdateAuthService) {
      this.currentAuthService = this.getAuthServiceByType(type);
      this.currentAuthService?.subscribeOnIsSignedIn(this.setIsSignedIn);
    }
  }

  private clearCurrentAuthService() {
    this.currentAuthService?.unsubscribeFromIsSignedIn(this.setIsSignedIn);
    this.currentAuthService = null;
  }

  private async startOrWaitForInitialization() {
    if (!this.initializationPromise) {
      await this.initialize;
    }
    await this.initializationPromise;
  }

  public async initialize() {
    if (this.isInitialized) return;
    if (this.initializationPromise) return this.initializationPromise;

    this.initializationPromise = this.initializeAuthManager();
  }

  private getAuthServiceByType(type: string | null) {
    switch (type) {
      case 'cognito':
        return CognitoAuthService.getInstance();
      case null:
        return null;
      default:
        throw new Error(`
          AuthManager tried to access inconsistent type of the AuthService.
          Requested "${type}" type but only

          "cognito", "custom"

          are supported.
        `);
    }
  }

  private async getSetNewGuestToken() {
    const response = await this.fetchNewGuestToken();
    if (!response.success) {
      throw new Error(
        `${this.SERVICE_ERROR_PREFIX}: failed to obtain a new guest token.`
      );
    }
    this.guestAccessToken = response.token;
  }

  private async fetchNewGuestToken() {
    const raw_url = AppConfig.backendUrl;
    const url = raw_url.endsWith('/') ? raw_url.slice(0, -1) : raw_url;

    const response = (
      await axios({
        method: 'GET',
        url: url + '/users/guest-token',
      })
    ).data as unknown as GetGuestTokenResponse;
    return response;
  }

  public static getInstance(): AuthManager {
    if (!AuthManagerService.instance) {
      AuthManagerService.instance = new AuthManagerService();
    }
    return AuthManagerService.instance;
  }

  public async signUp(
    type: AuthServiceType,
    method: AuthMethod,
    credentials?: OAuthEmailSignUpCredentials
  ) {
    this.setCurrentAuthServiceByTypeIfChanged(type);
    const response = await this.currentAuthService?.signUp(method, credentials);
    return response;
  }

  public async signIn(
    type: AuthServiceType,
    method: AuthMethod,
    credentials: OAuthEmailSignInCredentials
  ) {
    this.setCurrentAuthServiceByTypeIfChanged(type);
    const response = await this.currentAuthService?.signIn(method, credentials);
    this.setLastUsedAuthServiceType(type);
    return response;
  }

  public async getAccessToken() {
    await this.startOrWaitForInitialization();

    const service = this.currentAuthService;
    let accessToken: null | string = null;
    if (service && this.isSignedIn) {
      accessToken = await service.getAccessToken();
    } else if (this.guestAccessToken) {
      accessToken = this.guestAccessToken;
    } else {
      await this.recoverOrGetGuestAccessToken();
      accessToken = this.guestAccessToken;
    }

    return accessToken;
  }

  public async signOut() {
    await this.currentAuthService?.signOut();
    this.clearCurrentAuthService();
    this.removeLastUsedAuthServiceType();
  }

  public subscribeOnIsSignedIn(setState: SetIsSignedIn) {
    this.subscribersOnIsSignedIn.add(setState);
  }

  public unsubscribeFromIsSignedIn(setState: SetIsSignedIn) {
    this.subscribersOnIsSignedIn.delete(setState);
  }

  public async confirmEmail(
    type: AuthServiceType,
    username: string,
    code: string
  ) {
    const response = await this.getAuthServiceByType(type)?.confirmEmail(
      username,
      code
    );
    this.setLastUsedAuthServiceType(type);
    return response;
  }

  public async resendConfirmationCode(type: AuthServiceType, username: string) {
    return this.getAuthServiceByType(type)?.resendConfirmationCode(username);
  }

  public async requestResetPassword({
    username,
    email,
  }: RequestResetPasswordParams) {
    if (!username && !email) {
      throw new Error('requestResetPassword: should have username or email');
    }
    let response;

    if (email) {
      response = await resetPassword({ email });
      this.credentialsUsedForPasswordReset = email;
      this.credentialsTypeUsedForPasswordReset =
        CredentialsTypeUsedForPasswordReset.email;
    } else if (username) {
      response = await resetPassword({ username });
      this.credentialsUsedForPasswordReset = username;
      this.credentialsTypeUsedForPasswordReset =
        CredentialsTypeUsedForPasswordReset.username;
    }

    if (response && !response.success) {
      this.credentialsUsedForPasswordReset = '';
      this.credentialsTypeUsedForPasswordReset = null;
      return {
        result: 'error' as 'error',
        errorMessage: parseApiForgotPasswordError(response.status),
      };
    }

    return {
      result: 'success' as 'success',
    };
  }

  public async confirmResetPassword(
    password: string,
    confirmationCode: string
  ) {
    const request: { [p: string]: string } = {
      password,
      confirmationCode,
    };

    if (this.credentialsTypeUsedForPasswordReset) {
      request[
        this.credentialsTypeUsedForPasswordReset ===
        CredentialsTypeUsedForPasswordReset.email
          ? 'email'
          : 'username'
      ] = this.credentialsUsedForPasswordReset;
    }

    const response = await confirmResetPasswordRequest(
      request as ConfirmResetPasswordRequest
    );

    if (!response.success) {
      return {
        result: 'error' as 'error',
        errorMessage: parseApiResetPasswordError(response.status),
      };
    }

    return {
      result: 'success' as 'success',
    };
  }
}
