import { fetcher } from '../../configure'

export type ResetPasswordRequest = ResetPasswordByEmailRequest | ResetPasswordByUsernameRequest

export type ResetUserPasswordStatus = 'SUCCESS' | 'USER_DOESNT_EXISTS' | 'INTERNAL_ERROR'

export type ResetPasswordResponse = {
	success: boolean
	status: ResetUserPasswordStatus
}

type ResetPasswordByEmailRequest = {
	email: string
}

type ResetPasswordByUsernameRequest = {
	username: string
}
export type ResetPasswordEndpoint = (
	request: ResetPasswordRequest
) => Promise<ResetPasswordResponse>

export const resetPassword: ResetPasswordEndpoint = async (request) => {
	return await fetcher({
		method: 'post',
		path: '/users/reset-password',
		body: request,
	})
}
