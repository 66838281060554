import { fetcher } from '../../configure'
import { Comment } from './dto/comment.dto'

export type GetCommentsRequest = {
	waypointId: string
}

export type GetCommentsResponse = {
	waypointId: string,
	comments: Comment[]
}

export type GetCommentsWeekScoreResponse = {
	score: Number
}

export type GetCommentsEndpoint = (
    request: GetCommentsRequest
) => Promise<GetCommentsResponse>

export type GetCommentsWeekScoreEndpoint = (
    request: GetCommentsRequest
) => Promise<GetCommentsWeekScoreResponse>

export const getComments: GetCommentsEndpoint = async (
	request: GetCommentsRequest
): Promise<GetCommentsResponse> => {
	return await fetcher({ method: 'get', path: `/waypoints/${request.waypointId}/comments/` })
}

export const getCommentsWeekScore: GetCommentsWeekScoreEndpoint = async (
	request: GetCommentsRequest
): Promise<GetCommentsWeekScoreResponse> => {
	return await fetcher({ method: 'get', path: `/waypoints/${request.waypointId}/score/` })
}

