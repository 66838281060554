export enum FloatPlacement {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export enum ElevationProfileExpansionLevels {
  level_1 = 0,
  level_2 = 1,
  level_3 = 2,
}

export const LAST_ELEVATION_PROFILE_EXPANSION_LEVEL =
  ElevationProfileExpansionLevels.level_3;
export const FIRST_ELEVATION_PROFILE_EXPANSION_LEVEL =
  ElevationProfileExpansionLevels.level_1;
