import { LeftOutlined } from '@ant-design/icons';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import styled from 'styled-components';

export const StyledBackIcon = styled(LeftOutlined)<{ fontSize?: string }>`
  color: ${({ theme }) => theme.backButton.colors.icon};
  font-size: ${({ fontSize }) => fontSize || '30px'};
`;

export const StyledBaseButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 7px;
  height: unset;
  box-shadow: unset;

  & > .anticon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > span:first-child {
    display: flex;
  }
`;
