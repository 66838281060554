import isEqual from 'lodash.isequal';
import { S } from './ZoomButtons.styles';
import React from 'react';
import { ZoomButtonsViewProps } from './ZoomButtons.interfaces';
import { FloatPlacement } from '../../commonTypes/enums';
import { MACOS_PLATFORMS_ARRAY } from '../../constants/common/platforms';
import {
  PlusOutlined,
  MinusOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';
import { Button } from 'antd';


const ZoomButtonsViewComponent: React.FC<ZoomButtonsViewProps> = ({
  isAbsolute,
  floatButtonsPlacement = FloatPlacement.topLeft,
  onZoomIn,
  onZoomOut,
  zIndex = 400,
}) => {
  const extraTop =
    MACOS_PLATFORMS_ARRAY.indexOf(window.navigator.platform) === -1 ? 4 : 0;
  return (
    <S.ButtonsWrapper
      isAbsolute={isAbsolute}
      floatButtonsPlacement={floatButtonsPlacement}
      zIndex={zIndex}
    >

      <PlusCircleFilled onClick={onZoomIn} style={{margin: 20, marginBottom: 0, color:'#DE9434', fontSize:'65px', borderRadius: 50, background:'white'}}/>
      <MinusCircleFilled onClick={onZoomOut} style={{color:'#DE9434', fontSize:'65px', margin: 20, marginBottom: 75, borderRadius: 50, background:'white'}}/>

    </S.ButtonsWrapper>
  );
};

/*
<Button
  style={{margin: 25, marginBottom: 75, width:75, height:75, background: '#01838F', borderColor: "#01838F", textAlign:'center', alignItems:'center', justifyContent:'center'}}
  type="primary"
  shape="circle"
  icon={<MinusOutlined/>}
  size='large'
  onClick={onZoomOut}
/>

<Button
style={{margin: 25, width:75, height:75, background: '#01838F', borderColor: "#01838F", textAlign:'center', alignItems:'center', justifyContent:'center'}}
  type="primary"
  shape="circle"
  icon={<PlusOutlined/>}
  size='large'
  onClick={onZoomIn}
/>


 */

export const ZoomButtonsView = React.memo(ZoomButtonsViewComponent, isEqual);
