import { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';

import { Route, Switch, useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { CardCreateAccountView } from 'views/CardCreateAccount/CardCreateAccount';
import { CardSignInView } from 'views/CardSignIn/CardSignIn';
import { useAuth } from 'services/AuthManager/AuthManager.react';
import { HandleSubmitFormikLoginForm } from 'views/LogInForm/LogInForm.interfaces';
import {
  OAuthEmailSignInErrorResponse,
  OAuthEmailSignUpErrorResponse,
  AuthErrorResponse,
} from 'services/AuthManager/AuthManager.interfaces';
import { actions as trailsActions } from 'store/TrailsStore';
import { HandleSubmitFormikCreateAccountForm } from 'views/CreateAccountForm/CreateAccountForm.interfaces';
import { AppLocationState } from 'router';
import { CardForgotPasswordView } from '../../views/CardForgotPassword/CardForgotPassword';
import { HandleSubmitFormikForgotPasswordForm } from '../../views/ForgotPasswordForm/ForgotPasswordForm.interfaces';
import { CardResetPasswordView } from '../../views/CardResetPassword/CardResetPassword';
import { HandleSubmitFormikResetPasswordForm } from '../../views/ResetPasswordForm/ResetPasswordForm.interfaces';
import { useHistory } from 'react-router';

const AuthPageComponent: React.FC = () => {
  const location = useLocation<AppLocationState>();
  const {
    isSignedIn,
    signIn,
    signUp,
    requestResetPassword,
    confirmResetPassword,
  } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const userGuestTokenRef = useRef<null | string>(null);


  const transitions = useTransition(location, location => location.pathname, {
    from: {
      opacity: '0',
      transform: 'translate3d(40px,0,0)',
      position: 'absolute',
    },
    enter: { opacity: '1', transform: 'translate3d(0px,0,0)' },
    leave: { opacity: '0', transform: 'translate3d(-40px,0,0)' },
  });

  const handleSubmitSignInForm = useCallback<HandleSubmitFormikLoginForm>(
    async (values, actions) => {
      const { error_field, ...parameters } = values;
      const err = await signIn('cognito', 'email', parameters);

      const handleThrowError = (err: AuthErrorResponse) => {
        const { errorMessage } = err as OAuthEmailSignInErrorResponse;
        actions.setErrors({ error_field: 'validation_incorrect_username_password' });
        actions.setSubmitting(false);
      };

      if (err) {
        handleThrowError(err);
      }
    },
    [
      signIn,
      dispatch,
    ]
  );

  const handleSubmitCreateAccountForm =
    useCallback<HandleSubmitFormikCreateAccountForm>(
      async (values, actions) => {
        const err = await signUp('cognito', 'email', values);

        if (err) {
          const { fieldsErrors, errorMessage } =
            err as OAuthEmailSignUpErrorResponse;
          actions.setErrors(
            fieldsErrors
              ? fieldsErrors
              : { isTermsOfUseAccepted: errorMessage || 'An error occured' }
          );
          actions.setSubmitting(false);
        } 
      },
      [signUp]
    );

  const handleForgotPasswordSubmit =
    useCallback<HandleSubmitFormikForgotPasswordForm>(
      async (values, actions) => {
        const request = {
          email: values.email,
        };

        const response = await requestResetPassword(request);

        if (response.result === 'error') {
          actions.setErrors({ errorField: response.errorMessage });
          actions.setSubmitting(false);
        } else {
          history.push('/reset');
        }
      },
      [history, requestResetPassword]
    );

  const handleResetPasswordSubmit =
    useCallback<HandleSubmitFormikResetPasswordForm>(
      async (values, actions) => {
        const response = await confirmResetPassword(
          values.password,
          values.confirmationCode
        );

        if (response.result === 'error') {
          actions.setErrors({ errorField: response.errorMessage });
          actions.setSubmitting(false);
        } else {
          history.push('/login');
        }
      },
      []
    );

  useEffect(() => {
    if (isSignedIn) {
      dispatch(trailsActions.getAllTrailsListRequest());
      history.push('/');
    }
  }, [isSignedIn]);

  return (
    <>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route path="/login">
              <CardSignInView handleSignInSubmit={handleSubmitSignInForm} />
            </Route>
            <Route path="/create-account">
              <CardCreateAccountView
                handleCreateAccountSubmit={handleSubmitCreateAccountForm}
              />
            </Route>
            <Route exact path="/forgot">
              <CardForgotPasswordView
                handleForgotPasswordSubmit={handleForgotPasswordSubmit}
              />
            </Route>
            <Route path="/reset">
              <CardResetPasswordView
                handleResetPasswordSubmit={handleResetPasswordSubmit}
              />
            </Route>
          </Switch>
        </animated.div>
      ))}
    </>
  );
};

export const AuthPage = memo(AuthPageComponent, isEqual);
