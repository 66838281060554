import { fetcher } from '../../configure'

export type GetWaypointIconsRequest = void

export type GetWaypointIconsResponse = {
	icons: {
		[key: string]: string
	}
}

export type GetwaypointIconsEndpoint = (
	request: GetWaypointIconsRequest
) => Promise<GetWaypointIconsResponse>

export const getWaypointIcons: GetwaypointIconsEndpoint = async (request) => {
	return await fetcher({
		method: 'get',
		path: '/waypoint/icons',
		body: request,
	})
}
