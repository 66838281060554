import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { ProfileHeaderView } from '../../views/ProfileHeader/ProfileHeader';
import { ProfileHeaderContainerProps } from './ProfileHeader.interfaces';
import { useSelector } from 'react-redux';
import {
  getUploadingCoverImageLoadingStateSelector,
  getUploadingProfileImageLoadingStateSelector,
} from '../../store/UserStore/selectors';

const ProfileHeaderContainerComponent: React.FC<ProfileHeaderContainerProps> =
  ({ picturesView, userPhoto, backgroundPictureHeight }) => {
    const isUploadingCoverImage = useSelector(
      getUploadingCoverImageLoadingStateSelector
    );
    const isUploadingProfileImage = useSelector(
      getUploadingProfileImageLoadingStateSelector
    );

    return (
      <ProfileHeaderView
        picturesView={picturesView}
        backgroundPictureHeight={backgroundPictureHeight}
        userPhoto={userPhoto}
        isUploadingCoverImage={isUploadingCoverImage}
        isUploadingProfileImage={isUploadingProfileImage}
        pictureCarouselBorderRadius={'7px 7px 0 0'}
      />
    );
  };

export const ProfileHeaderContainer = memo(
  ProfileHeaderContainerComponent,
  isEqual
);
