import { memo } from 'react';
import { S as CommonStyles } from '../Arrow.styles';
import { S } from './RightArrow.styles';
import { ArrowProps } from '../Arrow.interfaces';

const RightArrowComponent: React.FC<ArrowProps> = ({
  sizes,
  color,
  onClick,
}) => {
  return (
    <CommonStyles.Container onClick={onClick} sizes={sizes}>
      <S.RightArrowIcon color={color} />
    </CommonStyles.Container>
  );
};

export const RightArrow = memo(RightArrowComponent);
