import React, { memo, useEffect, useCallback, useState } from 'react';
import isEqual from 'lodash.isequal';
import { ProfilePageView } from 'views/ProfilePage/ProfilePage';

import { ProfilePageContainerProps } from './ProfilePage.interface';

import {
  selectUserLoadingState,
  selectUserProfile,
  userActions,
} from '../../store/UserStore';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/rootReducer';
import { PurchasedReceipts } from '../PurchasedReceipts/PurchasedReceipts';
import { ProfileSettingsContainer } from '../ProfileSettings/ProfileSettings';
import { useAuth } from '../../services/AuthManager/AuthManager.react';
import { UserProfileWithPurchases } from 'atlasguides-web-common/src/functions/users/dto/user-profile.dto';

const ProfilePageComponent: React.FC<ProfilePageContainerProps> = ({
  isProfilePageShown,
  onHideProfilePage,
  onShowProfilePage,
}) => {
  const dispatch = useDispatch();
  const { isSignedIn } = useAuth();
  const [purchasedReceiptsVisibility, setPurchasedReceiptsVisibility] =
    useState(false);
  const userProfile = useSelector<AppState, UserProfileWithPurchases | null>(
    selectUserProfile
  );
  const loadingUserProfile = useSelector<AppState, boolean>(
    selectUserLoadingState
  );

  const [profileSettingsVisibility, setProfileSettingsVisibility] =
    useState(false);

  const handleViewPurchaseReceipts = useCallback(() => {
    onHideProfilePage();
    setPurchasedReceiptsVisibility(true);
  }, [onHideProfilePage]);

  const handleHidePurchasedReceipts = useCallback(() => {
    setPurchasedReceiptsVisibility(false);
    onHideProfilePage();
  }, []);

  const handleEditProfileSettings = useCallback(() => {
    setProfileSettingsVisibility(true);
    onHideProfilePage();
  }, []);

  const handleCloseProfileSettings = useCallback(() => {
    setProfileSettingsVisibility(false);
    onShowProfilePage();
  }, []);

  useEffect(() => {
    if (isProfilePageShown && !userProfile && isSignedIn) {
      dispatch(userActions.getUserProfileRequest({ userId: 'mine' }));
    }
  }, [dispatch, isProfilePageShown, userProfile, isSignedIn]);

  return (
    <>
      <ProfilePageView
        isLoading={loadingUserProfile}
        isProfilePageShown={isProfilePageShown}
        onHideProfilePage={onHideProfilePage}
        followersCount={userProfile?.socialInfo.followersCount}
        followingsCount={userProfile?.socialInfo.followingsCount}
        displayName={userProfile?.primaryInfo.displayName}
        username={userProfile?.primaryInfo.username}
        email={userProfile?.primaryInfo.email}
        bio={userProfile?.primaryInfo.bio}
        userPhoto={userProfile?.photos.profilePhotoUrl}
        userBackgroundPhoto={userProfile?.photos.profileCoverImageUrl}
        handleViewPurchaseReceipts={handleViewPurchaseReceipts}
        handleEditProfileSettings={handleEditProfileSettings}
        isProfilePublic={userProfile?.primaryInfo.isPublicProfile}
        points={123}
      />
      <PurchasedReceipts
        onHidePurchasedReceipts={handleHidePurchasedReceipts}
        isPurchasedReceiptsShown={purchasedReceiptsVisibility}
      />
      <ProfileSettingsContainer
        visibility={profileSettingsVisibility}
        onClose={handleCloseProfileSettings}
      />
    </>
  );
};

export const ProfilePage = memo(ProfilePageComponent, isEqual);
