import { fetcher } from '../../configure'
import { GuideWaypoints } from './dto/guide-geo.dto'

export type GetGuideWaypointsRequest = {
    guideId: string
}

export type GetGuideWaypointsResponse = GuideWaypoints

export type GetGuideWaypointsEndpoint = (
    request: GetGuideWaypointsRequest
) => Promise<GetGuideWaypointsResponse>

export const getGuideWaypoints: GetGuideWaypointsEndpoint = async (
    request
): Promise<GetGuideWaypointsResponse> => {
    return await fetcher({
        method: 'get',
        path: `/guides/${request.guideId}/waypoints`,
        headers: {
            'Accept-Encoding': 'gzip'
        }        
    })
}
