import { memo, useEffect, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { PurchasedReceiptsView } from '../../views/PurchasedReceipts/PurchasedReceipts';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserProfile,
  userActions,
} from '../../store/UserStore';
import { AppState } from '../../store/rootReducer';
import { PurchasedReceiptsProps } from './PurchasedReceipts.interfaces';
import { compareDesc } from 'date-fns';
import { UserProfileWithPurchases } from 'atlasguides-web-common/src/functions/users/dto/user-profile.dto';

const PurchasedReceiptsComponent: React.FC<PurchasedReceiptsProps> = ({
  onHidePurchasedReceipts,
  isPurchasedReceiptsShown,
}) => {
  const dispatch = useDispatch();
  const userProfile = useSelector<AppState, UserProfileWithPurchases | null>(
    selectUserProfile
  );

  useEffect(() => {
    if (isPurchasedReceiptsShown && !userProfile) {
      dispatch(userActions.getUserProfileRequest({ userId: 'mine' }));
    }
  }, [dispatch, isPurchasedReceiptsShown, userProfile]);

  const purchasedGuides = useMemo(() => {
    return userProfile?.purchases
      ? [...userProfile.purchases].sort((a, b) =>
          compareDesc(new Date(a.purchaseDate), new Date(b.purchaseDate))
        )
      : [];
  }, [userProfile?.purchases]);

  return (
    <PurchasedReceiptsView
      purchasedGuides={purchasedGuides}
      onHidePurchasedReceipts={onHidePurchasedReceipts}
      isPurchasedReceiptsShown={isPurchasedReceiptsShown}
    />
  );
};

export const PurchasedReceipts = memo(PurchasedReceiptsComponent, isEqual);
