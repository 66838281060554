import styled from 'styled-components';
import { Divider, Modal as AntModal } from 'antd';

const sectionSpan = styled.span`
  padding: 4px 0;
`;

export const S = {
  Modal: styled(AntModal)`
    & .ant-modal-content {
      max-width: 100%;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80vh;
      display: flex;
    }

    & .ant-modal-body {
      width: 100%;
      padding: 0;
    }
  `,
  Body: styled.div`
    position: relative;
    width: 100%;
  `,
  Content: styled.div`
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  DisplayedName: styled.span`
    font-size: 20px;
    color: ${({ theme }) => theme.profilePage.colors.textDisplayedName};
  `,
  UserName: styled(sectionSpan)`
    color: ${({ theme }) => theme.profilePage.colors.textUserName};
  `,
  FollowersInfo: styled(sectionSpan)`
    color: ${({ theme }) => theme.profilePage.colors.textFollowersInfo};
    text-transform: uppercase;
  `,
  Bio: styled(sectionSpan)`
    padding: 5px 56px;
    color: ${({ theme }) => theme.profilePage.colors.textBio};
    text-align: center;
    word-break: break-word;
  `,
  MobileAppLogInSuggestion: styled.span`
    padding: 24px 48px;
    color: ${({ theme }) => theme.profilePage.colors.textLogInSuggestion};
    text-align: center;
    font-style: italic;
  `,
  SignOutButtonContainer: styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
  `,
  SpinnerContainer: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
  StyledDivider: styled(Divider)`
    margin: 0;
  `,
  IsProfilePublicTitle: styled.span`
    font-size: 16px;
    color: ${({ theme }) => theme.profilePage.colors.textIsProfilePublic};
  `,
};
