import styled from 'styled-components';
import { Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  ElevationProfileExpansionLevels,
  FIRST_ELEVATION_PROFILE_EXPANSION_LEVEL,
} from '../../commonTypes/enums';
import { getHeightAccordingToElevationExpansionLevel } from '../../utils/getHeightAccordingToElevationExpansionLevel';
import { ZoomButtonsView } from '../ZoomButtons/ZoomButtons';
interface StyledProps {
  isMobile: boolean;
}

interface WrapperProps extends StyledProps {
  isExpanded?: boolean;
}

export const S = {
  Container: styled.div`
    height: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
  `,

  GuideBody: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `,

  SideWrapper: styled.div<WrapperProps>`
    position: relative;
    width: ${({ isMobile }) => isMobile && '100%'};
  `,

  ColumnWrapper: styled.div<WrapperProps>`
    position: relative;
    height: 100%;
    width: 100%;
    max-width: ${({ isMobile }) => !isMobile && '400px'};
    display: flex;
    flex-direction: column;
  `,

  CardsWrapper: styled.div`
    padding: 10px;
  `,

  ItemWrapper: styled.div`
    max-width: 260px;
  `,

  CarouselWrapper: styled.div`
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    z-index: 1001;
  `,

  FilterWrapper: styled.div`
    position: absolute;
    height: 100%;
    overflow: auto;
    z-index: 1002;
    background: ${({ theme }) => theme.guideUse.colors.backgroundFilterWrapper};
  `,

  ControlsPanelWrapper: styled.div<WrapperProps>`
    padding-right: ${({ isMobile }) => isMobile && '40px'};
    width: 100%;
  `,

  GuidesOptionsPanelWrapper: styled.div<WrapperProps>`
    position: absolute;
    z-index: 1001;
    top: ${({ isMobile }) => (isMobile ? '10px' : '30px')};
    right: ${({ isMobile }) => (isMobile ? '15px' : '20px')};
  `,

  MenuContainer: styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,

  Sider: styled(Layout.Sider)`
    height: 100%;
    background: ${({ theme }) => theme.guideUse.colors.backgroundSider};
  `,

  WrapTab: styled.div`
    position: absolute;
    top: 60px;
    left: 100%;
    height: 36px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    background: ${({ theme }) => theme.guideUse.colors.backgroundWrapTab};
    cursor: pointer;
    z-index: 999;
  `,

  ElevationProfileWrapper: styled.div<{
    elevationProfileExpansionLevel: ElevationProfileExpansionLevels;
  }>`
    bottom: ${({ elevationProfileExpansionLevel }) =>
      elevationProfileExpansionLevel === FIRST_ELEVATION_PROFILE_EXPANSION_LEVEL
        ? 0
        : 10}px;
    position: absolute;
    padding-right: 20px;
    padding-left: 40px;
    height: 100%;
    max-height: ${({ elevationProfileExpansionLevel }) =>
      getHeightAccordingToElevationExpansionLevel(
        elevationProfileExpansionLevel
      )};
    width: 100%;
    z-index: 998;
    box-sizing: border-box;
    transition: bottom 0.3s;
    transition: max-height 0.3s;
  `,
  LeftOutlined: styled(LeftOutlined)``,
  RightOutlined: styled(RightOutlined)``,
  SpinnerContainer: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
  SearchInputWrapper: styled.div<StyledProps>`
    padding: ${({ isMobile }) => `0 ${isMobile ? '60px' : '20px'} 0 10px`};
  `,
};
