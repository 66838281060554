import { LocalizationManager } from './LocalizationManager';
import { GeoWaypoint } from 'containers/GuideUse/GuideUse.interface';

export class WaypointLocalizator {

    public static getTitle(waypoint : GeoWaypoint){
        if (!LocalizationManager.isDefaultLanguage()){
            const lang = LocalizationManager.getLanguage();
            const name = "title_" + lang as keyof typeof waypoint;
            let title = waypoint[name]
            if (title){
                return title;
            }
        }
        return waypoint.title;
    }

    public static getDescription(waypoint : GeoWaypoint) : string{
        if (!LocalizationManager.isDefaultLanguage()){
            const lang = LocalizationManager.getLanguage();
            const name = "description_" + lang as keyof typeof waypoint;
            let description = waypoint[name] as string
            if (description){
                return description;
            }
        }
        return waypoint.description;
    }

}