import { Fetcher, extractToken } from '../configure'
import { API_URL } from '../configure'

const formatQueryParams = (queryParams: any) => {
	const result: any = {}
	if (!queryParams) {
		return null
	}
	for (const key in queryParams) {
		const value = queryParams[key]
		if (Array.isArray(value)) {
			result[key] = value.join()
		} else if (typeof value === 'object') {
			throw new Error(
				'Error while parsing query params: nested objects is not allowed'
			)
		} else {
			result[key] = value
		}
	}
	return result
}

export const axiosAdapter = (library: any): Fetcher => {
	return async ({
		method,
		body,
		headers,
		queryParams,
		path,
	}) => {
		const formattedQueryParams = formatQueryParams(queryParams)
		
		const request = {
			method: method.toUpperCase(),
			url: API_URL + path,
			data: body && method !== 'get' ? body : undefined,
			headers: {
				...headers,
			},
			params: formattedQueryParams,
		}

		const token = await extractToken()
		if (token) {
			request.headers.authorization = token
		}

		return (await library(request)).data
	}
}
