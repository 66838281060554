import { fetcher } from '../../configure'
import { UserProfileWithPurchases } from './dto/user-profile.dto'

type ID = string
export type GetUserProfileRequest = {
	username: ID | 'mine'
}

export type GetUserProfileResponse = {
	success: boolean
	message: 'SUCCESS' | 'USER_NOT_FOUND' | 'PROFILE_IS_NOT_PUBLIC'
	userProfile?: UserProfileWithPurchases
}

export type GetUserProfileEndpoint = (
	request: GetUserProfileRequest
) => Promise<GetUserProfileResponse>

export const getUserProfile: GetUserProfileEndpoint = async (request) => {
	return await fetcher({
		method: 'get',
		path: `/users/profile/${request.username}`,
		body: request, // If user authorized they can get own user information with their purchases otherwise only public user accounts
	})
}
