import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { getLocaleDate } from '../../../utils/formatUtils';
import { S } from './PointFeedback.styles';
import { PointFeedBackProps } from '../PointDetails/PointDetails.interface';

const PointFeedbackComponent: React.FC<PointFeedBackProps> = ({ comment }) => {
  return (
    <S.Feedback key={comment.id}>
      <S.FeedbackText>{comment.content}</S.FeedbackText>
      <S.FeedbackDetail>
        <S.FeedbackAuthor>{comment.authorName}</S.FeedbackAuthor>
        <S.FeedbackDate>
          {getLocaleDate(comment.date)}
        </S.FeedbackDate>
      </S.FeedbackDetail>
    </S.Feedback>
  );
};

export const PointFeedback = memo(PointFeedbackComponent, isEqual);
