import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CardProps as AntCardProps, Skeleton } from 'antd';
import { BaseButton } from 'controls/BaseButton/BaseButton';
import { CollapseArrow } from 'shared/CollapseArrow/CollapseArrow';
import { LoadingSpinner } from 'shared/LoadingSpinner/LoadingSpinner';
import { CheckOutlined } from '@ant-design/icons';
import { actions } from 'store/TrailsStore/actions';
import { getDistanceUnitSelector } from 'store/Common/selectors';
import { ReactComponent as ShoppingCartIcon } from 'static/add_shopping_cart_white_23px.svg';

import { StyledCard, StyledLoadedCard, S } from './Card.styles';
import { CardProps, TrailCardProps } from './Card.interface';
import { useDispatch } from 'react-redux';
import {
  actions as cartActions,
  CartItemFrontend,
  selectShopifyCart,
} from 'store/CartStore';
import { actions as trailsStoreActions } from 'store/TrailsStore/actions';
import { useTrail } from 'react-spring';
import { FormattedMessage, useIntl } from 'react-intl';
import { getIsActiveSubscriptionSelector } from 'store/TrailsStore';
import { formatPrice } from 'utils/formatUtils';

const Card: React.FC<CardProps> = ({
  isOnSale,
  shape,
  size,
  guideId,
  shopifyVariantId,
  guideName,
  guidePromoText,
  guideLength,
  guidePrice,
  guidePriceCurrency,
  guideIcon,
  guideIsFree,
  guideDiscountAmount,
  isPurchased,
  nestedCards,
  isInCart,
  isInProgress,
  isDeletionInProgress,
  isCardExpanded,
  hasChildren,
  onExpand,
  onOpen,
  onDelete,
  onAddToCart,
  handleGoToParentGuideView,
  showPromoPrice,
  openButtonTitle,
  guideIsPurchaseable,
  parentGuides,
  guideHasOpenButton,
  ...props
}) => {
  let intl = useIntl()
  const isActiveSubscription = useSelector(getIsActiveSubscriptionSelector);
  return (
    <StyledCard
      {...props}
      guideName={guideName}
      guideId={guideId}
      shopifyVariantId={shopifyVariantId}
      guidePrice={guidePrice}
      shape={shape}
      size={size}
      isCardExpanded={isCardExpanded}
      onExpand={onExpand}
    >
      <S.TrailCard size={size}>
        <S.LogoGrid size={size}>
          {guideIcon ? <S.Logo src={guideIcon} /> : <S.DefaultLogo />}
        </S.LogoGrid>
        {guideName && (
          <S.TitleGrid size={size}>
            <S.TitleWrapper>{guideName}</S.TitleWrapper>
          </S.TitleGrid>
        )}
        {showPromoPrice && guidePrice ? (
          <S.PromoPriceGrid size={size}>{formatPrice(guidePrice, guidePriceCurrency)}</S.PromoPriceGrid>
        ) : (
          guidePromoText && (
            <S.PromoTextGrid size={size}>{guidePromoText}</S.PromoTextGrid>
          )
        )}
        {guideLength && (
          <S.MilesInfoGrid size={size}>
            {guideLength?.distance.toFixed()} {intl.formatMessage({id:guideLength.shortName})}
          </S.MilesInfoGrid>
        )}
        <S.CostGrid size={size}>
          <S.CostWrapper onDelete={onDelete}>
            {guideIsFree || isPurchased || isActiveSubscription ? ( guideHasOpenButton &&
              <>
                {onOpen && (
                  <BaseButton
                    onClick={e => {
                      onOpen(guideName);
                      e.stopPropagation();
                    }}
                    type={isActiveSubscription ? "subscription" : "primary"}
                    label={openButtonTitle || intl.formatMessage({id:"open"})}
                    size="middle"
                  />
                )}
                {guideIsFree && (
                  <S.FreeMark>
                    <S.FreeMarkText><FormattedMessage id="free"/></S.FreeMarkText>
                  </S.FreeMark>
                )}
              </>
            ) : (
              <>
                {!guideIsFree && !guideIsPurchaseable ? (
                  <S.BaseButtonMediumHeight
                    type="primary"
                    label={intl.formatMessage({id:"bundled"})}
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      parentGuides &&
                        handleGoToParentGuideView &&
                        handleGoToParentGuideView(parentGuides[0].guideName);
                    }}
                  />
                ) : (
                  <>
                    {shape === 'bumpy' && (
                      <S.Cost
                        type="primary"
                        disabled={!guidePrice}
                        loading={isInProgress}
                        icon={
                          <S.CartIconWrapper>
                            {isInCart ? (
                              <CheckOutlined />
                            ) : (
                              <ShoppingCartIcon />
                            )}
                          </S.CartIconWrapper>
                        }
                        label={
                          <>
                            {`${
                              isInCart
                                ? intl.formatMessage({id:"in_cart"})
                                : `${formatPrice(guidePrice, guidePriceCurrency)}`
                            }`}
                          </>
                        }
                        onClick={onAddToCart}
                      />
                    )}
                    {shape === 'flat' && (
                      <S.CostFlat size={size}>
                        {`${
                          isInCart
                            ? intl.formatMessage({id:"in_cart"})
                            : `${formatPrice(guidePrice, guidePriceCurrency)}}`
                        }`}
                      </S.CostFlat>
                    )}
                  </>
                )}
                {isOnSale && (
                  <S.FreeMark>
                    <S.FreeMarkText><FormattedMessage id="sale"/></S.FreeMarkText>
                  </S.FreeMark>
                )}
                {guideDiscountAmount && (
                  <S.FreeMark>
                    <S.FreeMarkText><FormattedMessage id="sale"/></S.FreeMarkText>
                  </S.FreeMark>
                )}
              </>
            )}
            {onDelete && (
              <BaseButton
                type="text"
                loading={isDeletionInProgress}
                icon={
                  !isDeletionInProgress ? (
                    <S.CloseCircleFilled onClick={onDelete} />
                  ) : undefined
                }
              />
            )}
          </S.CostWrapper>
        </S.CostGrid>
        <S.CollapseArrowGrid>
          {hasChildren && onExpand && (
            <S.CollapseArrowGridInner>
              <CollapseArrow
                isItemExpanded={isCardExpanded}
                onExpand={onExpand}
              />
            </S.CollapseArrowGridInner>
          )}
        </S.CollapseArrowGrid>
      </S.TrailCard>
    </StyledCard>
  );
};

const config = { tension: 2000, friction: 100 };

export const TrailCard: React.FC<TrailCardProps> = ({
  isOnSale,
  hasChildren,
  nestedCards,
  shape,
  itemsInCart,
  itemsBeingAddedToCart,
  isInitiallyExpanded,
  handleAddGuideToInitiallyExpanded,
  handleRemoveGuideFromInitiallyExpanded,
  openButtonTitle,
  onOpen,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const distanceUnit = useSelector(getDistanceUnitSelector);
  const shopifyCart = useSelector(selectShopifyCart);
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const trail = useTrail(nestedCards?.length, {
    config,
    opacity: isCardExpanded ? 1 : 0,
    x: isCardExpanded ? 0 : 20,
    height: isCardExpanded ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  const onExpandCard = (isExpanded: boolean) => {
    if (!nestedCards) {
      dispatch(trailsStoreActions.getCardChildrenByIdRequest(props.guideId));
    }

    isExpanded &&
      handleAddGuideToInitiallyExpanded &&
      handleAddGuideToInitiallyExpanded(props.guideId);

    !isExpanded &&
      handleRemoveGuideFromInitiallyExpanded &&
      handleRemoveGuideFromInitiallyExpanded(props.guideId);

    setIsCardExpanded(isExpanded);
  };

  const createBuyButtonHandler = () => {
    return (
      e: React.MouseEvent<HTMLElement>,
      isInCart: boolean,
      item: CartItemFrontend
    ) => {
      e.stopPropagation();
      isInCart
        ? dispatch(cartActions.setPopoverVisibility(true))
        : dispatch(cartActions.requestAddItem(item));
    };
  };

  const mapCardToCartHandlingProps = (cardProps: typeof props) => {
    const {
      itemUid,
      guideId,
      guideName,
      guideIcon,
      guidePrice,
      guideDiscountAmount,
      guideDiscountPercent,
      guidePromoText,
      guideType,
      guideHasOpenButton,
      shopifyVariantId
    } = cardProps;
    // TODO: Also Check is in cart from shopify

    // const isInCart = !!itemsInCart?.hasOwnProperty(guideId); // To Do: get it from props, not inside the component
    var isInCart = false;

    if (shopifyCart.lineItems !== null && shopifyCart.lineItems !== undefined){
      shopifyCart.lineItems.forEach(lineItem => {
        if (lineItem.variant !== null && lineItem.variant !== undefined) {
          if (lineItem.variant.id === shopifyVariantId){
            isInCart = true;
          }
        }
      });
    }
    const isInProgress = !!itemsBeingAddedToCart?.hasOwnProperty(guideId); // To Do: get it from props, not inside the component
    const cartItem: CartItemFrontend = {
      shopifyVariantId,
      itemUid: itemUid || 'itemUid not found',
      guideId,
      guideName: guideName || 'Ooops...',
      guideIcon: guideIcon || '',
      guidePrice: guidePrice ?? 0,
      guidePromoText: guidePromoText || '',
      guideDiscountAmount: guideDiscountAmount || 0,
      guideDiscountPercent: guideDiscountPercent || 0,
      guideType: guideType || '',
      guideHasOpenButton: guideHasOpenButton,
    };
    const handleAddToCart = createBuyButtonHandler();

    return {
      isInCart,
      isInProgress,
      onAddToCart: (e: React.MouseEvent<HTMLElement>) =>
        handleAddToCart(e, isInCart, cartItem),
    };
  };

  const toGuideView = (guideName: string) => {
    dispatch(actions.setIsDetailsDisplaying(true));
    const guideViewPath = `/guides/${encodeURIComponent(guideName)}`;
    if (location.pathname !== guideViewPath) {
      history.push(guideViewPath, { prevPath: location.pathname });
    }
  };

  const handleGoToParentGuideView = (guideName: string) => {
    dispatch(actions.setIsDetailsDisplaying(true));
    history.push(`/guides/${encodeURIComponent(guideName)}`);
  };

  const handleOpenCard = (guideName: string) => {
    onOpen && onOpen(guideName);
  };

  useEffect(() => {
    isInitiallyExpanded && setIsCardExpanded(true);
  }, []);

  return (
    <S.TrailCardContainer shape={shape}>
      <div onClick={() => toGuideView(props.guideName)}>
        <Card
          isOnSale={isOnSale}
          {...props}
          {...mapCardToCartHandlingProps(props)}
          isCardExpanded={isCardExpanded}
          hasChildren={hasChildren}
          nestedCards={nestedCards}
          shape={shape}
          onExpand={onExpandCard}
          onOpen={
            props.guideHasOpenButton && onOpen
              ? () => handleOpenCard(props.guideName)
              : null
          }
          handleGoToParentGuideView={handleGoToParentGuideView}
          openButtonTitle={openButtonTitle}
        />
      </div>
      {isCardExpanded &&
        (nestedCards ? (
          trail.map(({ x, ...rest }, index) => {
            return (
              <S.NestedCardWrapper
                key={nestedCards[index].guideId}
                onClick={() => toGuideView(nestedCards[index].guideName)}
                className="trails-text"
                style={{
                  ...rest,
                  transform: (x as any).interpolate(
                    (x: any) => `translate3d(0,${x}px,0)`
                  ),
                }}
              >
                <Card
                  isOnSale={isOnSale}
                  {...nestedCards[index]}
                  isCardExpanded={isCardExpanded}
                  guidePrice={nestedCards[index]?.guidePrice?.final}
                  guidePriceCurrency={nestedCards[index]?.guidePrice?.currencyCode}
                  {...mapCardToCartHandlingProps({
                    ...nestedCards[index],
                    guidePrice: nestedCards[index]?.guidePrice?.final,
                  })}
                  guideLength={{
                    distance: nestedCards[index].guideLength[distanceUnit.name],
                    shortName: distanceUnit.shortName,
                  }}
                  shape="bumpy"
                  onOpen={() => handleOpenCard(nestedCards[index].guideName)}
                  handleGoToParentGuideView={handleGoToParentGuideView}
                />
              </S.NestedCardWrapper>
            );
          })
        ) : (
          <S.LoadingSpinnerWrapper>
            <LoadingSpinner size="large" />
          </S.LoadingSpinnerWrapper>
        ))}
    </S.TrailCardContainer>
  );
};

export const LoadedCard: React.FC<AntCardProps> = props => {
  return (
    <StyledLoadedCard {...props}>
      <Skeleton loading avatar active>
        <Skeleton.Avatar shape="square" size="large" />
      </Skeleton>
    </StyledLoadedCard>
  );
};
