import styled from 'styled-components';

export const S = {
  ItemWrapper: styled.div`
    max-width: 260px;
    padding: 0 5px;
  `,

  CarouselWrapper: styled.div`
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    z-index: 1001;

    && .slick-slide {
      display: flex;
      justify-content: center;
    }
  `,
};
