import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const S = {
  Modal: styled(AntModal)`
    & .ant-modal-content {
      max-width: 100%;
      margin: 0 auto;
      width: 400px;
      overflow: hidden;
      border-radius: ${({theme}) => theme.mapSettingsModal.dimensions.borderRadius}px;
      overflow-y: auto;
      min-height: 200px;
    }

    & .ant-modal-body {
      padding: 0;
    }
  `,
  Container: styled.div`
    width: 100%;
    position: absolute;
    overflow-y: auto;
    background: ${({ theme }) => theme.guideViewDetail.colors.background};
  `,
  Body: styled.div`
    position: relative;
    width: 100%;
  `,
  Header: styled.div`
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
  `,
  Title: styled.span`
    font-size: 21px;
    font-weight: bold;
  `,
  Section: styled.div`
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
  `,
  SectionTitle: styled.span`
    padding: 10px 0;
    font-size: 13px;
    color: ${({ theme }) => theme.mapSettingsModal.colors.textSectionTitle};
    text-transform: uppercase;
  `,
  Option: styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  `,
  OptionName: styled.span``,
  OptionControl: styled.div`
    display: flex;
  `,
  ValueName: styled.span`
    padding-right: 10px;
    color: ${({ theme }) => theme.mapSettingsModal.colors.textValueName};
    text-transform: capitalize;
  `,
  Control: styled.div``,
};
