import { forwardRef, memo } from 'react';
import isEqual from 'lodash.isequal';

import { StyledCarousel } from './Carousel.styles';
import { CarouselProps } from './Carousel.interface';
const CarouselComponent: React.FC<CarouselProps> = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <StyledCarousel {...props} ref={ref}>
        {children}
      </StyledCarousel>
    );
  }
);

export const Carousel = memo(CarouselComponent, isEqual);
