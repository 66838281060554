import { fetcher } from '../../configure'

type ID = string
export type SetShopifyCartAttributesRequest = {
	cartId: ID | 'testing'
  	parseUserId: ID,
	avantLinkHash?: string,
	countryCode: string | null	
}

export type SetShopifyCartAttributesResponse = {
	id?: string
}

export type SetShopifyCartAttributesEndpoint = (
	request: SetShopifyCartAttributesRequest
) => Promise<SetShopifyCartAttributesResponse>

export const setShopifyCartAttributes: SetShopifyCartAttributesEndpoint = async (request) => {
	console.log("set-shopify-cart-attributes : ", request);

	return await fetcher({
		method: 'put',
		path: `/shopify/cart/set`,
		body: request,
	})
}
