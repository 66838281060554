import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';
import { BaseButton } from '../../controls/BaseButton/BaseButton';
import MarkerClusterGroup from 'react-leaflet-markercluster';

export interface ContainerProps {
  isMobile: boolean;
}

// @ TODO refactor to theme
const MARKER_WIDTH = 30;
const MARKER_HEIGHT = 40;

export const S = {
  MapContainer: styled(MapContainer)`
    height: 100%;
    width: 100%;
  `,
  Container: styled.div<ContainerProps>`
    position: ${({ isMobile }) => (isMobile ? 'absolute' : 'relative')};
    top: 0;
    width: 100%;
    height: 100%;
  `,
  MarkerContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ color }) => `#${color}`};
    width: ${MARKER_WIDTH}px;
    height: ${MARKER_HEIGHT}px;
    transform: translate(-${MARKER_WIDTH * 0.3}px, -${MARKER_HEIGHT * 0.7}px);

    && svg {
      width: ${MARKER_WIDTH}px;
      height: ${MARKER_HEIGHT}px;
    }
  `,
  ZoomButton: styled(BaseButton)`
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    font-weight: bold;
    border-radius: 50px;
    margin: 25px 0;
  `,
  ButtonsWrapper: styled.div`
    position: absolute;
    top: 50%;
    z-index: 2000;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `,
  ClusterContainer: styled.div`
    position: relative;
  `,
  CustomMarkerContainer: styled.div<{ index: number }>`
    position: absolute;
    left: ${props => props.index * -3}px;
  `,
  MarkerGroup: styled(MarkerClusterGroup as any)``,
};

const Marker = styled.div``;

const Cluster = styled.div`
  display: inline;
  background-color: ${({ theme }) =>
    theme.guideUseMap.colors.backgroundCluster};
  height: 80px;
  width: 80px;
  color: ${({ theme }) => theme.guideUseMap.colors.textCluster};
`;

const CustomMarker = styled.div`
  && svg {
    width: ${MARKER_WIDTH}px;
    height: ${MARKER_HEIGHT}px;
  }
`;

export const CustomMarkerClass = `${CustomMarker}`;

export const MarkerClass = `${Marker}`;
export const ClusterClass = `${Cluster}`;

export const getClusterInlineStyles = (orderIndex: number) => {
  return [
    'position: absolute',
    'top: 0',
    `width: ${MARKER_WIDTH}px`,
    `height: ${MARKER_HEIGHT}px`,
    `left: ${orderIndex * -3}px`,
    'display: flex',
    'align-items: flex-end',
    'justify-content: center',
  ].join(';');
};
