import { memo, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import L, { LayerGroup as NativeLayerGroup } from 'leaflet';
import { TileLayer, LayerGroup, ZoomControl } from 'react-leaflet';
import { actions } from 'store/TrailsStore/actions';

import { S } from './CommonMap.styles';
import {
  CommonMapViewProps,
  AllPreviousCoordsState,
} from 'containers/CommonMap/CommonMap.interfaces';
import { useDefaultStateForMapInstance } from '../../hooks/useDefaultStateForMapInstance';

const CommonMapComponent: React.FC<CommonMapViewProps> = ({
  isMobile,
  selectedGuideId,
  allPreviousCoordinates,
  handleSetMap,
  handleGoToGuideView,
}) => {
  const dispatch = useDispatch();
  const mainLayerGroupRef: React.Ref<NativeLayerGroup> = useRef(null);
  const { handleWhenCreatedMap } = useDefaultStateForMapInstance(handleSetMap);

  useEffect(() => {
    if (mainLayerGroupRef.current) {
      const mainLG: any = mainLayerGroupRef?.current;
      const layersGroupList = mainLG.getLayers();

      layersGroupList
        ?.find((layer: any) => layer.options.ownTrailId === selectedGuideId)
        ?.bringToFront();
    }
  }, [selectedGuideId, allPreviousCoordinates]);

  return (
    <S.Container isMobile={isMobile}>
      <S.MapContainer
        center={[0, 0]}
        maxBoundsViscosity={1}
        maxBounds={[
          [-90, -200],
          [90, 200],
        ]}
        zoom={2}
        scrollWheelZoom
        zoomControl={false}
        attributionControl={true}
        whenCreated={handleWhenCreatedMap}
        maxZoom={9}
        minZoom={2}
        preferCanvas
        renderer={L.canvas({ padding: 1, tolerance: 5 })}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayerGroup ref={mainLayerGroupRef}>
          {allPreviousCoordinates.length > 0 &&
            allPreviousCoordinates.map(
              (trail: AllPreviousCoordsState) =>
                trail.coords && (
                  <S.Polyline
                    positions={trail.coords}
                    key={trail.id}
                    ownTrailId={trail.id}
                    selectedGuideId={selectedGuideId}
                    eventHandlers={{
                      click: isMobile
                        ? () =>
                            dispatch(actions.setSelectedTrailGuideId(trail.id))
                        : () => handleGoToGuideView(trail.name),
                    }}
                  />
                )
            )}
        </LayerGroup>
        {!isMobile && <ZoomControl position="bottomright" />}
      </S.MapContainer>
    </S.Container>
  );
};

export const CommonMap = memo(CommonMapComponent, isEqual);
