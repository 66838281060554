import styled from 'styled-components';
import { Table } from 'antd';

export const S = {
  Table: styled(Table)`
    width: 100%;
    max-height: 450px;
    overflow-y: auto;

    & .ant-table-thead .ant-table-cell {
      width: 25%;
      background-color: rgb(198, 198, 198);
    }

    & .ant-table-tbody > tr > td {
      border-bottom: none;
    }
  `,
  BodyContainer: styled.div`
    flex-grow: 1;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Header: styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    padding-top: 10px;
  `,
  EmailUsTip: styled.span`
    margin: 25px 0 0;
    text-align: center;
  `,
  EmailAnchor: styled.a``,
  InnerContainer: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  GuideName: styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.purchasedReceipts.colors.guideNameText};

    &:hover {
      color: ${({ theme }) =>
        theme.purchasedReceipts.colors.guideNameTextHover};
    }
  `,
};
