import { useState, useEffect, SetStateAction } from "react";

import breakpoints from "configs/breakpoints.json";

const matcher = (breakpoint: string) => window.matchMedia(breakpoint);
const match = (breakpoint: string) => matcher(breakpoint).matches;

export const useViewportSize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(match(breakpoints.mobile));
  const [isTablet, setIsTablet] = useState<boolean>(match(breakpoints.tablet));

  useEffect(() => {
    const makeHandler = (setState: React.Dispatch<SetStateAction<boolean>>) => {
      return (event: MediaQueryListEvent) => setState(event.matches);
    };
    const [mobileHandler, tabletHandler] = [setIsMobile, setIsTablet].map(
      makeHandler
    );

    matcher(breakpoints.mobile).addEventListener("change", mobileHandler);
    matcher(breakpoints.tablet).addEventListener("change", tabletHandler);

    return () => {
      matcher(breakpoints.mobile).removeEventListener("change", mobileHandler);
      matcher(breakpoints.tablet).removeEventListener("change", tabletHandler);
    };
  }, []);

  return { isMobile, isTablet };
};
