import styled from 'styled-components';
import {
  AttributesInterface,
  ContainerProps,
  IconInterface,
} from './Arrow.interfaces';
import * as React from 'react';

export const S = {
  Container: styled.span<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.collapseArrow.colors.default};
    font-size: ${({ theme, sizes = 'medium' }) =>
      theme.collapseArrow.sizes[sizes]};
    cursor: pointer;
  `,
};

export const generateStyledIcon = (
  SVGIcon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>
) => {
  return styled(SVGIcon).attrs(
    ({ theme, color, width, height }: AttributesInterface) => ({
      fill: color || theme.collapseArrow.colors.default,
      width: width || '1em',
      height: height || '1em',
    })
  )<IconInterface>``;
};
