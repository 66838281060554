import { List, Skeleton } from 'antd';
import { memo, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { SkeletonListProps } from './SkeletonCommentsList.interfaces';
import { S } from './SkeletonCommentsList.styles';

const SkeletonCommentsListComponent: React.FC<SkeletonListProps> = ({
  listItemsCount,
  paragraphWidth = 200,
  paragraphRows = 1,
  titleWidth = 240,
}) => {
  const listItems = useMemo(
    () => new Array(listItemsCount).fill(0),
    [listItemsCount]
  );

  return (
    <S.SkeletonContainer>
      <List
        dataSource={listItems}
        renderItem={() => (
          <List.Item>
            <Skeleton
              active
              loading={true}
              paragraph={{ rows: paragraphRows, width: paragraphWidth }}
              title={{ width: titleWidth }}
            />
          </List.Item>
        )}
      />
    </S.SkeletonContainer>
  );
};

export const SkeletonCommentsList = memo(
  SkeletonCommentsListComponent,
  isEqual
);
