import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { DC, DialogCard } from 'shared/DialogCard/DialogCard.styles';
import { CreateAccountForm } from '../CreateAccountForm/CreateAccountForm';
import { Link } from 'controls/Link/Link';
import { S as AuthPageWrapperStyles } from 'views/AuthPageWrapper/AuthPageWrapper.styles';
import { CardCreateAccountProps } from './CardCreateAccount.interfaces';
import { FormattedMessage } from 'react-intl';

const CardCreateAccountViewComponent: React.FC<CardCreateAccountProps> = ({
  handleCreateAccountSubmit,
}) => {
  return (
    <AuthPageWrapperStyles.ContentContainer>
      <DialogCard>
        <DC.WithMargins>
          <DC.TitleSection>
            <DC.Title><FormattedMessage id="create_account"/></DC.Title>
            <DC.TitleText><FormattedMessage id="create_account_info"/></DC.TitleText>
          </DC.TitleSection>
          <DC.BodySection>
            <CreateAccountForm handleSubmit={handleCreateAccountSubmit} />
          </DC.BodySection>
          <DC.FooterSection>
            <DC.FooterText><FormattedMessage id="signup_to_signin"/></DC.FooterText>{' '}
            <Link to="/login" type="primary">
              <FormattedMessage id="login"/>
            </Link>
          </DC.FooterSection>
        </DC.WithMargins>
      </DialogCard>
    </AuthPageWrapperStyles.ContentContainer>
  );
};

export const CardCreateAccountView = memo(
  CardCreateAccountViewComponent,
  isEqual
);
