import { cartReducer, CartState } from './CartStore';
import { userReducer, UserState } from './UserStore';
import * as fromTrailsStore from './TrailsStore/reducer';
import * as fromGuideUse from './GuideUse/reducer';
import * as fromCommon from './Common/reducer';

export interface AppState {
  common: fromCommon.State;
  trailStore: fromTrailsStore.State;
  guideUse: fromGuideUse.State;
  cartState: CartState;
  userState: UserState;
}
const rootReducer = {
  common: fromCommon.reducer,
  trailStore: fromTrailsStore.reducer,
  guideUse: fromGuideUse.reducer,
  cartState: cartReducer,
  userState: userReducer,
};

export default rootReducer;
