import styled from 'styled-components';
import { HintContainerProps } from './HintPopover.interfaces';

export const S = {
  Hint: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.hintPopover.colors.background};
    text-align: center;
  `,
  HintLabel: styled.span`
    color: ${({ theme }) => theme.hintPopover.colors.default};
    font-size: 10px;
    cursor: pointer;
  `,
  HintContentContainer: styled.div<HintContainerProps>`
    display: flex;
    flex-direction: column;
    padding: ${({ padding }) => padding ?? 15}px;
    max-width: ${({ width }) => width || 270}px;
  `,
  HintContentHeader: styled.span`
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
  `,
  HintContentText: styled.span`
    font-size: 14px;
    text-align: center;
  `,
};
