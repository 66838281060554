import styled from 'styled-components';

export const S = {
  Container: styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    overflow-y: auto;
    background: ${({ theme }) => theme.guideViewDetail.colors.background};
  `,
  ImageContainer: styled.img.attrs(({ src }) => ({
    src,
  }))`
    width: 100%;
    height: 100%;
    max-height: 300px;
    background: ${({ theme }) =>
      theme.guideViewDetail.colors.backgroundImageContainer};
    position: relative;
  `,
  ContentContainer: styled.div`
    position: relative;
    width: 100%;
  `,
  Content: styled.div`
    position: relative;
    width: 90%;
    margin: -70px auto 10px auto;
    background: ${({ theme }) =>
      theme.guideViewDetail.colors.backgroundContent};
    border-radius: 10px;
    box-shadow: 0 0 15px 0px lightgray;
  `,
  IconView: styled.img.attrs(({ src }: any) => ({
    src,
  }))`
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    transform: translateY(-50%);
    border: 3px solid
      ${({ theme }) => theme.guideViewDetail.colors.backgroundContent};
    border-radius: 10px;
  `,
  BackButtonWrapper: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
  `,
};
