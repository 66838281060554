import styled from 'styled-components';

import { ReactComponent as GuthookGuidesLogo } from 'static/logo_farout_white.svg';
import { ReactComponent as GuthookGuidesIcon } from 'static/Logo_Guthook_Icon_Only.svg';
import { APPLICATION_HEADER_HEIGHT } from '../../constants/common/layoutSizes';

interface HeaderLogoContainerProps {
  isMobileLayout: boolean;
}

export const S = {
  Container: styled.header`
    width: 100%;
    height: ${APPLICATION_HEADER_HEIGHT}px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0px 30px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.header.colors.background};
    display: flex;
    align-items: center;
    justify-content: stretch;
  `,

  LogoContainer: styled.div`
    flex-grow: 0;
    flex-basis: ${({ isMobileLayout }: HeaderLogoContainerProps) =>
      isMobileLayout ? '49px' : '325px'};

    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,

  GuthookGuidesLogo: styled(GuthookGuidesLogo)<{ width?: string }>`
    width: ${({ width = '100%' }) => width};
  `,
  GuthookGuidesIcon: styled(GuthookGuidesIcon)`
    width: 100%;
  `,

  EmptySpace: styled.div`
    flex-grow: 9999;
  `,
};
