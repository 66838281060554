import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Formik, Form } from 'formik';

import { InputFormik as Input } from 'controls-formik/Input/InputFormik';

import { BaseButton } from 'controls/BaseButton/BaseButton';
import { forgotPasswordSchema } from 'configs/formsValidationSchemas';
import { FormikErrorMessage } from 'shared/FormikErrorMessage/FormikErrorMessage';

import { S } from './ForgotPasswordForm.styles';
import {
  ForgotPasswordFormValues,
  ForgotPasswordFormViewProps,
} from './ForgotPasswordForm.interfaces';

const initialValues: ForgotPasswordFormValues = {
  email: '',
  errorField: '', // dummy field for displaying a single error for all fields
};

const ForgotPasswordFormComponent: React.FC<ForgotPasswordFormViewProps> = ({
  handleSubmit,
}) => {
  return (
    <S.Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input name="email" placeholder="Email" autoComplete="email" />
            <FormikErrorMessage name="email" />
            <FormikErrorMessage name="errorField" />
            <S.ButtonContainer>
              <BaseButton
                type="primary"
                label="Reset password"
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </S.ButtonContainer>
          </Form>
        )}
      </Formik>
    </S.Layout>
  );
};

export const ForgotPasswordForm = memo(ForgotPasswordFormComponent, isEqual);
