import { fetcher } from '../../configure'
import { GuideGeo } from './dto/guide-geo.dto'

export type GetGuideGeoRequest = {
    guideId: string
}

export type GetGuideGeoResponse = GuideGeo

export type GetGuideEndpoint = (
    request: GetGuideGeoRequest
) => Promise<GetGuideGeoResponse>

export const getGuideGeo: GetGuideEndpoint = async (
    request
): Promise<GetGuideGeoResponse> => {
    let response = await fetcher({
        method: 'get',
        path: `/guides/${request.guideId}/geo`,
        headers: {
            'Accept-Encoding': 'gzip'
        }        
    })

    return response
}
