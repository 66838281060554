import styled from 'styled-components';
import ImgCrop from 'antd-img-crop';
import { ReactComponent as EditIcon } from '../../static/edit.svg';
import { Upload } from 'antd';

export const S = {
  StyledImgCrop: styled(ImgCrop)`
    z-index: 4000;

    & .ant-modal-wrap {
      z-index: 4000;
    }

    & .antd-img-crop-modal {
      z-index: 4000;
    }
  `,
  Upload: styled(Upload)`
    width: 100%;

    & > .ant-upload,
    & > .ant-upload > span {
      width: 100%;
    }
  `,
};
