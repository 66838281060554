import { IntlShape } from 'react-intl';

export class PluralLocalizator {

    private static resolvePluralString(intl: IntlShape, pluralBaseId : string, pluralSuffix: string){
        const finalId = pluralBaseId + "." + pluralSuffix
        return intl.formatMessage({id: finalId})
    }

    public static formatPlural(intl: IntlShape, value: number, pluralTemplateId : string, pluralBaseId: string){
        return intl.formatMessage({id: pluralTemplateId}, {
                num: value, 
                zero: PluralLocalizator.resolvePluralString(intl, pluralBaseId, "zero"), 
                one: PluralLocalizator.resolvePluralString(intl, pluralBaseId, "one"), 
                few: PluralLocalizator.resolvePluralString(intl, pluralBaseId, "few"),  
                other: PluralLocalizator.resolvePluralString(intl, pluralBaseId, "other")})
    }
}