import styled, { css } from 'styled-components';
import { Button as AntdButton } from 'antd';
import { BaseButtonProps } from './BaseButton.interface';

const resetAntdButtonStyles = css`
  height: unset;
  padding: 0;
  border: none;
  box-shadow: none;
  line-height: inherit;
  font-size: inherit;

  &:after {
    animation: 0s;
  }
`;

export const StyledButton: React.FC = styled(
  ({ fullwidth, square, type, ...props }: BaseButtonProps) => (
    <AntdButton {...props} />
  )
)`
  border-radius: ${({ square }) => !square && '7px'};
  height: ${({ height }) => height && `${height}px`};
  width: ${({ fullwidth }) => fullwidth && '100%'};
  background: ${({ theme, type, background }) =>
    background || theme.buttons[type].colors.background};
  border-color: ${({ theme, type, border }) =>
    border || theme.buttons[type].colors.border};
  color: ${({ theme, type }) => theme.buttons[type].colors.text};
  ${({ type }) => type === 'text' && 'border: none; box-shadow: none'};

  ${({ type }) =>
    (type === 'invisible' || type === 'link') && resetAntdButtonStyles}

  &:hover {
    background: ${({ theme, type }) =>
      theme.buttons[type].colors.backgroundHover};

    border-color: ${({ theme, type }) =>
      theme.buttons[type].colors.borderHover};

    color: ${({ theme, type }) => theme.buttons[type].colors.textHover};
  }

  &:focus {
    background: ${({ theme, type }) =>
      theme.buttons[type].colors.backgroundHover};

    border-color: ${({ theme, type }) =>
      theme.buttons[type].colors.borderHover};

    color: ${({ theme, type }) => theme.buttons[type].colors.textHover};
  }

  &:active {
    background: ${({ theme, type }) =>
      theme.buttons[type].colors.backgroundActive};

    border-color: ${({ theme, type }) =>
      theme.buttons[type].colors.borderActive};

    color: ${({ theme, type }) => theme.buttons[type].colors.textActive};
  }

  &.ant-btn-icon-only {
    ${resetAntdButtonStyles}
  }
`;
