import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Carousel } from 'shared/Carousel/Carousel';

import { S } from './PictureCarousel.styles';
import { PictureCarouselProps } from './PictureCarousel.interface';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';

const PictureCarouselComponent: React.FC<PictureCarouselProps> = ({
  picturesView,
  height,
  isLoading = false,
  borderRadius,
}) => {
  return (
    <S.CarouselContainer borderRadius={borderRadius}>
      <Carousel
        slidesToShow={1}
        draggable={true}
        swipeToSlide={true}
        touchThreshold={50}
        focusOnSelect={true}
        dots={false}
        height={height}
      >
        {isLoading ? (
          <LoadingOverlay relative={true} />
        ) : (
          <>
            {picturesView?.map(picture => (
              <S.ImageContainer src={picture} key={picture} />
            ))}
          </>
        )}
      </Carousel>
    </S.CarouselContainer>
  );
};

export const PictureCarousel = memo(PictureCarouselComponent, isEqual);
