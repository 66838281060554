import { memo } from "react";
import isEqual from "deep-equal";
import { DrawerView } from "views/Drawer/Drawer";

import { DrawerContainerProps } from "./Drawer.interfaces";

const DrawerContainerComponent: React.FC<DrawerContainerProps> = ({
  isUserLoggedIn,
  isVisible,
  handleClose,
}) => {
  return (
    <DrawerView
      isVisible={isVisible}
      handelClose={handleClose}
      isUserLoggedIn={isUserLoggedIn}
    />
  );
};

export const DrawerContainer = memo(DrawerContainerComponent, isEqual);
