import { fetcher } from '../../configure'
import {ShopifyCartDto} from './dto/cart.dto'

export type AddCartItemRequest = {
    shopifyVariantId: string
    shopifyCartId: string
	countryCode: string | null    
}

export type AddCartItemResponse = ShopifyCartDto

export type AddCartEndpoint = (
    request: AddCartItemRequest
) => Promise<ShopifyCartDto>

export const addCartItem: AddCartEndpoint = async (
    request
): Promise<ShopifyCartDto> => {
    const shopifyCartId = request.shopifyCartId
    return await fetcher({
      method: 'post',
      path: `/shopify/cart/add`,
      body: request,
    })
}
