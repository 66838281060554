import { fetcher } from '../../configure'

export type UpdateUserAttributesRequest = {
	isMarketingAllowed: boolean
}

export type UpdateUserAttributesResponse = void

export type UpdateUserAttributesEndpoint = (
	request: UpdateUserAttributesRequest
) => Promise<UpdateUserAttributesResponse>

export const updateUserAttributes: UpdateUserAttributesEndpoint = async (request): Promise<UpdateUserAttributesResponse> => {
	return await fetcher({
		method: 'post',
		path: '/users/update-attributes',
		body: request,
	})
}
