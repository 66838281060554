import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { ActivityTypeProps } from '../Filter.interface';
import { S } from './ActivityType.styles';
import { FormattedMessage  } from 'react-intl';

const ActivityTypeComponent: React.FC<ActivityTypeProps> = ({
  filterParamsState: { activityType: activityTypeParams },
  selectedFilters,
  handleSetSelectedFilters,
}) => {
  const { activityType } = selectedFilters;

  const handleChange = (tag: string, isChecked: boolean) => {
    handleSetSelectedFilters({
      sectionName: 'activityType',
      value: isChecked
        ? activityType
          ? [...activityType, tag]
          : [tag]
        : activityType?.filter((item: string) => item !== tag),
    });
  };

  return (
    <S.Container>
      {(activityTypeParams?.sort((a, b) => (a < b ? -1 : 1)) || []).map(
        ({ tag }) => (
          <S.StyledCheckableTag
            key={tag}
            checked={activityType ? activityType.includes(tag) : false}
            onChange={checked => handleChange(tag, checked)}
          >
            <FormattedMessage id={tag.toLowerCase()}/>
          </S.StyledCheckableTag>
        )
      )}
    </S.Container>
  );
};

export const ActivityType = memo(ActivityTypeComponent, isEqual);
