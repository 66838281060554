import { fetcher } from '../../configure'
import { BaseResponse } from "../../common/base-response"
import { SubscriptionInfo } from "./subscriptions.types"

export type GetActiveSubscriptionResponse = BaseResponse & {
    subscription: SubscriptionInfo
}

export type GetActiveSubscriptionEndpoint = (
) => Promise<GetActiveSubscriptionResponse>

export const getActiveSubscription: GetActiveSubscriptionEndpoint = async (
): Promise<GetActiveSubscriptionResponse> => {
    return await fetcher({
        method: 'get',
        path: `/payment/active-subscription`
    })
}
