import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LatLngBoundsLiteral, Map } from 'leaflet';
import isEqual from 'lodash.isequal';
import { CommonMap as CommonMapView } from 'views/CommonMap/CommonMap';
import {
  getSelectedTrailGuideIdSelector,
  getIsFilteredStateSelector,
  getIsSearchedStateSelector,
} from 'store/TrailsStore/selectors';

import {
  CommonMapContainerProps,
  AllPreviousCoordsState,
  Guide,
} from 'containers/CommonMap/CommonMap.interfaces';

const CommonMapComponent: React.FC<CommonMapContainerProps> = ({
  trailsList,
  isMobile,
  handleGoToGuideView,
}) => {
  const selectedGuideId = useSelector(getSelectedTrailGuideIdSelector);
  const isFiltering = useSelector(getIsFilteredStateSelector);
  const isSearching = useSelector(getIsSearchedStateSelector);
  const [allPreviousCoordinates, setAllPreviousCoordinates] = useState<
    AllPreviousCoordsState[]
  >([]);
  const [map, setMap] = useState<Map>();

  const handleSetMap = useCallback((map: Map) => {
    setMap(map);
  }, []);

  const handleFitBounds = useCallback(
    (bounds: LatLngBoundsLiteral | null) => {
      if (map) {
        bounds
          ? map.flyToBounds(bounds, {
              duration: 1,
            })
          : map.flyToBounds(
              [
                [-90, -200],
                [90, 200],
              ],
              {
                duration: 1,
              }
            );
      }
    },
    [map]
  );

  const countFoundGuidesBounds = useCallback(
    (guideList: AllPreviousCoordsState[]) => {
      const resultWithBounds = guideList.filter(item => item.bounds);

      const resultBounds: LatLngBoundsLiteral | null =
        resultWithBounds.length > 0
          ? [
              [
                Math.min(...resultWithBounds.map(item => item.bounds[0][0])),
                Math.min(...resultWithBounds.map(item => item.bounds[0][1])),
              ],
              [
                Math.max(...resultWithBounds.map(item => item.bounds[1][0])),
                Math.max(...resultWithBounds.map(item => item.bounds[1][1])),
              ],
            ]
          : null;

      resultBounds && map?.fitBounds(resultBounds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFitBounds]
  );

  useEffect(() => {
    setAllPreviousCoordinates(
      trailsList.map((trail: Guide) => ({
        name: trail.guideName,
        id: trail.guideId,
        coords: trail.guidePreviewCoordinates.coordinates,
        bounds: trail.guideBounds,
      }))
    );
  }, [trailsList]);

  useEffect(() => {
    const guideBounds: LatLngBoundsLiteral | null =
      (allPreviousCoordinates.length &&
        allPreviousCoordinates.find(
          (item: AllPreviousCoordsState) => item.id === selectedGuideId
        )?.bounds) ||
      null;

    handleFitBounds(guideBounds);
  }, [allPreviousCoordinates, selectedGuideId, handleFitBounds]);

  useEffect(() => {
    (isFiltering || isSearching) &&
      countFoundGuidesBounds(allPreviousCoordinates);
  }, [
    isFiltering,
    isSearching,
    allPreviousCoordinates,
    countFoundGuidesBounds,
  ]);

  return (
    <CommonMapView
      isMobile={isMobile}
      allPreviousCoordinates={allPreviousCoordinates}
      selectedGuideId={selectedGuideId}
      handleSetMap={handleSetMap}
      handleGoToGuideView={handleGoToGuideView}
    />
  );
};

export const CommonMap = memo(CommonMapComponent, isEqual);
