import styled from 'styled-components';
import { Collapse } from 'antd';

export const S = {
  Header: styled.header`
    min-height: 60px;
    padding: 15px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.filter.colors.sectionBorder};
  `,

  HeaderTitle: styled.span`
    font-weight: bold;
  `,

  Collapse: styled(Collapse)`
    width: 100%;
    padding: 0 15px;
    flex: 1;
    overflow-y: auto;

    && .ant-collapse-header {
      color: ${({ theme }) => theme.filter.colors.sectionTitleText};
      font-size: 0.7rem;
      text-transform: uppercase;
    }
  `,
  Panel: styled(Collapse.Panel)`
    display: flex;
    flex-direction: column;

    && {
      border-bottom: 1px solid
        ${({ theme }) => theme.filter.colors.sectionBorder};
    }

    &&& .ant-collapse-content-box {
      padding-top: 0;
    }
  `,
  HeaderTopWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  HeaderBottomWrapper: styled.div`
    padding: 0 10px;
    display: flex;
    flex-direction: row-reverse;

    & .ant-btn {
      font-size: 12px;
    }
  `,
  Container: styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1002;
    background: ${({ theme }) => theme.filter.colors.backgroundContainer};
  `,
};
