import styled from 'styled-components';
import { getStylePropertiesByFloatPlacement } from '../../utils/getStylePropertiesByFloatPlacement';
import { BaseButton } from '../../controls/BaseButton/BaseButton';
import { FloatPlacement } from '../../commonTypes/enums';

interface ZoomButtonsWrapperStyle {
  isAbsolute?: boolean;
  floatButtonsPlacement: FloatPlacement;
  zIndex?: number;
}

export const S = {
  ButtonsWrapper: styled.div<ZoomButtonsWrapperStyle>`
    position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'relative')};
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${({ floatButtonsPlacement, isAbsolute }) => {
      if (!isAbsolute) {
        return '';
      }
      const properties = getStylePropertiesByFloatPlacement(
        floatButtonsPlacement
      );

      return `
        top: ${properties.top};
        bottom: ${properties.bottom};
        right: ${properties.right};
        left: ${properties.left};
      `;
    }}

    z-index: ${({ zIndex = 400 }) => zIndex};
  `,
  ZoomButton: styled(BaseButton)<{ extraTop?: number }>`
    position: relative;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    font-weight: bold;
    border-radius: 50px;
    margin: 25px 0;

    && span {
      position: absolute;
      top: calc(50% + ${({ extraTop }) => extraTop || 0}px);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  `,
};
