import styled from 'styled-components';
import { Spin } from 'antd';

import { LoadingSpinnerProps } from './LoadingSpinner.interface';

export const StyledLoadingSpinner =
  styled(Spin) <
  LoadingSpinnerProps >
  `
  color: ${({ theme }) => theme.loadingSpinner.colors.primary};
`;
