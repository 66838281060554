import { memo } from 'react';
import isEqual from 'lodash.isequal';

import { DC, DialogCard } from 'shared/DialogCard/DialogCard.styles';
import { LogInForm } from '../LogInForm/LogInForm';
import { Link } from 'controls/Link/Link';
import { S as AuthPageWrapperStyles } from 'views/AuthPageWrapper/AuthPageWrapper.styles';
import { CardSignInProps } from './CardSignIn.interfaces';
import { S } from './CardSignIn.styles';
import { FormattedMessage } from 'react-intl';

const CardSignInViewComponent: React.FC<CardSignInProps> = ({
  handleSignInSubmit,
}) => {
  return (
    <AuthPageWrapperStyles.ContentContainer>
      <DialogCard>
        <DC.WithMargins>
          <DC.TitleSection>
            <DC.Title><FormattedMessage id="login"/></DC.Title>
            <DC.TitleText><FormattedMessage id="login_info"/></DC.TitleText>
          </DC.TitleSection>
          <DC.BodySection>
            <LogInForm handleSubmit={handleSignInSubmit} />
          </DC.BodySection>
          <DC.FooterSection>
            <S.FooterTextWrap>
              <DC.FooterText><FormattedMessage id="go_signup_text"/></DC.FooterText>{' '}
              <Link to="/create-account" type="primary">
                <FormattedMessage id="go_signup">
                    {(text) => {
                      return <>{text.toString().toUpperCase()}</>;
                    }}
                </FormattedMessage>
              </Link>
            </S.FooterTextWrap>
            {/* <S.ForgotPasswordTextWrap>
              <DC.FooterText>Forgot your password?</DC.FooterText>{' '}
              <Link to="/forgot" type="primary">
                Reset password
              </Link>
            </S.ForgotPasswordTextWrap> */}
          </DC.FooterSection>
        </DC.WithMargins>
      </DialogCard>
    </AuthPageWrapperStyles.ContentContainer>
  );
};

export const CardSignInView = memo(CardSignInViewComponent, isEqual);
