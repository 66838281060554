import { Env } from "../env";

export const devEnv: Env = {
    backendUrl: 'https://90ymmhs3xh.execute-api.us-east-1.amazonaws.com/dev',
    cognito: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_5uE4pT4ed',
        userPoolWebClientId: '3jsfnb1ppl64orjtbln1bbbj1n',
    }
}
