import { memo } from 'react';
import { S as CommonStyles } from '../Arrow.styles';
import { S } from './LeftArrow.styles';
import { useTheme } from 'styled-components';
import { ArrowProps } from '../Arrow.interfaces';

const LeftArrowComponent: React.FC<ArrowProps> = ({
  sizes,
  color,
  onClick,
}) => {
  return (
    <CommonStyles.Container onClick={onClick} sizes={sizes}>
      <S.LeftArrowIcon color={color} />
    </CommonStyles.Container>
  );
};

export const LeftArrow = memo(LeftArrowComponent);
