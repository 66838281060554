import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetUpdatedProfileRequestPayload,
  GetUserProfileRequestPayload,
  UserState,
} from './user-profile.payload';
import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { UpdateUserProfilePhotoRequest } from '../../atlasguides-web-common/src/functions/users/update-user-profile-photo';
import { GuidePurchaseReceipt, UserPrimaryInfo, UserProfileWithPurchases } from '../../atlasguides-web-common/src/functions/users/dto/user-profile.dto';
export * from './user-profile.payload';

const initialState: UserState = {
  isLoading: false,
  userProfile: null,
  isUploadingProfileImage: false,
  isUploadingCoverImage: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserProfileRequest: (
      state,
      action: PayloadAction<GetUserProfileRequestPayload>
    ) => {
      state.isLoading = true;
    },
    getUserProfileResponse: (
      state,
      action: PayloadAction<UserProfileWithPurchases>
    ) => {
      state.userProfile = action.payload;
      state.isLoading = false;
    },
    getUserProfileFail: (state, action: PayloadAction<void>) => {
      state.isLoading = false;
    },
    clearUserProfile: (state, action: PayloadAction<void>) => {
      state.userProfile = null;
    },
    getUpdatedProfileRequest: (
      state,
      action: PayloadAction<GetUpdatedProfileRequestPayload>
    ) => state,
    getUpdatedProfileResponse: (
      state,
      action: PayloadAction<Partial<UserPrimaryInfo>>
    ) => {
      if (state.userProfile) {
        state.userProfile.primaryInfo = {
          ...state.userProfile.primaryInfo,
          ...action.payload,
        };
      }
      state.isLoading = false;
    },
    updateUserImageProfileRequest: (
      state,
      action: PayloadAction<UpdateUserProfilePhotoRequest>
    ) => {
      state.isUploadingProfileImage = true;
    },
    updateUserImageProfile: (
      state,
      action: PayloadAction<{ imageUrl: string }>
    ) => {
      if (state.userProfile) {
        state.userProfile.photos.profilePhotoUrl = action.payload.imageUrl;
        state.isUploadingProfileImage = false;
      }
    },
    updateUserImageProfileFail: (state, action: PayloadAction<void>) => {
      state.isUploadingProfileImage = false;
    },
    updateUserCoverImageRequest: (
      state,
      action: PayloadAction<UpdateUserProfilePhotoRequest>
    ) => {
      state.isUploadingCoverImage = true;
    },
    updateUserCoverImage: (
      state,
      action: PayloadAction<{ imageUrl: string }>
    ) => {
      if (state.userProfile) {
        state.userProfile.photos.profileCoverImageUrl = action.payload.imageUrl;
        state.isUploadingCoverImage = false;
      }
    },
    updateUserCoverImageFail: (state, action: PayloadAction<void>) => {
      state.isUploadingCoverImage = false;
    },
    addPurchasedItems: (
      state,
      action: PayloadAction<{ purchasedItems: GuidePurchaseReceipt[] }>
    ) => {
      if (state.userProfile) {
        state.userProfile.purchases = [
          ...action.payload.purchasedItems,
          ...(state.userProfile?.purchases || []),
        ];
      }
    },
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

const getUserStoreState = (state: AppState): UserState => state.userState;
export const selectUserProfileBasicInfo = (state: AppState) =>
  state.userState.userProfile;

export const selectUserProfile = createSelector(
  getUserStoreState,
  state => state.userProfile
);

export const selectUserLoadingState = createSelector(
  getUserStoreState,
  state => state.isLoading
);
