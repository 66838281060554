import { fork } from 'redux-saga/effects';

import trailStoreSaga from './TrailsStore/saga';
import guideUseSaga from './GuideUse/saga';
import { cartStoreSaga } from './CartStore/saga';
import { userStoreSaga } from './UserStore/saga';

export default function* rootSaga(): Generator {
  yield fork(trailStoreSaga);
  yield fork(guideUseSaga);
  yield fork(cartStoreSaga);
  yield fork(userStoreSaga);
}
