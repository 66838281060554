import Auth from '@aws-amplify/auth';
import { APICognitoRequestsManager } from 'services/APIRequestsManagers/APICognitoRequestsManager';
import {
  parseCognitoEmailSignUpError,
  parseCognitoSignInError,
} from 'adapters/APICognitoAuth/apiCognitoAuthAdapters';
import { CognitoError } from "adapters/APICognitoAuth/apiCognitoAuthAdapters.interfaces";
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  AuthMethod,
  AuthServiceInterface,
  GeneralAuthServiceInterface,
  OAuthEmailSignInCredentials,
  OAuthEmailSignUpCredentials,
  SetIsSignedIn,
  CognitoAuthServiceType,
} from 'services/AuthManager/AuthManager.interfaces';
import { cognitoConfig } from 'configs/cognitoConfig';
import { isLocalInstance } from 'configs/env';
import { PostSignupRequest, postSignup } from 'atlasguides-web-common/src/functions/users/post-signup';
import { updateUserAttributes } from 'atlasguides-web-common/src/functions/users/update-attributes';

export class CognitoAuthService implements GeneralAuthServiceInterface {
  public type: CognitoAuthServiceType = 'cognito';
  private static instance: AuthServiceInterface | null = null;
  private user: null | CognitoUser = null;
  private tokensAutoRefreshTimeout: number | null = null;
  private isSignedIn: boolean = false;
  private subscribersOnIsSignedIn: Set<SetIsSignedIn> = new Set();
  private START_REFRESH_TOKENS_BEFORE_EXPIRE_MS = 30000;
  private ACCESS_TOKEN_EXPIRATION_SAFETY_MARGIN_MS = 3000;
  private SERVICE_ERROR_PREFIX = 'CognitoAuthServiceError';

  private constructor() {
    Auth.configure(cognitoConfig);
  }

  public static getInstance() {
    if (!CognitoAuthService.instance) {
      CognitoAuthService.instance = new CognitoAuthService();
    }
    return CognitoAuthService.instance;
  }

  private notifySubscribersOnIsSignedIn(isSignedIn: boolean) {
    this.subscribersOnIsSignedIn.forEach(setIsSignedInCallback =>
      setIsSignedInCallback(isSignedIn)
    );
  }

  private setIsSignedIn(isSignedIn: boolean) {
    if (this.isSignedIn !== isSignedIn) {
      this.isSignedIn = isSignedIn;
      this.notifySubscribersOnIsSignedIn(this.isSignedIn);
    }
  }

  private async setTokensAutorefreshBeforeExpire() {
    const isAccessExpiredOrDoesntExist =
      this.isAccessTokenExpiredOrDoesntExist();
    if (isAccessExpiredOrDoesntExist) {
      await this.refreshTokensOrSignOut();
      if (this.isSignedIn) await this.setTokensAutorefreshBeforeExpire();
      return;
    }
    const accessTokenExpiresInMs = this.getAccessTokenExpiresInMs();
    accessTokenExpiresInMs &&
      this.setTokensAutoRefreshTimeout(accessTokenExpiresInMs);
  }

  private async refreshTokensOrSignOut() {
    return new Promise<void>((resolve, reject) => {
      const user = this.user;
      const refreshToken = this.getRefreshToken();
      if (!user || !refreshToken) {
        reject();
        return;
      }
      user.refreshSession(refreshToken, (error, result) => {
        if (error) {
          reject();
        } else {
          resolve();
          return null;
        }
      });
    }).catch(() => {
      this.signOutCurrentUser();
    });
  }

  private setTokensAutoRefreshTimeout(ms: number) {
    this.tokensAutoRefreshTimeout = window.setTimeout(async () => {
      await this.refreshTokensOrSignOut();
      await this.setTokensAutorefreshBeforeExpire();
      return;
    }, ms);
  }

  private cancelTokensAutorefreshTimeout() {
    if (this.tokensAutoRefreshTimeout) {
      window.clearTimeout(this.tokensAutoRefreshTimeout);
      this.tokensAutoRefreshTimeout = null;
    }
  }

  private getAccessTokenExpiresInMs() {
    const accessToken = this.getAccessTokenFromCognito();
    const accessTokenExpirationUNIX = accessToken?.getExpiration();
    return accessTokenExpirationUNIX
      ? this.getExpiresInMs(
          accessTokenExpirationUNIX * 1000,
          this.START_REFRESH_TOKENS_BEFORE_EXPIRE_MS
        )
      : undefined;
  }

  private getAccessTokenFromCognito() {
    return this.user?.getSignInUserSession()?.getAccessToken();
  }

  private getRefreshToken() {
    return this.user?.getSignInUserSession()?.getRefreshToken();
  }

  private isAccessTokenExpiredOrDoesntExist() {
    const token = this.getAccessTokenFromCognito();
    return token
      ? this.checkIfExpired(
          token.getExpiration(),
          this.ACCESS_TOKEN_EXPIRATION_SAFETY_MARGIN_MS
        )
      : true;
  }

  private checkIfExpired(
    UNIX_expirationDatetime: number,
    safetyMargin: number = 0
  ) {
    const expired =
      this.getExpiresInMs(UNIX_expirationDatetime * 1000, safetyMargin) <= 0;
    return expired;
  }

  private getExpiresInMs(expirationDatetime: number, safetyMargin: number = 0) {
    return expirationDatetime - Date.now() - safetyMargin;
  }

  private async retrieveLastLoggedInUserIfSessionStillValid() {
    let user: CognitoUser | undefined | null = null;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.warn('CognitoAuth: No valid user session to restore.');
    }
    if (user) this.signInUser(user);
  }

  private async signInUser(user: CognitoUser | undefined) {
    if (!user) {
      throw new Error('failed to sign in');
    }
    this.user = user;
    this.setIsSignedIn(true);
    await this.setTokensAutorefreshBeforeExpire();
  }

  private signOutCurrentUser() {
    this.user = null;
    this.setIsSignedIn(false);
    this.cancelTokensAutorefreshTimeout();
  }

  public async initialize() {
    await this.retrieveLastLoggedInUserIfSessionStillValid();
  }

  public async signUp(
    method: AuthMethod,
    credentials?: OAuthEmailSignUpCredentials
  ) {
    try {
      if (method === 'email' && credentials) {
        const {
          displayName,
          email,
          username,
          password,
          //isMarketingAllowed,
          isTermsOfUseAccepted,
        } = credentials;
        const request = {
            username,
            password,
            attributes: {
            email,
            'custom:displayName': displayName,
            //'custom:isMarketingAllowed': `${isMarketingAllowed}`,
            'custom:isAgreementAccepted': `${isTermsOfUseAccepted}`,
          },
        };

        const response = await APICognitoRequestsManager.getInstance().signUpWithEmailAndPassword(request);
        this.user = response.user;

        if (this.user){

          const parseUser: PostSignupRequest = { displayName, username, password, email }
          await postSignup(parseUser)

          // Sign In
          await APICognitoRequestsManager.getInstance().signInWithEmailAndPass({
            username,
            password,
          });
          const user = await Auth.currentAuthenticatedUser();
  
          await this.signInUser(user);

          await updateUserAttributes({isMarketingAllowed: credentials.isMarketingAllowed})
        }
      }
    } catch (error) {
      let cognitoError = error as CognitoError;
      return {
        method,
        result: 'error' as 'error',
        fieldsErrors: parseCognitoEmailSignUpError(cognitoError),
        errorMessage: cognitoError.message,
      };
    }
  }

  public async signIn(
    method: AuthMethod,
    credentials?: OAuthEmailSignInCredentials
  ) {
    try {
      if (method === 'email' && credentials) {
        //await PrivateApi.checkUserCredentials({
        //  username: credentials.username,
        //  password: credentials.password,
        //})
        await APICognitoRequestsManager.getInstance().signInWithEmailAndPass({
          username: credentials.username,
          password: credentials.password,
        });
        const user = await Auth.currentAuthenticatedUser();

        if (credentials.isMarketingAllowed === undefined || credentials.isMarketingAllowed == null){
           credentials.isMarketingAllowed = false
        }

        // await Auth.updateUserAttributes(user, {
        //   'custom:isMarketingAllowed': `${credentials.isMarketingAllowed}`,
        // });
        await this.signInUser(user);

        await updateUserAttributes({isMarketingAllowed: credentials.isMarketingAllowed})
      }
    } catch (error) {
      let cognitoError = error as CognitoError;
      return {
        method,
        result: 'error' as 'error',
        errorMessage: parseCognitoSignInError(cognitoError),
      };
    }
  }

  public async getAccessToken() {
    const user = this.user;
    const accessToken = user
      ?.getSignInUserSession()
      ?.getAccessToken()
      .getJwtToken();

    if (!user) {
      throw new Error(
        `${this.SERVICE_ERROR_PREFIX}: attempted to request access token but no valid user existed.`
      );
    } else if (!accessToken) {
      throw new Error(
        `${this.SERVICE_ERROR_PREFIX}: attempted to request access token but no no token found;
        The error might occur when requesting access token when the user is not signed in`
      );
    }

    return accessToken;
  }

  public async signOut() {
    if (this.user) {
      this.user.signOut();
      this.signOutCurrentUser();
    }
  }

  public async confirmEmail(username: string, code: string) {
    try {
      await APICognitoRequestsManager.getInstance().confirmEmail(
        username,
        code
      );
    } catch (error) {
      let cognitoError = error as CognitoError;
      return {
        method: 'email' as 'email',
        result: 'error' as 'error',
        errorMessage: `${cognitoError.message}`,
      };
    }
  }

  public async resendConfirmationCode(username: string) {
    try {
      await APICognitoRequestsManager.getInstance().resendConfirmationCode(
        username
      );
    } catch (error) {
      let cognitoError = error as CognitoError;
      return {
        method: 'email' as 'email',
        result: 'error' as 'error',
        errorMessage: `${cognitoError.message}`,
      };
    }
  }

  public subscribeOnIsSignedIn(setIsSignedIn: SetIsSignedIn) {
    this.subscribersOnIsSignedIn.add(setIsSignedIn);
  }

  public unsubscribeFromIsSignedIn(setIsSignedIn: SetIsSignedIn) {
    this.subscribersOnIsSignedIn.delete(setIsSignedIn);
  }
}
