import styled from 'styled-components';
import { CheckableTag } from 'controls/Tag/Tag';

interface CheckbleTagProps {
  checked: boolean;
}

export const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
  `,
  StyledCheckableTag: styled(CheckableTag)<CheckbleTagProps>`
    display: block;
    padding: 7px 0;
    text-align: center;

    :not(:last-child) {
      margin-bottom: 15px;
    }
  `,
};
