import { fetcher } from '../../configure'
import { GuideDto } from './dto/guide.dto'

export enum GuideFilterType {
	REGION = 'Region',
	ACITVITY_TYPE = 'Activity Type',
	ENVIRONMENT = 'Environment/Terrain',
	OTHER = 'OTHER',
}

export type GuideFilter = {
	type: GuideFilterType
}

export type GuideFilters = {
	guideName?: string
	minLength: number
	maxLength: number
	environment: string[]
	region: string[]
	activityType: string[]
	other: string[]
}

export type GetGuidesResponse = {
	filters: GuideFilters
	guides: GuideDto[]
	//shopGuides: any
}

export type GetGuidesRequest = {
	filters?: Partial<GuideFilters>
    countryCode?: string | null
}

export type GetGuidesEndpoint = (
	request?: GetGuidesRequest
) => Promise<GetGuidesResponse>

export const getGuides: GetGuidesEndpoint = async (request) => {
	//console.log("GET GUIDES REQUEST: ", request);
	const main = await fetcher({ method: 'get', path: '/guides/', queryParams: { 
		filters: request?.filters, 
		countryCode: request?.countryCode 
	} })
	//console.log("MAIN: ", main);
	return main
}
