import styled from 'styled-components';
import { theme } from 'theme';
import { CartIsEmptyMessageSize } from './CartIsEmptyMessage.interface';

type ContainerProps = {
  fitFullContainerSize: boolean;
};
type CartIsEmptyHeaderProps = {
  size: CartIsEmptyMessageSize;
};
type AddGuidesPromptProps = {
  size: CartIsEmptyMessageSize;
};

export const S = {
  Container: styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ fitFullContainerSize }) =>
      fitFullContainerSize
        ? `
      justify-content: center;
      height: 100%; 
      width: 100%;
      `
        : `max-width: 200px;`}
  `,
  CartIsEmptyHeader: styled.h5<CartIsEmptyHeaderProps>`
    font-size: ${({ size }) => {
      switch (size) {
        case 'small':
          return '20px';
        case 'large':
          return '35px';
      }
    }};
    font-weight: ${({ size, theme }) => {
      switch (size) {
        case 'small':
          return 'normal';
        case 'large':
          return 'bold';
      }
    }};
    color: ${({ size }) => {
      switch (size) {
        case 'small':
          return 'unset';
        case 'large':
          return theme.cart.colors.cartIsEmptyMessage;
      }
    }};
    text-align: center;
  `,
  AddGuidesPrompt: styled.p<AddGuidesPromptProps>`
    font-style: italic;
    text-align: center;
    font-size: ${({ size }) => {
      switch (size) {
        case 'small':
          return '15px';
        case 'large':
          return '20px';
      }
    }};
  `,
};
