import { AnyAction } from 'redux';
import { LatLngTuple } from 'leaflet'
import { Awaited } from 'global_types';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { actions } from 'store/TrailsStore/actions';
import { getArrayDepth } from 'utils/getArrayDepth';
import { swipeCoords } from 'utils/swipeTrailCoords';
import { getGuides, GetGuidesEndpoint, GetGuidesRequest, GetGuidesResponse } from 'atlasguides-web-common/src/functions/guides/get-guides';
import { getGuide, GetGuideEndpoint } from 'atlasguides-web-common/src/functions/guides/get-guide';
import ReactPixel from 'react-facebook-pixel';
import { GetActiveSubscriptionEndpoint, getActiveSubscription } from 'atlasguides-web-common/src/functions/subscriptions/get-subscription-info';
import { AvailableCountriesEndpoint, AvailableCountriesResponse, getAvailableCountries } from 'atlasguides-web-common/src/functions/payment/get-available-countries';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { getResultCountryCode } from 'utils/geoUtils';


function* getActiveSubscriptionInfoSaga(): Generator<
  unknown,
  void,
  Awaited<ReturnType<GetActiveSubscriptionEndpoint>>
> {
  try {
    const res = yield call<GetActiveSubscriptionEndpoint>(getActiveSubscription);
    yield put(
      actions.getActiveSubscriptionResponse({
        subscriptionInfo: res.subscription
      })
    );
  } catch (error) {
    console.log('failed getActiveSubscriptionInfoSaga: ', error);
  }
}

function* getAllTrailsListSaga(): any{
  try {

    var resCountryCode = yield call(getResultCountryCode)
    const getGuidesRequest:GetGuidesRequest = {countryCode: resCountryCode}

    const [res, countriesResponse] = yield all([
      call<GetGuidesEndpoint>(getGuides, getGuidesRequest),
      call<AvailableCountriesEndpoint>(getAvailableCountries)
    ])

    yield put(
      actions.getAllTrailsListResponse(
        (res as GetGuidesResponse).guides.map(trail => ({
          ...trail,
          guidePreviewCoordinates: {
            ...trail.guidePreviewCoordinates,
            coordinates: swipeCoords(
              trail.guidePreviewCoordinates?.coordinates as LatLngTuple[]
            ),
          },
        }))
      )
    );
    yield put(
      actions.getGuidesFilterParamsResponse(
        res.filters
      )
    );

    // Check if already selected country is available 
    if (countriesResponse){

      const localStorage = LocalStorageManager.getInstance();
      const selectedCountryCode: string | null = localStorage.getItem('shopifyCountry');
  
      if (selectedCountryCode){
        let found: boolean = false;
        for (let country of (countriesResponse as AvailableCountriesResponse).countries) {
          if (selectedCountryCode === country.code){
            found = true;
            break;
          }
        };

        if (!found){
          localStorage.removeItem('shopifyCountry');
        }
      }

      yield put(actions.setAvailableCountries(countriesResponse.countries));      
    }

  } catch (error) {
    // yield put(actions.getAllTrailsListResponseFail(error.message))
    console.log('failed getAllTrailsListSaga: ', error);
  }
}

function* getTrailGuideViewSaga({
  payload,
}: AnyAction): Generator<unknown, void, Awaited<ReturnType<GetGuideEndpoint>>> {
  try {
    console.log("getTrailGuideViewSaga: ", payload);

    var resCountryCode = (yield call(getResultCountryCode)) as unknown as string 

    const request = {guideId: payload, countryCode: resCountryCode}
    const res = yield call(getGuide, request);
    yield put(
      actions.getTrailGuideViewResponse({
        ...res,
        guidePreviewCoordinates: {
          ...res.guidePreviewCoordinates,
          coordinates:
            getArrayDepth(res.guidePreviewCoordinates?.coordinates) > 2
              ? (res.guidePreviewCoordinates?.coordinates as LatLngTuple[][]).map((coordPlenty: LatLngTuple[]) => swipeCoords(coordPlenty))
              : swipeCoords(
                res.guidePreviewCoordinates?.coordinates as LatLngTuple[]
              ),
        },
      })
    );

    ReactPixel.track('ViewContent', {
      content_name: res.guideName,
      content_category: 'Guide',
      content_ids: [res.guideId],
      content_type: 'product',
      value: res.guidePrice?.final || '$0.00',
      currency: 'USD'
    });
  } catch (error) {
    // yield put(actions.getAllTrailsListResponseFail(error.message))
    console.log('failed getTrailGuideViewSaga: ', error);
  }
}

function* getCardChildrenByIdSaga({
  payload,
}: AnyAction): Generator<unknown, void, Awaited<ReturnType<GetGuideEndpoint>>> {
  try {
    const res = yield call(getGuide, {guideId: payload});
    yield put(
      actions.getCardChildrenByIdResponse({
        id: res.guideId,
        childGuides: res.childGuides,
      })
    );
  } catch (error) {
    // yield put(actions.getAllTrailsListResponseFail(error.message))
    console.log('failed getCardChildrenByIdSaga: ', error);
  }
}

export default function* saga(): Generator {
  yield takeLatest('@/GET_ACTIVE_SUBSCRIPTION_REQUEST', getActiveSubscriptionInfoSaga);
  yield takeLatest('@/GET_ALL_TRAILS_LIST_REQUEST', getAllTrailsListSaga);
  yield takeLatest('@/GET_TRAIL_GUIDE_VIEW_REQUEST', getTrailGuideViewSaga);
  yield takeLatest('@/GET_CARD_CHILDREN_BY_ID_REQUEST', getCardChildrenByIdSaga);
}
