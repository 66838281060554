import { Menu } from 'antd';
import styled from 'styled-components';
import 'antd/dist/antd.css'; // REMOVE this after the tree shaking is set up

export const S = {
  IconsMenu: styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    flex-grow: 0;
    flex-shrink: 0;
    border-right: 0;

    & svg {
      margin-left: 5px;
      margin-right: 5px;

      transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      fill: ${({ theme }) => theme.header.colors.iconOutline};
    }

    & svg:hover {
      cursor: pointer;
      fill: ${({ theme }) => theme.header.colors.iconOutlineHover};
    }
  `,

  LoggedInMenu: styled(Menu)`
    border: 0;

    & > .ant-menu-item a:hover {
      color: ${({ theme }) => theme.header.colors.subMenuItemTextHover};
    }
  `,
};
