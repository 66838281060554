import { env } from "./env";

// possible options
// export interface AuthOptions {
//   userPoolId?: string;
//   userPoolWebClientId?: string;
//   identityPoolId?: string;
//   region?: string;
//   mandatorySignIn?: boolean;
//   cookieStorage?: ICookieStorageData;
//   oauth?: OAuthOpts;
//   refreshHandlers?: object;
//   storage?: ICognitoStorage;
//   authenticationFlowType?: string;
//   identityPoolRegion?: string;
//   clientMetadata?: any;
//   endpoint?: string;
// }

export const cognitoConfig = {
  region: env.cognito.region,
  userPoolId: env.cognito.userPoolId,
  userPoolWebClientId: env.cognito.userPoolWebClientId,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
};


