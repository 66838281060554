import React, { memo, useCallback, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { Formik } from 'formik';
import { S } from '../ProfileSettingsForm/ProfileSettingsForm.styles';
import { BaseButton } from '../../controls/BaseButton/BaseButton';
import { InputFormik } from '../../controls-formik/Input/InputFormik';
import {
  ProfilePrimaryInfoFormNames,
  ProfilePrimaryInfoFormValues,
  OnChangePublicProfileSwitch,
  ProfilePrimaryInfoFormInterfaces,
} from './ProfilePrimaryInfoForm.interfaces';
import { FormikErrorMessage } from '../../shared/FormikErrorMessage/FormikErrorMessage';
import { HintPopover } from '../../shared/HintPopover/HintPopover';
import { SwitchButton } from '../../controls/SwitchButton/SwitchButton';
import { updateProfileSchema } from '../../configs/formsValidationSchemas';
import { FormattedMessage, useIntl } from 'react-intl';

const ProfilePrimaryInfoFormViewComponent: React.FC<ProfilePrimaryInfoFormInterfaces> =
  ({ handleSubmit, initialValues, isAbsoluteSubmitButton = false }) => {
    const profileSettingsInitialValues: ProfilePrimaryInfoFormValues = useMemo(
      () => ({
        bio: '',
        coverPhoto: '',
        displayName: '',
        firstName: '',
        lastName: '',
        profilePhoto: '',
        username: '',
        isPublicProfile: false,
      }),
      []
    );

    const onChangePublicProfileSwitch =
      useCallback<OnChangePublicProfileSwitch>(({ checked, setFieldValue }) => {
        setFieldValue(ProfilePrimaryInfoFormNames.isPublicProfile, checked);
      }, []);

    let intl = useIntl()
    return (
      <S.Layout>
        <Formik
          initialValues={initialValues || profileSettingsInitialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          enableReinitialize={false}
          validationSchema={updateProfileSchema}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <S.FormikForm>
              <InputFormik
                name={ProfilePrimaryInfoFormNames.displayName}
                placeholder={intl.formatMessage({id:"display_name"})}
                topLabel={intl.formatMessage({id:"display_name"})}
              />
              <FormikErrorMessage
                name={ProfilePrimaryInfoFormNames.displayName}
              />
              <InputFormik
                name={ProfilePrimaryInfoFormNames.username}
                placeholder={intl.formatMessage({id:"username"})}
                topLabel={intl.formatMessage({id:"username"})}
                disabled
              />
              <FormikErrorMessage name={ProfilePrimaryInfoFormNames.username} />
              <InputFormik
                name={ProfilePrimaryInfoFormNames.firstName}
                placeholder={intl.formatMessage({id:"first_name"})}
                topLabel={intl.formatMessage({id:"first_name"})}
              />
              <FormikErrorMessage
                name={ProfilePrimaryInfoFormNames.firstName}
              />
              <InputFormik
                name={ProfilePrimaryInfoFormNames.lastName}
                placeholder={intl.formatMessage({id:"last_name"})}
                topLabel={intl.formatMessage({id:"last_name"})}
              />
              <FormikErrorMessage name={ProfilePrimaryInfoFormNames.lastName} />
              <InputFormik
                name={ProfilePrimaryInfoFormNames.bio}
                placeholder={intl.formatMessage({id:"bio"})}
                maxLength={120}
                topLabel={intl.formatMessage({id:"bio"})}
                hasCharactersCounter
              />
              <FormikErrorMessage name={ProfilePrimaryInfoFormNames.bio} />
              <S.MakePublicProfileContainer>
                <S.MakePublicProfileText>
                  <FormattedMessage id="make_profile_public"/>
                  <HintPopover
                    hintText={intl.formatMessage({id:"make_profile_public_info"})}
                    containerPadding={5}
                    customContent={
                      <S.HintContainer>
                        <S.HintText>?</S.HintText>
                      </S.HintContainer>
                    }
                  />
                </S.MakePublicProfileText>
                <SwitchButton
                  checked={values.isPublicProfile}
                  onChange={checked =>
                    onChangePublicProfileSwitch({
                      checked,
                      setFieldValue,
                    })
                  }
                />
              </S.MakePublicProfileContainer>
              <S.SaveButtonContainer
                isAbsolutePosition={isAbsoluteSubmitButton}
              >
                <BaseButton
                  type="primary"
                  label={intl.formatMessage({id:"save"})}
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </S.SaveButtonContainer>
            </S.FormikForm>
          )}
        </Formik>
      </S.Layout>
    );
  };

export const ProfilePrimaryInfoFormView = memo(
  ProfilePrimaryInfoFormViewComponent,
  isEqual
);
