import { fetcher } from '../../configure'
import { ShopifyCartDto } from '../cart/dto/cart.dto'

type ID = string
export type GetShopifyCartRequest = {
	id?: ID | 'testing'
	countryCode: string | null
}

export type GetShopifyCartResponse = ShopifyCartDto

export type GetShopifyCartEndpoint = (
	request: GetShopifyCartRequest
) => Promise<GetShopifyCartResponse>

export const getShopifyCart: GetShopifyCartEndpoint = async (request) => {
	//console.log("get-shopify-cart : ", request);

	let id = ""
	if (request.id){
		id = request.id
	}

	const idBase64 = encodeURIComponent(Buffer.from(id).toString('base64'))

	return await fetcher({
		method: 'get',
		path: `/shopify/cart/${idBase64}`,
        queryParams: { 
            countryCode: request.countryCode
        },		
		body: request,
	})
}
