import { useCallback, useEffect, useState } from 'react';

export const useViewportDimensions = () => {
  const [height, setHeight] = useState<number>(window.innerHeight);

  const updateDimensions = useCallback(() => {
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  return { height };
};
