import { modalGlobalConfig } from 'antd/lib/modal/confirm';
import { DefaultTheme } from 'styled-components';

const mainThemeColors = {
  white: '#ffffff',
  black: '#000000',
  blackText: '#2e2e2e',
  gray: '#808080',
  slightGray: '#f5f5f5',
  superLightGray: '#efefef',
  lightGray: '#d3d3d3',
  titleGray: '#808284',
  smokyGray: '#828282',
  darkGray: '#555555',
  iconGray: '#c0bfbf',
  themePrimary: '#DC1C50',
  themeOrange: '#C24629',
  themeYellow: '#DE9434',
  themeDarkBlue: '#016076',
  themeSpruce: '#01838F',
  themeTeal: '#00776F',
  themeAqua: '#6DBCB6',
  themePurple: '#9589B2',
  primaryGreen: '#00828f',
  hoverPrimaryGreen: '#004f57',
  activePrimaryGreen: '#2fa84f',
  palePrimaryGreen: '#99cdd2',
  hoverPrimaryBlue: '#1890ff',
  hoverPrimaryBlueTransparent: 'rgb(24 144 255 / 20%)',
  errorTextRed: '#cc0000',
  primaryOrange: '#e25c19',
  red: '#ff0000',
  warnRed: '#f24726',
  crusoeTransparent: 'rgba(34,60,8,0.3)',
  wheat: '#f5deb3',
  sinbad: '#B2D2D5',
  darkCyan: '#00828F',
  signalGray: 'rgb(150, 150, 150)',
  hoverSignalGray: 'rgb(110, 110, 110)',
  subscriptions: '#d5923e'
};

const mainThemeDimensions = {
  modalBorderRadius: 7,
  optionsPanelBorderRadius: 7,
  optionsPanelCollapseItemBorderRadius: '0 0 7px 7px',
  mapSettingsModalBorderRadius: 7,
  layersModalBorderRadius: 7,
  elevationProfileBorderRadius: 7,
  roundedPopoverMenuBorderRadius: 7,
  roundedSubmenuPopupBorderRadius: 7
}

export const theme: DefaultTheme = {
  header: {
    colors: {
      background: mainThemeColors.themePrimary,
      text: mainThemeColors.white,
      iconOutline: mainThemeColors.white,
      iconOutlineHover: mainThemeColors.black,
      menuItemText: mainThemeColors.white,
      menuItemTextHover: mainThemeColors.black,
      subMenuItemText: mainThemeColors.themePrimary,
      subMenuItemTextHover: mainThemeColors.themePrimary,
    },
  },

  drawer: {
    colors: {
      background: mainThemeColors.themePrimary,
      text: mainThemeColors.white,
      menuItemBackground: mainThemeColors.themePrimary,
      menuItemBackgroundHover: mainThemeColors.themePrimary,
    },
  },

  buttons: {
    primary: {
      colors: {
        border: mainThemeColors.themeSpruce,
        borderHover: mainThemeColors.hoverPrimaryGreen,
        borderActive: mainThemeColors.activePrimaryGreen,

        background: mainThemeColors.themeSpruce,
        backgroundHover: mainThemeColors.hoverPrimaryGreen,
        backgroundActive: mainThemeColors.activePrimaryGreen,

        text: mainThemeColors.white,
        textHover: mainThemeColors.white,
        textActive: mainThemeColors.white,
      },
    },
    default: {
      colors: {
        border: mainThemeColors.themeSpruce,
        borderHover: mainThemeColors.themeSpruce,
        borderActive: mainThemeColors.activePrimaryGreen,

        background: mainThemeColors.white,
        backgroundHover: mainThemeColors.lightGray,
        backgroundActive: mainThemeColors.white,

        text: mainThemeColors.themeSpruce,
        textHover: mainThemeColors.themeSpruce,
        textActive: mainThemeColors.activePrimaryGreen,
      },
    },
    subscription: {
      colors: {
        border: mainThemeColors.subscriptions,
        borderHover: mainThemeColors.hoverPrimaryGreen,
        borderActive: mainThemeColors.activePrimaryGreen,

        background: mainThemeColors.subscriptions,
        backgroundHover: mainThemeColors.hoverPrimaryGreen,
        backgroundActive: mainThemeColors.activePrimaryGreen,

        text: mainThemeColors.white,
        textHover: mainThemeColors.white,
        textActive: mainThemeColors.white,
      },
    },    
    text: {
      colors: {
        text: mainThemeColors.themeSpruce,
        textHover: mainThemeColors.themeSpruce,
        textActive: mainThemeColors.activePrimaryGreen,
      },
    },
    invisible: {
      colors: {
        border: 'transparent',
        borderHover: 'transparent',
        borderActive: 'transparent',

        background: 'transparent',
        backgroundHover: mainThemeColors.slightGray,
        backgroundActive: 'transparent',

        text: mainThemeColors.black,
        textHover: mainThemeColors.black,
        textActive: mainThemeColors.black,
      },
    },

    link: {
      colors: {
        border: 'transparent',
        borderHover: 'transparent',
        borderActive: 'transparent',

        background: 'transparent',
        backgroundHover: 'transparent',
        backgroundActive: 'transparent',

        text: mainThemeColors.themeSpruce,
        textHover: mainThemeColors.hoverPrimaryGreen,
        textActive: mainThemeColors.activePrimaryGreen,
      },
    },
    switch: {
      colors: {
        checkedBackground: mainThemeColors.themePrimary,
      },
    },
  },

  slider: {
    colors: {
      rail: mainThemeColors.palePrimaryGreen,
      track: mainThemeColors.themeSpruce,
      handle: mainThemeColors.themeSpruce,
    },
  },

  links: {
    primary: {
      colors: {
        color: mainThemeColors.themeSpruce,
        colorHover: mainThemeColors.hoverPrimaryGreen,
        colorActive: mainThemeColors.activePrimaryGreen,
        colorVisited: mainThemeColors.themeSpruce,
      },
    },
    light: {
      colors: {
        color: mainThemeColors.gray,
        colorHover: mainThemeColors.hoverPrimaryGreen,
        colorActive: mainThemeColors.hoverPrimaryGreen,
        colorVisited: mainThemeColors.gray,
      },
    },
    default: {
      colors: {
        color: mainThemeColors.black,
        colorHover: mainThemeColors.hoverPrimaryBlue,
        colorActive: mainThemeColors.hoverPrimaryBlueTransparent,
        colorVisited: mainThemeColors.black,
      },
    },
    undefined: {
      colors: {
        color: mainThemeColors.black,
        colorHover: mainThemeColors.hoverPrimaryBlue,
        colorActive: mainThemeColors.hoverPrimaryBlueTransparent,
        colorVisited: mainThemeColors.black,
      },
    },
  },

  radioButton: {
    colors: {
      border: mainThemeColors.themeSpruce,
      borderChecked: mainThemeColors.themeSpruce,

      background: mainThemeColors.white,
      backgroundChecked: mainThemeColors.themeSpruce,

      text: mainThemeColors.themeSpruce,
      textChecked: mainThemeColors.white,
    },
  },

  checkbox: {
    colors: {
      background: mainThemeColors.white,
      border: mainThemeColors.themeSpruce,
      checkmark: mainThemeColors.themeSpruce,
    },
  },

  searchInput: {
    colors: {
      text: mainThemeColors.smokyGray,
      placeholder: mainThemeColors.smokyGray,
      border: mainThemeColors.themeSpruce,
      reset: mainThemeColors.signalGray,
      resetHover: mainThemeColors.hoverSignalGray,
    },
  },

  tag: {
    colors: {
      default: {
        background: mainThemeColors.superLightGray,
        text: mainThemeColors.themeSpruce,
      },

      checkable: {
        border: mainThemeColors.themeSpruce,
        borderChecked: mainThemeColors.themeSpruce,

        background: mainThemeColors.white,
        backgroundChecked: mainThemeColors.themeSpruce,

        text: mainThemeColors.themeSpruce,
        textChecked: mainThemeColors.white,
      },
    },
  },

  filter: {
    colors: {
      sectionTitleText: mainThemeColors.darkGray,
      sectionBorder: mainThemeColors.superLightGray,

      backgroundContainer: mainThemeColors.white,
    },
  },

  trailsStore: {
    colors: {
      controlsPanel: {
        icon: mainThemeColors.iconGray,
        iconBackground: mainThemeColors.themeSpruce,
        backgroundCountFilters: mainThemeColors.red,
        
        textCountFilters: mainThemeColors.white,
      },
      commonMap: {
        backgoundZoomControl: mainThemeColors.themeYellow,
        textZoomButton: mainThemeColors.white,
      },
    },
  },

  trailCard: {
    colors: {
      titleText: '#2c2c2e',
      milesInfoText: mainThemeColors.gray,
      promoText: mainThemeColors.themeSpruce,
      promoPrice: mainThemeColors.black,
      costText: mainThemeColors.white,

      costBackground: '#efeff4',
      nestedCardBackground: '#e2e2e2',
      backgroundCloseCircle: mainThemeColors.lightGray,
      backgroundDefaultIconWrapper: mainThemeColors.gray,

      nestedCardBorder: '#c9c9c9',
    },
    sizes: {
      small: {
        height: '70px',
        padding: '5px',
        logoPadding: '5px',
        costPadding: '6px 0 0 0',
        templateColumns: '60px auto 80px',
        templateRows: '25px 20px 15px',
        columnGap: '5px',

        titleFont: '14px',
        titleLineHeight: '14px',
        promoFont: '11px',
        milesInfoFont: '10px',
        costFont: '12px',
      },
      default: {
        height: '110px',
        padding: '10px',
        logoPadding: '5px',
        costPadding: '8px 10px 0 0',
        templateColumns: '90px auto 80px',
        templateRows: '40px 25px 25px',
        columnGap: '10px',

        titleFont: '16px',
        titleLineHeight: '18px',
        promoFont: '14px',
        milesInfoFont: '13px',
        costFont: '14px',
      },
    },
    shape: {
      bumpy: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        borderRadius: '7px',
      },
      flat: {
        boxShadow: 'none',
        borderRadius: '0px',
      },
    },
  },

  dialogCard: {
    colors: {
      background: mainThemeColors.white,
      title: mainThemeColors.black,
      titleParagraph: mainThemeColors.black,
      footerText: mainThemeColors.black,
    },
  },

  baseTextInput: {
    colors: {
      border: mainThemeColors.black,
      borderHover: mainThemeColors.hoverPrimaryBlue,
      borderFocus: mainThemeColors.hoverPrimaryBlue,
      shadowFocus: mainThemeColors.hoverPrimaryBlueTransparent,
    },
  },

  formErrorMessage: {
    colors: {
      text: mainThemeColors.errorTextRed,
    },
  },

  formikInput: {
    colors: {
      topLabel: mainThemeColors.smokyGray,
      characterCounter: mainThemeColors.smokyGray,
    },
  },

  asyncStatusIcon: {
    colors: {
      spinner: mainThemeColors.themeSpruce,
      success: mainThemeColors.themeSpruce,
      error: mainThemeColors.errorTextRed,
    },
  },
  guideViewDetail: {
    colors: {
      background: mainThemeColors.white,
      backgroundImageContainer: mainThemeColors.gray,
      backgroundContent: mainThemeColors.white,
      backgroundPurchasedOption: mainThemeColors.superLightGray,
      backgroundFreeMark: mainThemeColors.red,
      backgroundOldPriceCross: mainThemeColors.red,

      iconParentInfo: mainThemeColors.themeSpruce,

      sectionTitle: mainThemeColors.gray,
      textPrice: mainThemeColors.blackText,
      textShortInfo: mainThemeColors.gray,
      textFreeMark: mainThemeColors.white,
      textLinkToDemo: mainThemeColors.themeSpruce,
      textOtherPurchaseOptionActualPrice: mainThemeColors.blackText,
      textOtherPurchaseOptionOldPrice: mainThemeColors.gray,
      textOtherPurchaseOptionTitle: mainThemeColors.gray,
      textDiscount: mainThemeColors.warnRed,
    },
  },

  sectionViewDetail: {
    colors: {
      sectionTitle: mainThemeColors.gray,
      textShortInfo: mainThemeColors.gray,
    },
  },

  backButton: {
    colors: {
      icon: mainThemeColors.themeSpruce,
    },
  },

  profilePage: {
    colors: {
      textDisplayedName: mainThemeColors.themeSpruce,
      textUserName: mainThemeColors.gray,
      textFollowersInfo: mainThemeColors.darkGray,
      textBio: mainThemeColors.blackText,
      textLogInSuggestion: mainThemeColors.gray,
      textLinkToPurchaseReceipts: mainThemeColors.blackText,
      textPopoverLink: mainThemeColors.themeSpruce,
      textPopoverLinkHover: mainThemeColors.hoverPrimaryGreen,
      closeButton: mainThemeColors.white,
      textIsProfilePublic: mainThemeColors.themeSpruce,
    },
    
  },

  modal: {
    dimensions: {
      modalBorderRadius: mainThemeDimensions.modalBorderRadius
    } 
  },

  profileSettingsForm: {
    colors: {
      hint: mainThemeColors.themeSpruce,
      checkedSwitchBackground: mainThemeColors.sinbad,
      circleSwitchBackground: mainThemeColors.darkCyan,
    },
  },

  checkout: {
    stepIndicator: {
      colors: {
        stepFinish: mainThemeColors.black,
        stepActive: mainThemeColors.themeSpruce,
        stepWait: mainThemeColors.darkGray,
        stepWaitHover: mainThemeColors.darkGray,
        stepFinishHover: mainThemeColors.hoverPrimaryGreen,
        line: mainThemeColors.slightGray,
      },
    },
    cartStep: {
      colors: {
        priceRowName: mainThemeColors.smokyGray,
        priceRowNameBold: mainThemeColors.black,
        priceRowAmount: mainThemeColors.black,
        priceRowAmountBold: mainThemeColors.themeSpruce,
        couponPromptText: mainThemeColors.lightGray,
        discountApplied: mainThemeColors.errorTextRed,
      },
    },
  },

  loadingSpinner: {
    colors: {
      primary: mainThemeColors.themePrimary,
    },
    sizes: {
      small: '24px',
      medium: '48px',
      large: '72px',
    },
  },

  LeafletPolyline: {
    colors: {
      default: mainThemeColors.themeYellow,
      selected: mainThemeColors.themeSpruce,
    },
  },

  collapseArrow: {
    sizes: {
      small: '18px',
      medium: '24px',
      large: '36px',
    },
    colors: {
      default: mainThemeColors.themeSpruce,
    },
  },

  pointDetails: {
    colors: {
      backgroundContainer: mainThemeColors.white,
      backgroundCloseButton: mainThemeColors.lightGray,
      backgroundSortInfoDivider: mainThemeColors.black,

      borderDetailSection: mainThemeColors.superLightGray,
      borderIcon: mainThemeColors.white,

      textShowOnSection: mainThemeColors.themeSpruce,
    },
  },

  cart: {
    colors: {
      cartIsEmptyMessage: mainThemeColors.themeSpruce,
      badgeBackground: mainThemeColors.primaryOrange,
      badgeText: mainThemeColors.white,
    },
  },

  purchasedReceipts: {
    colors: {
      guideNameText: mainThemeColors.themeSpruce,
      guideNameTextHover: mainThemeColors.hoverPrimaryGreen,
    },
  },

  hintPopover: {
    colors: {
      default: mainThemeColors.white,
      background: mainThemeColors.lightGray,
      hintHeader: mainThemeColors.black,
    },
  },

  helpButton: {
    colors: {
      anchor: mainThemeColors.black,
      icon: mainThemeColors.themeSpruce,
    },
  },

  layersModal: {
    colors: {
      borderLayerCell: mainThemeColors.themeSpruce,

      textLayerCell: mainThemeColors.white,

      backgroundContainer: mainThemeColors.white,
      backgroundLayerCell: mainThemeColors.black,
    },
    dimensions: {
      borderRadius: mainThemeDimensions.layersModalBorderRadius
    }
  },

  elevationProfile: {
    colors: {
      graphContainerBackground: mainThemeColors.white,
      tabExpandBackground: mainThemeColors.white,
    },
    shadow: {
      graphContainer: `0px 0px 10px 0px ${mainThemeColors.crusoeTransparent}`,
      tabExpand: `0px -8px 10px -5px ${mainThemeColors.crusoeTransparent}`,
    },
    dimensions: {
      borderRadius: mainThemeDimensions.elevationProfileBorderRadius
    },
  },

  distanceToPoint: {
    colors: {
      textSideControls: mainThemeColors.themeSpruce,
    },
  },

  guidesOptionsPanel: {
    colors: {
      backgroundContainer: mainThemeColors.white,
    },
    dimensions: {
      borderRadius: mainThemeDimensions.optionsPanelBorderRadius,
      collapseItemBorderRadius: mainThemeDimensions.optionsPanelCollapseItemBorderRadius
    }
  },

  mapSettingsModal: {
    colors: {
      backgroundContainer: mainThemeColors.white,

      textSectionTitle: mainThemeColors.gray,
      textValueName: mainThemeColors.gray,
    },
    dimensions: {
      borderRadius: mainThemeDimensions.mapSettingsModalBorderRadius
    }
  },

  pointCard: {
    colors: {
      backgroundContainer: mainThemeColors.superLightGray,
      backgroundSortInfoDivider: mainThemeColors.black,
      backgroundArrowIcon: mainThemeColors.gray,

      borderIcon: mainThemeColors.white,
    },
  },

  pointFeedback: {
    colors: {
      textFeedbackAuthor: mainThemeColors.themeSpruce,
      textFeedbackText: mainThemeColors.black,
      textFeedbackDate: mainThemeColors.smokyGray,

      borderFeedback: mainThemeColors.superLightGray,
    },
  },

  guideUse: {
    colors: {
      backgroundFilterWrapper: mainThemeColors.white,
      backgroundSider: mainThemeColors.white,
      backgroundWrapTab: mainThemeColors.white,
    },
  },

  guideUseMap: {
    colors: {
      textCluster: mainThemeColors.red,
      backgroundCluster: mainThemeColors.wheat,
    },
  },

  roundedPopoverMenu: {
    dimensions: {
      borderRadius: mainThemeDimensions.roundedPopoverMenuBorderRadius
    }
  },

  roundedSubmenuPopup: {
    dimensions: {
      borderRadius: mainThemeDimensions.roundedSubmenuPopupBorderRadius
    }
  }
};
