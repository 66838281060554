import { useSelector } from 'react-redux';
import { getAllTrailsWithChildrenSelector } from '../store/TrailsStore';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getSortedGuideDataSelector } from '../store/GuideUse';
import { useLocation } from 'react-router-dom';

export const useRedirectToStartPageDecorator = () => {
  const history = useHistory();
  const location = useLocation();
  const guideData = useSelector(getSortedGuideDataSelector);
  const allGuidesList = useSelector(getAllTrailsWithChildrenSelector);

  const redirectToStartPage = useCallback(
    <T extends (args?: any) => void>(func: T) => {
      return async (...funcArgs: Parameters<T>) => {
        await func(...funcArgs);
        const pathName = location.pathname.split('/');

        const trailData = allGuidesList.find(
          guide => guide.guideId === guideData.guideId
        );
        if (trailData?.guideIsFree && pathName.includes('geo')) {
          return;
        }

        if (pathName.includes('geo') || pathName.includes('checkout')) {
          history.push('/');
        }
      };
    },
    [history, guideData, allGuidesList, location]
  );

  return {
    withRedirectToStartPage: redirectToStartPage,
  };
};
