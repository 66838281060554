import styled from 'styled-components';
import { Slider as AntSlider } from 'antd';
import { SliderSingleProps, SliderRangeProps } from './Slider.interface';

export const StyledSlider: React.FC = styled(
  (props: SliderSingleProps | SliderRangeProps) => <AntSlider {...props} />
)`
  padding: 0;

  && .ant-slider-rail {
    background-color: ${({ theme }) => theme.slider.colors.rail};
  }

  && .ant-slider-track {
    background-color: ${({ theme }) => theme.slider.colors.track};
  }

  && .ant-slider-handle {
    background-color: ${({ theme }) => theme.slider.colors.handle};
    border-color: ${({ theme }) => theme.slider.colors.handle};
  }
`;
