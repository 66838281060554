import { actions } from 'store/TrailsStore/actions';
import { GuideDto } from 'atlasguides-web-common/src/functions/guides/dto/guide.dto';
import { GetGuideResponse } from 'atlasguides-web-common/src/functions/guides/get-guide';
import { SubscriptionInfo } from 'atlasguides-web-common/src/functions/subscriptions/subscriptions.types';
import { AvailableCountryDto } from 'atlasguides-web-common/src/functions/payment/dto/countries.dto';

export const initialState = {
  isActiveSubscription: null as boolean | null,
  activeSubscriptionInfo: null as SubscriptionInfo | null,
  isFetchingSubscriptionInfo: false as boolean,

  allTrailsList: [] as GuideDto[],
  filteredGuideList: [] as GuideDto[],
  searchedGuideList: [] as GuideDto[],
  availableCountries: [] as AvailableCountryDto[],
  trailGuideView: {} as GetGuideResponse,
  filterParams: {} as any,
  activeSelectedFilters: {} as any,
  isFetchingAllTrailsList: false as boolean,
  isFetchingTrailGuideView: false as boolean,
  selectedTrailGuideId: '' as string,
  isFilteredState: false as boolean,
  isSearchedState: false as boolean,
  searchedValue: '' as string,
  isDetailsDisplaying: false as boolean,
};

export type State = typeof initialState;

export type PropActions<T> = T extends { [key: string]: infer R } ? R : never;
export type ReturnAnyActions<
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<PropActions<T>>;

export type TrailsStoreAnyActions = ReturnAnyActions<typeof actions>;
export const reducer = (
  state: State = initialState,
  action: TrailsStoreAnyActions
): State => {
  switch (action.type) {
    case '@/GET_ALL_TRAILS_LIST_REQUEST':
      return {
        ...state,
        isFetchingAllTrailsList: true,
        allTrailsList: [],
      };
    case '@/GET_ALL_TRAILS_LIST_RESPONSE':
      return {
        ...state,
        isFetchingAllTrailsList: false,
        allTrailsList: action.payload,
      };
    case '@/GET_TRAIL_GUIDE_VIEW_REQUEST':
      return {
        ...state,
        isFetchingTrailGuideView: true,
      };
    case '@/GET_TRAIL_GUIDE_VIEW_RESPONSE':
      return {
        ...state,
        isFetchingTrailGuideView: false,
        trailGuideView: action.payload,
      };
    case '@/GET_ACTIVE_SUBSCRIPTION_REQUEST':
        return {
          ...state,
          isFetchingSubscriptionInfo: true,
          isActiveSubscription: false,
          activeSubscriptionInfo: null,
        };
    case '@/GET_ACTIVE_SUBSCRIPTION_RESPONSE':
        return {
          ...state,
          isFetchingSubscriptionInfo: false,
          activeSubscriptionInfo: action.payload.subscriptionInfo,
          isActiveSubscription: (action.payload.subscriptionInfo != null)
        };
    case '@/SET_SELECTED_TRAIL_GUIDE_ID':
      return {
        ...state,
        selectedTrailGuideId: action.payload,
      };
    case '@/GET_CARD_CHILDREN_BY_ID_RESPONSE':
      return {
        ...state,
        allTrailsList: state.allTrailsList.map(item =>
          item.guideId !== action.payload.id
            ? item
            : { ...item, childGuides: action.payload.childGuides }
        ),
      };
    case '@/GET_GUIDES_FILTER_PARAMS_RESPONSE':
      return {
        ...state,
        filterParams: action.payload,
      };
    case '@/SET_ACTIVE_SELECTED_FILTERS':
      return {
        ...state,
        activeSelectedFilters: action.payload,
      };
    case '@/SET_IS_FILTERED_STATE':
      return {
        ...state,
        isFilteredState: action.payload,
      };
    case '@/SET_IS_SEARCHED_STATE':
      return {
        ...state,
        isSearchedState: action.payload,
      };
    case '@/SET_FILTERED_GUIDES':
      return {
        ...state,
        filteredGuideList: action.payload,
      };
    case '@/SET_SEARCHED_GUIDES':
      return {
        ...state,
        searchedGuideList: action.payload,
      };
    case '@/SET_SEARCHED_VALUE':
      return {
        ...state,
        searchedValue: action.payload,
      };
    case '@/SET_CLEARED_FILTERS':
      return {
        ...state,
        activeSelectedFilters: {},
        filteredGuideList: [],
        isFilteredState: false,
      };
    case '@/SET_AVAILABLE_COUNTRIES':
        return {
          ...state,
          availableCountries: action.payload,
        };
    case '@/SET_IS_DETAILS_DISPLAYING':
      return {
        ...state,
        isDetailsDisplaying: action.payload,
      };
    case '@/SET_GUIDES_AS_FULL_PURCHASED':
      const updatedTrailsList = state.allTrailsList.map(trail => {
        return action.payload.includes(trail.guideId)
          ? {
              ...trail,
              isPurchased: true,
              childGuides: trail?.childGuides?.map(childGuide => ({
                ...childGuide,
                isPurchased: true,
              })),
            }
          : { ...trail };
      });
      return {
        ...state,
        allTrailsList: updatedTrailsList as GuideDto[],
      };

    case '@/SET_GUIDE_AS_FULL_PURCHASED':
      for (const guide of state.allTrailsList){
          if (guide.guideId === action.payload){
            guide.isPurchased = true;
          }
      }
      return {
        ...state,
        allTrailsList: [...state.allTrailsList]
      };

    case '@/CLEAR_ALL_GUIDES_PURCHASED_STATE':
      const unPurchasedTrailsList = state.allTrailsList.map(trail => ({
        ...trail,
        isPurchased: false,
        childGuides: trail.childGuides?.map(guide => ({
          ...guide,
          isPurchased: false,
        })),
      }));
      return {
        ...state,
        isActiveSubscription: false,
        activeSubscriptionInfo: null,
        isFetchingSubscriptionInfo: false,
        allTrailsList: unPurchasedTrailsList,
      };
    default:
      return state;
  }
};
