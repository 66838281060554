import { fetcher } from '../../configure'
import {ShopifyCartDto} from './dto/cart.dto'

export type RemoveShopifyLineItemRequest = {
    shopifyCartId: string
    lineItemId: string
    countryCode: string | null    
}

export type RemoveShopifyLineItemResponse = ShopifyCartDto

export type RemoveShopifyLineItemEndpoint = (
    request: RemoveShopifyLineItemRequest
) => Promise<RemoveShopifyLineItemResponse>

export const removeShopifyLineItem: RemoveShopifyLineItemEndpoint = async (
    request
) => {
  const shopifyCartId = request.shopifyCartId
  console.log("shopifyRemove shopifyCartId: ", shopifyCartId);
  const shopifyDelete = await fetcher({
    method: 'delete',
    path: `/shopify/cart/remove`,
    body: request,
  })
  return shopifyDelete
}
